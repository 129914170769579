import {
    ACESFilmicToneMapping,
    AmbientLight,
    CameraHelper,
    ColorRepresentation,
    DirectionalLight,
    DirectionalLightHelper,
    DoubleSide,
    Group,
    Intersection,
    LineSegments,
    LinearFilter,
    Mesh,
    MeshBasicMaterial,
    MeshStandardMaterial,
    Object3D,
    OrthographicCamera,
    PCFSoftShadowMap,
    PerspectiveCamera,
    PlaneGeometry,
    RGBAFormat,
    Raycaster,
    Scene,
    SpotLightHelper,
    Vector2,
    Vector3,
    WebGLRenderTarget,
    WebGLRenderer,
    sRGBEncoding,
    CubeTextureLoader,
    BoxGeometry,
    TextureLoader,
    Color,
    MirroredRepeatWrapping,
} from "three";
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import Stats from "three/examples/jsm/libs/stats.module";
import {
    AFTER_KEY_MOVE_TIME,
    EDITOR_INIT_OPTIONS,
    PLANE_TYPE_HORIZONTAL,
    PLANE_TYPE_VERTICAL,
    RENDER_TYPE_ANIMATION_FRAME,
    RENDER_TYPE_ANIMATION_LOOP,
} from "../../../constants";
import {IThreeService} from "../../interfaces/IThreeService";
import {CommonObject} from "../../objects/CommonObject/CommonObject";
import {TPlaneType} from "../../types/TPlaneType";
import {AVAILABLE_CANVAS_CURSORS, DEFAULT_CLEAR_COLOR, HISTORY_STATE_TYPE_MOVE} from "../../constants";
import {EffectComposer} from "three/examples/jsm/postprocessing/EffectComposer";
import {RenderPass} from "three/examples/jsm/postprocessing/RenderPass";
import {OutlinePass} from "three/examples/jsm/postprocessing/OutlinePass";
import {ShaderPass} from "three/examples/jsm/postprocessing/ShaderPass";
import {GammaCorrectionShader} from "three/examples/jsm/shaders/GammaCorrectionShader";
import {SSAARenderPass} from "three/examples/jsm/postprocessing/SSAARenderPass";
import {TAARenderPass} from "three/examples/jsm/postprocessing/TAARenderPass";
import {SAOPass} from "three/examples/jsm/postprocessing/SAOPass";
import {IEditorOptions} from "../../../interfaces/IEditorOptions";
import {TScreenPosition} from "../../../types/TScreenPosition";
import {TAspectData} from "../../../../common-code/types/TAspectData";
import {IContextMenu} from "../../../interfaces/IContextMenu";
import {IDetailCuttingData} from "../../interfaces/cutting/IDetailCuttingData";
import {ThreeKUnit} from "../../objects/threeD/units/ThreeKUnit/ThreeKUnit";
import {ThreeBuiltInEquipment} from "../../objects/threeD/equipments/ThreeBuiltInEquipment/ThreeBuiltInEquipment";

// environment map images
import px from "./texturex/envMap/px.jpg";
import nx from "./texturex/envMap/nx.jpg";
import py from "./texturex/envMap/py.jpg";
import ny from "./texturex/envMap/ny.jpg";
import pz from "./texturex/envMap/pz.jpg";
import nz from "./texturex/envMap/nz.jpg";
import {INewMaterialData} from "../../../ui/elements/WizardEdit/FormMaterial/FormMaterial";
import {INewTextureData} from "../../../ui/elements/WizardEdit/FormMaterial/Textures/Textures";
import {IMaterialData} from "common-code/lib/interfaces/materials/IMaterialData";
import {INewPlinthData} from "../../../ui/elements/WizardEdit/FormPlinth/FormPlinth";
import {ThreeUnit} from '../../objects/threeD/ThreeUnit/ThreeUnit';
import {IPositionInfo} from '../../interfaces/IPositionInfo';
import {MathHelper} from 'common-code';
import {IHistoryMoveState} from '../../interfaces/history/IHistoryMoveState';
import {TMousePosition} from '../../../types/TMousePosition';
import {PointerEvent} from 'react';

export class ThreeEditor {
    public readonly CAMERA_FOV: number = 45;
    public readonly CAMERA_NEAR: number = 0.1;
    public readonly CAMERA_FAR: number = 50000;

    protected readonly win: Window | null;
    /**
     * Контейнер для канваса
     */
    public htmlContainer: HTMLDivElement | null;

    /**
     * Флаг готовности
     * @private
     */
    protected ready: boolean;

    /**
     * Editor width
     */
    protected width: number | undefined;

    /**
     * Editor height
     */
    protected height: number | undefined;

    protected options: IEditorOptions;

    protected mousePosition: TMousePosition;

    protected rendering: boolean;

    protected planeVector: Vector3;

    protected commonObjects: { [n: number]: CommonObject };

    protected threeCovers: { [n: string]: Object3D };

    /**
     * Выбираемый объект первый раз
     * @protected
     */
    protected trySelectingCover?: Object3D;

    /**
     * Выбираемый объект второй раз
     * @protected
     */
    protected selectingCover?: Object3D;

    /**
     * Текущий основной выбранный объект
     * @protected
     */
    protected currentCover?: Object3D;

    /**
     * Выбранные объекты
     * @protected
     */
    protected selectedCovers: { [s: string]: Object3D };

    protected horizontalPlane?: Mesh;
    protected verticalPlane?: Mesh;

    protected directionLight?: DirectionalLight;

    protected directionLight2?: DirectionalLight;

    protected directionalLightHelper?: DirectionalLightHelper;

    protected directionalLightCameraHelper?: CameraHelper;

    protected spotLightHelper?: SpotLightHelper;

    protected spotLightHelper2?: SpotLightHelper;

    protected spotLightCameraHelper?: CameraHelper;

    protected spotLightCameraHelper2?: CameraHelper;

    protected ambientLight?: AmbientLight;

    // public GUI = new dat.GUI();
    /**
     * Object Scene class
     */
    protected scene?: Scene;

    /**
     * Camera
     */
    protected camera?: PerspectiveCamera | OrthographicCamera;

    /**
     *
     */
    protected orbitControl?: OrbitControls;

    /**
     * Renderer
     */
    protected renderer?: WebGLRenderer;

    protected raycaster?: Raycaster;

    protected stats?: Stats;

    protected service?: IThreeService;

    protected editorAnimationFrame?: number;

    /**
     * postprocessing
     * @protected
     */
    protected composer?: EffectComposer;
    protected renderPass?: RenderPass;
    protected outlinePass?: OutlinePass;
    protected effectFXAA?: ShaderPass;

    protected screenPosition: Vector2;

    protected cuttingRenderer?: WebGLRenderer;
    protected cuttingScene?: Scene;
    protected directCuttingLight?: DirectionalLight;
    protected cuttingCamera?: OrthographicCamera;
    protected frustumSize?: number;
    protected frustumSizeType: "width" | "height";
    protected disableChangeCursor: boolean;

    onKeyMoveListener: (event: KeyboardEvent) => void;
    afterKeyMoveTimer?: ReturnType<typeof setTimeout>;
    /**
     *
     * @param win
     * @param initOptions
     */
    constructor(win: Window, initOptions: IEditorOptions = EDITOR_INIT_OPTIONS) {
        this.htmlContainer = null;
        this.win = win;
        this.ready = false;
        this.mousePosition = {x: 0, y: 0};
        this.options = initOptions;
        this.rendering = false;
        this.planeVector = new Vector3();
        this.screenPosition = new Vector2();
        this.commonObjects = {};
        this.threeCovers = {};
        this.selectedCovers = {};
        this.frustumSizeType = "width";
        this.disableChangeCursor = false;
        this.onKeyMoveListener = (event: KeyboardEvent) => {
            this.onKeyMoveCurrentObject(event);
        }
    }

    public initState(container: HTMLDivElement | null): void {
        if (!container) {
            throw new Error("RoomEditor needs html Container");
        }
        if (this.ready) {
            return;
        }
        this.htmlContainer = container;
        this.calculateContainerSizes();
        this.mousePosition = {x: 0, y: 0};
        this.initCamera();
        this.initScene();
        this.initRenderer();
        this.initOrbitControl();
        this.initLights();
        this.initPlanes();
        //this.initPostProcessing();
        this.initStats();
        this.ready = true;
    }

    public initStateEDIT(container: HTMLDivElement | null): void {
        if (!container) {
            throw new Error("RoomEditor needs html Container");
        }
        if (this.ready) {
            return;
        }
        this.htmlContainer = container;
        this.calculateContainerSizes();
        this.mousePosition.x = 0;
        this.mousePosition.y = 0;
        this.initCameraEDIT();
        this.initScene();
        this.initRendererEDIT();
        this.initOrbitControlEDIT();
        // this.initPostProcessing();
        this.ready = true;
    }

    public calculateContainerSizes() {
        if (!this.htmlContainer) {
            this.setWidth(0);
            this.setHeight(0);

            return {width: this.getWidth(), height: this.getHeight()};
        }
        this.setWidth(this.htmlContainer.clientWidth);
        this.setHeight(this.htmlContainer.clientHeight);

        return {width: this.getWidth(), height: this.getHeight()};
    }

    public getCanvas(): HTMLCanvasElement {
        if (!this.renderer) {
            throw new Error("error-ThreeEditor-getCanvas");
        }

        return this.renderer.domElement;
    }

    public isDisableChangeCursor(): boolean {
        return this.disableChangeCursor;
    }

    public setDisableChangeCursor(value: boolean) {
        this.disableChangeCursor = value;
    }

    public setCanvasCursor(value: string) {
        if (!this.renderer) {
            return;
        }
        if (!AVAILABLE_CANVAS_CURSORS.includes(value)) {
            console.log('not available canvas cursor', value);
            return;
        }
        this.getCanvas().style.cursor = value;
    }

    public getWin(): Window | null {
        return this.win;
    }

    public trySelect(event: PointerEvent) {
        console.log("editor trySelect");
    }

    public setService(service: IThreeService) {
        this.service = service;
    }

    public onResize(): void {
        if (this.renderer && this.camera) {
            this.calculateContainerSizes();
            this.renderer.setSize(this.getWidth(), this.getHeight());

            if (this.camera instanceof PerspectiveCamera) {
                this.camera.aspect = this.getWidth() / this.getHeight();
            } else {
                //OrthographicCamera
                this.camera.left = -this.getWidth() / 2;
                this.camera.right = this.getWidth() / 2;
                this.camera.top = this.getHeight() / 2;
                this.camera.bottom = -this.getHeight() / 2;
                // this.camera.setViewOffset(this.getWidth(), this.getHeight(), 0, 0, this.getWidth(), this.getHeight());
            }
            this.camera.updateProjectionMatrix();
        }
        if (this.composer) {
            this.composer.setSize(this.getWidth(), this.getHeight());
        }
        if (this.effectFXAA && this.getWidth() > 0 && this.getHeight() > 0) {
            this.effectFXAA.uniforms["resolution"].value.set(
                1 / this.getWidth(),
                1 / this.getHeight()
            );
        }
    }

    public onPointerMove(event: PointerEvent) {
        if (this.renderer) {
            event.preventDefault();
            const rectContainer = this.renderer.domElement.getBoundingClientRect();
            this.mousePosition.x = ((event.clientX - rectContainer.left) / (rectContainer.width)) * 2 - 1;
            this.mousePosition.y = -((event.clientY - rectContainer.top) / (rectContainer.height)) * 2 + 1;
        }

        return true;
    }

    public startRender() {
        this.rendering = true;
        switch (this.options.renderType) {
            case RENDER_TYPE_ANIMATION_FRAME:
                this.editorAnimationFrame = requestAnimationFrame(() =>
                    this.renderStep()
                );
                break;
            case RENDER_TYPE_ANIMATION_LOOP:
                this.renderer?.setAnimationLoop(() => this.renderStep());
                break;
        }
    }

    public stopRender() {
        this.rendering = false;
        switch (this.options.renderType) {
            case RENDER_TYPE_ANIMATION_FRAME:
                this.editorAnimationFrame = undefined;
                break;
            case RENDER_TYPE_ANIMATION_LOOP:
                this.renderer?.setAnimationLoop(null);
                break;
        }
    }

    public isReady() {
        return this.ready;
    }

    public initCuttingViewer() {
        if (this.cuttingRenderer === undefined) {
            this.initCuttingRenderer();
            this.initCuttingScene();
            this.initCuttingCamera();
            this.initCuttingLights();
        }
    }

    public removeCuttingViewer() {
        this.cuttingScene = undefined;
        this.cuttingCamera = undefined;
        this.directCuttingLight = undefined;
        if (this.cuttingRenderer && this.htmlContainer) {
            this.htmlContainer.removeChild(this.cuttingRenderer.domElement);
        }
        this.cuttingRenderer = undefined;
    }

    public clearCuttingScene() {
        if (this.cuttingScene !== undefined) {
            while (this.cuttingScene.children.length > 0) {
                this.cuttingScene.remove(this.cuttingScene.children[0]);
            }
        }
    }

    public clearSceneFromModel() {
        let sceneChildren = this.scene?.children.filter(
            (child) =>
                (child instanceof Group ||
                    child instanceof Mesh ||
                    child instanceof LineSegments) &&
                !(child instanceof CameraHelper)
        );
        sceneChildren?.forEach((child) => this.scene?.remove(child));
    }

    public renderStep(once?: boolean): void {
        if (
            !this.ready ||
            !this.rendering ||
            !this.renderer ||
            !this.camera ||
            !this.orbitControl ||
            !this.scene
        ) {
            return;
        }
        if (this.options.renderType === RENDER_TYPE_ANIMATION_FRAME && !once) {
            this.editorAnimationFrame = requestAnimationFrame(() =>
                this.renderStep()
            );
        }
        this.renderer.setSize(this.getWidth(), this.getHeight());
        // this.renderer.clear();
        this.renderer.render(this.scene, this.camera);
        if (this.stats && this.options.stats) {
            this.stats.update();
        }
        this.updatePlanes();
        this.orbitControl.update();
        if (this.composer) {
            this.composer.setSize(this.getWidth(), this.getHeight());
            this.composer.render();
        }
    }

    public renderCuttingStep(cuttingData: IDetailCuttingData[]) {
        if (!this.cuttingRenderer || !this.cuttingScene || !this.cuttingCamera) {
            return;
        }

        this.setFrustumSizeByCuttingData(cuttingData);
        this.updateCuttingCamera();
        this.cuttingRenderer.render(this.cuttingScene, this.cuttingCamera);
    }

    /**
     * Метод добавляет объект на сцену.
     *
     * @param object
     */

    // Method for WizardEdit
    public addToSceneEDIT(object: CommonObject): void {
        if (!this.scene) {
            return;
        }

        function enableShadows(obj: Object3D) {
            if (obj instanceof Mesh && obj.material instanceof MeshStandardMaterial) {
                obj.castShadow = true;
                obj.receiveShadow = true;
                obj.material.envMapIntensity = 4;
            } else if (obj instanceof Group) {
                obj.children.forEach((child) => {
                    enableShadows(child);
                });
            }
        }

        object.view3d.children.forEach((child) => {
            enableShadows(child);
        });

        // const axes = new AxesHelper(1000);
        // object.view3d.add(axes);

        this.scene?.add(object.view3d);

        // Get position for orbit control target
        const myPromise = new Promise((resolve: (value: Vector3) => void) => {
            resolve(object.view3d.position);
        });

        myPromise.then((value) => {
            this.orbitControl?.target.set(value.x, value.y, value.z);
        });

        this.commonObjects[object.getId()] = object;
        if (object.hasCover) {
            this.scene.add(object.cover);
            this.threeCovers[object.cover.uuid] = object.cover;
        }
    }

    public addFacadeTextureObject(
        {color, emissiveColor, textures}: IMaterialData,
        material2?: IMaterialData
    ): void {
        const geometryA = new BoxGeometry(500, 1000, 30);
        const materialA = this.createMaterial(color, emissiveColor, textures);

        const geometryB = new BoxGeometry(250, 1001, 31);
        const materialB = material2
            ? this.createMaterial(
                material2.color,
                material2.emissiveColor,
                material2.textures
            )
            : new MeshStandardMaterial({
                color: new Color(0xffffff),
                emissive: new Color(0xffffff),
                emissiveIntensity: 0.3,
            });

        const facadeA = new Mesh(geometryA, materialA);
        const facadeB = new Mesh(geometryB, materialB);
        facadeB.position.x = 125.5;

        this.scene?.add(facadeA, facadeB);
    }

    private createMaterial(
        color: string | undefined,
        emissiveColor: string | undefined,
        textures: { type: string; path: string }[] | undefined
    ): MeshStandardMaterial {
        const material = new MeshStandardMaterial({
            color: color ? new Color(color) : new Color(0xffffff),
            emissive: emissiveColor ? new Color(emissiveColor) : new Color(0xffffff),
            emissiveIntensity: 0.3,
        });

        if (textures) {
            textures.forEach(({type, path}) => {
                switch (type) {
                    case "texture":
                        material.map = new TextureLoader().load(path);
                        break;
                    case "normal":
                        material.normalMap = new TextureLoader().load(path);
                        break;
                    case "roughness":
                        material.roughnessMap = new TextureLoader().load(path);
                        break;
                    case "diffuse":
                        material.displacementMap = new TextureLoader().load(path);
                        break;
                }
            });
        }

        return material;
    }


    public addPlinthObject(newMaterial: INewPlinthData): void {
        if (!this.scene) {
            return;
        }

        const {color, emissiveColor, texturesNew, textures, depth, height} = newMaterial;

        const geometry = new BoxGeometry(400 * 3, height * 3, depth * 3);
        const material = new MeshStandardMaterial({
            color: color ? new Color(color) : new Color(0xffffff),
            emissive: emissiveColor ? new Color(emissiveColor) : new Color(0xffffff),
            emissiveIntensity: 0.3,
        });

        if (textures) {
            textures.forEach(({type, path}) => {
                switch (type) {
                    case "texture":
                        material.map = new TextureLoader().load(path);
                        break;
                    case "normal":
                        material.normalMap = new TextureLoader().load(path);
                        break;
                    case "roughness":
                        material.roughnessMap = new TextureLoader().load(path);
                        break;
                    case "diffuse":
                        material.displacementMap = new TextureLoader().load(path);
                        break;
                }
            });
        }

        if (texturesNew) {
            texturesNew.forEach(({type, url}) => {
                if (!url) return
                switch (type) {
                    case "texture":
                        material.map = new TextureLoader().load(url);
                        break;
                    case "normal":
                        material.normalMap = new TextureLoader().load(url);
                        break;
                    case "roughness":
                        material.roughnessMap = new TextureLoader().load(url);
                        break;
                    case "diffuse":
                        material.displacementMap = new TextureLoader().load(url);
                        break;
                }
            });
        }

        const plinth = new Mesh(geometry, material);
        this.scene?.add(plinth);

    }

    public addTextureObject(newMaterial: INewMaterialData): void {
        if (!this.scene) {
            return;
        }

        // все необходимые параметры из newMaterial
        const params = {
            map: "",
            normal: "",
            roughness: "",
            height: "",
            color: "",
            emissiveColor: "",
            repeat: {
                x: 1,
                y: 1,
            },
            repeatX: 1,
            repeatY: 1,
        };

        if (newMaterial.color) params.color = newMaterial.color;
        if (newMaterial.emissiveColor)
            params.emissiveColor = newMaterial.emissiveColor;
        if (newMaterial.textures?.[0] && newMaterial.textures?.[0].path) {
            params.map = newMaterial.textures?.[0].path;
            params.repeat.x = newMaterial.textures?.[0].repeat.x;
            params.repeat.y = newMaterial.textures?.[0].repeat.y;
        }

        if (newMaterial.texturesNew && newMaterial.texturesNew.length > 0) {
            newMaterial.texturesNew.forEach((el: INewTextureData) => {
                if (el.url) {
                    if (el.type === "texture") params.map = el.url;
                    if (el.type === "normal") params.normal = el.url;
                    if (el.type === "roughness") params.roughness = el.url;
                    if (el.type === "diffuse") params.height = el.url;
                    params.repeat.x = el.repeat.x;
                    params.repeat.y = el.repeat.y;
                }
            });
        }

        const {
            map,
            normal,
            roughness,
            height,
            repeatX,
            repeatY,
            color,
            emissiveColor,
        } = params;
        const loadTexture = (source: string | null) => {
            if (source) {
                const texture = new TextureLoader().load(source);
                texture.repeat.set(repeatX, repeatY);
                texture.wrapS = MirroredRepeatWrapping;
                texture.wrapT = MirroredRepeatWrapping;
                return texture;
            }
            return null;
        };

        const textureMap = loadTexture(map);
        const normalMap = loadTexture(normal);
        const roughnessMap = loadTexture(roughness);
        const heightMap = loadTexture(height);

        const geometryPlane = new PlaneGeometry(600, 1000);
        const material = new MeshStandardMaterial({
            side: DoubleSide,
            map: textureMap,
            normalMap: normalMap,
            roughnessMap: roughnessMap,
            displacementMap: heightMap,
            color: color === "" ? new Color(0xffffff) : new Color(color),
            emissive:
                emissiveColor === "" ? new Color(0xffffff) : new Color(emissiveColor),
        });

        const mesh = new Mesh(geometryPlane, material);
        mesh.position.z -= 250;

        const geometryY = new BoxGeometry(30, 1000, 500);
        const geometryX = new BoxGeometry(600, 30, 499.9);

        const cubes = [];
        for (let i = 0; i < 5; i++) {
            const cube = new Mesh(i < 2 ? geometryY : geometryX, material);
            if (i === 0) cube.position.x -= 300;
            if (i === 1) cube.position.x += 300;
            if (i === 2) cube.position.y += 500 - 15.1;
            if (i === 3) cube.position.y -= 500 - 15.1;
            cubes.push(cube);
        }

        const group = new Group();
        group.add(...cubes, mesh);
        group.name = "textureObject";
        this.orbitControl?.target.set(
            group.position.x,
            group.position.y,
            group.position.z
        );
        this.scene.add(group);
    }

    // Standard method
    public addToScene(object: CommonObject): void {
        if (!this.scene) {
            return;
        }


        this.scene.add(object.view3d);

        this.commonObjects[object.getId()] = object;
        if (object.hasCover) {
            this.scene.add(object.cover);
            this.threeCovers[object.cover.uuid] = object.cover;
        }
    }

    public removeFromScene(object: CommonObject): void {
        if (!this.scene) {
            return;
        }
        this.scene.remove(object.view3d);
        delete this.commonObjects[object.getId()];
        if (object.hasCover) {
            delete this.threeCovers[object.cover.uuid];
            this.scene.remove(object.cover);
        }
    }

    public addToSceneObject3D(object: Object3D) {
        if (!this.scene) {
            return;
        }
        this.scene.add(object);
    }

    public removeFromSceneObject3D(object: Object3D) {
        if (!this.scene) {
            return;
        }
        this.scene.remove(object);
    }

    public addToCuttingSceneObject3D(object: Object3D) {
        if (!this.cuttingScene) {
            return;
        }
        this.cuttingScene.add(object);
    }

    public setRayCaster(screenPosition: TScreenPosition) {
        if (this.raycaster && this.camera) {
            this.screenPosition.set(screenPosition.x, screenPosition.y);
            this.raycaster.setFromCamera(this.screenPosition, this.camera);
        }
    }

    public getRayCasterIntersection(objects: Object3D[]): Intersection[] {
        let intersects: Intersection[] = [];

        if (this.raycaster) {
            intersects = this.raycaster.intersectObjects(objects);
        }
        return intersects;
    }

    public getThreeCoversArray(): Object3D[] {
        let selectObjects: Object3D[];

        selectObjects = Object.keys(this.threeCovers).map(
            (k) => this.threeCovers[k]
        );
        selectObjects = selectObjects.concat(this.getBuiltinEquipmentCovers());

        return selectObjects;
    }

    public getThreeCoversWithArrowsArray(): Object3D[] {
        return this.getThreeCoversArray();
    }

    public getBuiltinEquipmentCovers(): Object3D[] {
        let selectObjects: Object3D[] = [];
        let index: string;
        let threeUnit: ThreeKUnit;
        let equipment: ThreeBuiltInEquipment;

        for (index in this.commonObjects) {
            if (this.commonObjects[index] instanceof ThreeKUnit && 'equipments' in this.commonObjects[index]) {
                threeUnit = this.commonObjects[index] as ThreeKUnit;
                if (threeUnit.equipments) {
                    for (equipment of threeUnit.equipments) {
                        selectObjects.push(equipment.selectCover);
                    }
                }
            }
        }

        return selectObjects;
    }

    public hasSelectedCover(cover: Object3D): boolean {
        return !!this.selectedCovers[cover.uuid];
    }

    public hideContextMenu() {
        if (this.service) {
            this.service.hideContextMenu();
        }
    }

    public hideSettingsMenu() {
        if (this.service) {
            this.service.hideSettingsMenu();
        }
    }

    public updateSettingsMenu(object: CommonObject) {
        if (this.service) {
            this.service.updateSettingsMenu(object);
        }
    }

    public clearTrySelectingCover() {
        let commonObject: CommonObject;

        if (this.trySelectingCover) {
            if (
                this.trySelectingCover.userData.commonObject instanceof CommonObject
            ) {
                commonObject = this.trySelectingCover.userData.commonObject;
                commonObject.clearSelect();
            }
        }
        this.trySelectingCover = undefined;
    }

    public clearSelectingCover() {
        let commonObject: CommonObject;

        if (this.selectingCover) {
            if (this.selectingCover.userData.commonObject instanceof CommonObject) {
                commonObject = this.selectingCover.userData.commonObject;
                commonObject.clearSelect();
            }
        }
        this.selectingCover = undefined;
    }

    public clearSelectedCovers() {
        this.selectedCovers = {};
    }

    public clearCurrentCover() {
        this.currentCover = undefined;
    }

    public getSelectedCover(id: string): Object3D | undefined {
        return this.selectedCovers[id];
    }

    public getCanvasSize(ratio?: number) {
        ratio = ratio ?? 1;
        return {
            width: this.getWidth() * ratio,
            height: this.getHeight() * ratio,
        };
    }

    public addSelectedCover(cover: Object3D): void {
        this.selectedCovers[cover.uuid] = cover;
    }

    public initialSelectingCover(cover: Object3D) {
        this.setTrySelectingCover(cover);
        this.setSelectingCover(cover);
    }

    public setTrySelectingCover(cover: Object3D) {
        this.clearTrySelectingCover();
        this.trySelectingCover = cover;
        if (cover.userData.commonObject instanceof CommonObject) {
            cover.userData.commonObject.setSelect();
        }
    }

    public setSelectingCover(cover: Object3D) {
        this.clearSelectingCover();
        this.selectingCover = cover;
        if (cover.userData.commonObject instanceof CommonObject) {
            cover.userData.commonObject.setSelect();
        }
    }

    public setCurrentCover(cover: Object3D) {
        this.currentCover = cover;
        if (!this.hasSelectedCover(cover)) {
            this.addSelectedCover(cover);
        }
    }

    public getCurrentCover(): Object3D | undefined {
        return this.currentCover;
    }

    public getTrySelectingCover(): Object3D | undefined {
        return this.trySelectingCover;
    }

    public getSelectingCover(): Object3D | undefined {
        return this.selectingCover;
    }

    public stopOrbitControl() {
        if (this.orbitControl) {
            this.orbitControl.enabled = false;
            this.orbitControl.update();
        }
    }

    public startOrbitControl() {
        if (this.orbitControl) {
            this.orbitControl.enabled = true;
            this.orbitControl.update();
        }
    }

    public startKeyMoveCurrentCover() {
        this.stopOrbitControl();
        if (window) {
            window.addEventListener('keydown', this.onKeyMoveListener, false);
        }
    }

    public stopKeyMoveCurrentCover() {
        this.startOrbitControl();
        if (window) {
            window.removeEventListener('keydown', this.onKeyMoveListener, false);
        }
    }

    public getCamera(): PerspectiveCamera | OrthographicCamera {
        if (!this.camera) {
            throw new Error("error-ThreeEditor-getCamera");
        }

        return this.camera;
    }

    public getCameraPosition(position?: Vector3): Vector3 {
        if (this.camera) {
            if (!position) {
                position = new Vector3();
            }
            position.copy(this.camera.position);
        }

        return position ? position : new Vector3();
    }

    public getOrbitControlTarget(position?: Vector3): Vector3 {
        if (this.orbitControl) {
            if (!position) {
                position = new Vector3();
            }
            position.copy(this.orbitControl.target);
        }

        return position ? position : new Vector3();
    }

    public getCovers(): { [n: string]: Object3D } {
        return this.threeCovers;
    }

    public onPointUpActions(event: PointerEvent) {
    }

    public onRightClick(event: PointerEvent) {
        let params;

        if (!this.service) {
            return;
        }
        params = this.getContextMenuData(event);
        if (params) {
            this.service.showContextMenu(params);
        }
    }

    public setVerticalPointer() {
        if (this.isDisableChangeCursor()) {
            return;
        }
        this.setCanvasCursor('ns-resize');
    }

    public setHorizontalPointer() {
        if (this.disableChangeCursor) {
            return;
        }
        this.setCanvasCursor('move');
    }

    public setDefaultPointer() {
        if (this.disableChangeCursor) {
            return;
        }
        this.setCanvasCursor('default');
    }

    public getRectContainer(): DOMRect | undefined {
        return this.renderer
            ? this.renderer.domElement.getBoundingClientRect()
            : undefined;
    }

    /**
     * Возвращает позицию мыши на сцене.
     *
     * @param event
     */
    public calculateScreenPosition(event: PointerEvent): TScreenPosition | undefined {
        let rectContainer = this.getRectContainer();

        return rectContainer ?
            {
                x: ((event.clientX - rectContainer.left) / (rectContainer.right - rectContainer.left)) * 2 - 1,
                y: -((event.clientY - rectContainer.top) / (rectContainer.bottom - rectContainer.top)) * 2 + 1
            } : undefined;
    }

    public getMouseCoordinates(screenPosition?: TScreenPosition | undefined) {
        let rectContainer = this.getRectContainer();
        return screenPosition && rectContainer ?
            {
                x: (screenPosition.x + 1) / 2 * (rectContainer.width - rectContainer.left) + rectContainer.left,
                y: -((screenPosition.y - 1)) / 2 * (rectContainer.bottom - rectContainer.top) + rectContainer.top
            } : undefined;
    }

    public showAll() {
        let aspectData;

        aspectData = this.getDefaultAspect();
        if (aspectData) {
            this.setAspect(aspectData);
        }
    }

    public setAspect(aspectData: TAspectData) {
        if (this.orbitControl && this.camera) {
            this.orbitControl.target.set(
                +aspectData.target.x,
                +aspectData.target.y,
                +aspectData.target.z
            );
            this.camera.position.set(
                +aspectData.camera.x,
                +aspectData.camera.y,
                +aspectData.camera.z
            );
        }
    }

    public getDefaultAspect(): TAspectData {
        throw new Error("replace-ThreeEditor-getDefaultAspect");
    }

    public getShowAllAspect(): TAspectData {
        throw new Error("replace-ThreeEditor-getShowAllAspect");
    }

    protected onKeyMoveCurrentObject(event: KeyboardEvent) {
        if (!this.currentCover) {
            return;
        }
        let threeUnit: ThreeUnit | undefined;
        let nextPosition: Vector3;
        let isMove: boolean = false;
        let step: number;
        let directionX: -1 | 1 | undefined;
        let directionZ: -1 | 1 | undefined;
        let axisAngle: number | undefined;
        let oldPositionInfo: IPositionInfo;
        let frontVectorAngle: number;

        if (this.currentCover.userData.commonObject instanceof ThreeUnit) {
            threeUnit = this.currentCover.userData.commonObject;
        }
        if (!threeUnit) {
            return;
        }
        step = event.shiftKey ? 10 : 1;
        nextPosition = threeUnit.getPosition().clone();
        frontVectorAngle = threeUnit.getFrontVectorAngle();
        switch (event.code) {
            case 'ArrowUp':
                if (event.ctrlKey) {
                    nextPosition.y += step;
                } else {
                    directionX = ((frontVectorAngle >= 0 && frontVectorAngle <= Math.PI)) ? -1 : 1;
                    directionZ =  ((frontVectorAngle >= 0 && frontVectorAngle <= Math.PI/2) ||
                        (frontVectorAngle >= Math.PI * 1.5 && frontVectorAngle <= Math.PI * 2)) ? -1 : 1;
                    axisAngle = MathHelper.normalizeAngle((frontVectorAngle + (Math.PI/2)));
                }
                isMove = true;
                break;
            case 'ArrowDown':
                if (event.ctrlKey) {
                    nextPosition.y -= step;
                } else {
                    directionX = ((frontVectorAngle >= Math.PI && frontVectorAngle <= Math.PI * 2)) ? -1 : 1;
                    directionZ =  ((frontVectorAngle >= Math.PI / 2 && frontVectorAngle <= Math.PI * 1.5)) ? -1 : 1;
                    axisAngle = MathHelper.normalizeAngle((frontVectorAngle + (Math.PI/2)));
                }
                isMove = true;
                break;
            case 'ArrowLeft':
                axisAngle = frontVectorAngle;
                directionX = ((frontVectorAngle >= Math.PI * 1.5 && frontVectorAngle <= Math.PI * 2) ||
                    (frontVectorAngle >= 0 && frontVectorAngle <= Math.PI / 2)) ? -1 : 1;
                directionZ = ((frontVectorAngle >= Math.PI && frontVectorAngle <= Math.PI * 2)) ? -1 : 1;
                isMove = true;
                break;
            case 'ArrowRight':
                axisAngle = frontVectorAngle;
                directionX = ((frontVectorAngle >= Math.PI / 2 && frontVectorAngle <= Math.PI * 1.5)) ? -1 : 1;
                directionZ = ((frontVectorAngle >= 0 && frontVectorAngle <= Math.PI)) ? -1 : 1;
                isMove = true;
                break;
        }
        if (isMove) {
            if (directionX !== undefined && directionZ !== undefined && axisAngle !== undefined) {
                nextPosition.x = +(nextPosition.x + Math.abs(Math.cos(axisAngle)) * (step * directionX)).toFixed(10);
                nextPosition.z = +(nextPosition.z + Math.abs(Math.sin(axisAngle)) * (step * directionZ)).toFixed(10);
            }
            oldPositionInfo = threeUnit.getPositionInfo();
            if (threeUnit.trySetPosition({position: nextPosition}, true, false)) {
                if (this.afterKeyMoveTimer) {
                    clearTimeout(this.afterKeyMoveTimer);
                }
                this.afterKeyMoveTimer = setTimeout(() => {
                    if (threeUnit) {
                        this.afterKeyMoveCurrentObject(threeUnit, oldPositionInfo);
                    }
                }, AFTER_KEY_MOVE_TIME);
            }

            event.preventDefault();
            return false;
        }
    }

    protected afterKeyMoveCurrentObject(threeUnit: ThreeUnit, oldPositionInfo: IPositionInfo) {
        threeUnit.afterTryMove(true, true);
        this.service?.rebuildScene();
        this.service?.setHistoryState({
            type: HISTORY_STATE_TYPE_MOVE,
            data: {
                objects: [
                    {
                        objectId: threeUnit.getId(),
                        oldPosition: oldPositionInfo,
                        newPosition: threeUnit.getPositionInfo(true),
                    }
                ]
            }
        } as IHistoryMoveState);
    }

    protected initCuttingRenderer() {
        if (!this.htmlContainer || !this.win) {
            return;
        }
        this.cuttingRenderer = new WebGLRenderer({
            antialias: true,
            preserveDrawingBuffer: true,
            logarithmicDepthBuffer: true,
        });

        this.cuttingRenderer.toneMappingExposure = 3;
        this.cuttingRenderer.setPixelRatio(window.devicePixelRatio);
        this.cuttingRenderer.setClearColor(0xffffff, 1.0);
        this.cuttingRenderer.setSize(this.getWidth(), this.getHeight());
        this.cuttingRenderer.domElement.className = "Wizard-CuttingCanvas";
        this.htmlContainer.appendChild(this.cuttingRenderer.domElement);
    }

    protected initCuttingScene() {
        this.cuttingScene = new Scene();
    }

    protected initCuttingCamera() {
        if (!this.htmlContainer || !this.win || !this.getWidth()) {
            return;
        }
        let aspect;

        aspect = this.getHeight() / this.getWidth();
        this.frustumSize = 5000;
        this.frustumSizeType = "width";
        this.cuttingCamera = new OrthographicCamera(
            -this.frustumSize / 2,
            this.frustumSize / 2,
            (this.frustumSize * aspect) / 2,
            (this.frustumSize * aspect) / -2,
            1,
            20000
        );
        this.cuttingCamera.position.x = 0;
        this.cuttingCamera.position.y = 0;
        this.cuttingCamera.position.z = this.frustumSize;
    }

    protected initCuttingLights() {
        if (!this.cuttingScene) {
            return;
        }
        this.directCuttingLight = new DirectionalLight(0xffffff, 1);
        this.directCuttingLight.position.set(1, 1, 1).normalize();
        this.cuttingScene.add(this.directCuttingLight);
    }

    protected setFrustumSizeByCuttingData(cuttingData: IDetailCuttingData[]) {
        let maxWidth: number;
        let maxHeight: number;
        let lineWidth: number;
        let cuttingDataItem: IDetailCuttingData;
        let gap: number = 170;
        let index: number = 1;
        let aspect: number;

        if (cuttingData.length <= 0 || !this.getHeight()) {
            this.frustumSize = 5000;
            this.frustumSizeType = "width";
            return;
        }
        maxWidth = -Infinity;
        maxHeight = -Infinity;
        lineWidth = 0;
        for (cuttingDataItem of cuttingData) {
            if (index === 1) {
                maxWidth = cuttingDataItem.sizes.length;
            }
            if (index % 2 === 0) {
                lineWidth =
                    cuttingDataItem.sizes.length > lineWidth
                        ? cuttingDataItem.sizes.length * 2
                        : lineWidth * 2;
                if (maxWidth < lineWidth) {
                    maxWidth = Math.floor(lineWidth);
                }
                lineWidth = 0;
            } else {
                lineWidth = cuttingDataItem.sizes.length;
            }

            if (maxHeight < cuttingDataItem.sizes.width) {
                maxHeight = Math.floor(cuttingDataItem.sizes.width);
            }
            index++;
        }

        if (maxWidth > 0 && maxHeight > 0) {
            maxWidth = maxWidth + gap * 4;
            aspect = this.getWidth() / this.getHeight();
            maxHeight =
                (maxHeight + gap) * Math.ceil(cuttingData.length / 2) + gap * 4;
            this.frustumSizeType =
                maxWidth / maxHeight >= aspect ? "width" : "height";
            this.frustumSize = maxWidth / maxHeight >= aspect ? maxWidth : maxHeight;
        } else {
            console.error("error!! setFrustumSize");
            this.frustumSize = 5000;
            this.frustumSizeType = "width";
        }
    }

    protected updateCuttingCamera() {
        let aspect;

        if (
            !this.cuttingCamera ||
            !this.frustumSize ||
            !this.getWidth() ||
            !this.getHeight()
        ) {
            return;
        }
        this.cuttingCamera.position.z = this.frustumSize / 2;
        if (this.frustumSizeType === "height") {
            aspect = this.getWidth() / this.getHeight();
            this.cuttingCamera.left = (-this.frustumSize * aspect) / 2;
            this.cuttingCamera.right = (this.frustumSize * aspect) / 2;
            this.cuttingCamera.top = this.frustumSize / 2;
            this.cuttingCamera.bottom = -this.frustumSize / 2;
        } else {
            aspect = this.getHeight() / this.getWidth();
            this.cuttingCamera.left = -this.frustumSize / 2;
            this.cuttingCamera.right = this.frustumSize / 2;
            this.cuttingCamera.top = (this.frustumSize * aspect) / 2;
            this.cuttingCamera.bottom = (-this.frustumSize * aspect) / 2;
        }
        this.cuttingCamera.updateProjectionMatrix();
    }

    protected getContextMenuData(event: PointerEvent): IContextMenu | undefined {
        let currentCommonObject: CommonObject;
        let selectCommonObject: CommonObject;
        let intersects: Intersection[];
        let index: number;
        let screenPosition: TScreenPosition | undefined;

        screenPosition = this.calculateScreenPosition(event);
        if (
            screenPosition &&
            this.currentCover &&
            this.currentCover.userData.commonObject instanceof CommonObject
        ) {
            currentCommonObject = this.currentCover.userData.commonObject;
            this.setRayCaster(screenPosition);
            intersects = this.getRayCasterIntersection(this.getThreeCoversArray());
            if (intersects.length > 0) {
                for (index = 0; index < intersects.length; index++) {
                    if (
                        intersects[index].object &&
                        intersects[index].object.userData.commonObject instanceof
                        CommonObject
                    ) {
                        selectCommonObject = intersects[index].object.userData.commonObject;
                        if (selectCommonObject.getId() === currentCommonObject.getId()) {
                            return {
                                visible: true,
                                position: {x: event.clientX, y: event.clientY},
                                icons: currentCommonObject
                                    .getContextIcons()
                                    .sort((a, b) => a.sort - b.sort),
                            };
                        }
                    }
                }
            }
        }

        return undefined;
    }

    protected createPlane(type: TPlaneType): Mesh {
        let plane: Mesh;
        let width: number;

        width = 1000000;
        if (!this.orbitControl) {
            throw new Error("error-ThreeEditor-createPlane");
        }
        plane = new Mesh(
            new PlaneGeometry(width, width),
            new MeshBasicMaterial({
                side: DoubleSide,
                visible: false,
                // transparent: true,
                // opacity: 0.2,
                // color: type === PLANE_TYPE_VERTICAL ? '#ff0000' : '#00ff00'
            })
        );
        plane.position.copy(this.orbitControl.target);
        switch (type) {
            case PLANE_TYPE_HORIZONTAL:
                plane.rotateX(-0.5 * Math.PI);
                plane.userData.type = PLANE_TYPE_HORIZONTAL;
                plane.position.y = 0;
                break;
            case PLANE_TYPE_VERTICAL:
                plane.userData.type = PLANE_TYPE_VERTICAL;
                break;
        }

        return plane;
    }

    protected updatePlanes() {
        if (!this.orbitControl || !this.camera) {
            return;
        }
        if (this.verticalPlane) {
            this.planeVector.copy(this.camera.position);
            // this.planeVector.y = 0;
            this.verticalPlane.lookAt(this.planeVector);
            this.verticalPlane.position.copy(this.orbitControl.target);
        }
        if (this.horizontalPlane) {
            if (
                this.trySelectingCover &&
                this.trySelectingCover.userData.commonObject instanceof CommonObject
            ) {
                this.horizontalPlane.position.copy(
                    this.trySelectingCover.userData.commonObject.view3d.position
                );
            } else {
                this.horizontalPlane.position.copy(this.orbitControl.target);
            }
        }
    }

    /**
     * Set this.width parameter
     *
     * @param size
     * @protected
     */
    protected setWidth(size?: number): void {
        this.width = size ? size : 0;
    }

    /**
     * Set this.height parameter
     *
     * @param size
     * @protected
     */
    protected setHeight(size?: number): void {
        this.height = size ? size : 0;
    }

    /**
     * Get this.width parameter value
     *
     * @private
     */
    public getWidth(): number {
        if (this.width) {
            return this.width;
        }

        return 0;
    }

    /**
     * Get this.height parameter value
     *
     * @private
     */
    public getHeight(): number {
        if (this.height) {
            return this.height;
        }

        return 0;
    }

    public getPlanes(): Object3D[] {
        let planes: Object3D[] = [];

        if (this.horizontalPlane) {
            planes.push(this.horizontalPlane);
        }
        if (this.verticalPlane) {
            planes.push(this.verticalPlane);
        }
        if (this.service) {
            this.service.setRoomPlanes(planes);
        }

        return planes;
    }

    public getVerticalPlane(): Object3D | undefined {
        return this.verticalPlane;
    }

    public getOrbitControl(): OrbitControls | undefined {
        return this.orbitControl;
    }

    public addErrorSelectedMesh(mesh: Mesh) {
        if (this.outlinePass) {
            this.outlinePass.selectedObjects.push(mesh);
        }
    }

    public clearErrorSelectedMeshes() {
        if (this.outlinePass) {
            this.outlinePass.selectedObjects = [];
        }
    }

    public setClearColor(color: ColorRepresentation, alpha: number = 1.0) {
        this.renderer?.setClearColor(color, alpha);
    }

    protected initCamera(): void {
        if (!this.getWidth() || !this.getHeight()) {
            return;
        }
        this.camera = new PerspectiveCamera(
            this.CAMERA_FOV,
            this.getWidth() / this.getHeight(),
            this.CAMERA_NEAR,
            this.CAMERA_FAR
        );
    }

    protected initCameraEDIT(): void {
        if (!this.getWidth() || !this.getHeight()) {
            return;
        }
        this.camera = new PerspectiveCamera(
            this.CAMERA_FOV,
            this.getWidth() / this.getHeight(),
            this.CAMERA_NEAR,
            this.CAMERA_FAR
        );
        this.camera.position.set(450, 1019, 2412.903);
    }

    protected initScene() {
        this.scene = new Scene();
        this.raycaster = new Raycaster();
    }

  protected initRenderer() {
    if (!this.htmlContainer || !this.win) {
        return null;
    }
    this.renderer = new WebGLRenderer({
        antialias: true,
        logarithmicDepthBuffer: true,
        preserveDrawingBuffer: true,
    });

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = PCFSoftShadowMap;
    this.renderer.shadowMap.autoUpdate = true;

    this.renderer.setPixelRatio(this.win.devicePixelRatio);
    this.renderer.setClearColor(DEFAULT_CLEAR_COLOR, 1.0);
    this.renderer.setSize(this.getWidth(), this.getHeight());
    this.renderer.domElement.className = this.options.canvasClass || 'Wizard-Canvas';
    this.htmlContainer.appendChild(this.renderer.domElement);
    return this.renderer;
};

    protected initRendererEDIT() {
        console.log("initRendererEDIT");

        if (!this.htmlContainer || !this.win) {
            return null;
        }

        this.renderer = new WebGLRenderer({
            antialias: true,
            logarithmicDepthBuffer: true,
            preserveDrawingBuffer: true,
            // test using
            powerPreference: "high-performance",
            stencil: true,
            alpha: true,
        });

        // this.renderer.outputEncoding = sRGBEncoding;
        this.renderer.toneMapping = ACESFilmicToneMapping;
        this.renderer.toneMappingExposure = 0.3;
        this.renderer.setClearColor(DEFAULT_CLEAR_COLOR, 1.0);
        this.renderer.setSize(this.getWidth(), this.getHeight());
        // enable shadows
        this.renderer.shadowMap.enabled = true;
        this.renderer.shadowMap.type = PCFSoftShadowMap;
        this.renderer.shadowMap.autoUpdate = true;

        this.renderer.setPixelRatio(this.win.devicePixelRatio);
        this.renderer.domElement.className =
            this.options.canvasClass || "Wizard-Canvas";
        this.htmlContainer.appendChild(this.renderer.domElement);

        if (this.scene) {
            // Additional light for shadows
            const directionalLight = new DirectionalLight("#FFFFFF", 2.5);
            directionalLight.position.set(700, 950, 1050);
            directionalLight.castShadow = true;
            directionalLight.shadow.normalBias = 10;
            directionalLight.shadow.mapSize.set(1024, 1024);
            directionalLight.shadow.camera.far = 2000;
            directionalLight.shadow.camera.left = -500;
            directionalLight.shadow.camera.bottom = -500;
            directionalLight.shadow.camera.right = 500;
            directionalLight.shadow.camera.top = 2000;
            directionalLight.shadow.mapSize.set(2048, 2048);

            // Directional light helpers
            const directionalLightHelper = new DirectionalLightHelper(
                directionalLight,
                100,
                "#9F7DE9"
            );
            directionalLightHelper.visible = false;

            const directionalLightCameraHelper = new CameraHelper(
                directionalLight.shadow.camera
            );
            directionalLightCameraHelper.visible = false;

            this.scene.add(
                directionalLight,
                directionalLightCameraHelper,
                directionalLightHelper
            );
        }

        // Environment map
        const cubeTextureLoadre = new CubeTextureLoader();
        const environmentMap = cubeTextureLoadre.load([px, nx, py, ny, pz, nz]);
        if (this.scene) {
            this.scene.environment = environmentMap;
        }
        return this.renderer;
    }

    public resizeCanvas() {
        this.onResize();
    }

    protected initOrbitControl(): void {
        if (!this.camera || !this.renderer) {
            return;
        }
        this.orbitControl = new OrbitControls(
            this.camera,
            this.renderer.domElement
        );
        this.orbitControl.keys = {
            LEFT: "ArrowLeft", //left arrow
            UP: "ArrowUp", // up arrow
            RIGHT: "ArrowRight", // right arrow
            BOTTOM: "ArrowDown", // down arrow
        };
        this.orbitControl.target.set(0, 0, 0);
        this.orbitControl.keyPanSpeed = 14;
        this.orbitControl.listenToKeyEvents(window);
    }

    protected initOrbitControlEDIT(): void {
        if (!this.camera || !this.renderer) {
            return;
        }
        this.orbitControl = new OrbitControls(
            this.camera,
            this.renderer.domElement
        );
        this.orbitControl.enableDamping = true;
    }

    protected initLights() {
        if (!this.scene) {
            return null;
        }
        this.ambientLight = new AmbientLight("#ffffff", 0.9);
        this.scene.add(this.ambientLight);
        this.directionLight = new DirectionalLight(0xffffff, 0.5);
        this.directionLight.position.set(0.5, 0.5, 0.8).normalize();
        this.scene.add(this.directionLight);
    }

    protected initPlanes() {
        if (!this.orbitControl) {
            return;
        }

        // this.verticalPlane = this.createPlane(PLANE_TYPE_VERTICAL);
        // this.addToSceneObject3D(this.verticalPlane);
        this.horizontalPlane = this.createPlane(PLANE_TYPE_HORIZONTAL);
        this.addToSceneObject3D(this.horizontalPlane);
    }

    public getSceneChildren(): Object3D[] {
        if (!this.scene) {
            return [];
        }
        return this.scene.children;
    }

    public initPostProcessing() {
        console.log("Post Processing Start");

        if (!this.renderer || !this.scene || !this.camera || !this.win) {
            return;
        }

        const renderTarget = new WebGLRenderTarget(
            this.getWidth(),
            this.getHeight(),
            {
                magFilter: LinearFilter,
                minFilter: LinearFilter,
                format: RGBAFormat,
                encoding: sRGBEncoding,
            }
        );

        this.composer = new EffectComposer(this.renderer, renderTarget);
        this.composer.setPixelRatio(1);
        this.composer.setSize(this.getWidth(), this.getHeight());
        this.renderPass = new RenderPass(this.scene, this.camera);
        this.composer.addPass(this.renderPass);

        // ssaa
        const ssaaRenderPass = new SSAARenderPass(this.scene, this.camera);
        ssaaRenderPass.unbiased = true;
        ssaaRenderPass.sampleLevel = 5;
        ssaaRenderPass.renderToScreen = true;

        // taa
        const taaRenderPass = new TAARenderPass(
            this.scene,
            this.camera,
            "#ffffff",
            0.5
        );
        taaRenderPass.unbiased = false;
        taaRenderPass.renderToScreen = true;
        taaRenderPass.clearAlpha = 0.5;
        taaRenderPass.sampleLevel = 3;
        // sao
        const sao = new SAOPass(this.scene, this.camera, true, true);
        sao.resolution.set(2048, 2048);
        sao.params.saoBias = 0.16;
        sao.params.saoIntensity = 0.00014;
        sao.params.saoScale = 10;
        sao.params.saoKernelRadius = 43;
        sao.params.saoBlurRadius = 10;
        sao.params.saoMinResolution = 0;

        // gamma correction
        const gammaCorrectionPass = new ShaderPass(GammaCorrectionShader);
        gammaCorrectionPass.renderToScreen = true;

        // this.composer.addPass( ssaaRenderPass );
        this.composer.addPass(taaRenderPass);

        // this.composer.addPass( sao );
        this.composer.addPass(gammaCorrectionPass);
    }

    protected initStats() {
        if (this.htmlContainer && this.options.stats) {
            this.stats = new Stats();
            this.htmlContainer.appendChild(this.stats.dom);
            this.setStatsPosition();
        }
    }

    protected setStatsPosition() {
        if (this.stats) {
            this.stats.dom.style.position = "absolute";
            this.stats.dom.style.right = "0px";
            this.stats.dom.style.left = "auto";
        }
    }
}

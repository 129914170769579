import {useEffect} from "react";
import {IPointerEvents} from "../interfaces/IPointerEvents";
import {PointerEvent} from 'react';

const usePointerEvent = (effects: IPointerEvents, deps: any[], target?: HTMLElement | null) => {
    const targetElement = target !== undefined ? (target !== null ? target : null) : window;

    useEffect(() => {
        if (effects.downEffect && targetElement) {
            targetElement.addEventListener('pointerdown', onPointerDown, false);
        }
        if (effects.moveEffect && targetElement) {
            targetElement.addEventListener('pointermove', onPointerMove, false);
        }
        if (effects.upEffect && targetElement) {
            targetElement.addEventListener('pointerup', onPointerUp, false);
        }

        return () => {
            if (effects.downEffect && targetElement) {
                targetElement.removeEventListener('pointerdown', onPointerDown, false);
            }
            if (effects.moveEffect && targetElement) {
                targetElement.removeEventListener('pointermove', onPointerMove, false);
            }
            if (effects.upEffect && targetElement) {
                targetElement.removeEventListener('pointerup', onPointerUp, false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetElement, ...deps]);

    const onPointerDown = (event: Event) => {
        if (effects.downEffect) {
            effects.downEffect(event as unknown as PointerEvent);
        }
    }

    const onPointerMove = (event: Event) => {
        if (effects.moveEffect) {
            effects.moveEffect(event as unknown as PointerEvent);
        }
    }

    const onPointerUp = (event: Event) => {
        if (effects.upEffect) {
            effects.upEffect(event as unknown as PointerEvent);
        }
    }
}

export default usePointerEvent;
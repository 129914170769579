import { ThreeBottomUnit } from "../ThreeBottomUnit";
import { KitchenService } from "../../../../../services/KitchenService/KitchenService";
import { ThreeTabletop } from "../../../details/ThreeTabletop/ThreeTabletop";
import { Box3, Euler, Vector3 } from "three";
import { ThreeBottomAngleCubeCorpus } from "../../../details/ThreeCorpus/types/ThreeBottomAngleCubeCorpus";
import { TTabletopsCalculateData } from "../../../../../types/TTabletopsCalculateData";
import { TPoint2D } from "../../../../../../../common-code/types/TPoint2D";
import {
  FACADE_CELL_DEFAULT,
  SIDE_TYPE_FRONT,
  SIDE_TYPE_LEFT,
  SIDE_TYPE_NONE,
  SIDE_TYPE_RIGHT,
  UNIT_PLINTHS_TYPE_BACK_NEIGHBOR,
  UNIT_PLINTHS_TYPE_DEFAULT,
} from "../../../../../../../common-code/constants";
import { TPoint3D } from "../../../../../../../common-code/types/TPoint3D";
import { ThreeFacade } from "../../../details/ThreeFacade/ThreeFacade";
import { ISaveBottomUnitAngleCubeData } from "../../../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleCubeData";
import { ISaveBottomAngleCubeCorpusData } from "../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleCubeCorpusData";
import { CommonHelper } from "common-code";
import { ISaveKUnitDetailData } from "../../../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData";
import { MathHelper } from "common-code";

export class ThreeBottomUnitAngleCube extends ThreeBottomUnit {
  saveData: ISaveBottomUnitAngleCubeData;
  corpus: ThreeBottomAngleCubeCorpus;

  constructor(options: ISaveBottomUnitAngleCubeData, service: KitchenService) {
    super(options, service);
    this.saveData = options;
    this.corpus = this.initCorpus(options.corpus);
  }

  public isAngleUnit(): boolean {
    return true;
  }

  public getInitTabletopPosition(tabletop: ThreeTabletop): Vector3 {
    let position: Vector3;
    let coverBox: Box3;

    coverBox = this.getCoverBox(0);
    position = new Vector3(
      (coverBox.min.x + coverBox.max.x) / 2,
      coverBox.max.y + tabletop.getHeight() / 2,
      this.getZInitTabletopPosition(tabletop)
    );

    return position;
  }

  protected initCorpus(
    corpusData: ISaveBottomAngleCubeCorpusData
  ): ThreeBottomAngleCubeCorpus {
    return new ThreeBottomAngleCubeCorpus(
      CommonHelper.deepCopy(corpusData),
      this
    );
  }

  protected initTabletopsData(
    tabletops?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData[] {
    let calculateTabletop;

    if (this.saveData.oneTabletop) {
      return [this.getOneTabletop(tabletops)];
    }
    calculateTabletop = this.calculateTabletopsData();

    return [
      this.getMainTabletop(calculateTabletop, tabletops),
      this.getSecondTabletop(calculateTabletop, tabletops),
    ];
  }

  protected getOneTabletopLeftPath(): TPoint2D[] {
    return [
      { x: 0, y: -this.getDepth() / 2 },
      { x: 0, y: this.getDepth() / 2 },
    ];
  }

  protected getOneTabletopRightPath(): TPoint2D[] {
    return [
      { x: Math.abs(this.getDepth() - 600), y: -this.getDepth() / 2 },
      { x: Math.abs(this.getDepth() - 600), y: -this.getDepth() / 2 + 600 },
      { x: 0, y: this.getDepth() / 2 },
    ];
  }

  protected getOneTabletop(
    tabletops?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData {
    let initTabletopData: ISaveKUnitDetailData | undefined;

    if (tabletops && tabletops[0]) {
      initTabletopData = tabletops[0];
    }
    return {
      id: initTabletopData ? initTabletopData.id : 0,
      sizes: {
        length: this.getWidth(),
        width: this.getDepth(),
        height: this.service.getTabletopHeight(),
      },
      leftPoints: this.getOneTabletopLeftPath(),
      rightPoints: this.getOneTabletopRightPath(),
      position: {
        x: -this.getWidth() / 2 + this.corpus.getWidth() / 2,
        y: this.corpus.getHeight() / 2 + this.service.getTabletopHeight() / 2,
        z: -this.getDepth() / 2 + this.corpus.getDepth() / 2,
      },
      functionalType: initTabletopData
        ? initTabletopData.functionalType
        : undefined,
      groupId: initTabletopData ? initTabletopData.groupId : undefined,
      calculateSizes: initTabletopData
        ? initTabletopData.calculateSizes
        : undefined,
      isCalculate: initTabletopData ? initTabletopData.isCalculate : undefined,
      priceType: initTabletopData ? initTabletopData.priceType : undefined,
    };
  }

  protected getMainTabletop(
    calculateTabletop: TTabletopsCalculateData,
    tabletops?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData {
    let initTabletopData: ISaveKUnitDetailData | undefined;

    if (tabletops && tabletops[0]) {
      initTabletopData = tabletops[0];
    }
    return {
      id: initTabletopData ? initTabletopData.id : 0,
      sizes: {
        length: calculateTabletop.main.length,
        width: calculateTabletop.main.width,
        height: calculateTabletop.main.height,
      },
      leftPoints: this.getMainTabletopPath(calculateTabletop),
      rightPoints: this.getMainTabletopPath(calculateTabletop),
      position: {
        x: calculateTabletop.main.pointCenter.x,
        y: this.corpus.getHeight() / 2 + calculateTabletop.main.height / 2,
        z: calculateTabletop.main.pointCenter.y,
      },
      rotation: { y: 0.25 * Math.PI },
      functionalType: initTabletopData
        ? initTabletopData.functionalType
        : undefined,
      groupId: initTabletopData ? initTabletopData.groupId : undefined,
      calculateSizes: initTabletopData
        ? initTabletopData.calculateSizes
        : undefined,
      isCalculate: initTabletopData ? initTabletopData.isCalculate : undefined,
      priceType: initTabletopData ? initTabletopData.priceType : undefined,
    };
  }

  protected getMainTabletopPath(
    calculateTabletop: TTabletopsCalculateData
  ): TPoint2D[] {
    let points = [],
      minGap;

    minGap =
      calculateTabletop.main.bottomGap < calculateTabletop.main.topGap
        ? calculateTabletop.main.bottomGap
        : calculateTabletop.main.topGap;
    points.push({
      x: calculateTabletop.main.bottomGap - minGap,
      y: -calculateTabletop.main.width / 2,
    });
    points.push({
      x: calculateTabletop.main.middleGap - minGap,
      y:
        -calculateTabletop.main.width / 2 + calculateTabletop.main.middleHeight,
    });
    points.push({
      x: calculateTabletop.main.topGap - minGap,
      y: calculateTabletop.main.width / 2,
    });

    return points;
  }

  protected getSecondTabletopPath(
    calculateTabletop: TTabletopsCalculateData
  ): TPoint2D[] {
    let points = [];

    points.push({ x: 0, y: -calculateTabletop.second.width / 2 });
    points.push({
      x: calculateTabletop.second.length / 2,
      y: calculateTabletop.second.width / 2,
    });

    return points;
  }

  protected getSecondTabletop(
    calculateTabletop: TTabletopsCalculateData,
    tabletops?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData {
    let initTabletopData: ISaveKUnitDetailData | undefined;

    if (tabletops && tabletops[1]) {
      initTabletopData = tabletops[1];
    }
    return {
      id: initTabletopData ? initTabletopData.id : 0,
      sizes: {
        length: calculateTabletop.second.length,
        width: calculateTabletop.second.width,
        height: calculateTabletop.second.height,
      },
      leftPoints: this.getSecondTabletopPath(calculateTabletop),
      rightPoints: this.getSecondTabletopPath(calculateTabletop),
      position: {
        x: calculateTabletop.second.pointCenter.x,
        y: this.corpus.getHeight() / 2 + calculateTabletop.second.height / 2,
        z: calculateTabletop.second.pointCenter.y,
      },
      rotation: { y: 0.25 * Math.PI },
      functionalType: initTabletopData
        ? initTabletopData.functionalType
        : undefined,
      groupId: initTabletopData ? initTabletopData.groupId : undefined,
      calculateSizes: initTabletopData
        ? initTabletopData.calculateSizes
        : undefined,
      isCalculate: initTabletopData ? initTabletopData.isCalculate : undefined,
      priceType: initTabletopData ? initTabletopData.priceType : undefined,
    };
  }

  public getBackGap() {
    return this.getDepth() - this.corpus.getDepth();
  }

  protected calculateFacadePlinthLength() {
    let length;

    length = Math.sqrt(
      Math.pow(this.corpus.getWidth() - this.corpus.getSideDepth(), 2) * 2
    );

    return length;
  }

  protected calculateFacadePlinthPosition(): TPoint3D {
    let catheter, hypotenuse, position: TPoint3D;

    hypotenuse =
      this.getWidth() - this.corpus.getSideDepth() - this.getBackGap();
    catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2);
    hypotenuse = catheter + this.service.getPlinthDepth() / 2;
    catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2);
    position = {
      x: this.corpus.getWidth() / 2 - catheter,
      y: -this.corpus.getHeight() / 2 - this.service.getPlinthHeight() / 2,
      z: this.corpus.getDepth() / 2 - catheter,
    };

    return position;
  }

  protected calculateInitPlinthsData(
    plinths?: ISaveKUnitDetailData[]
  ): ISaveKUnitDetailData[] | undefined {
    let index;
    let length: number;

    if (plinths) {
      for (index in plinths) {
        switch (plinths[index].positionType) {
          case SIDE_TYPE_LEFT:
            break;
          case SIDE_TYPE_RIGHT:
            switch (this.getPlinthsType()) {
              case UNIT_PLINTHS_TYPE_BACK_NEIGHBOR:
                plinths[index].sizes = {
                  length: this.corpus.getSideDepth() + this.getBackGap(),
                };
                break;
              case UNIT_PLINTHS_TYPE_DEFAULT:
                plinths[index].sizes = { length: this.corpus.getSideDepth() };
                break;
            }
            break;
          case SIDE_TYPE_FRONT:
            switch (this.getPlinthsType()) {
              case UNIT_PLINTHS_TYPE_BACK_NEIGHBOR:
                length = this.corpus.getSideDepth() + this.getBackGap();
                plinths[index].sizes = { length: length };
                plinths[index].position = {
                  x: -this.getWidth() / 2 - this.getBackGap() / 2 + length / 2,
                };
                break;
              case UNIT_PLINTHS_TYPE_DEFAULT:
                length = this.corpus.getSideDepth();
                plinths[index].sizes = { length: length };
                plinths[index].position = {
                  x: -this.corpus.getWidth() / 2 + length / 2,
                };
                break;
            }
            break;
          case SIDE_TYPE_NONE:
            plinths[index].sizes = {
              length: this.calculateFacadePlinthLength(),
            };
            plinths[index].position = this.calculateFacadePlinthPosition();
            // разворот плинтуса вдоль гипотенузы переднего треугольника
            plinths[index].rotation = { y: 0.25 * Math.PI };

            break;
        }
      }
    }

    return plinths;
  }

  protected calculateTabletopsData(): TTabletopsCalculateData {
    let result,
      tabletopDepth,
      pointA,
      pointB,
      offsetPoints,
      halfOffsetPoints,
      pointCenter;
    let backWidth: number, backDepth: number;
    let frontWidth: number, frontDepth: number;

    backWidth = -this.corpus.getWidth() / 2 - this.getBackGap();
    backDepth = -this.corpus.getDepth() / 2 - this.getBackGap();
    frontWidth = this.corpus.getWidth() / 2;
    frontDepth = this.corpus.getDepth() / 2;
    tabletopDepth = this.service.getTabletopWidth();
    result = {
      main: {
        height: this.service.getTabletopHeight(),
        length: Math.sqrt(Math.pow(this.getWidth(), 2) * 2),
        width: tabletopDepth,
        middleGap: Math.sqrt(Math.pow(this.getWidth(), 2) * 2) / 2,
        topGap: 0,
        bottomGap: Math.sqrt(Math.pow(this.getWidth(), 2) * 2) / 2,
        middleHeight: 0,
        pointCenter: { x: 0, y: 0 },
      },
      second: {
        height: this.service.getTabletopHeight(),
        width: 0,
        length: 0,
        pointCenter: { x: 0, y: 0 },
      },
    };
    pointA = {
      x: backWidth + tabletopDepth,
      y: frontDepth,
    };
    pointB = {
      x: frontWidth,
      y: backDepth + tabletopDepth,
    };
    offsetPoints = MathHelper.getParallelLinePoints(
      pointA,
      pointB,
      tabletopDepth
    );
    halfOffsetPoints = MathHelper.getParallelLinePoints(
      pointA,
      pointB,
      tabletopDepth / 2
    );
    result.main.topGap = MathHelper.getLength2D(pointA, pointB) / 2;
    pointA = MathHelper.getIntersectionPoint(
      {
        pointA: { x: backWidth, y: backDepth },
        pointB: { x: frontWidth, y: frontDepth },
      },
      offsetPoints
    );
    pointB = MathHelper.getIntersectionPoint(
      {
        pointA: { x: backWidth, y: backDepth },
        pointB: { x: backWidth, y: frontDepth },
      },
      offsetPoints
    );
    if (pointA && pointB) {
      result.main.bottomGap = MathHelper.getLength2D(pointA, pointB);
      result.second.width = MathHelper.getLength2D(pointA, {
        x: backWidth,
        y: backDepth,
      });
      result.second.pointCenter = MathHelper.getPointByRatio2D(
        pointA,
        { x: backWidth, y: backDepth },
        0.5
      );
    }
    pointA = MathHelper.getIntersectionPoint(
      {
        pointA: { x: backWidth, y: backDepth },
        pointB: { x: frontWidth, y: backDepth },
      },
      offsetPoints
    );
    pointB = MathHelper.getIntersectionPoint(
      {
        pointA: { x: backWidth, y: backDepth },
        pointB: { x: backWidth, y: frontDepth },
      },
      offsetPoints
    );
    if (pointA && pointB) {
      result.second.length = MathHelper.getLength2D(pointA, pointB);
      pointCenter = MathHelper.getIntersectionPoint(
        {
          pointA: { x: backWidth, y: backDepth },
          pointB: { x: frontWidth, y: frontDepth },
        },
        halfOffsetPoints
      );
      if (pointCenter) {
        result.main.pointCenter = pointCenter;
      }
    }
    pointA = MathHelper.getIntersectionPoint(
      {
        pointA: { x: backWidth, y: frontDepth },
        pointB: { x: frontWidth, y: backDepth },
      },
      {
        pointA: { x: backWidth, y: backDepth },
        pointB: { x: frontWidth, y: frontDepth },
      }
    );
    pointB = MathHelper.getIntersectionPoint(
      {
        pointA: { x: backWidth, y: backDepth },
        pointB: { x: frontWidth, y: frontDepth },
      },
      offsetPoints
    );
    if (pointA && pointB) {
      result.main.middleHeight = MathHelper.getLength2D(pointA, pointB);
    }

    return result;
  }

  protected createFacades() {
    super.createFacades();
    let facade: ThreeFacade;
    let catheter, hypotenuse;

    for (facade of this.facades) {
      if (facade.getCellName() !== FACADE_CELL_DEFAULT) {
        continue;
      }
      hypotenuse =
        this.corpus.getWidth() -
        this.corpus.getBackThickness() -
        this.corpus.getSideDepth() -
        2;
      catheter = Math.sqrt(Math.pow(hypotenuse, 2) / 2);
      hypotenuse = catheter - this.corpus.getThickness() / 2;
      catheter =
        Math.sqrt(Math.pow(hypotenuse, 2) / 2) + this.corpus.getThickness();
      facade.setPosition(
        new Vector3(
          this.corpus.getWidth() / 2 - catheter,
          0,
          this.corpus.getDepth() / 2 - catheter
        )
      );
      facade.setRotation(new Euler(0, 0.25 * Math.PI, 0));
    }
  }
}

import {KitchenService} from '../KitchenService';
import {IKitchenUnits} from '../../../interfaces/IKitchenUnits';
import {ThreeBottomUnitNormal} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitNormal';
import {ThreeUnit} from '../../../objects/threeD/ThreeUnit/ThreeUnit';
import {ThreeTopUnitNormal} from '../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitNormal';
import {ThreePenalUnitNormal} from '../../../objects/threeD/units/ThreePenalUnit/types/ThreePenalUnitNormal';
import {ThreePenalUnitTop} from '../../../objects/threeD/units/ThreePenalUnit/types/ThreePenalUnitTop';
import {
    CLASSNAME_APRON_UNIT,
    CLASSNAME_BOTTOM_ANGLE_UNIT_CUBE,
    CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK,
    CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_NORMAL_SINK,
    CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL,
    CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE,
    CLASSNAME_BOTTOM_ANGLE_UNIT_TWO_FACADES_90,
    CLASSNAME_BOTTOM_UNIT_BAR_P,
    CLASSNAME_BOTTOM_UNIT_BAR_RAVENNA,
    CLASSNAME_BOTTOM_UNIT_BAR_STAND,
    CLASSNAME_BOTTOM_UNIT_DISHWASHER,
    CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE,
    CLASSNAME_BOTTOM_UNIT_END_CONSOLE,
    CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL,
    CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_CONSOLE,
    CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_OPENED,
    CLASSNAME_BOTTOM_UNIT_END_NORMAL,
    CLASSNAME_BOTTOM_UNIT_END_OPENED,
    CLASSNAME_BOTTOM_UNIT_END_OPENED_CIRCLE,
    CLASSNAME_BOTTOM_UNIT_END_PILASTER,
    CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL,
    CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_OVEN,
    CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_SINK,
    CLASSNAME_BOTTOM_UNIT_NORMAL,
    CLASSNAME_BOTTOM_UNIT_OVEN,
    CLASSNAME_BOTTOM_UNIT_PILASTER,
    CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE,
    CLASSNAME_BOTTOM_UNIT_SINK,
    CLASSNAME_BOTTOM_UNIT_SINK_OPENED,
    CLASSNAME_CONSTRUCTIVE_COOLER,
    CLASSNAME_CONSTRUCTIVE_DOOR,
    CLASSNAME_CONSTRUCTIVE_DOORWAY,
    CLASSNAME_CONSTRUCTIVE_GAS_BOILER,
    CLASSNAME_CONSTRUCTIVE_GAS_METER,
    CLASSNAME_CONSTRUCTIVE_PILLAR,
    CLASSNAME_CONSTRUCTIVE_RADIATOR_SECTION,
    CLASSNAME_CONSTRUCTIVE_SOCKET,
    CLASSNAME_CONSTRUCTIVE_SWITCH,
    CLASSNAME_CONSTRUCTIVE_VENTILATION,
    CLASSNAME_CONSTRUCTIVE_WALL_ISLAND,
    CLASSNAME_CONSTRUCTIVE_WATER_HEATER_HORIZONTAL,
    CLASSNAME_CONSTRUCTIVE_WATER_HEATER_VERTICAL,
    CLASSNAME_CONSTRUCTIVE_WINDOW,
    CLASSNAME_EQUIPMENT_COOKER,
    CLASSNAME_EQUIPMENT_DISHWASHER,
    CLASSNAME_EQUIPMENT_REFRIGERATOR,
    CLASSNAME_EQUIPMENT_SEPARATE_EXTRACT,
    CLASSNAME_EQUIPMENT_WASHER,
    CLASSNAME_PENAL_UNIT_END_CONSOLE,
    CLASSNAME_PENAL_UNIT_FULL_LENGTH_SIDEWALL,
    CLASSNAME_PENAL_UNIT_NORMAL,
    CLASSNAME_PENAL_UNIT_SINGLE_HANDLE,
    CLASSNAME_PENAL_UNIT_TOP,
    CLASSNAME_SIDE_BOTTOM_FACADE_UNIT,
    CLASSNAME_SIDE_TOP_FACADE_UNIT,
    CLASSNAME_TABLETOP_UNIT,
    CLASSNAME_TOP_UNIT_ANGLE_CUBE,
    CLASSNAME_TOP_UNIT_ANGLE_DOUBLE,
    CLASSNAME_TOP_UNIT_ANGLE_NORMAL,
    CLASSNAME_TOP_UNIT_ANGLE_TWO_FACADES_90,
    CLASSNAME_TOP_UNIT_BAR_RAVENNA,
    CLASSNAME_TOP_UNIT_END_NORMAL,
    CLASSNAME_TOP_UNIT_END_OPENED,
    CLASSNAME_TOP_UNIT_END_OPENED_CIRCLE,
    CLASSNAME_TOP_UNIT_END_PILASTER,
    CLASSNAME_TOP_UNIT_NORMAL,
    CLASSNAME_TOP_UNIT_PILASTER,
    CLASSNAME_TOP_UNIT_RACK_FRAME,
    CLASSNAME_TOP_UNIT_SHELF,
    CLASSNAME_TOP_UNIT_SHELF_FIGURED,
    CLASSNAME_TOP_UNIT_WINERY,
    ERROR_MODULES_DELETE,
    FACADE_MATERIAL_TYPE_CORPUS_MATERIAL,
    FACADE_OPEN_TYPE_VERTICAL_PIVOT,
    LEVEL_BOTTOM,
    LEVEL_TOP,
    PRICE_CELL_EXTRA_OFFERS
} from '../../../../../common-code/constants';
import {ThreeBottomUnit} from '../../../objects/threeD/units/ThreeBottomUnit/ThreeBottomUnit';
import {ThreeBottomEquipment} from '../../../objects/threeD/equipments/ThreeBottomEquipment/ThreeBottomEquipment';
import {ThreeTabletopUnit} from '../../../objects/threeD/units/ThreeAccessoryUnit/types/ThreeTabletopUnit';
import {ThreeApronUnit} from '../../../objects/threeD/units/ThreeAccessoryUnit/types/ThreeApronUnit';
import {ThreeTopUnit} from '../../../objects/threeD/units/ThreeTopUnit/ThreeTopUnit';
import {ThreeDecorUnit} from '../../../objects/threeD/units/ThreeDecorUnit/ThreeDecorUnit';
import {ThreeBarCounter} from '../../../objects/threeD/units/ThreeBarCounter/ThreeBarCounter';
import {ThreePenalUnit} from '../../../objects/threeD/units/ThreePenalUnit/ThreePenalUnit';
import {ThreeBottomUnitSink} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitSink';
import {ThreeBottomUnitOven} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitOven';
import {
    ThreeBottomUnitAngleNormal
} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitAngleNormal';
import {ThreeTopUnitAngleCube} from '../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitAngleCube';
import {ThreeTopUnitAngleNormal} from '../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitAngleNormal';
import {ThreeBottomUnitAngleCube} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitAngleCube';
import {ThreeBottomUnitEndNormal} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitEndNormal';
import {ThreeBottomUnitEndOpened} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitEndOpened';
import {ThreeTopUnitEndNormal} from '../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitEndNormal';
import {ThreeTopUnitEndOpened} from '../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitEndOpened';
import {Box3, Vector3} from 'three';
import {ThreePlinthUnion} from '../../../objects/threeD/details/ThreePlinth/ThreePlinthUnion';
import {ThreeCornerUnion} from '../../../objects/threeD/details/ThreeCorner/ThreeCornerUnion';
import {ThreeApronUnion} from '../../../objects/threeD/details/ThreeApron/ThreeApronUnion';
import {ThreeTabletopUnion} from '../../../objects/threeD/details/ThreeTabletop/ThreeTabletopUnion';
import {TPoint2D} from '../../../../../common-code/types/TPoint2D';
import {ThreeExtractEquipment} from '../../../objects/threeD/equipments/ThreeTopEquipment/types/ThreeExtractEquipment';
import {IKitchenEquipments} from '../../../interfaces/IKitchenEquipments';
import {ThreeCookerEquipment} from '../../../objects/threeD/equipments/ThreeBottomEquipment/types/ThreeCookerEquipment';
import {ThreeWasherEquipment} from '../../../objects/threeD/equipments/ThreeBottomEquipment/types/ThreeWasherEquipment';
import {ThreeEquipment} from '../../../objects/threeD/equipments/ThreeEquipment/ThreeEquipment';
import {
    ThreeDishwasherEquipment
} from '../../../objects/threeD/equipments/ThreeBottomEquipment/types/ThreeDishwasherEquipment';
import {
    ThreeRefrigeratorEquipment
} from '../../../objects/threeD/equipments/ThreeBigEquipment/types/ThreeRefrigeratorEquipment';
import {TClassName} from '../../../../../common-code/types/TClassName';
import {ThreeApron} from '../../../objects/threeD/details/ThreeApron/ThreeApron';
import {ThreeTabletop} from '../../../objects/threeD/details/ThreeTabletop/ThreeTabletop';
import {ThreeCorner} from '../../../objects/threeD/details/ThreeCorner/ThreeCorner';
import {ThreePlinth} from '../../../objects/threeD/details/ThreePlinth/ThreePlinth';
import {ThreeConstructive} from '../../../objects/threeD/constructive/ThreeConstructive/ThreeConstructive';
import {ThreeTopUnitAngleDouble} from "../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitAngleDouble";
import {ThreePenalUnitEndConsole} from "../../../objects/threeD/units/ThreePenalUnit/types/ThreePenalUnitEndConsole";
import {ThreeBottomUnitEndConsole} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitEndConsole";
import {ThreeBottomUnitSinkOpened} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitSinkOpened";
import {ThreeTopUnitWinery} from "../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitWinery";
import {
    ThreeBottomUnitFullLengthSidewall
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitFullLengthSidewall";
import {
    ThreePenalUnitFullLengthSidewall
} from "../../../objects/threeD/units/ThreePenalUnit/types/ThreePenalUnitFullLengthSidewall";
import {
    ThreeBottomUnitFullLengthSidewallOven
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitFullLengthSidewallOven";
import {
    ThreeBottomUnitFullLengthSidewallSink
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitFullLengthSidewallSink";
import {
    ThreeBottomUnitEndFullLengthSidewall
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitEndFullLengthSidewall";
import {
    ThreeBottomUnitEndFullLengthSidewallConsole
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitEndFullLengthSidewallConsole";
import {
    ThreeBottomUnitEndFullLengthSidewallOpened
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitEndFullLengthSidewallOpened";
import {
    ThreeBottomUnitAngleFullLengthSidewallCubeSink
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitAngleFullLengthSidewallCubeSink";
import {ThreeTopUnitRackFrame} from "../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitRackFrame";
import {TDirectionSideType} from '../../../../../common-code/types/TDirectionSideType';
import {
    ThreeTopUnitAngleTwoFacades90
} from "../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitAngleTwoFacades90";
import {ThreeTopUnitShelfFigured} from "../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitShelfFigured";
import {
    ThreeBottomUnitAngleTwoFacades90
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitAngleTwoFacades90";
import {ThreeBottomUnitBarStand} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitBarStand";
import {ThreeTopUnitPilaster} from "../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitPilaster";
import {ThreeBottomUnitPilaster} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitPilaster";
import {
    ThreeBottomUnitAngleFullLengthSidewallNormalSink
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitAngleFullLengthSidewallNormalSink";
import {ThreeFacade} from '../../../objects/threeD/details/ThreeFacade/ThreeFacade';
import {
    ThreeBottomUnitEndPilaster
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitEndPilaster";
import {ThreeTopUnitEndPilaster} from "../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitEndPilaster";
import {
    ThreeTopUnitEndOpenedCircle
} from "../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitEndOpenedCircle";
import {
    ThreeBottomUnitEndOpenedCircle
} from "../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitEndOpenedCircle";
import {ThreeTopUnitShelf} from '../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitShelf';
import {ThreeBottomUnitBarP} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitBarP';
import {ThreeSideBottomFacadeUnit} from '../../../objects/threeD/units/ThreeFacadeUnit/types/ThreeSideBottomFacadeUnit';
import {ThreeFacadeUnit} from '../../../objects/threeD/units/ThreeFacadeUnit/ThreeFacadeUnit';
import {ThreeSideTopFacadeUnit} from '../../../objects/threeD/units/ThreeFacadeUnit/types/ThreeSideTopFacadeUnit';
import {ISaveUnitData} from '../../../../../common-code/interfaces/saveData/ISaveUnitData';
import {IProjectUnits} from '../../../../../common-code/interfaces/project/IProjectUnits';
import {IProjectEquipments} from '../../../../../common-code/interfaces/project/IProjectEquipments';
import {IModulePriceData} from '../../../../../common-code/interfaces/catalog/IModulePriceData';
import {ISaveCorpusData} from '../../../../../common-code/interfaces/saveData/ISaveCorpusData';
import {CommonHelper} from 'common-code';
import {ISaveBottomUnitData} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitData';
import {
    ISaveBottomUnitAngleNormalData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleNormalData';
import {
    ISaveBottomUnitAngleTwoFacades90Data
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleTwoFacades90Data';
import {
    ISaveBottomUnitAngleFullLengthSidewallNormalSinkData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleFullLengthSidewallNormalSinkData';
import {
    ISaveBottomUnitAngleCubeData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleCubeData';
import {
    ISaveBottomUnitAngleFullLengthSidewallCubeSinkData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitAngleFullLengthSidewallCubeSinkData';
import {
    ISaveBottomUnitEndNormalData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndNormalData';
import {
    ISaveBottomUnitEndConsoleData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndConsoleData';
import {
    ISaveBottomUnitEndFullLengthSidewallData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndFullLengthSidewallData';
import {
    ISaveBottomUnitEndOpenedData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndOpenedData';
import {
    ISaveBottomUnitEndOpenedCircleData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndOpenedCircleData';
import {
    ISaveBottomUnitEndFullLengthSidewallOpenedData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndFullLengthSidewallOpenedData';
import {
    ISaveBottomUnitEndFullLengthSidewallConsoleData
} from '../../../../../common-code/interfaces/saveData/ISaveBottomUnitEndFullLengthSidewallConsoleData';
import {ISaveTopUnitData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitData';
import {
    ISaveTopUnitShelfFiguredData
} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitShelfFiguredData';
import {ISaveTopUnitAngleNormalData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitAngleNormalData';
import {
    ISaveTopUnitAngleTwoFacades90Data
} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitAngleTwoFacades90Data';
import {ISaveTopUnitAngleCubeData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitAngleCubeData';
import {ISaveTopUnitAngleDoubleData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitAngleDoubleData';
import {ISaveTopUnitEndNormalData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitEndNormalData';
import {ISaveTopUnitEndPilasterData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitEndPilasterData';
import {ISaveTopUnitEndOpenedData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitEndOpenedData';
import {
    ISaveTopUnitEndOpenedCircleData
} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitEndOpenedCircleData';
import {ISaveTopUnitRackFrameData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitRackFrameData';
import {ISaveTopUnitShelfData} from '../../../../../common-code/interfaces/saveData/ISaveTopUnitShelfData';
import {ISavePenalUnitData} from '../../../../../common-code/interfaces/saveData/ISavePenalUnitData';
import {
    ISavePenalUnitEndConsoleData
} from '../../../../../common-code/interfaces/saveData/ISavePenalUnitEndConsoleData';
import {ISaveFacadeUnitData} from '../../../../../common-code/interfaces/saveData/ISaveFacadeUnitData';
import {ISaveExtractEquipmentData} from '../../../../../common-code/interfaces/saveData/ISaveExtractEquipmentData';
import {ISaveCookerEquipmentData} from '../../../../../common-code/interfaces/saveData/ISaveCookerEquipmentData';
import {ISaveWasherEquipmentData} from '../../../../../common-code/interfaces/saveData/ISaveWasherEquipmentData';
import {
    ISaveDishwasherEquipmentData
} from '../../../../../common-code/interfaces/saveData/ISaveDishwasherEquipmentData';
import {ISaveEquipmentData} from '../../../../../common-code/interfaces/saveData/ISaveEquipmentData';
import {ISaveConstructiveData} from '../../../../../common-code/interfaces/saveData/ISaveConstructiveData';
import {ICreateObjectData} from '../../../../../common-code/interfaces/createData/ICreateObjectData';
import {IOptionHiddenText} from '../../../../../common-code/interfaces/option/IOptionHiddenText';
import {TWizardUIOptions} from '../../../../types/TWizardUIOptions';
import {IFacadeMaterialData} from '../../../../../common-code/interfaces/materials/IFacadeMaterialData';
import {ITabletopData} from '../../../../../common-code/interfaces/materials/ITabletopData';
import {ICornerData} from '../../../../../common-code/interfaces/materials/ICornerData';
import {IApronData} from '../../../../../common-code/interfaces/materials/IApronData';
import {IPlinthData} from '../../../../../common-code/interfaces/materials/IPlinthData';
import {IMaterialData} from '../../../../../common-code/interfaces/materials/IMaterialData';
import {ThreeBottomUnitDishwasher} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitDishwasher';
import {
    DETAIL_APRONS,
    DETAIL_CORNERS,
    DETAIL_PLINTHS,
    DETAIL_TABLETOPS,
    HISTORY_STATE_TYPE_CHANGE,
    HISTORY_STATE_TYPE_DELETE
} from '../../../constants';
import {IHistoryCreateObjectsState} from '../../../interfaces/history/IHistoryCreateObjectsState';
import {ISaveBoxData} from '../../../../../common-code/interfaces/saveData/ISaveBoxData';
import {IProjectTabletopData} from '../../../../../common-code/interfaces/project/IProjectTabletopData';
import {ITabletopPlanks} from '../../../../../common-code/interfaces/plank/ITabletopPlanks';
import {IProjectApronData} from '../../../../../common-code/interfaces/project/IProjectApronData';
import {IApronPlanks} from '../../../../../common-code/interfaces/plank/IApronPlanks';
import {IProjectCornerData} from '../../../../../common-code/interfaces/project/IProjectCornerData';
import {IProjectPlinthData} from '../../../../../common-code/interfaces/project/IProjectPlinthData';
import {TFacadeOpenType} from '../../../../../common-code/types/TFacadeOpenType';
import {ThreeHandle} from '../../../objects/threeD/details/ThreeHandle/ThreeHandle';
import {MESSAGE_TYPE_INFO, MESSAGE_TYPE_WARNING, SHOW_SETTINGS_MENU} from '../../../../constants';
import {i18n} from '../../../../i18n';
import {IHandleData} from '../../../../../common-code/interfaces/materials/IHandleData';
import {TLevel} from '../../../../../common-code/types/TLevel';
import {IHistoryChangeObjectsState} from '../../../interfaces/history/IHistoryChangeObjectsState';
import {ThreeKUnit} from '../../../objects/threeD/units/ThreeKUnit/ThreeKUnit';
import {ThreeBuiltInEquipment} from '../../../objects/threeD/equipments/ThreeBuiltInEquipment/ThreeBuiltInEquipment';
import {IProjectOffers} from '../../../../../common-code/interfaces/project/IProjectOffers';
import {IImportOffer} from '../../../../../common-code/interfaces/api/IImportOffer';
import {IProjectOffer} from '../../../../../common-code/interfaces/project/IProjectOffer';
import {ThreeBottomUnitBarRavenna} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitBarRavenna';
import {ThreeTopUnitBarRavenna} from '../../../objects/threeD/units/ThreeTopUnit/types/ThreeTopUnitBarRavenna';
import {IHistoryObjectData} from '../../../interfaces/history/IHistoryObjectData';
import {ThreeLeg} from '../../../objects/threeD/details/ThreeLeg/ThreeLeg';
import {
    ThreeBottomSingleHoleHandleUnit
} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomSingleIntegratedHandleUnit';
import {
    ThreeBottomDoubleHoleHandleUnit
} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomDoubleIntegratedHandleUnit';
import {
    ThreePenalUnitSingleIntegratedHandle
} from '../../../objects/threeD/units/ThreePenalUnit/types/ThreePenalUnitSingleIntegratedHandle';
import {
    ThreeBottomUnitAngleNormalSingleHandle
} from '../../../objects/threeD/units/ThreeBottomUnit/types/ThreeBottomUnitAngleNormalSingleHandle';
import {
    ThreeIntegratedHandleUnion
} from '../../../objects/threeD/details/ThreeIntegratedHandle/ThreeIntegratedHandleUnion';
import {IProductPrice} from '../../../../../common-code/interfaces/catalog/IproductPrice';
import {IProjectOfferParams} from '../../../../../common-code/interfaces/project/IProjectOfferParams';
import {IDetailPriceData} from '../../../../../common-code/interfaces/catalog/IDetailPriceData';
import {ISaveApronUnitData} from '../../../../../common-code/interfaces/saveData/ISaveApronUnitData';
import {ISaveTabletopUnitData} from '../../../../../common-code/interfaces/saveData/ISaveTabletopUnitData';

export class MainManager {
    service: KitchenService;
    units: IKitchenUnits;
    equipments: IKitchenEquipments;
    extraOffers?: IProjectOffers;

    constructor(service: KitchenService) {
        this.service = service;
        this.units = this.initUnits();
        this.equipments = this.initEquipments();
    }

    public initProject() {
        let unitGroup: keyof IProjectUnits;
        let equipmentGroup: keyof IProjectEquipments;
        let unitData: ISaveUnitData;
        let offerIds: {[key: string]: string} = {};
        let isEmpty: boolean = true;
        let newUnit: ThreeUnit | ThreeConstructive | undefined;
        let unitOfferIds: string[];
        let offerId: string;

        if (this.service.projectData.units) {
            for (unitGroup in this.service.projectData.units) {
                for (unitData of this.service.projectData.units[unitGroup]) {
                    newUnit = this.createCommonObject(unitData);
                    if (newUnit) {
                        newUnit.clearCacheCalculateData();
                        newUnit.updateAllMatrices();
                        isEmpty = false;
                        unitOfferIds = newUnit.getOfferIds();
                        for (offerId of unitOfferIds) {
                            offerIds[offerId] = offerId;
                        }
                    }
                }
            }
        }
        if (this.service.projectData.equipments) {
            for (equipmentGroup in this.service.projectData.equipments) {
                for (unitData of this.service.projectData.equipments[equipmentGroup]) {
                    newUnit = this.createCommonObject(unitData);
                    if (newUnit) {
                        newUnit.clearCacheCalculateData();
                        newUnit.updateAllMatrices();
                        isEmpty = false;
                    }
                }
            }
        }
        this.initProjectOffers();
        if (Object.keys(offerIds).length > 0) {
            this.service.loadPrices(Object.keys(offerIds)).then(() => {
                if (!isEmpty) {
                    this.service.showAll();
                }
                this.service.calculateUnitsPrice({}, true);
                this.service.rebuildScene();
            })
        } else {
            if (!isEmpty) {
                this.service.showAll();
            }
        }
    }

    public initProjectPrices(): Promise<boolean> {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let offerIds: {[key: string]: string} = {};
        let unitOfferIds: string[];
        let unitOfferId: string;

        units = this.getObjects();

        for (unit of units) {
            unitOfferIds = unit.getOfferIds();
            for (unitOfferId of unitOfferIds) {
                offerIds[unitOfferId] = unitOfferId;
            }
        }
        return new Promise<boolean>((resolve, reject) => {
            this.service.loadPrices(Object.keys(offerIds)).then(() => {
                this.service.calculateUnitsPrice({}, true);
                this.service.rebuildScene();
                resolve(true);
            }).catch(() => {
                reject();
            })
        });

    }

    public getProjectOffers(): IProjectOffers | undefined {
        return this.extraOffers;
    }

    public getProjectBuiltInEquipmentOffers(): IProjectOffers | undefined {
        let equipments: ThreeBuiltInEquipment[];
        let equipment: ThreeBuiltInEquipment;
        let extraOffers: IProjectOffers = {};
        let itemExtraOffers: IProjectOffers | undefined;
        let index: string;
        let equipmentId: string;

        equipments = this.getBuiltInEquipments();
        for (equipment of equipments) {
            itemExtraOffers = equipment.getExtraOffers();
            if (itemExtraOffers) {
                equipmentId = ''+equipment.getId();
                for (index in itemExtraOffers) {
                    extraOffers[equipmentId + '__' + index] = itemExtraOffers[index];
                }
            }
        }

        return Object.keys(extraOffers).length ? extraOffers : undefined;
    }

    public getBuiltInEquipments(): ThreeBuiltInEquipment[] {
        let equipments: ThreeBuiltInEquipment[] = [];
        let equipment: ThreeBuiltInEquipment;
        let objects: ThreeUnit[];
        let object: ThreeUnit;

        objects = this.getObjects();
        for (object of objects) {
            if (object instanceof ThreeKUnit) {
                if (object.equipments) {
                    for (equipment of object.equipments) {
                        equipments.push(equipment);
                    }
                }
            }
        }

        return equipments;
    }

    public getProjectOffer(offer: IImportOffer, part?: number): IProjectOffer | undefined {
        let externalId: 'externalGuid' | 'vendorCode';
        let offerId: string;

        externalId = this.service.getOfferExternalId();
        offerId = offer[externalId] + (part !== undefined && part > 0 ? '' + part : '');
        if (!this.extraOffers || !this.extraOffers[offerId]) {
            return undefined;
        }

        return this.extraOffers[offerId];
    }

    public checkProjectModules() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let deletedUnits: string[] = [];
        let priceData: IModulePriceData | undefined;

        units = this.getUnits();

        for (unit of units) {
            if (unit.getNotPrice()) {
                continue;
            }
            priceData = unit.getPriceData();
            if (!priceData || priceData.errors.length) {
                switch (this.service.getErrorModulesFunction()) {
                    case ERROR_MODULES_DELETE:
                        deletedUnits.push(unit.getSpecName());
                        this.tryDeleteUnit(unit.getId());
                        break;
                }
            }
        }
        if (deletedUnits.length) {
            this.service.showMessage({
                type: MESSAGE_TYPE_WARNING,
                autoClose: false,
                message: '<b>'+i18n.t('Ошибка расчета цены') + '!</b><br/>' + i18n.t('Из проекта удалены модули') + ":<ul><li>" + deletedUnits.join("</li><li>") + '</li></ul>'
            })
        }
    }

    public setHandle(handle: IHandleData, level: TLevel) {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let facade: ThreeFacade;

        units = this.getObjects();
        for (unit of units) {
            if (unit.facades) {
                for (facade of unit.facades) {
                    if (facade.handle && facade.getLevel() === level) {
                        facade.handle.setHandleData(handle);
                        facade.handle.rebuild();
                    }
                }
            }
        }
    }

    public setProjectOffersPrices() {
        if (!this.extraOffers) {
            return;
        }
        let id: string;
        let itemPrice: IProductPrice | undefined;

        for (id in this.extraOffers) {
            itemPrice = this.service.getOfferProductPrice(this.extraOffers[id].id);
            if (itemPrice) {
                this.extraOffers[id].price = itemPrice.price;
                this.extraOffers[id].oldPrice = itemPrice.oldPrice;
                this.extraOffers[id].active = itemPrice.active;
                this.extraOffers[id].stock = itemPrice.stock;
                this.extraOffers[id].onlyOrder = itemPrice.onlyOrder;
                this.extraOffers[id].inWay = itemPrice.inWay;
            }
        }
    }

    public setUnitOffer(unitId: number, offer: IImportOffer, count: number, once?: boolean): IHistoryChangeObjectsState | undefined {
        let object: ThreeUnit | undefined ;

        object = this.getObjectById(unitId);
        if (object) {
            const oldSaveData: ISaveUnitData = CommonHelper.deepCopy(object.getData());
            object.setExtraOffer(offer, count, once);
            const newSaveData: ISaveUnitData = CommonHelper.deepCopy(object.getData());
            if (!CommonHelper.deepCompare(oldSaveData, newSaveData)) {
                this.service.sendToRedux({
                    type: SHOW_SETTINGS_MENU,
                    payload: {
                        visible: true,
                        unitId: object.getId(),
                        data: object.getData(),
                        createObjectData: this.service.getCreateObjectByUid(object.getUid()),
                        price: object.getPrice(),
                        groups: object.getSettingsGroups(),
                    }
                });
                return {
                    type: HISTORY_STATE_TYPE_CHANGE,
                    data: {
                        objects: [{oldData: oldSaveData, newData: newSaveData}]
                    }
                };
            }

        }

        return undefined;
    }

    public setProjectOffer(
        offer: IImportOffer,
        count: number,
        params: IProjectOfferParams
    ) {
        let externalId: 'externalGuid' | 'vendorCode';
        let priceData: IDetailPriceData;
        let alreadyProjectOffer: IProjectOffer | undefined;
        let projectOffer: IProjectOffer;
        let offerId: string;
        let orderPart: number | undefined;


        externalId = this.service.getOfferExternalId();
        if (!this.extraOffers) {
            this.extraOffers = {};
        }
        orderPart = params.part !== undefined && params.part > 0 ? params.part : this.service.getDefaultOrderPart();
        offerId = offer[externalId] + (orderPart !== undefined && orderPart > 0 ? '' + orderPart : '');
        alreadyProjectOffer = this.extraOffers[offerId];
        if (count) {
            priceData = this.service.getDetailPriceData(offer, count, 0, PRICE_CELL_EXTRA_OFFERS, 0);
            if (alreadyProjectOffer) {
                projectOffer = alreadyProjectOffer;
                projectOffer.count = count;
                projectOffer.sort = params.sort || alreadyProjectOffer.sort;
                projectOffer.isAuto = params.isAuto || alreadyProjectOffer.isAuto;
                projectOffer.autoCount = params.autoCount || alreadyProjectOffer.autoCount;
                projectOffer.isAutoEnable = params.isAutoEnable || alreadyProjectOffer.isAutoEnable;
                projectOffer.comment = alreadyProjectOffer.comment !== undefined ?
                    (params.comment !== undefined ? alreadyProjectOffer.comment + "\n" + params.comment : alreadyProjectOffer.comment) :
                    (params.comment !== undefined ? params.comment : undefined);
            } else {
                projectOffer = {
                    id: offer[externalId],
                    count: count,
                    offer: offer,
                    price: priceData.price,
                    oldPrice: priceData.oldPrice,
                    stock: priceData.stock,
                    active: priceData.active,
                    onlyOrder: priceData.onlyOrder,
                    inWay: priceData.inWay,
                    unitId: priceData.unitId,
                    cell: priceData.cell,
                    sort: params.sort,
                    part: orderPart,
                    isAuto: params.isAuto,
                    autoCount: params.autoCount,
                    isAutoEnable: params.isAutoEnable,
                    comment: params.comment,
                }
            }
            this.extraOffers[offerId] = projectOffer;
        } else if (!count) {
            if (alreadyProjectOffer && alreadyProjectOffer.isAuto && !alreadyProjectOffer.isAutoEnable) {
                this.extraOffers[offerId].count = 0;
            } else {
                delete this.extraOffers[offerId];
            }
        }
    }

    public setProjectOfferIsAuto(projectOffer: IProjectOffer, isAutoEnable: boolean) {
        let offerId: string;

        offerId = projectOffer.id + (projectOffer.part !== undefined && projectOffer.part > 0 ? '' + projectOffer.part : '');
        if (this.extraOffers &&
            this.extraOffers[offerId] &&
            this.extraOffers[offerId].isAuto) {
            this.extraOffers[offerId].isAutoEnable = isAutoEnable;
            if (isAutoEnable) {
                if (this.extraOffers[offerId].autoCount !== undefined) {
                    this.extraOffers[offerId].count = this.extraOffers[offerId].autoCount as number;
                }
            } else {
                this.extraOffers[offerId].count = 0;
            }
        }
    }

    public setAutoProjectOffer(
        offer: IImportOffer,
        count: number,
        offerParams: IProjectOfferParams,
        showMessage: boolean
    ) {
        let alreadyOffer: IProjectOffer | undefined;

        alreadyOffer = this.getProjectOffer(offer, offerParams.part);
        if (!alreadyOffer ||
            (alreadyOffer && alreadyOffer.isAuto && alreadyOffer.isAutoEnable && alreadyOffer.count < count)) {
            offerParams.isAuto = true;
            offerParams.isAutoEnable = true;
            offerParams.autoCount = count;
            this.setProjectOffer(offer, count, offerParams);
            if (showMessage && alreadyOffer && alreadyOffer.count < count) {
                this.service.showMessage({
                    type: MESSAGE_TYPE_INFO,
                    autoClose: true,
                    interval: 1500,
                    params: {
                        id: 'setAutoProjectOffer'
                    },
                    message: (alreadyOffer.offer?.name || i18n.t('Товар'))  + ' ' +
                        i18n.t('нельзя добавить в спецификацию меньше автоматически рассчитанного количества')
                })
            }
        }
    }

    public remove() {
        this.removeUnits();
        this.removeEquipments();
    }

    public getEquipments(types?: string[]): ThreeUnit[] {
        let group: keyof IKitchenEquipments;
        let equipment: ThreeUnit;
        let equipments: ThreeUnit[] = [];

        for (group in this.equipments) {
            if (types && !types.includes(group)) {
                continue;
            }
            if (!this.equipments.hasOwnProperty(group)) {
                continue;
            }
            for (equipment of this.equipments[group]) {
                equipments.push(equipment);
            }
        }

        return equipments;
    }

    public getUnit(id: number): ThreeUnit | undefined {
        let unit: ThreeUnit;
        let groupUnits: ThreeUnit[];
        let group: keyof IKitchenUnits;

        for (group in this.units) {
            if (!this.units.hasOwnProperty(group)) {
                continue;
            }
            groupUnits = this.units[group];
            for (unit of groupUnits) {
                if (unit.getId() === id) {
                    return unit;
                }
            }
        }

        return undefined;
    }

    public getEquipment(id: number): ThreeUnit | undefined {
        let unit: ThreeUnit;
        let groupUnits: ThreeUnit[];
        let group: keyof IKitchenEquipments;

        for (group in this.equipments) {
            if (!this.equipments.hasOwnProperty(group)) {
                continue;
            }
            groupUnits = this.equipments[group];
            for (unit of groupUnits) {
                if (unit.getId() === id) {
                    return unit;
                }
            }
        }

        return undefined;
    }

    public calculateTablesCount(): number {
        return this.units.bottomUnits.length;
    }

    public calculateStretchTablesCount(): number {
        return this.units.bottomUnits.filter(unit => unit.isStretch()).length;
    }

    public calculateCupboardsCount(): number {
        return this.units.topUnits.length;
    }

    public calculateStretchCupboardsCount(): number {
        return this.units.topUnits.filter(unit => unit.isStretch()).length;
    }

    public calculatePenBoxCount(): number {
        return this.units.penals.length;
    }

    public getBarCountersCount(): number {
        return this.units.barCounters.length;
    }

    public calculateStretchPenBoxCount(): number {
        return this.units.penals.filter(unit => unit.isStretch()).length;
    }

    public calculateBoxes(): ISaveBoxData[] {
        let group: keyof IKitchenUnits;
        let unit: ThreeUnit;
        let boxes: ISaveBoxData[] = [];
        let unitBoxes: ISaveBoxData[] = [];

        for (group in this.units) {
            for (unit of this.units[group]) {
                unitBoxes = unit.getBoxes();
                boxes = boxes.concat(unitBoxes);
            }
        }

        return boxes;
    }

    public calculateTabletopsData(tabletops: ThreeTabletop[]): IProjectTabletopData {
        let data: IProjectTabletopData;
        let index: string;
        let length: number;
        let maxLength: number;
        let planks: ITabletopPlanks;

        data = {
            pieces: [],
            count: 0,
            realPieces: [],
            edges: 0,
            planks: {
                end: 0,
                angle: 0,
                connect: 0,
                euroZapil: 0,
            }
        };
        for (index in tabletops) {
            length = tabletops[index].getLength();
            maxLength = this.service.getMaxDetailLength(DETAIL_TABLETOPS, tabletops[index]) || 3000;
            data.realPieces.push({
                length: length,
                height: tabletops[index].getHeight(),
                width: tabletops[index].getWidth(),
                count: 1,
                parent: tabletops[index].getId()
            });
            if (length > maxLength) {
                data.pieces.push({
                    length: maxLength,
                    height: tabletops[index].getHeight(),
                    width: tabletops[index].getWidth(),
                    count: Math.floor(length / maxLength),
                    parent: tabletops[index].getId()
                });
                if (length % maxLength > 0) {
                    data.pieces.push({
                        length: (length - maxLength * Math.floor(length / maxLength)),
                        height: tabletops[index].getHeight(),
                        width: tabletops[index].getWidth(),
                        count: 1,
                        parent: tabletops[index].getId()
                    });
                }
            } else {
                data.pieces.push({
                    length: length,
                    height: tabletops[index].getHeight(),
                    width: tabletops[index].getWidth(),
                    count: 1,
                    parent: tabletops[index].getId()
                });
            }
        }

        data.edges = Math.ceil(data.pieces.length * 1.2 * 2) / 2;
        planks = this.service.getTabletopPlanks();
        for (index in planks) {
            data.planks[planks[index].type] += 1;
        }
        data.count = data.pieces.length;

        return data;
    }

    public calculateApronsData(aprons: ThreeApron[]): IProjectApronData {
        let data: IProjectApronData;
        let fullLength: number;
        let index: string;
        let length: number;
        let maxLength: number;
        let planks: IApronPlanks;

        data = {
            pieces: [],
            count: 0,
            realPieces: [],
            runningMeters: 0,
            fullLength: 0,
            isAkril: false,
            planks: {
                end: 0,
                angle: 0,
                connect: 0,
            }
        };
        fullLength = 0;
        for (index in aprons) {
            maxLength = this.service.getMaxDetailLength(DETAIL_APRONS, aprons[index]) || 3000;
            length = aprons[index].getLength();
            fullLength += length;
            data.isAkril = aprons[index].isAkril();
            data.realPieces.push({
                length: length,
                height: aprons[index].getHeight(),
                width: aprons[index].getWidth(),
                count: 1,
                isAkril: aprons[index].isAkril(),
                parent: aprons[index].getId()
            });
            if (length > maxLength) {
                data.pieces.push({
                    length: maxLength,
                    height: aprons[index].getHeight(),
                    width: aprons[index].getWidth(),
                    count: Math.floor(length / maxLength),
                    isAkril: aprons[index].isAkril(),
                    parent: aprons[index].getId()

                });
                if (length % maxLength > 0) {
                    data.pieces.push({
                        length: length % maxLength,
                        height: aprons[index].getHeight(),
                        width: aprons[index].getWidth(),
                        count: 1,
                        isAkril: aprons[index].isAkril(),
                        parent: aprons[index].getId()
                    });
                }
            } else {
                data.pieces.push({
                    length: length,
                    height: aprons[index].getHeight(),
                    width: aprons[index].getWidth(),
                    count: 1,
                    isAkril: aprons[index].isAkril(),
                    parent: aprons[index].getId()
                });
            }
        }

        planks = this.service.getApronPlanks();
        for (index in planks) {
            data.planks[planks[index].type] += 1;
        }

        data.count = data.pieces.length;
        data.fullLength = fullLength;
        data.runningMeters = fullLength / 1000;

        return data;
    }

    public calculateCornersData(corners: ThreeCorner[]): IProjectCornerData {
        let data: IProjectCornerData;
        let index: string;
        let length: number;
        let maxLength: number;

        data = {
            pieces: [],
            count: 0,
            realPieces: [],
            caps: 0,
        };
        for (index in corners) {
            maxLength = this.service.getMaxDetailLength(DETAIL_CORNERS, corners[index]) || 3000;
            length = corners[index].getLength();
            data.realPieces.push({
                length: length,
                height: corners[index].getHeight(),
                width: corners[index].getWidth(),
                count: 1,
                parent: corners[index].getId(),
            });
            if (length > maxLength) {
                data.pieces.push({
                    length: maxLength,
                    height: corners[index].getHeight(),
                    width: corners[index].getWidth(),
                    count: Math.floor(length / maxLength),
                    parent: corners[index].getId()

                });
                if (length % 3000 > 0) {
                    data.pieces.push({
                        length: length % 3000,
                        height: corners[index].getHeight(),
                        width: corners[index].getWidth(),
                        count: 1,
                        parent: corners[index].getId()
                    });
                }
            } else {
                data.pieces.push({
                    length: length,
                    height: corners[index].getHeight(),
                    width: corners[index].getWidth(),
                    count: 1,
                    parent: corners[index].getId()
                });
            }
        }
        data.caps = data.pieces.length;
        data.count = data.pieces.length;

        return data;
    }

    public calculatePlinthsData(plinths: ThreePlinth[]): IProjectPlinthData {
        let data: IProjectPlinthData;
        let index: string;
        let length: number;
        let maxLength: number;

        data = {
            pieces: [],
            count: 0,
            realPieces: [],
            caps: 0,
        };
        for (index in plinths) {
            length = plinths[index].getLength();
            maxLength = this.service.getMaxDetailLength(DETAIL_PLINTHS, plinths[index]) || 3000;
            data.realPieces.push({
                length: length,
                height: plinths[index].getHeight(),
                width: plinths[index].getWidth(),
                count: 1,
                parent: plinths[index].getId()
            });
            if (length > maxLength) {
                data.pieces.push({
                    length: maxLength,
                    height: plinths[index].getHeight(),
                    width: plinths[index].getWidth(),
                    count: Math.floor(length / maxLength),
                    parent: plinths[index].getId()

                });
                if (length % maxLength > 0) {
                    data.pieces.push({
                        length: length % maxLength,
                        height: plinths[index].getHeight(),
                        width: plinths[index].getWidth(),
                        count: 1,
                        parent: plinths[index].getId()
                    });
                }
            } else {
                data.pieces.push({
                    length: length,
                    height: plinths[index].getHeight(),
                    width: plinths[index].getWidth(),
                    count: 1,
                    parent: plinths[index].getId()
                });
            }
        }
        data.caps = data.pieces.length;
        data.count = data.pieces.length;

        return data;
    }

    public getAllFacades(openTypes?: TFacadeOpenType[], functionalTypes?: string[]): ThreeFacade[] {
        let objects: ThreeUnit[];
        let object: ThreeUnit;
        let facades: ThreeFacade[] = [];
        let facade: ThreeFacade;
        let openType: TFacadeOpenType | undefined;
        let functionalType: string | undefined;

        objects = this.getObjects();
        for (object of objects) {
            if (object.facades) {
                for (facade of object.facades) {
                    openType = facade.getOpenType();
                    functionalType = facade.getFunctionalType();
                    if (openTypes && openType &&
                        !openTypes.includes(openType)) {
                        continue;
                    }
                    if (functionalTypes && functionalType &&
                        !functionalTypes.includes(functionalType)) {
                        continue;
                    }
                    facades.push(facade);
                }
            }
        }

        return facades;
    }

    public getAllHandles(): ThreeHandle[] {
        let facades: ThreeFacade[];
        let facade: ThreeFacade;
        let handles: ThreeHandle[] = [];

        facades = this.getAllFacades();
        for (facade of facades) {
            if (facade.handle && facade.handle.isVisible()) {
                handles.push(facade.handle);
            }
        }

        return handles;
    }

    public getEndUnits(): ThreeUnit[] {
        let units: ThreeUnit[] = [];
        let unit: ThreeUnit;
        let groupUnits: ThreeBottomUnit[] | ThreeBottomEquipment[] |
            ThreeTabletopUnit[] | ThreeApronUnit[] | ThreeTopUnit[] |
            ThreeDecorUnit[] | ThreeBarCounter[] | ThreePenalUnit[] | ThreeFacadeUnit[];
        let group: keyof IKitchenUnits;

        for (group in this.units) {
            if (!this.units.hasOwnProperty(group)) {
                continue;
            }
            groupUnits = this.units[group];
            for (unit of groupUnits) {
                if (unit.isEndUnit()) {
                    units.push(unit);
                }
            }
        }

        return units;
    }

    public getAngleUnits(): ThreeUnit[] {
        let units: ThreeUnit[] = [];
        let unit: ThreeUnit;
        let groupUnits: ThreeBottomUnit[] | ThreeBottomEquipment[] |
            ThreeTabletopUnit[] | ThreeApronUnit[] | ThreeTopUnit[] |
            ThreeDecorUnit[] | ThreeBarCounter[] | ThreePenalUnit[];
        let group: keyof IKitchenUnits;

        for (group in this.units) {
            if (!this.units.hasOwnProperty(group)) {
                continue;
            }
            groupUnits = this.units[group];
            for (unit of groupUnits) {
                if (unit.isAngleUnit()) {
                    units.push(unit);
                }
            }
        }

        return units;
    }

    public getUnits(types?: string[]): ThreeUnit[] {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let groupUnits: ThreeBottomUnit[] | ThreeBottomEquipment[] |
            ThreeTabletopUnit[] | ThreeApronUnit[] | ThreeTopUnit[] |
            ThreeDecorUnit[] | ThreeBarCounter[] | ThreePenalUnit[];
        let group: keyof IKitchenUnits;

        units = [];
        for (group in this.units) {
            if (types && !types.includes(group)) {
                continue;
            }
            if (!this.units.hasOwnProperty(group)) {
                continue;
            }
            groupUnits = this.units[group];
            for (unit of groupUnits) {
                units.push(unit);
            }
        }

        return units;
    }

    public clearSelfVisibleAprons() {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let apron: ThreeApron;

        units = this.getObjects();
        for (unit of units) {
            if (!unit.aprons) {
                continue;
            }
            for (apron of unit.aprons) {
                apron.clearSelfVisible();
            }
        }
    }

    public getAprons(): ThreeApron[] {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let apron: ThreeApron;
        let aprons: ThreeApron[] = [];

        units = this.getObjects();
        for (unit of units) {
            if (unit.aprons){
                for (apron of unit.aprons) {
                    if (apron.isView3dVisible() && apron.isCalculate() &&
                        this.service.isCalculateAprons()) {
                        aprons.push(apron);
                    }
                }
            }
        }

        return aprons;
    }

    public getFacades(): ThreeFacade[] {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let itemFacades: ThreeFacade[];
        let facades: ThreeFacade[] = [];
        let facade: ThreeFacade;

        units = this.getObjects();
        for (unit of units) {
            itemFacades = unit.getSelfCalculateFacades();
            for (facade of itemFacades) {
                facades.push(facade);
            }
        }

        return facades;
    }

    public getTabletops(): ThreeTabletop[] {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let tabletop: ThreeTabletop;
        let tabletops: ThreeTabletop[] = [];

        if (!this.service.isCalculateTabletops()) {
            return tabletops;
        }
        units = this.getObjects();
        for (unit of units) {
            if (unit.tabletops){
                for (tabletop of unit.tabletops) {
                    if (tabletop.isView3dVisible() && tabletop.isCalculate() &&
                        !tabletop.isIncludeModulePrice()) {
                        tabletops.push(tabletop);
                    }
                }
            }
        }

        return tabletops;
    }

    public getCorners(): ThreeCorner[] {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let corner: ThreeCorner;
        let corners: ThreeCorner[] = [];

        units = this.getObjects();
        for (unit of units) {
            if (unit.corners){
                for (corner of unit.corners) {
                    if (corner.isView3dVisible() && corner.isCalculate() &&
                        this.service.isCalculateCorners()) {
                        corners.push(corner);
                    }
                }
            }
        }

        return corners;
    }

    public calculateProjectExtraOffers(): IProjectOffers | undefined {
        if (!this.extraOffers) {
            return undefined;
        }
        let projectExtraOffers: IProjectOffers = {};
        let index: string;

        for (index in this.extraOffers) {
            projectExtraOffers[index] = {
                id: this.extraOffers[index].id,
                count: this.extraOffers[index].count,
                part: this.extraOffers[index].part,
                unitId: this.extraOffers[index].unitId,
                cell: this.extraOffers[index].cell,
                isAutoEnable: this.extraOffers[index].isAutoEnable,
                sort: this.extraOffers[index].sort,
                isAuto: this.extraOffers[index].isAuto,
                autoCount: this.extraOffers[index].autoCount,
                comment: this.extraOffers[index].comment,
            }
        }

        return projectExtraOffers;
    }

    public getPlinths(): ThreePlinth[] {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let plinth: ThreePlinth;
        let plinths: ThreePlinth[] = [];

        units = this.getObjects();
        for (unit of units) {
            if (unit.plinths){
                for (plinth of unit.plinths) {
                    if (plinth.isView3dVisible() && plinth.isCalculate() &&
                        this.service.isCalculatePlinths()) {
                        plinths.push(plinth);
                    }
                }
            }
        }

        return plinths;
    }

    public getObjects(): ThreeUnit[] {
        let units: ThreeUnit[];
        let equipments: ThreeUnit[];

        units = this.getUnits();
        equipments = this.getEquipments();

        return units.concat(equipments);
    }

    public getObjectById(id: number): ThreeUnit | undefined {
        let objects: ThreeUnit[];
        let object: ThreeUnit;
        let equipment: ThreeBuiltInEquipment;

        objects = this.getObjects();
        for (object of objects) {
            if (object.getId() === id) {
                return object;
            }
            if (object instanceof ThreeKUnit) {
                if (object.equipments) {
                    for (equipment of object.equipments) {
                        if (equipment.getId() === id) {
                            return equipment;
                        }
                    }
                }
            }
        }

        return undefined;
    }

    public getBottomObjects(): ThreeUnit[] {
        let units: ThreeUnit[] = [];
        let facadeUnit: ThreeFacadeUnit;

        units = units.concat(this.units.penals);
        units = units.concat(this.units.bottomUnits);
        units = units.concat(this.units.barCounters);
        units = units.concat(this.units.tabletops);
        units = units.concat(this.equipments.bottom);
        for (facadeUnit of this.units.facades) {
            if (facadeUnit.getLevel() === LEVEL_BOTTOM) {
                units.push(facadeUnit);
            }
        }

        return units;
    }

    public getTopObjects(): ThreeUnit[] {
        let units: ThreeUnit[] = [];
        let facadeUnit: ThreeFacadeUnit;

        units = units.concat(this.units.topUnits);
        units = units.concat(this.equipments.top);
        for (facadeUnit of this.units.facades) {
            if (facadeUnit.getLevel() === LEVEL_TOP) {
                units.push(facadeUnit);
            }
        }

        return units;
    }

    public getUnitsWithPlinths(): ThreeUnit[] {
        let units: ThreeUnit[] = [];
        let unit: ThreeBottomUnit;
        let penal: ThreePenalUnit;

        for (unit of this.units.bottomUnits) {
            units.push(unit);
        }
        for (penal of this.units.penals) {
            units.push(penal);
        }

        return units;
    }

    /**
     * Метод генерирует SaveData для модуля из выбранных параметров
     * @param uid
     * @param createOptions
     * @param modulePrice
     * @param catalogCode
     */
    public generateSaveObjectData(uid: string, createOptions: any, modulePrice: IModulePriceData, catalogCode?: string): ISaveUnitData {
        let corpus: ISaveCorpusData;
        let sizes;
        let index;
        let saveObjectData: ISaveUnitData;

        sizes = {
            length: !isNaN(createOptions.width) ? +createOptions.width : 0,
            height: !isNaN(createOptions.height) ? +createOptions.height : 0,
            width: !isNaN(createOptions.depth) ? +createOptions.depth : 0,
        }
        if ('corpus' in createOptions) {
            corpus = {
                id: this.service.generateUId(),
                thickness: createOptions.corpus.thickness,
                backThickness: createOptions.corpus.backThickness,
                sizes: {
                    length: +createOptions.corpus.width,
                    height: +createOptions.corpus.height,
                    width: +createOptions.corpus.depth,
                },
                material: createOptions.corpus.material || createOptions.corpusMaterial || this.service.getCorpusMaterial().id,
                shelves: createOptions.corpus.shelves || [],
            };
            for (index in createOptions.corpus) {
                if (['width', 'height', 'depth'].indexOf(index) !== -1) {
                    continue;
                }
                // @ts-ignore
                corpus[index] = createOptions.corpus[index];
            }
            createOptions.corpus = corpus;
            sizes = {
                length: +corpus.sizes.length,
                height: +corpus.sizes.height,
                width: +corpus.sizes.width,
            }
        }
        if ('legs' in createOptions) {
            sizes.height += 100;
        }
        if ('sizes' in createOptions) {
            if (!isNaN(createOptions.sizes.width)) {
                sizes.length = +createOptions.sizes.width;
            }
            if (!isNaN(createOptions.sizes.height)) {
                sizes.height = +createOptions.sizes.height;
            }
            if (!isNaN(createOptions.sizes.depth)) {
                sizes.width = +createOptions.sizes.depth;
            }
        }

        saveObjectData = {
            id: this.service.generateUId(),
            uid: uid,
            name: createOptions.name,
            image: createOptions.image,
            className: createOptions.className,
            sizes: sizes,
            notPrice: createOptions.notPrice,
            calculateType: createOptions.calculateType,
            checkCatalogType: createOptions.checkCatalogType,
            canStretch: createOptions.canStretch,
            isStretch: createOptions.isStretch,
        };
        for (index in createOptions) {
            if (index === 'sizes') {
                continue;
            }
            // @ts-ignore
            saveObjectData[index] = CommonHelper.deepCopy(createOptions[index]);
        }
        saveObjectData.offerId = modulePrice.id;
        saveObjectData.modulePrice = modulePrice;

        if (catalogCode !== undefined) {
            saveObjectData.catalogCode = catalogCode;
        }
        if (saveObjectData.syncSideTypes !== false &&
            saveObjectData.sideType &&
            saveObjectData.facades &&
            saveObjectData.facades.length > 0) {
            for (index in saveObjectData.facades) {
                if (saveObjectData.facades[index].openType === FACADE_OPEN_TYPE_VERTICAL_PIVOT &&
                    !saveObjectData.facades[index].disableSideTypes) {
                    saveObjectData.facades[index].sideType = '' + saveObjectData.sideType as TDirectionSideType;
                }
            }
        }
        if (saveObjectData.facades &&
            saveObjectData.facades.length > 0) {
            for (index in saveObjectData.facades) {
                if (!saveObjectData.facades[index].facadeMaterial) {
                    switch (saveObjectData.facades[index].facadeMaterialType) {
                        case FACADE_MATERIAL_TYPE_CORPUS_MATERIAL:
                            if (createOptions.corpusFacadeMaterial) {
                                saveObjectData.facades[index].facadeMaterial = createOptions.corpusFacadeMaterial;
                            }
                            break;
                        default:
                            if (createOptions.facadeMaterial) {
                                saveObjectData.facades[index].facadeMaterial = createOptions.facadeMaterial;
                            }
                            break;
                    }
                }
            }
        }

        return saveObjectData;
    }

    public createCommonObject(objectData: ISaveUnitData): ThreeUnit | ThreeConstructive | undefined {

        switch (objectData.className) {

            case CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE:
                const bottomUnitDoubleHandle = new ThreeBottomDoubleHoleHandleUnit(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitDoubleHandle.initState();
                bottomUnitDoubleHandle.createView();
                this.units.bottomUnits.push(bottomUnitDoubleHandle);

            
                return bottomUnitDoubleHandle  
            case CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE:
                const bottomUnitSingleHandle = new ThreeBottomSingleHoleHandleUnit(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitSingleHandle.initState();
                bottomUnitSingleHandle.createView();
                this.units.bottomUnits.push(bottomUnitSingleHandle);

            
                return bottomUnitSingleHandle;
            case CLASSNAME_BOTTOM_UNIT_NORMAL:
                const bottomUnit = new ThreeBottomUnitNormal(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnit.initState();
                bottomUnit.createView();
                this.units.bottomUnits.push(bottomUnit);

                return bottomUnit;
            case CLASSNAME_BOTTOM_UNIT_PILASTER:
                const bottomUnitPilaster = new ThreeBottomUnitPilaster(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitPilaster.initState();
                bottomUnitPilaster.createView();
                this.units.bottomUnits.push(bottomUnitPilaster);

                return bottomUnitPilaster;
            case CLASSNAME_BOTTOM_UNIT_BAR_STAND:
                const bottomUnitBarStand = new ThreeBottomUnitBarStand(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitBarStand.initState();
                bottomUnitBarStand.createView();
                this.units.bottomUnits.push(bottomUnitBarStand);

                return bottomUnitBarStand;
            case CLASSNAME_BOTTOM_UNIT_BAR_P:
                const bottomUnitBarP = new ThreeBottomUnitBarP(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitBarP.initState();
                bottomUnitBarP.createView();
                this.units.bottomUnits.push(bottomUnitBarP);

                return bottomUnitBarP;
            case CLASSNAME_BOTTOM_UNIT_BAR_RAVENNA:
                const bottomUnitBarRavenna = new ThreeBottomUnitBarRavenna(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitBarRavenna.initState();
                bottomUnitBarRavenna.createView();
                this.units.bottomUnits.push(bottomUnitBarRavenna);

                return bottomUnitBarRavenna;
            case CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL:
                const bottomUnitFullLengthSidewall = new ThreeBottomUnitFullLengthSidewall(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitFullLengthSidewall.initState();
                bottomUnitFullLengthSidewall.createView();
                this.units.bottomUnits.push(bottomUnitFullLengthSidewall);

                return bottomUnitFullLengthSidewall;
            case CLASSNAME_BOTTOM_UNIT_SINK:
                const bottomUnitSink = new ThreeBottomUnitSink(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitSink.initState();
                bottomUnitSink.createView();
                this.units.bottomUnits.push(bottomUnitSink);

                return bottomUnitSink;
            case CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_SINK:
                const bottomUnitFullLengthSidewallSink = new ThreeBottomUnitFullLengthSidewallSink(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitFullLengthSidewallSink.initState();
                bottomUnitFullLengthSidewallSink.createView();
                this.units.bottomUnits.push(bottomUnitFullLengthSidewallSink);

                return bottomUnitFullLengthSidewallSink;
            case CLASSNAME_BOTTOM_UNIT_SINK_OPENED:
                const bottomUnitSinkOpened = new ThreeBottomUnitSinkOpened(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitSinkOpened.initState();
                bottomUnitSinkOpened.createView();
                this.units.bottomUnits.push(bottomUnitSinkOpened);

                return bottomUnitSinkOpened;
            case CLASSNAME_BOTTOM_UNIT_OVEN:
                const bottomUnitOven = new ThreeBottomUnitOven(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitOven.initState();
                bottomUnitOven.createView();
                this.units.bottomUnits.push(bottomUnitOven);

                return bottomUnitOven;
            case CLASSNAME_BOTTOM_UNIT_DISHWASHER:
                const bottomUnitDishwasher = new ThreeBottomUnitDishwasher(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitDishwasher.initState();
                bottomUnitDishwasher.createView();
                this.units.bottomUnits.push(bottomUnitDishwasher);

                return bottomUnitDishwasher;
            case CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_OVEN:
                const bottomUnitFullLengthSidewallOven = new ThreeBottomUnitFullLengthSidewallOven(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitFullLengthSidewallOven.initState();
                bottomUnitFullLengthSidewallOven.createView();
                this.units.bottomUnits.push(bottomUnitFullLengthSidewallOven);

                return bottomUnitFullLengthSidewallOven;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL:
                const bottomUnitAngleNormal = new ThreeBottomUnitAngleNormal(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleNormalData, this.service);
                bottomUnitAngleNormal.initState();
                bottomUnitAngleNormal.createView();
                this.units.bottomUnits.push(bottomUnitAngleNormal);

                return bottomUnitAngleNormal;

            case CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE:
                const bottomUnitAngleNormalSingleHandle = new ThreeBottomUnitAngleNormalSingleHandle(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleNormalData, this.service);
                bottomUnitAngleNormalSingleHandle.initState();
                bottomUnitAngleNormalSingleHandle.createView();
                this.units.bottomUnits.push(bottomUnitAngleNormalSingleHandle);

                return bottomUnitAngleNormalSingleHandle;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_TWO_FACADES_90:
                const bottomUnitAngleTwoFacades90 = new ThreeBottomUnitAngleTwoFacades90(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleTwoFacades90Data, this.service);
                bottomUnitAngleTwoFacades90.initState();
                bottomUnitAngleTwoFacades90.createView();
                this.units.bottomUnits.push(bottomUnitAngleTwoFacades90);

                return bottomUnitAngleTwoFacades90;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_NORMAL_SINK:
                const BottomAngleUnitFullLengthSidewallNormalSink = new ThreeBottomUnitAngleFullLengthSidewallNormalSink(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleFullLengthSidewallNormalSinkData, this.service);
                BottomAngleUnitFullLengthSidewallNormalSink.initState();
                BottomAngleUnitFullLengthSidewallNormalSink.createView();
                this.units.bottomUnits.push(BottomAngleUnitFullLengthSidewallNormalSink);

                return BottomAngleUnitFullLengthSidewallNormalSink;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_CUBE:
                const bottomUnitAngleCube = new ThreeBottomUnitAngleCube(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleCubeData, this.service);
                bottomUnitAngleCube.initState();
                bottomUnitAngleCube.createView();
                this.units.bottomUnits.push(bottomUnitAngleCube);

                return bottomUnitAngleCube;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK:
                const BottomUnitAngleFullLengthSidewallCubeSink = new ThreeBottomUnitAngleFullLengthSidewallCubeSink(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleFullLengthSidewallCubeSinkData, this.service);
                BottomUnitAngleFullLengthSidewallCubeSink.initState();
                BottomUnitAngleFullLengthSidewallCubeSink.createView();
                this.units.bottomUnits.push(BottomUnitAngleFullLengthSidewallCubeSink);

                return BottomUnitAngleFullLengthSidewallCubeSink;
            case CLASSNAME_BOTTOM_UNIT_END_NORMAL:
                const bottomUnitEndNormal = new ThreeBottomUnitEndNormal(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndNormalData, this.service);
                bottomUnitEndNormal.initState();
                bottomUnitEndNormal.createView();
                this.units.bottomUnits.push(bottomUnitEndNormal);

                return bottomUnitEndNormal;
            case CLASSNAME_BOTTOM_UNIT_END_PILASTER:
                const bottomUnitEndPilaster = new ThreeBottomUnitEndPilaster(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndConsoleData, this.service);
                bottomUnitEndPilaster.initState();
                bottomUnitEndPilaster.createView();
                this.units.bottomUnits.push(bottomUnitEndPilaster);

                return bottomUnitEndPilaster;
            case CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL:
                const bottomUnitEndFullLengthSidewall = new ThreeBottomUnitEndFullLengthSidewall(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndFullLengthSidewallData, this.service);
                bottomUnitEndFullLengthSidewall.initState();
                bottomUnitEndFullLengthSidewall.createView();
                this.units.bottomUnits.push(bottomUnitEndFullLengthSidewall);

                return bottomUnitEndFullLengthSidewall;
            case CLASSNAME_BOTTOM_UNIT_END_OPENED:
                const bottomUnitEndOpened = new ThreeBottomUnitEndOpened(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndOpenedData, this.service);
                bottomUnitEndOpened.initState();
                bottomUnitEndOpened.createView();
                this.units.bottomUnits.push(bottomUnitEndOpened);

                return bottomUnitEndOpened;

            case CLASSNAME_BOTTOM_UNIT_END_OPENED_CIRCLE:
                const bottomUnitEndOpenedCircle = new ThreeBottomUnitEndOpenedCircle(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndOpenedCircleData, this.service);
                bottomUnitEndOpenedCircle.initState();
                bottomUnitEndOpenedCircle.createView();
                this.units.bottomUnits.push(bottomUnitEndOpenedCircle);

                return bottomUnitEndOpenedCircle;

            case CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_OPENED:
                const BottomUnitEndFullLengthSidewallOpened = new ThreeBottomUnitEndFullLengthSidewallOpened(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndFullLengthSidewallOpenedData, this.service);
                BottomUnitEndFullLengthSidewallOpened.initState();
                BottomUnitEndFullLengthSidewallOpened.createView();
                this.units.bottomUnits.push(BottomUnitEndFullLengthSidewallOpened);

                return BottomUnitEndFullLengthSidewallOpened;

            case CLASSNAME_BOTTOM_UNIT_END_CONSOLE:
                const bottomUnitEndConsole = new ThreeBottomUnitEndConsole(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndConsoleData, this.service);
                bottomUnitEndConsole.initState();
                bottomUnitEndConsole.createView();
                this.units.bottomUnits.push(bottomUnitEndConsole);

                return bottomUnitEndConsole;
            case CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_CONSOLE:
                const bottomUnitEndFullLengthSidewallConsole = new ThreeBottomUnitEndFullLengthSidewallConsole(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndFullLengthSidewallConsoleData, this.service);
                bottomUnitEndFullLengthSidewallConsole.initState();
                bottomUnitEndFullLengthSidewallConsole.createView();
                this.units.bottomUnits.push(bottomUnitEndFullLengthSidewallConsole);

                return bottomUnitEndFullLengthSidewallConsole;
            case CLASSNAME_TOP_UNIT_NORMAL:
                const topUnit = new ThreeTopUnitNormal(CommonHelper.deepCopy(objectData) as ISaveTopUnitData, this.service);
                topUnit.initState();
                topUnit.createView();
                this.units.topUnits.push(topUnit);

                return topUnit;
            case CLASSNAME_TOP_UNIT_PILASTER:
                const topUnitPilaster = new ThreeTopUnitPilaster(CommonHelper.deepCopy(objectData) as ISaveTopUnitData, this.service);
                topUnitPilaster.initState();
                topUnitPilaster.createView();
                this.units.topUnits.push(topUnitPilaster);

                return topUnitPilaster;
            case CLASSNAME_TOP_UNIT_SHELF_FIGURED:
                const topUnitShelfFigured = new ThreeTopUnitShelfFigured(CommonHelper.deepCopy(objectData) as ISaveTopUnitShelfFiguredData, this.service);
                topUnitShelfFigured.initState();
                topUnitShelfFigured.createView();
                this.units.topUnits.push(topUnitShelfFigured);

                return topUnitShelfFigured;
            case CLASSNAME_TOP_UNIT_ANGLE_NORMAL:
                const topUnitAngleNormal = new ThreeTopUnitAngleNormal(CommonHelper.deepCopy(objectData) as ISaveTopUnitAngleNormalData, this.service);
                topUnitAngleNormal.initState();
                topUnitAngleNormal.createView();
                this.units.topUnits.push(topUnitAngleNormal);

                return topUnitAngleNormal;
            case CLASSNAME_TOP_UNIT_ANGLE_TWO_FACADES_90:
                const topUnitAngleTwoFacades90 = new ThreeTopUnitAngleTwoFacades90(CommonHelper.deepCopy(objectData) as ISaveTopUnitAngleTwoFacades90Data, this.service);
                topUnitAngleTwoFacades90.initState();
                topUnitAngleTwoFacades90.createView();
                this.units.topUnits.push(topUnitAngleTwoFacades90);

                return topUnitAngleTwoFacades90;
            case CLASSNAME_TOP_UNIT_ANGLE_CUBE:
                const topUnitAngleCube = new ThreeTopUnitAngleCube(CommonHelper.deepCopy(objectData) as ISaveTopUnitAngleCubeData, this.service);
                topUnitAngleCube.initState();
                topUnitAngleCube.createView();
                this.units.topUnits.push(topUnitAngleCube);

                return topUnitAngleCube;
            case CLASSNAME_TOP_UNIT_ANGLE_DOUBLE:
                const topUnitAngleDouble = new ThreeTopUnitAngleDouble(CommonHelper.deepCopy(objectData) as ISaveTopUnitAngleDoubleData, this.service);
                topUnitAngleDouble.initState();
                topUnitAngleDouble.createView();
                this.units.topUnits.push(topUnitAngleDouble);

                return topUnitAngleDouble;
            case CLASSNAME_TOP_UNIT_END_NORMAL:
                const topUnitEndNormal = new ThreeTopUnitEndNormal(CommonHelper.deepCopy(objectData) as ISaveTopUnitEndNormalData, this.service);
                topUnitEndNormal.initState();
                topUnitEndNormal.createView();
                this.units.topUnits.push(topUnitEndNormal);

                return topUnitEndNormal;
            case CLASSNAME_TOP_UNIT_END_PILASTER:
                const TopUnitEndPilaster = new ThreeTopUnitEndPilaster(CommonHelper.deepCopy(objectData) as ISaveTopUnitEndPilasterData, this.service);
                TopUnitEndPilaster.initState();
                TopUnitEndPilaster.createView();
                this.units.topUnits.push(TopUnitEndPilaster);

                return TopUnitEndPilaster;

            case CLASSNAME_TOP_UNIT_END_OPENED:
                const topUnitEndOpened = new ThreeTopUnitEndOpened(CommonHelper.deepCopy(objectData) as ISaveTopUnitEndOpenedData, this.service);
                topUnitEndOpened.initState();
                topUnitEndOpened.createView();
                this.units.topUnits.push(topUnitEndOpened);

                return topUnitEndOpened;

            case CLASSNAME_TOP_UNIT_END_OPENED_CIRCLE:
                const topUnitEndOpenedCircle = new ThreeTopUnitEndOpenedCircle(CommonHelper.deepCopy(objectData) as ISaveTopUnitEndOpenedCircleData, this.service);
                topUnitEndOpenedCircle.initState();
                topUnitEndOpenedCircle.createView();
                this.units.topUnits.push(topUnitEndOpenedCircle);

                return topUnitEndOpenedCircle;
            case CLASSNAME_TOP_UNIT_WINERY:
                const topUnitWinery = new ThreeTopUnitWinery(CommonHelper.deepCopy(objectData) as ISaveTopUnitData, this.service);
                topUnitWinery.initState();
                topUnitWinery.createView();
                this.units.topUnits.push(topUnitWinery);

                return topUnitWinery;
            case CLASSNAME_TOP_UNIT_RACK_FRAME:
                const topUnitRackFrame = new ThreeTopUnitRackFrame(CommonHelper.deepCopy(objectData) as ISaveTopUnitRackFrameData, this.service);
                topUnitRackFrame.initState();
                topUnitRackFrame.createView();
                this.units.topUnits.push(topUnitRackFrame);

                return topUnitRackFrame;
            case CLASSNAME_TOP_UNIT_BAR_RAVENNA:
                const topUnitBarRavenna = new ThreeTopUnitBarRavenna(CommonHelper.deepCopy(objectData) as ISaveTopUnitData, this.service);
                topUnitBarRavenna.initState();
                topUnitBarRavenna.createView();
                this.units.topUnits.push(topUnitBarRavenna);

                return topUnitBarRavenna;
            case CLASSNAME_TOP_UNIT_SHELF:
                const topUnitShelf = new ThreeTopUnitShelf(CommonHelper.deepCopy(objectData) as ISaveTopUnitShelfData, this.service);
                topUnitShelf.initState();
                topUnitShelf.createView();
                this.units.topUnits.push(topUnitShelf);

                return topUnitShelf;
            case CLASSNAME_PENAL_UNIT_NORMAL:
                const penalUnit = new ThreePenalUnitNormal(CommonHelper.deepCopy(objectData) as ISavePenalUnitData, this.service);
                penalUnit.initState();
                penalUnit.createView();
                this.units.penals.push(penalUnit);

                return penalUnit;
            case CLASSNAME_PENAL_UNIT_SINGLE_HANDLE:
                const penalUnitSingleHandle = new ThreePenalUnitSingleIntegratedHandle(CommonHelper.deepCopy(objectData) as ISavePenalUnitData, this.service);
                penalUnitSingleHandle.initState();
                penalUnitSingleHandle.createView();
                this.units.penals.push(penalUnitSingleHandle);

                return penalUnitSingleHandle;
            case CLASSNAME_PENAL_UNIT_FULL_LENGTH_SIDEWALL:
                const penalUnitFullLengthSidewall = new ThreePenalUnitFullLengthSidewall(CommonHelper.deepCopy(objectData) as ISavePenalUnitData, this.service);
                penalUnitFullLengthSidewall.initState();
                penalUnitFullLengthSidewall.createView();
                this.units.penals.push(penalUnitFullLengthSidewall);

                return penalUnitFullLengthSidewall;
            case CLASSNAME_PENAL_UNIT_TOP:
                const penalUnitTop = new ThreePenalUnitTop(CommonHelper.deepCopy(objectData) as ISavePenalUnitData, this.service);
                penalUnitTop.initState();
                penalUnitTop.createView();
                this.units.penals.push(penalUnitTop);

                return penalUnitTop;

            case CLASSNAME_PENAL_UNIT_END_CONSOLE:
                const penalUnitEndConsole = new ThreePenalUnitEndConsole(CommonHelper.deepCopy(objectData) as ISavePenalUnitEndConsoleData, this.service);
                penalUnitEndConsole.initState();
                penalUnitEndConsole.createView();
                this.units.penals.push(penalUnitEndConsole);

                return penalUnitEndConsole;
            case CLASSNAME_SIDE_BOTTOM_FACADE_UNIT:
                const sideBottomFacadeUnit = new ThreeSideBottomFacadeUnit(CommonHelper.deepCopy(objectData) as ISaveFacadeUnitData, this.service);
                sideBottomFacadeUnit.initState();
                sideBottomFacadeUnit.createView();
                this.units.facades.push(sideBottomFacadeUnit);

                return sideBottomFacadeUnit;
            case CLASSNAME_SIDE_TOP_FACADE_UNIT:
                const sideTopFacadeUnit = new ThreeSideTopFacadeUnit(CommonHelper.deepCopy(objectData) as ISaveFacadeUnitData, this.service);
                sideTopFacadeUnit.initState();
                sideTopFacadeUnit.createView();
                this.units.facades.push(sideTopFacadeUnit);

                return sideTopFacadeUnit;
            case CLASSNAME_APRON_UNIT:
                const apronUnit = new ThreeApronUnit(CommonHelper.deepCopy(objectData) as ISaveApronUnitData, this.service);
                apronUnit.initState();
                apronUnit.createView();
                this.units.aprons.push(apronUnit);

                return apronUnit;
            case CLASSNAME_TABLETOP_UNIT:
                const tabletopUnit = new ThreeTabletopUnit(CommonHelper.deepCopy(objectData) as ISaveTabletopUnitData, this.service);
                tabletopUnit.initState();
                tabletopUnit.createView();
                this.units.tabletops.push(tabletopUnit);

                return tabletopUnit;
            case CLASSNAME_EQUIPMENT_SEPARATE_EXTRACT:
                const extractEquipment = new ThreeExtractEquipment(CommonHelper.deepCopy(objectData) as ISaveExtractEquipmentData, this.service);
                extractEquipment.initState();
                extractEquipment.createView();
                this.equipments.top.push(extractEquipment);

                return extractEquipment;
            case CLASSNAME_EQUIPMENT_COOKER:
                const cookerEquipment = new ThreeCookerEquipment(CommonHelper.deepCopy(objectData) as ISaveCookerEquipmentData, this.service);
                cookerEquipment.initState();
                cookerEquipment.createView();
                this.equipments.bottom.push(cookerEquipment);

                return cookerEquipment;
            case CLASSNAME_EQUIPMENT_WASHER:
                const washerEquipment = new ThreeWasherEquipment(CommonHelper.deepCopy(objectData) as ISaveWasherEquipmentData, this.service);
                washerEquipment.initState();
                washerEquipment.createView();
                this.equipments.bottom.push(washerEquipment);

                return washerEquipment;
            case CLASSNAME_EQUIPMENT_DISHWASHER:
                const dishwasherEquipment = new ThreeDishwasherEquipment(CommonHelper.deepCopy(objectData) as ISaveDishwasherEquipmentData, this.service);
                dishwasherEquipment.initState();
                dishwasherEquipment.createView();
                this.equipments.bottom.push(dishwasherEquipment);

                return dishwasherEquipment;
            case CLASSNAME_EQUIPMENT_REFRIGERATOR:
                const refrigeratorEquipment = new ThreeRefrigeratorEquipment(CommonHelper.deepCopy(objectData) as ISaveEquipmentData, this.service);
                refrigeratorEquipment.initState();
                refrigeratorEquipment.createView();
                this.equipments.big.push(refrigeratorEquipment);

                return refrigeratorEquipment;
            case CLASSNAME_CONSTRUCTIVE_DOOR:
            case CLASSNAME_CONSTRUCTIVE_DOORWAY:
            case CLASSNAME_CONSTRUCTIVE_WINDOW:
                if (!objectData.wall) {
                    objectData.wall = this.service.getRoom().getDefaultWall().getId();
                }
                return this.service.getRoom().createConstructiveUnit(CommonHelper.deepCopy(objectData) as ISaveConstructiveData);
            case CLASSNAME_CONSTRUCTIVE_PILLAR:
            case CLASSNAME_CONSTRUCTIVE_WALL_ISLAND:
            case CLASSNAME_CONSTRUCTIVE_GAS_METER:
            case CLASSNAME_CONSTRUCTIVE_GAS_BOILER:
            case CLASSNAME_CONSTRUCTIVE_VENTILATION:
            case CLASSNAME_CONSTRUCTIVE_WATER_HEATER_HORIZONTAL:
            case CLASSNAME_CONSTRUCTIVE_WATER_HEATER_VERTICAL:
            case CLASSNAME_CONSTRUCTIVE_SOCKET:
            case CLASSNAME_CONSTRUCTIVE_SWITCH:
            case CLASSNAME_CONSTRUCTIVE_RADIATOR_SECTION:
            case CLASSNAME_CONSTRUCTIVE_COOLER:
                return this.service.getRoom().createConstructiveUnit(CommonHelper.deepCopy(objectData) as ISaveConstructiveData);
            default:
                console.log(objectData.className);
                break;
        }

        return undefined;
    }

    public createCommonObjectEDIT(objectData: ISaveUnitData): ThreeUnit | ThreeConstructive | undefined {
        switch (objectData.className) {
            case CLASSNAME_BOTTOM_UNIT_SINGLE_HANDLE:
                const bottomUnitSingleHandle = new ThreeBottomSingleHoleHandleUnit(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitSingleHandle.initState();
                bottomUnitSingleHandle.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitSingleHandle);

            
                return bottomUnitSingleHandle  
            case CLASSNAME_BOTTOM_UNIT_DOUBLE_HANDLE:
                const bottomUnitDoubleHandle = new ThreeBottomDoubleHoleHandleUnit(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitDoubleHandle.initState();
                bottomUnitDoubleHandle.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitDoubleHandle);

            
                return bottomUnitDoubleHandle  
            case CLASSNAME_BOTTOM_UNIT_NORMAL:
                const bottomUnit = new ThreeBottomUnitNormal(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnit.initState();
                bottomUnit.createViewEDIT();
                this.units.bottomUnits.push(bottomUnit);

                return bottomUnit;
            case CLASSNAME_BOTTOM_UNIT_PILASTER:
                const bottomUnitPilaster = new ThreeBottomUnitPilaster(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitPilaster.initState();
                bottomUnitPilaster.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitPilaster);

                return bottomUnitPilaster;
            case CLASSNAME_BOTTOM_UNIT_BAR_STAND:
                const bottomUnitBarStand = new ThreeBottomUnitBarStand(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitBarStand.initState();
                bottomUnitBarStand.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitBarStand);

                return bottomUnitBarStand;
            case CLASSNAME_BOTTOM_UNIT_BAR_P:
                const bottomUnitBarP = new ThreeBottomUnitBarP(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitBarP.initState();
                bottomUnitBarP.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitBarP);

                return bottomUnitBarP;
            case CLASSNAME_BOTTOM_UNIT_BAR_RAVENNA:
                const bottomUnitBarRavenna = new ThreeBottomUnitBarRavenna(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitBarRavenna.initState();
                bottomUnitBarRavenna.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitBarRavenna);

                return bottomUnitBarRavenna;
            case CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL:
                const bottomUnitFullLengthSidewall = new ThreeBottomUnitFullLengthSidewall(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitFullLengthSidewall.initState();
                bottomUnitFullLengthSidewall.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitFullLengthSidewall);

                return bottomUnitFullLengthSidewall;
            case CLASSNAME_BOTTOM_UNIT_SINK:
                const bottomUnitSink = new ThreeBottomUnitSink(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitSink.initState();
                bottomUnitSink.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitSink);

                return bottomUnitSink;
            case CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_SINK:
                const bottomUnitFullLengthSidewallSink = new ThreeBottomUnitFullLengthSidewallSink(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitFullLengthSidewallSink.initState();
                bottomUnitFullLengthSidewallSink.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitFullLengthSidewallSink);

                return bottomUnitFullLengthSidewallSink;
            case CLASSNAME_BOTTOM_UNIT_SINK_OPENED:
                const bottomUnitSinkOpened = new ThreeBottomUnitSinkOpened(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitSinkOpened.initState();
                bottomUnitSinkOpened.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitSinkOpened);

                return bottomUnitSinkOpened;
            case CLASSNAME_BOTTOM_UNIT_OVEN:
                const bottomUnitOven = new ThreeBottomUnitOven(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitOven.initState();
                bottomUnitOven.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitOven);

                return bottomUnitOven;
            case CLASSNAME_BOTTOM_UNIT_DISHWASHER:
                const bottomUnitDishwasher = new ThreeBottomUnitDishwasher(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitDishwasher.initState();
                bottomUnitDishwasher.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitDishwasher);

                return bottomUnitDishwasher;
            case CLASSNAME_BOTTOM_UNIT_FULL_LENGTH_SIDEWALL_OVEN:
                const bottomUnitFullLengthSidewallOven = new ThreeBottomUnitFullLengthSidewallOven(CommonHelper.deepCopy(objectData) as ISaveBottomUnitData, this.service);
                bottomUnitFullLengthSidewallOven.initState();
                bottomUnitFullLengthSidewallOven.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitFullLengthSidewallOven);

                return bottomUnitFullLengthSidewallOven;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL:
                const bottomUnitAngleNormal = new ThreeBottomUnitAngleNormal(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleNormalData, this.service);
                bottomUnitAngleNormal.initState();
                bottomUnitAngleNormal.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitAngleNormal);

                return bottomUnitAngleNormal;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE:
                const bottomUnitAngleNormalSingleHandle = new ThreeBottomUnitAngleNormalSingleHandle(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleNormalData, this.service);
                bottomUnitAngleNormalSingleHandle.initState();
                bottomUnitAngleNormalSingleHandle.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitAngleNormalSingleHandle);

                return bottomUnitAngleNormalSingleHandle;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_TWO_FACADES_90:
                const bottomUnitAngleTwoFacades90 = new ThreeBottomUnitAngleTwoFacades90(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleTwoFacades90Data, this.service);
                bottomUnitAngleTwoFacades90.initState();
                bottomUnitAngleTwoFacades90.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitAngleTwoFacades90);

                return bottomUnitAngleTwoFacades90;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_NORMAL_SINK:
                const BottomAngleUnitFullLengthSidewallNormalSink = new ThreeBottomUnitAngleFullLengthSidewallNormalSink(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleFullLengthSidewallNormalSinkData, this.service);
                BottomAngleUnitFullLengthSidewallNormalSink.initState();
                BottomAngleUnitFullLengthSidewallNormalSink.createViewEDIT();
                this.units.bottomUnits.push(BottomAngleUnitFullLengthSidewallNormalSink);

                return BottomAngleUnitFullLengthSidewallNormalSink;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_CUBE:
                const bottomUnitAngleCube = new ThreeBottomUnitAngleCube(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleCubeData, this.service);
                bottomUnitAngleCube.initState();
                bottomUnitAngleCube.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitAngleCube);

                return bottomUnitAngleCube;
            case CLASSNAME_BOTTOM_ANGLE_UNIT_FULL_LENGTH_SIDEWALL_CUBE_SINK:
                const BottomUnitAngleFullLengthSidewallCubeSink = new ThreeBottomUnitAngleFullLengthSidewallCubeSink(CommonHelper.deepCopy(objectData) as ISaveBottomUnitAngleFullLengthSidewallCubeSinkData, this.service);
                BottomUnitAngleFullLengthSidewallCubeSink.initState();
                BottomUnitAngleFullLengthSidewallCubeSink.createViewEDIT();
                this.units.bottomUnits.push(BottomUnitAngleFullLengthSidewallCubeSink);

                return BottomUnitAngleFullLengthSidewallCubeSink;
            case CLASSNAME_BOTTOM_UNIT_END_NORMAL:
                const bottomUnitEndNormal = new ThreeBottomUnitEndNormal(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndNormalData, this.service);
                bottomUnitEndNormal.initState();
                bottomUnitEndNormal.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitEndNormal);

                return bottomUnitEndNormal;
            case CLASSNAME_BOTTOM_UNIT_END_PILASTER:
                const bottomUnitEndPilaster = new ThreeBottomUnitEndPilaster(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndConsoleData, this.service);
                bottomUnitEndPilaster.initState();
                bottomUnitEndPilaster.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitEndPilaster);

                return bottomUnitEndPilaster;
            case CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL:
                const bottomUnitEndFullLengthSidewall = new ThreeBottomUnitEndFullLengthSidewall(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndFullLengthSidewallData, this.service);
                bottomUnitEndFullLengthSidewall.initState();
                bottomUnitEndFullLengthSidewall.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitEndFullLengthSidewall);

                return bottomUnitEndFullLengthSidewall;
            case CLASSNAME_BOTTOM_UNIT_END_OPENED:
                const bottomUnitEndOpened = new ThreeBottomUnitEndOpened(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndOpenedData, this.service);
                bottomUnitEndOpened.initState();
                bottomUnitEndOpened.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitEndOpened);

                return bottomUnitEndOpened;

            case CLASSNAME_BOTTOM_UNIT_END_OPENED_CIRCLE:
                const bottomUnitEndOpenedCircle = new ThreeBottomUnitEndOpenedCircle(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndOpenedCircleData, this.service);
                bottomUnitEndOpenedCircle.initState();
                bottomUnitEndOpenedCircle.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitEndOpenedCircle);

                return bottomUnitEndOpenedCircle;

            case CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_OPENED:
                const BottomUnitEndFullLengthSidewallOpened = new ThreeBottomUnitEndFullLengthSidewallOpened(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndFullLengthSidewallOpenedData, this.service);
                BottomUnitEndFullLengthSidewallOpened.initState();
                BottomUnitEndFullLengthSidewallOpened.createViewEDIT();
                this.units.bottomUnits.push(BottomUnitEndFullLengthSidewallOpened);

                return BottomUnitEndFullLengthSidewallOpened;

            case CLASSNAME_BOTTOM_UNIT_END_CONSOLE:
                const bottomUnitEndConsole = new ThreeBottomUnitEndConsole(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndConsoleData, this.service);
                bottomUnitEndConsole.initState();
                bottomUnitEndConsole.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitEndConsole);

                return bottomUnitEndConsole;
            case CLASSNAME_BOTTOM_UNIT_END_FULL_LENGTH_SIDEWALL_CONSOLE:
                const bottomUnitEndFullLengthSidewallConsole = new ThreeBottomUnitEndFullLengthSidewallConsole(CommonHelper.deepCopy(objectData) as ISaveBottomUnitEndFullLengthSidewallConsoleData, this.service);
                bottomUnitEndFullLengthSidewallConsole.initState();
                bottomUnitEndFullLengthSidewallConsole.createViewEDIT();
                this.units.bottomUnits.push(bottomUnitEndFullLengthSidewallConsole);

                return bottomUnitEndFullLengthSidewallConsole;
            case CLASSNAME_TOP_UNIT_NORMAL:
                const topUnit = new ThreeTopUnitNormal(CommonHelper.deepCopy(objectData) as ISaveTopUnitData, this.service);
                topUnit.initState();
                topUnit.createViewEDIT();
                this.units.topUnits.push(topUnit);

                return topUnit;
            case CLASSNAME_TOP_UNIT_PILASTER:
                const topUnitPilaster = new ThreeTopUnitPilaster(CommonHelper.deepCopy(objectData) as ISaveTopUnitData, this.service);
                topUnitPilaster.initState();
                topUnitPilaster.createViewEDIT();
                this.units.topUnits.push(topUnitPilaster);

                return topUnitPilaster;
            case CLASSNAME_TOP_UNIT_SHELF_FIGURED:
                const topUnitShelfFigured = new ThreeTopUnitShelfFigured(CommonHelper.deepCopy(objectData) as ISaveTopUnitShelfFiguredData, this.service);
                topUnitShelfFigured.initState();
                topUnitShelfFigured.createViewEDIT();
                this.units.topUnits.push(topUnitShelfFigured);

                return topUnitShelfFigured;
            case CLASSNAME_TOP_UNIT_ANGLE_NORMAL:
                const topUnitAngleNormal = new ThreeTopUnitAngleNormal(CommonHelper.deepCopy(objectData) as ISaveTopUnitAngleNormalData, this.service);
                topUnitAngleNormal.initState();
                topUnitAngleNormal.createViewEDIT();
                this.units.topUnits.push(topUnitAngleNormal);

                return topUnitAngleNormal;
            case CLASSNAME_TOP_UNIT_ANGLE_TWO_FACADES_90:
                const topUnitAngleTwoFacades90 = new ThreeTopUnitAngleTwoFacades90(CommonHelper.deepCopy(objectData) as ISaveTopUnitAngleTwoFacades90Data, this.service);
                topUnitAngleTwoFacades90.initState();
                topUnitAngleTwoFacades90.createViewEDIT();
                this.units.topUnits.push(topUnitAngleTwoFacades90);

                return topUnitAngleTwoFacades90;
            case CLASSNAME_TOP_UNIT_ANGLE_CUBE:
                const topUnitAngleCube = new ThreeTopUnitAngleCube(CommonHelper.deepCopy(objectData) as ISaveTopUnitAngleCubeData, this.service);
                topUnitAngleCube.initState();
                topUnitAngleCube.createViewEDIT();
                this.units.topUnits.push(topUnitAngleCube);

                return topUnitAngleCube;
            case CLASSNAME_TOP_UNIT_ANGLE_DOUBLE:
                const topUnitAngleDouble = new ThreeTopUnitAngleDouble(CommonHelper.deepCopy(objectData) as ISaveTopUnitAngleDoubleData, this.service);
                topUnitAngleDouble.initState();
                topUnitAngleDouble.createViewEDIT();
                this.units.topUnits.push(topUnitAngleDouble);

                return topUnitAngleDouble;
            case CLASSNAME_TOP_UNIT_END_NORMAL:
                const topUnitEndNormal = new ThreeTopUnitEndNormal(CommonHelper.deepCopy(objectData) as ISaveTopUnitEndNormalData, this.service);
                topUnitEndNormal.initState();
                topUnitEndNormal.createViewEDIT();
                this.units.topUnits.push(topUnitEndNormal);

                return topUnitEndNormal;
            case CLASSNAME_TOP_UNIT_END_PILASTER:
                const TopUnitEndPilaster = new ThreeTopUnitEndPilaster(CommonHelper.deepCopy(objectData) as ISaveTopUnitEndPilasterData, this.service);
                TopUnitEndPilaster.initState();
                TopUnitEndPilaster.createViewEDIT();
                this.units.topUnits.push(TopUnitEndPilaster);

                return TopUnitEndPilaster;

            case CLASSNAME_TOP_UNIT_END_OPENED:
                const topUnitEndOpened = new ThreeTopUnitEndOpened(CommonHelper.deepCopy(objectData) as ISaveTopUnitEndOpenedData, this.service);
                topUnitEndOpened.initState();
                topUnitEndOpened.createViewEDIT();
                this.units.topUnits.push(topUnitEndOpened);

                return topUnitEndOpened;

            case CLASSNAME_TOP_UNIT_END_OPENED_CIRCLE:
                const topUnitEndOpenedCircle = new ThreeTopUnitEndOpenedCircle(CommonHelper.deepCopy(objectData) as ISaveTopUnitEndOpenedCircleData, this.service);
                topUnitEndOpenedCircle.initState();
                topUnitEndOpenedCircle.createViewEDIT();
                this.units.topUnits.push(topUnitEndOpenedCircle);

                return topUnitEndOpenedCircle;
            case CLASSNAME_TOP_UNIT_WINERY:
                const topUnitWinery = new ThreeTopUnitWinery(CommonHelper.deepCopy(objectData) as ISaveTopUnitData, this.service);
                topUnitWinery.initState();
                topUnitWinery.createViewEDIT();
                this.units.topUnits.push(topUnitWinery);

                return topUnitWinery;
            case CLASSNAME_TOP_UNIT_RACK_FRAME:
                const topUnitRackFrame = new ThreeTopUnitRackFrame(CommonHelper.deepCopy(objectData) as ISaveTopUnitRackFrameData, this.service);
                topUnitRackFrame.initState();
                topUnitRackFrame.createViewEDIT();
                this.units.topUnits.push(topUnitRackFrame);

                return topUnitRackFrame;
            case CLASSNAME_TOP_UNIT_BAR_RAVENNA:
                const topUnitBarRavenna = new ThreeTopUnitBarRavenna(CommonHelper.deepCopy(objectData) as ISaveTopUnitData, this.service);
                topUnitBarRavenna.initState();
                topUnitBarRavenna.createViewEDIT();
                this.units.topUnits.push(topUnitBarRavenna);

                return topUnitBarRavenna;
            case CLASSNAME_TOP_UNIT_SHELF:
                const topUnitShelf = new ThreeTopUnitShelf(CommonHelper.deepCopy(objectData) as ISaveTopUnitShelfData, this.service);
                topUnitShelf.initState();
                topUnitShelf.createViewEDIT();
                this.units.topUnits.push(topUnitShelf);

                return topUnitShelf;
            case CLASSNAME_PENAL_UNIT_NORMAL:
                const penalUnit = new ThreePenalUnitNormal(CommonHelper.deepCopy(objectData) as ISavePenalUnitData, this.service);
                penalUnit.initState();
                penalUnit.createViewEDIT();
                this.units.penals.push(penalUnit);

                return penalUnit;
            case CLASSNAME_PENAL_UNIT_SINGLE_HANDLE:
                const penalUnitSingleHandle = new ThreePenalUnitSingleIntegratedHandle(CommonHelper.deepCopy(objectData) as ISavePenalUnitData, this.service);
                penalUnitSingleHandle.initState();
                penalUnitSingleHandle.createViewEDIT();
                this.units.penals.push(penalUnitSingleHandle);
    
                return penalUnitSingleHandle;
            case CLASSNAME_PENAL_UNIT_FULL_LENGTH_SIDEWALL:
                const penalUnitFullLengthSidewall = new ThreePenalUnitFullLengthSidewall(CommonHelper.deepCopy(objectData) as ISavePenalUnitData, this.service);
                penalUnitFullLengthSidewall.initState();
                penalUnitFullLengthSidewall.createViewEDIT();
                this.units.penals.push(penalUnitFullLengthSidewall);

                return penalUnitFullLengthSidewall;
            case CLASSNAME_PENAL_UNIT_TOP:
                const penalUnitTop = new ThreePenalUnitTop(CommonHelper.deepCopy(objectData) as ISavePenalUnitData, this.service);
                penalUnitTop.initState();
                penalUnitTop.createViewEDIT();
                this.units.penals.push(penalUnitTop);

                return penalUnitTop;

            case CLASSNAME_PENAL_UNIT_END_CONSOLE:
                const penalUnitEndConsole = new ThreePenalUnitEndConsole(CommonHelper.deepCopy(objectData) as ISavePenalUnitEndConsoleData, this.service);
                penalUnitEndConsole.initState();
                penalUnitEndConsole.createViewEDIT();
                this.units.penals.push(penalUnitEndConsole);

                return penalUnitEndConsole;
            case CLASSNAME_SIDE_BOTTOM_FACADE_UNIT:
                const sideBottomFacadeUnit = new ThreeSideBottomFacadeUnit(CommonHelper.deepCopy(objectData) as ISaveFacadeUnitData, this.service);
                sideBottomFacadeUnit.initState();
                // !
                sideBottomFacadeUnit.createViewEDIT();
                this.units.facades.push(sideBottomFacadeUnit);

                return sideBottomFacadeUnit;
            case CLASSNAME_SIDE_TOP_FACADE_UNIT:
                const sideTopFacadeUnit = new ThreeSideTopFacadeUnit(CommonHelper.deepCopy(objectData) as ISaveFacadeUnitData, this.service);
                sideTopFacadeUnit.initState();
                // !
                sideTopFacadeUnit.createViewEDIT();
                this.units.facades.push(sideTopFacadeUnit);

                return sideTopFacadeUnit;
            default:
                console.log(objectData.className);
                break;
        }

        return undefined;
    }

    public deleteAllObjects(): {isOk: boolean, historyState?: IHistoryCreateObjectsState} {
        let objects: ThreeUnit[];
        let tempObjects: ThreeUnit[];
        let index: string;
        let index2: string;
        let objectId: number;
        let tempObjectId: number;
        let historyState: IHistoryCreateObjectsState;
        let objectSaveData: ISaveUnitData;

        objects = this.getObjects();
        if (!objects.length) {
            return {
                isOk: false
            };
        }
        historyState = {
            type: HISTORY_STATE_TYPE_DELETE,
            data: {
                objects: []
            }
        };
        tempObjects = [...objects];
        for (index in objects) {
            objectId = objects[index].getId();
            objectSaveData = objects[index].getData();
            if (this.tryDeleteUnit(objectId) || this.tryDeleteEquipment(objectId)) {
                for (index2 in tempObjects) {
                    tempObjectId = tempObjects[index2].getId();
                    if (objectId === tempObjectId) {
                        tempObjects.splice(+index2, 1);
                        break;
                    }
                }
                historyState.data.objects.push(objectSaveData);
            }
        }
        if (tempObjects.length) {
            throw new Error('error-MainManager-deleteAllObjects');
        }

        return {
            isOk: true,
            historyState: historyState.data.objects.length > 0 ? historyState : undefined
        };
    }

    public tryDeleteUnit(id: number): IHistoryCreateObjectsState | undefined {
        let group: keyof IKitchenUnits;
        let index: string;
        let unit: ThreeUnit;
        let historyState: IHistoryCreateObjectsState;

        for (group in this.units) {
            if (!this.units.hasOwnProperty(group)) {
                continue;
            }
            for (index in this.units[group]) {
                if (!this.units[group].hasOwnProperty(index)) {
                    continue;
                }
                unit = this.units[group][index];
                if (unit.getId() === id) {
                    historyState = {
                        type: HISTORY_STATE_TYPE_DELETE,
                        data: {
                            objects: [unit.getData()]
                        }
                    }
                    unit.remove();
                    this.units[group].splice(+index, 1);

                    return historyState;
                }
            }
        }

        return undefined;
    }

    public tryDeleteEquipment(id: number): IHistoryCreateObjectsState | undefined {
        let group: keyof IKitchenEquipments;
        let index: string;
        let equipment: ThreeEquipment;
        let historyState: IHistoryCreateObjectsState;

        for (group in this.equipments) {
            if (!this.equipments.hasOwnProperty(group)) {
                continue;
            }
            for (index in this.equipments[group]) {
                if (!this.equipments[group].hasOwnProperty(index)) {
                    continue;
                }
                equipment = this.equipments[group][index];
                if (equipment.getId() === id) {
                    historyState = {
                        type: HISTORY_STATE_TYPE_DELETE,
                        data: {
                            objects: [equipment.getData()]
                        }
                    };
                    equipment.remove();
                    this.equipments[group].splice(+index, 1);

                    return historyState;
                }
            }
        }

        return undefined;
    }

    public tryDeleteBuiltInEquipment(equipmentId: number): IHistoryChangeObjectsState | undefined {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let equipmentUnit: ThreeKUnit | undefined;
        let threeKUnit: ThreeKUnit;
        let equipment: ThreeBuiltInEquipment;
        let oldData: ISaveUnitData;
        let historyState: IHistoryChangeObjectsState | undefined;

        units = this.getUnits();
        for (unit of units) {
            if (unit instanceof ThreeKUnit) {
                threeKUnit = unit;
                if (threeKUnit.equipments) {
                    for (equipment of threeKUnit.equipments) {
                        if (equipmentId === equipment.getId()) {
                            equipmentUnit = threeKUnit;
                            break;
                        }
                    }
                }
            }
            if (equipmentUnit) {
                break;
            }
        }
        if (equipmentUnit) {
            oldData = equipmentUnit.getData();
            equipmentUnit.deleteEquipment(equipmentId);
            historyState = {
                type: HISTORY_STATE_TYPE_CHANGE,
                data: {
                    objects: [{
                        oldData: oldData,
                        newData: equipmentUnit.getData()
                    }]
                }
            }
        }

        return historyState;
    }

    public clearCreateBuiltInEquipment() {
        let units: ThreeUnit[], unit: ThreeUnit;

        units = this.getUnits();
        for (unit of units) {
            unit.clearCoverSelectColor();
            unit.clearSelectCoverSelectColor();
        }
    }

    public tryCreateBuiltInEquipment(objectData: ICreateObjectData): ThreeUnit[] {
        let index, unitsForEquipment: ThreeUnit[] = [], unit: ThreeUnit;
        let className: TClassName | undefined;

        for (index in objectData.options) {
            if (objectData.options[index].id === 'className') {
                className = (objectData.options[index] as IOptionHiddenText).value as TClassName;
                break;
            }
        }
        if (className) {
            unitsForEquipment = this.findUnitsForEquipment(className);
        }
        if (unitsForEquipment.length > 0) {
            for (unit of unitsForEquipment) {
                unit.selectForEquipment(objectData);
            }
        }

        return unitsForEquipment;
    }

    public updateViewType() {
        let unit: ThreeUnit;
        let options: TWizardUIOptions;
        let units: ThreeUnit[];


        options = this.service.getOptions();
        units = this.getObjects();
        for (unit of units) {
            unit.setViewType(options.viewType);
        }
    }

    public getUnitsPolygon(): TPoint2D[] | undefined {
        let unitsBox: Box3;

        unitsBox = this.getSceneBox();

        if (!Number.isFinite(unitsBox.min.x) || !Number.isFinite(unitsBox.min.z) ||
            !Number.isFinite(unitsBox.max.x) || !Number.isFinite(unitsBox.max.z)) {
            return undefined;
        }

        return [
            {x: unitsBox.min.x, y: unitsBox.min.z},
            {x: unitsBox.min.x, y: unitsBox.max.z},
            {x: unitsBox.max.x, y: unitsBox.min.z},
            {x: unitsBox.max.x, y: unitsBox.max.z},
        ];
    }

    public getSceneBox(): Box3 {
        let units: ThreeUnit[];
        let unions: Array<ThreePlinthUnion | ThreeCornerUnion | ThreeApronUnion | ThreeTabletopUnion | ThreeIntegratedHandleUnion>;
        let union: ThreePlinthUnion | ThreeCornerUnion | ThreeApronUnion | ThreeTabletopUnion | ThreeIntegratedHandleUnion;
        let unit: ThreeUnit;
        let box: Box3;
        let unitBox: Box3;
        let points: Vector3[];

        units = this.getObjects();
        points = [];
        box = new Box3();
        for (unit of units) {
            unitBox = unit.getGlobalPoints(unit.selectCover).box;
            points.push(unitBox.min, unitBox.max);
        }
        unions = this.service.getUnionUnits();
        for (union of unions) {
            unitBox = union.getGlobalPoints(union.selectCover).box;
            points.push(unitBox.min, unitBox.max);
        }
        box.setFromPoints(points);

        return box;
    }

    public setBottomFacadeMaterial(data: IFacadeMaterialData): ThreeUnit[] {
        return this.setUnitsFacadeMaterial(this.getBottomObjects(), data);
    }

    public setTopFacadeMaterial(data: IFacadeMaterialData): ThreeUnit[] {
        return this.setUnitsFacadeMaterial(this.getTopObjects(), data);
    }

    public setTabletop(tabletop: ITabletopData, cornerMaterial?: ICornerData) {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.getObjects();
        for (unit of units) {
            unit.trySetTabletop(tabletop, cornerMaterial);
        }
    }

    public setApron(apron: IApronData) {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.getObjects();
        for (unit of units) {
            unit.trySetApron(apron);
        }
    }

    public setCorner(corner: ICornerData) {
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.getObjects();
        for (unit of units) {
            unit.trySetCorner(corner);
        }
    }

    public setPlinth(plinth: IPlinthData, replacedUnits?: ThreeUnit[]) {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let replacedUnit: ThreeUnit;

        units = this.getObjects();
        for (unit of units) {
            if (replacedUnits) {
                for (replacedUnit of replacedUnits) {
                    if (replacedUnit.getId() === unit.getId()) {
                        unit.trySetPlinth(plinth);
                    }
                }
            } else {
                unit.trySetPlinth(plinth);
            }
        }
    }

    public setCorpusMaterial(data: IMaterialData): ThreeUnit[] {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let newOfferId: string;
        let unitOfferIds: string[] = [];
        let newOfferIds: string[] = [];
        let replacedUnits: ThreeUnit[] = [];

        units = this.getObjects();
        for (unit of units) {
            if (unit.trySetCorpusMaterial(data)) {
                replacedUnits.push(unit);
            }
            unitOfferIds = unit.getOfferIds();
            for (newOfferId of unitOfferIds) {
                newOfferIds.push(newOfferId);
            }
        }
        this.service.loadPrices(newOfferIds).then(() => {
            this.service.rebuildScene();
        });

        return replacedUnits;
    }

    public calculateUnitsData(): IProjectUnits {
        return {
            bottomUnits: this.calculateGroupUnitsData(this.units.bottomUnits),
            topUnits: this.calculateGroupUnitsData(this.units.topUnits),
            penals: this.calculateGroupUnitsData(this.units.penals),
            aprons: this.calculateGroupUnitsData(this.units.aprons),
            tabletops: this.calculateGroupUnitsData(this.units.tabletops),
            barCounters: this.calculateGroupUnitsData(this.units.barCounters),
            decors: this.calculateGroupUnitsData(this.units.decors),
            facades: this.calculateGroupUnitsData(this.units.facades),
        }
    }

    public calculateEquipmentsData(): IProjectEquipments {
        return {
            bottom: this.calculateGroupEquipmentsData(this.equipments.bottom),
            top: this.calculateGroupEquipmentsData(this.equipments.top),
            big: this.calculateGroupEquipmentsData(this.equipments.big),
            portable: this.calculateGroupEquipmentsData(this.equipments.portable),
        }
    }

    public setLegsHeight(height: number): IHistoryObjectData[] {
        let units: ThreeUnit[];
        let unit: ThreeUnit;
        let changeObjects: IHistoryObjectData[] = [];
        let changeObject: IHistoryObjectData | undefined;

        units = this.getObjects();
        for (unit of units) {
            changeObject = unit.trySetLegsHeight(height);
            if (changeObject) {
                changeObjects.push(changeObject);
            }
        }

        return changeObjects;
    }

    public getProjectLegs(): ThreeLeg[] {
        let legs: ThreeLeg[] = [];
        let leg: ThreeLeg;
        let units: ThreeUnit[];
        let unit: ThreeUnit;

        units = this.getObjects();

        for (unit of units) {
            if (unit.legs) {
                for (leg of unit.legs) {
                    if (leg.isVisible()) {
                        legs.push(leg);
                    }
                }
            }
        }

        return legs;
    }

    protected setUnitsFacadeMaterial(units: ThreeUnit[], data: IFacadeMaterialData) {
        let newOfferId: string;
        let newOfferIds: string[] = [];
        let unit: ThreeUnit;
        let replacedUnits: ThreeUnit[] = [];
        let unitOfferIds: string[];

        for (unit of units) {
            if (unit.trySetFacadeMaterial(data)) {
                replacedUnits.push(unit);
            }
            unitOfferIds = unit.getOfferIds();
            for (newOfferId of unitOfferIds) {
                newOfferIds.push(newOfferId);
            }
        }
        this.service.loadPrices(newOfferIds).then(() => {
            this.service.calculateUnitsPrice({}, true);
            this.service.rebuildScene();
        });

        return replacedUnits;
    }

    protected calculateGroupUnitsData(units: ThreeUnit[]): ISaveUnitData[] {
        let unitsData: ISaveUnitData[] = [];
        let unit: ThreeUnit;

        if (units.length > 0) {
            for (unit of units) {
                unitsData.push(unit.getData());
            }
        }

        return unitsData;
    }

    protected calculateGroupEquipmentsData(equipments: ThreeEquipment[]): ISaveEquipmentData[] {
        let equipmentsData: ISaveEquipmentData[] = [];
        let equipment: ThreeEquipment;

        if (equipments.length > 0) {
            for (equipment of equipments) {
                equipmentsData.push(equipment.getData());
            }
        }

        return equipmentsData;
    }

    protected initEquipments(): IKitchenEquipments {
        return {
            big: [],
            bottom: [],
            top: [],
            portable: [],
        }
    }

    protected findUnitsForEquipment(className: TClassName): ThreeUnit[] {
        let findUnits: ThreeUnit[], units, unit;

        units = this.getUnits();
        findUnits = [];
        for (unit of units) {
            if (unit.checkCreateEquipment(className)) {
                findUnits.push(unit);
            }
        }

        return findUnits;
    }

    protected initUnits(): IKitchenUnits {
        return {
            bottomUnits: [],
            topUnits: [],
            penals: [],
            barCounters: [],
            tabletops: [],
            aprons: [],
            decors: [],
            facades: []
        }
    }

    protected removeUnits() {
        let unit: ThreeUnit;
        let group: keyof IKitchenUnits;

        for (group in this.units) {
            if (!this.units.hasOwnProperty(group)) {
                continue;
            }
            for (unit of this.units[group]) {
                unit.remove();
            }
        }
        this.units = this.initUnits();
    }

    protected removeEquipments() {
        let unit: ThreeUnit;
        let group: keyof IKitchenEquipments;

        for (group in this.equipments) {
            if (!this.units.hasOwnProperty(group)) {
                continue;
            }
            for (unit of this.equipments[group]) {
                unit.remove();
            }
        }
        this.equipments = this.initEquipments();
    }

    protected initProjectOffers() {
        let projectExtraOffers: IProjectOffers | undefined;
        let extraOffers: IProjectOffers = {};
        let id: string;
        let externalId: 'externalGuid' | 'vendorCode';
        let offer: IImportOffer | undefined;
        let offerId: string;
        let part: number | undefined;

        projectExtraOffers = this.service.projectData.extraOffers;
        if (!projectExtraOffers) {
            this.extraOffers = undefined;
            return;
        }
        externalId = this.service.getOfferExternalId();
        for (id in projectExtraOffers) {
            offer = this.service.getOfferById(projectExtraOffers[id].id);
            if (offer) {
                part = projectExtraOffers[id].part !== undefined && (projectExtraOffers[id].part as number) > 0 ?
                    projectExtraOffers[id].part : this.service.getDefaultOrderPart();
                offerId = projectExtraOffers[id].id + (part !== undefined && part > 0 ? '' + part : '');
                extraOffers[offerId] = {
                    id: offer[externalId],
                    count: projectExtraOffers[id].count,
                    offer: offer,
                    part: part,
                    unitId: projectExtraOffers[id].unitId,
                    cell: projectExtraOffers[id].cell,
                    autoCount: projectExtraOffers[id].autoCount,
                    isAuto: projectExtraOffers[id].isAuto,
                    sort: projectExtraOffers[id].sort,
                    isAutoEnable: projectExtraOffers[id].isAutoEnable,
                    comment: projectExtraOffers[id].comment,
                }
            }
        }
        this.extraOffers = extraOffers;
        this.service.updateProjectOffers();
    }
}
import { IOptionGroup } from "common-code/lib/interfaces/option/IOptionGroup";
import { IOptionRadioButton } from "../../../../../common-code/interfaces/option/IOptionRadioButton";
import {  SIDE_TYPE_BACK } from "common-code/lib/constants";
import { SIDE_TYPE_LEFT } from "common-code/lib/constants";
import { SIDE_TYPE_RIGHT } from "common-code/lib/constants";
import { SIDE_TYPE_FRONT } from "common-code/lib/constants";
import { IOption } from "common-code/lib/interfaces/option/IOption";
import { IItemCreateObjectData } from "./FormModule";
import { TSelectItem } from "common-code/lib/types/TSelectItem";

export interface IFormInput {
    uid?: string;
    catalogCode?: string;
    title: string;
    image: string;
    depthText?: string;
    heightText?: string;
    widthText?: string;
    depth: string;
    height: string;
    width?: TSelectItem[] | IOptionRadioButton | IOption | null;
    thickness: string;
    widthValue?: number;
    sideType: TSelectItem[] |  IOption | null;
    sideTypeDefault?: string | null;
    corpusCatalogCode: string;
    backThickness: string;
    defaultWidth: string;
    smallWidth: string;
    smallDepth: string;
    sidePanel: string;
    frameWidth: string;
    frameDepth: string;
    frameHeight: string;
    rearPlinthWidth: string;
    rearPlinthHeight: string;
    rearPlinthDepth: string;
    tsargaWidth: string;
    tsargaHeight: string;
    tsargaDepth: string;
    railWidth: string;
    railHeight: string;
    railDepth: string;
    backSideDepth: string;
    sideDepth: string;
    frontPanelLength: string;
    shelves?: IOption | null;
    boxes?: IOption | null;
    tabletops?: TSelectItem[] | IOption | IOptionGroup | { value: { id: number; initPosition: { x: string; z: string; }; }[]; } | { value: { id: number; }[]; } | null;
    aprons?: IOption | IOptionGroup | { value: { id: number; positionType: string; }[]; };
    corners?: IOption | IOptionGroup | { value: { id: number; positionType: string; }[]; } | null;
    plinths?: IOption | IOptionGroup | { value: { id: number; positionType: string; }[]; } | null;
    legs?: IOption | IOptionGroup |  { value: { id: number; initPosition: { x: string; z: string; }; }[]; } | null;
    facades?: IOption | IOptionGroup | null;
    isEdit: boolean;
    catalogCodeEdited: boolean;
    integratedHandles?: IOption | IOptionGroup| null;
    techHoleCPositionY?: number;
    techHoleLPositionY?: number;
    className?: IOption;
    isSizes?: boolean;
    verticalPanelLength?: string;
    isFrontPanel?: boolean;
    isVerticalPanel?: boolean;
  };

export const initialValue: (item: IItemCreateObjectData | null) =>  IFormInput = (item: IItemCreateObjectData | null) =>{ 
const corpusInitional = (item?.options.find((el) => el.id === "corpus") as IOptionGroup)?.options || [];
const sizesInitional = (item?.options.find((el) => el.id === "sizes") as IOptionGroup | IOption);
const sizeValue = (dimensions: string) => sizesInitional?.hasOwnProperty("options") ? 
(sizesInitional as IOptionGroup)?.options.find((el) => el.id === dimensions)?.value?.toString() 
: 
sizesInitional ? (sizesInitional as IOption)?.value[dimensions].toString() : undefined

    
const initialValues: IFormInput  = item ? 
{
  uid: item.uid,
  title: item.title,
  image: item.image,
  catalogCode: item.catalogCode,
  depthText: sizeValue("depth"),
  heightText: sizeValue("height"),
  widthText: sizeValue("width"),
  sideType: (item.options.find((el) => el.id === "sideType") as IOptionRadioButton)?.items,
  sideTypeDefault: (item.options.find((el) => el.id === "sideType") as IOptionRadioButton)?.defaultValue,
  corpusCatalogCode: corpusInitional?.find((el) => el.id === "catalogCode")?.value,
  height: corpusInitional.find((el) => el.id === "height")?.value?.toString(),
  depth: corpusInitional.find((el) => el.id === "depth")?.value?.toString(),
  backThickness: corpusInitional.find((el) => el.id === "backThickness")?.value?.toString(),
  thickness: corpusInitional.find((el) => el.id === "thickness")?.value?.toString(),
  width: (corpusInitional.find((el) => el.id === "width") as IOptionRadioButton)?.items,
  widthValue: corpusInitional.find((el) => el.id === "width")?.value?.toString(),
  defaultWidth: corpusInitional.find((el) => el.id === "width")?.defaultValue,
  smallWidth: corpusInitional.find((el) => el.id === "smallWidth")?.value?.toString(),
  smallDepth: corpusInitional.find((el) => el.id === "smallDepth")?.value?.toString(),
  sidePanel: corpusInitional.find((el) => el.id === "sidePanel")?.value?.toString(),
  frameWidth: corpusInitional.find((el) => el.id === "frame")?.value?.width?.toString(),
  frameDepth: corpusInitional.find((el) => el.id === "frame")?.value?.depth?.toString(),
  frameHeight: corpusInitional.find((el) => el.id === "frame")?.value?.height?.toString(),
  rearPlinthWidth: corpusInitional.find((el) => el.id === "rearPlinth")?.value?.width?.toString(),
  rearPlinthDepth: corpusInitional.find((el) => el.id === "rearPlinth")?.value?.depth?.toString(),
  rearPlinthHeight: corpusInitional.find((el) => el.id === "rearPlinth")?.value?.height?.toString(),
  tsargaWidth: corpusInitional.find((el) => el.id === "tsarga")?.value?.width?.toString(),
  tsargaDepth: corpusInitional.find((el) => el.id === "tsarga")?.value?.depth?.toString(),
  tsargaHeight: corpusInitional.find((el) => el.id === "tsarga")?.value?.height?.toString(),
  railWidth: corpusInitional.find((el) => el.id === "rail")?.value?.width?.toString(),
  railDepth: corpusInitional.find((el) => el.id === "rail")?.value?.depth?.toString(),
  railHeight: corpusInitional.find((el) => el.id === "rail")?.value?.height?.toString(),
  backSideDepth: corpusInitional.find((el) => el.id === "backSideDepth")?.value?.toString(),
  sideDepth: corpusInitional.find((el) => el.id === "sideDepth")?.value?.toString(),
  frontPanelLength: corpusInitional.find((el) => el.id === "frontPanel")?.value?.length?.toString(),
  shelves: corpusInitional.find((el) => el.id === "shelves"),
  boxes: corpusInitional.find((el) => el.id === "boxes"),
  tabletops: item.options.find((el) => el.id === "tabletops"),
  aprons: item.options.find((el) => el.id === "aprons"),
  corners: item.options.find((el) => el.id === "corners"),
  plinths: item.options.find((el) => el.id === "plinths"),
  legs: item.options.find((el) => el.id === "legs"),
  facades: item.options.find((el) => el.id === "facades"),
  isEdit: true,
  catalogCodeEdited: false,
  integratedHandles: item.options.find((el) => el.id === "integratedHandles"),
  techHoleCPositionY: corpusInitional.find((el) => el.id === "techHole")?.value?.positionC?.toString(),
  techHoleLPositionY: corpusInitional.find((el) => el.id === "techHole")?.value?.positionL?.toString(),
  verticalPanelLength:corpusInitional.find((el) => el.id === "verticalPanel")?.value?.length?.toString(),
}
:
{
    isEdit: false,
    title: "Новый модуль",
    thickness: "16",
    backThickness: "4",
    height: "700",
    image: "/static-files/unit/interiorCenter/Стол с 1 распашной дверью.jpg",
    defaultWidth: "450",
    depth: "496",
    smallWidth: "200",
    smallDepth: "300",
    sidePanel: "200",
    frameWidth: "20",
    frameDepth: "20",
    frameHeight: "20",
    rearPlinthWidth: "20",
    rearPlinthDepth: "20",
    rearPlinthHeight: "20",
    tsargaWidth: "20",
    tsargaDepth: "20",
    tsargaHeight: "20",
    railWidth: "20",
    railDepth: "20",
    railHeight: "20",
    backSideDepth: "200",
    sideDepth: "200",
    frontPanelLength: "200",
    sideType: [
        { id: "left", title: "Левая" },
        { id: "right", title: "Правая" },
    ],
    sideTypeDefault: "left",
    corpusCatalogCode: "shnr",
    catalogCodeEdited: false,
    tabletops: {
        value: [{id: 0,}]
    },
    legs: {
        value: [
            {
                id: 0,
                initPosition: {
                    x: '50',
                    z: '50'
                },
            },
            {
                id: 0,
                initPosition: {
                    x: '=({%100}-50)',
                    z: '50'
                },
            },
            {
                id: 0,
                initPosition: {
                    x: '=({%100}-50)',
                    z: '=({%100}-50)'
                },
            },
            {
                id: 0,
                initPosition: {
                    x: '50',
                    z: '=({%100}-50)'
                },
            },
        ]
    },
    plinths: {
        value: [
            {
                id: 0,
                positionType: SIDE_TYPE_BACK,
            },
            {
                id: 0,
                positionType: SIDE_TYPE_LEFT,
            },
            {
                id: 0,
                positionType: SIDE_TYPE_RIGHT,
            },
            {
                id: 0,
                positionType: SIDE_TYPE_FRONT,
            }
        ]
    },
    corners: {
        value: [
            {
                id: 0,
                positionType: SIDE_TYPE_BACK,
            },
            {
                id: 0,
                positionType: SIDE_TYPE_LEFT,
            },
            {
                id: 0,
                positionType: SIDE_TYPE_RIGHT,
            }
        ]
    },
    aprons: {
        value: [
            {
                id: 0,
                positionType: SIDE_TYPE_BACK,
            },
            {
                id: 0,
                positionType: SIDE_TYPE_LEFT,
            },
            {
                id: 0,
                positionType: SIDE_TYPE_RIGHT,
            }
        ]
    }
    
};

return initialValues
}



import { NONE_MATERIAL, OPTION_TYPE_HIDDEN_TEXT } from "common-code/lib/constants"
import { IOptionHiddenText } from "../../../../../../common-code/interfaces/option/IOptionHiddenText"

export const facadeMaterials = {
    "aurora-gold-craft-oak" : "Золотой крафтовый дуб Aurora",
}


export const geometryTypes = {
    'square': "Прямоугольный",
    // 'arc': "Выпуклый",
    // 'concave': "Вогнутый"
}

export const sideTypes ={
    'default': "По умолчанию",
    'left': "Левая",
    'right': "Справа",
    'front': "Передняя",
    'back': "Задняя",
    'bottom': "Нижняя",
    'arc': "Дуговой",
    'top': "Верхний",
    'none': "Не открывается",
}

export const alignValues = {
    'left': "Слева",
    'right': "Справа",
    'top': "Сверху",
    'bottom': "Снизу",
    'center': "По центру"
}

export const facadeModeles = {
    'default': "Глухой",
    // 'decor': "Декор",
    // 'default+v': "По умолчанию по вертикали",
    'glass': "Витрина",
    // 'glass2': "Стеклянный 2", 
    // 'arc': "Выпуклый",
    // 'concave': "Вогнутый",
    // 'plane': "Плоский", 
    // 'warp': "Деформированный",
    // 'butil': "butil",
    // 'butil2': "butil2",
}

export const locations: {[key: string] : string}  = {
    'horizontal' : "Горизонтальная",
    'vertical' : "Вертикальная"
}


export const hingeAvailableKitCodes = {
    medium: "Комфорт",
    expensive: "Люкс",
}

export const legsPosition:{[key: string]: Record<string, string>}  = 
{
    "back-left": { x: "50", z: "50" },
    "front-left": { x: "=({%100}-50)", z: "50" },
    "front-right" : { x: "=({%100}-50)", z: "=({%100}-50)" },
    "back-right" : { x: "50", z: "=({%100}-50)" },
    "front-optional"   : { x: "=({%100}-50)", z: "=({%100}-50)" },
};


export const legsValues: Record<string, string>  = 
{
    "back-left": "Задняя левая",
    "front-left": "Задняя справа",
    "front-right" : "Передняя справа",
    "back-right" : "Передняя левая",
    "front-optional"   : "Передняя опциональная",
};

export const widthsValues= [
    { id: "300", title: "300 мм" },
    { id: "400", title: "400 мм" },
    { id: "450", title: "450 мм" },
    { id: "500", title: "500 мм" },
    { id: "600", title: "600 мм" },
]

export const corpusMaterial = {
    id: 'corpusMaterial',
    value: NONE_MATERIAL,
    sort: 1,
    title: 'материал корпуса',
    type: OPTION_TYPE_HIDDEN_TEXT,
} as IOptionHiddenText;


import {ThreeCorpus} from '../ThreeCorpus';
import {BoxGeometry, Euler, Vector3} from 'three';
import {BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT, BOTTOM_UNIT_TOP_PANEL_WIDTH} from '../../../../../constants'; 
import {SIDE_TYPE_LEFT, SIDE_TYPE_RIGHT} from '../../../../../../../common-code/constants';
import { ISaveBottomAngleNormalSingleHandleCorpusData } from '../../../../../../../common-code/interfaces/saveData/ISaveBottomAngleNormalSingleHandleCorpusData';
import { ThreeBottomUnitAngleNormalSingleHandle } from '../../../units/ThreeBottomUnit/types/ThreeBottomUnitAngleNormalSingleHandle';

export class ThreeBottomAngleNormalSingleHandleCorpus extends ThreeCorpus {
    saveData: ISaveBottomAngleNormalSingleHandleCorpusData;
    unit: ThreeBottomUnitAngleNormalSingleHandle;

    constructor(options: ISaveBottomAngleNormalSingleHandleCorpusData, unit: ThreeBottomUnitAngleNormalSingleHandle) {
        super(options, unit);
        this.saveData = options;
        this.unit = unit;
    }


    public initState(isRebuild?: boolean) {
        this.createFrontPanels();
        this.createVerticalPanels();
        super.initState(isRebuild);
    }

    protected createPanels() {
        this.createPanel(
            new BoxGeometry(this.getWidth(), this.getThickness(), this.getDepth() - this.getBackThickness()),
            'bottom',
            new Vector3(
                0,
                -this.getHeight() / 2 + this.getThickness() / 2,
                this.getBackThickness()/2
            ),
            new Euler(0, 0, 0),
            true
        );

        if(this.unit.getSideType() === SIDE_TYPE_LEFT) {
            this.createPanel(
                new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
                'left',
                new Vector3(
                    -this.getWidth()/2 + this.getThickness() / 2,
                    this.getThickness() / 2,
                    this.getBackThickness()/2
                )
            );
            this.createPanel(
                new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), 30),
                'middle',
                new Vector3(
                    (this.saveData.frontPanel ? -this.getWidth()/2 + this.saveData.frontPanel.length - this.getThickness()/2 : 0),
                    0 + this.getThickness() / 2,
                    this.getDepth()/2 - this.getThickness()
                ),
                new Euler(0, 0, 0),

            );
            this.createSingleIntegratedHandleSidePanel(
                new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
                'right',
                new Vector3(
                    this.getWidth()/2 ,
                    this.getThickness() / 2,
                    this.getBackThickness()/2
                ),
                new Euler(0, -Math.PI/2, 0)
            );
            this.createPanel(
                new BoxGeometry((this.saveData.frontPanel ? this.getWidth() - this.saveData.frontPanel.length : this.getWidth() - this.getThickness() * 2), BOTTOM_UNIT_TOP_PANEL_WIDTH, this.getThickness()),
                'front',
                new Vector3(
                    // this.saveData.frontPanel ? - this.getWidth()/2 + this.saveData.frontPanel.length + ((this.getWidth() - this.saveData.frontPanel.length)/2) : 0,
                    this.saveData.frontPanel ? this.saveData.frontPanel.length - this.saveData.frontPanel.length/2 : 0,

                    this.getHeight() / 2 - BOTTOM_UNIT_TOP_PANEL_WIDTH / 2 - BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT,
                    this.getDepth()/2 - this.getThickness()/2
                )
            );
        } else if (this.unit.getSideType() === SIDE_TYPE_RIGHT) {
            this.createSingleIntegratedHandleSidePanel(
                new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
                'left',
                new Vector3(
                    -this.getWidth()/2 + this.getThickness(),
                    this.getThickness() / 2,
                    this.getBackThickness()/2
                ),
                new Euler(0, -Math.PI/2, 0)

            );
            this.createPanel(
                new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), 30),
                'middle',
                new Vector3(
                    (this.saveData.frontPanel ? this.getWidth()/2 - this.saveData.frontPanel.length + this.getThickness()/2 : 0),
                    0 + this.getThickness() / 2,
                    this.getDepth()/2 - this.getThickness()
                ),
                new Euler(0, 0, 0),

            );
            this.createPanel(
                new BoxGeometry(this.getThickness(), this.getHeight() - this.getThickness(), this.getDepth() - this.getBackThickness()),
                'right',
                new Vector3(
                    this.getWidth()/2 - this.getThickness() / 2,
                    this.getThickness() / 2,
                    this.getBackThickness()/2
                )
            );
            this.createPanel(
                new BoxGeometry((this.saveData.frontPanel ? this.getWidth() - this.saveData.frontPanel.length : this.getWidth() - this.getThickness() * 2), BOTTOM_UNIT_TOP_PANEL_WIDTH, this.getThickness()),
                'front',
                new Vector3(
                    // this.saveData.frontPanel ? this.getWidth()/2 - this.saveData.frontPanel.length - ((this.getWidth() - this.saveData.frontPanel.length)/2) : 0,
                    this.saveData.frontPanel ? - this.saveData.frontPanel.length + this.saveData.frontPanel.length/2 : 0,
                    this.getHeight() / 2 - BOTTOM_UNIT_TOP_PANEL_WIDTH / 2 - BOTTOM_UNIT_TECHNOLOGICAL_HOLE_HEIGHT,
                    this.getDepth()/2 - this.getThickness()/2
                )
            );
        }

        this.createPanel(
            new BoxGeometry(this.getWidth() - this.getThickness() * 2, BOTTOM_UNIT_TOP_PANEL_WIDTH, this.getThickness()),
            'back',
            new Vector3(
                0,
                0,
                -this.getDepth()/2 + this.getThickness()/2
            ),
            new Euler(0, 0, 0),
            true
        );
    }

    protected createFrontPanels() {

        let position: Vector3;

        if (!this.saveData.frontPanel) {
            return;
        }
        position = new Vector3();
        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                position.set(
                    -this.getWidth()/2 + this.saveData.frontPanel.length/2,
                    0,
                    this.getDepth()/2 + this.getThickness()/2
                );
                break;
            case SIDE_TYPE_RIGHT:
                position.set(
                    this.getWidth()/2 - this.saveData.frontPanel.length/2,
                    0,
                    this.getDepth()/2 + this.getThickness()/2
                );
                break;
        }
        this.createPanel(
            new BoxGeometry(this.saveData.frontPanel.length, this.getHeight(), this.getThickness()),
            'frontPanel',
            position
        );
    }

    protected createVerticalPanels() {

        if (!this.saveData.verticalPanel) {
            return;
        }

        const verticalPanelGap: number = 25;
        let positionLeft: Vector3 = new Vector3();
        let positionRight: Vector3 = new Vector3();


        switch (this.unit.getSideType()) {
            case SIDE_TYPE_LEFT:
                positionLeft.set(
                    (this.saveData.frontPanel ? -this.getWidth()/2 + this.getThickness()/2 + verticalPanelGap : -this.getWidth()/2 + this.getThickness()/2),
                    0,
                    this.getDepth()/2 + this.saveData.verticalPanel.length/2 + this.getThickness()
                );
                positionRight.set(
                    (this.saveData.frontPanel ? -this.getWidth()/2 + this.saveData.frontPanel.length - this.getThickness()/2 - verticalPanelGap  : this.getWidth()/2 - this.getThickness()/2) ,
                    0,
                    this.getDepth()/2 + this.saveData.verticalPanel.length/2 + this.getThickness()
                );
                break;
            case SIDE_TYPE_RIGHT:
                positionLeft.set(
                    (this.saveData.frontPanel ? this.getWidth()/2 - this.getThickness()/2 - verticalPanelGap : this.getWidth()/2 - this.getThickness()/2),
                    0,
                    this.getDepth()/2 + this.saveData.verticalPanel.length/2 + this.getThickness()
                );
                positionRight.set(
                    (this.saveData.frontPanel ? this.getWidth()/2 - this.saveData.frontPanel.length + this.getThickness()/2 + verticalPanelGap : -this.getWidth()/2) ,
                    0,
                    this.getDepth()/2 + this.saveData.verticalPanel.length/2 + this.getThickness()
                );
                break;
        }
        this.createPanel(
            new BoxGeometry(this.saveData.verticalPanel.length, this.getHeight(), this.getThickness()),
            'verticalLeftPanel',
            positionLeft,
            new Euler(0, Math.PI / 2, 0)
        );
        this.createPanel(
            new BoxGeometry(this.saveData.verticalPanel.length, this.getHeight(), this.getThickness()),
            'verticalRightPanel',
            positionRight,
            new Euler(0, Math.PI / 2, 0)
        );
    }
}
import React, { useState } from "react";
import { IClassNameProps } from "@bem-react/core";
import "./WizardEdit-VerticalMenu.css";
import { cn } from "@bem-react/classname";
import { KitchenService } from "../../../../3d/services/KitchenService/KitchenService";
import { Svg } from "../../../components/Svg";
import { TWizardUIOptions } from "../../../../types/TWizardUIOptions";
import { useSelector } from "react-redux";
import { AppState } from "../../../../redux/AppStore";
import {
  KITCHEN_SIZES_TYPE_ALL,
  KITCHEN_SIZES_TYPE_NONE,
} from "../../../../constants";
import { Popup } from "../../../components/Popup";
import { useTranslation } from "react-i18next";
import { frontCameraAspect } from "../utils/CameraFrontAspect";
import { leftCameraAspect } from "../utils/CameraLeftAspect";

export const cnWizardVerticalMenu = cn("WizardVerticalMenu");
export interface IWizardVerticalMenuProps extends IClassNameProps {
  service: KitchenService;
}

export const WizardEditVerticalMenu: React.FC<IWizardVerticalMenuProps> = ({
  className,
  service,
}) => {
  const itemModule = useSelector((state: AppState) => state.wizard.editModule.itemModule);
  const kitchenOptions: TWizardUIOptions = useSelector(
    (state: AppState) => state.wizard.options
  );
  const [hoverIcon, setHoverIcon] = useState<string | null>(null);
  const { t } = useTranslation();

  const toggleSizes = () => {
    if (!service.sceneCheck().length) {
      window.alert("Добавьте модуль!");
      return;
    }
    if (kitchenOptions.sizesType === KITCHEN_SIZES_TYPE_NONE) {
      service.setSizeType(KITCHEN_SIZES_TYPE_ALL);
    } else {
      service.setSizeType(KITCHEN_SIZES_TYPE_NONE);
    }
  };

  
  const toogleRenderImage = () => {
    if (!service.sceneCheck().length) {
      window.alert("Добавьте модуль!");
      return;
    }
    service.createImagesEDIT(leftCameraAspect(service, itemModule), frontCameraAspect(service, itemModule)).then((data) => {
      if (data) console.log(data);
      service.saveUnitImages(itemModule.uid, data).then((data) => {
        window.alert("saved");
        console.log(data);
      });
    });
  };

  return (
    <div className={cnWizardVerticalMenu({}, [className])}>
      {/* SIZES */}
      <div
        className={cnWizardVerticalMenu("Action", {
          active: kitchenOptions.sizesType !== KITCHEN_SIZES_TYPE_NONE,
        })}
        onClick={toggleSizes}
        onMouseEnter={() => {
          setHoverIcon("sizes");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"sizes"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.sizesType !== KITCHEN_SIZES_TYPE_NONE,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"sizes" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Вкл/выкл размеры")}
        </Popup>
      </div>

      {/* RENDER */}
      <div
        className={cnWizardVerticalMenu("Action")}
        onClick={toogleRenderImage}
        onMouseEnter={() => {
          setHoverIcon("render");
        }}
        onMouseLeave={() => {
          setHoverIcon(null);
        }}
      >
        <Svg
          icon={"3d-model"}
          className={cnWizardVerticalMenu("Icon", {
            active: kitchenOptions.showCorners !== true,
          })}
        />
        <Popup
          className={cnWizardVerticalMenu("Popup")}
          active={"render" === hoverIcon}
          position={{ x: -40, y: 0 }}
        >
          {t("Render")}
        </Popup>
      </div>
    </div>
  );
};

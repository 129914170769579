import {ThreeEditor} from "../../editors/ThreeEditor/ThreeEditor";
import {Intersection, Object3D} from "three";
import {CommonObject} from "../../objects/CommonObject/CommonObject";
import {LONG_TOUCH_TIME} from "../../../constants";
import {ThreeUnit} from "../../objects/threeD/ThreeUnit/ThreeUnit";
import {TScreenPosition} from "../../../types/TScreenPosition";
import {TStartVectors} from "../../types/TStartVectors";
import {isMobile} from "../../../helpers";
import {PointerEvent as ReactPointerEvent} from 'react';

export class UserControls {
    editor: ThreeEditor;
    htmlContainer?: HTMLDivElement;
    screenPosition: TScreenPosition;
    startVectors: TStartVectors;
    longTouch: boolean;
    longTouchTimer?: ReturnType<typeof setTimeout>;
    tryMoving: boolean;
    isMove: {
        isVerticalMove: boolean;
        isLeftRightMove: boolean;
        isForwardBackMove: boolean;
    };
    moving: boolean;

    constructor(editor: ThreeEditor) {
        this.editor = editor;
        this.screenPosition = {x: 0, y: 0};
        this.longTouch = false;
        this.tryMoving = false;
        this.moving = false;
        this.isMove = {
            isVerticalMove: false,
            isLeftRightMove: false,
            isForwardBackMove: false,
        };
        this.editor.clearSelectingCover();
        this.editor.clearSelectedCovers();
        this.startVectors = {};
    }

    public run(htmlContainer: HTMLDivElement) {
        const canvas: HTMLCanvasElement = this.editor.getCanvas();
        const win: Window | null = this.editor.getWin();
        this.htmlContainer = htmlContainer;

        canvas.addEventListener('pointerdown', this.onPointerDown.bind(this), false);
        canvas.addEventListener('pointermove', this.onPointerMove.bind(this), false);
        canvas.addEventListener('pointerup', this.onPointerUp.bind(this), false);
        if (win) {
            win.addEventListener('keydown', this.onKeyDown.bind(this), false);
        }
    }

    public stop() {
        if (!this.editor.isReady()) {
            return;
        }
        const canvas: HTMLCanvasElement = this.editor.getCanvas();
        const win: Window | null = this.editor.getWin();
        this.htmlContainer = undefined;

        canvas.removeEventListener('pointerdown', this.onPointerDown.bind(this), false);
        canvas.removeEventListener('pointermove', this.onPointerMove.bind(this), false);
        canvas.removeEventListener('pointerup', this.onPointerUp.bind(this), false);
        if (win) {
            win.removeEventListener('keydown', this.onKeyDown.bind(this), false);
        }
    }

    public validatePointerDown(event: PointerEvent) {
        if (!this.editor.isReady()) {
            return false;
        }
        // // Если тип ввода мышь и нажата не левая клавиша мыши
        // return !(event.pointerType === 'mouse' && event.button !== 0);

        return true;
    }

    public onPointerDown(event: PointerEvent): boolean {
        let index: string;
        let selectingCovers: Object3D[];
        let selectingCover: Object3D | undefined;
        let currentCover: Object3D | undefined;
        this.editor.startOrbitControl();
        // Проверка условий обработки ввода
        if (!this.validatePointerDown(event)) {
            return true;
        }
        // Установить текущее значение на основе экранных координат
        if (!this.setScreenPositionByEvent(event)) {
            return true;
        }
        // Переопределить стандартное поведение события
        event.preventDefault();
        // Обнулить информацию о перемещении
        this.clearMovingInfo();
        // Обнулить значение таймера для сенсорного нажатия
        this.clearLongTouchInfo();
        // Очистить выбранный объект
        this.clearSelecting();
        this.editor.hideContextMenu();
        // Бросить луч от камеры по направлению к мировым координатам, преобразованных из X, Y экранных координат
        this.editor.setRayCaster(this.screenPosition);
        selectingCovers = this.getIntersectSelectingCovers(event);
        // Если выбранных объектов больше 0 И тип ввода мышь
        if (selectingCovers.length > 0 && event.pointerType === "mouse") {
            // Если существует уже выбранный (без повторного нажатия) объект
            currentCover = this.editor.getCurrentCover();
            selectingCover = selectingCovers[0];
            if (currentCover) {
                // Для каждого выбранного объекта
                for (index in selectingCovers) {
                    // Если uuid оболочки совпадает с uuid выбранного объекта
                    // пытаемся выделить следующий за ним объект
                    if (selectingCovers[index].uuid === currentCover.uuid) {
                        if (event.ctrlKey && selectingCovers[+index + 1]) {
                            selectingCover = selectingCovers[+index + 1];
                            break;
                        } else {
                            selectingCover = selectingCovers[+index];
                        }
                    }
                }
            }
            if (selectingCover && selectingCover.userData.commonObject) {
                // Инициализировать выбранный объект
                this.editor.updateSettingsMenu(selectingCover.userData.commonObject);
                this.initialSelectingCover(selectingCover);
            }
        }

        return true;
    }

    public onPointerMove(event: PointerEvent) {
        let threeUnit: ThreeUnit;
        let selectingCover: Object3D | undefined;
        let currentCover: Object3D | undefined;

        // Установить текущее значение на основе экранных координат
        if (!this.setScreenPositionByEvent(event)) {
            return true;
        }
        selectingCover = this.editor.getSelectingCover();
        if (!selectingCover) {
            return true;
        }
        if (this.isMove.isVerticalMove) {
            this.editor.setVerticalPointer();
        } else {
            this.editor.setHorizontalPointer();
        }

        // Проверка условий обработки ввода
        if (!this.validatePointerMove(event)) {
            return true;
        }

        currentCover = this.editor.getCurrentCover();
        if (!currentCover) {
            return true;
        }
        // Бросить луч от камеры по направлению к мировым координатам, преобразованных из X, Y экранных координат
        this.editor.setRayCaster(this.screenPosition);
        // Если объект выделен первым и вторым кликом или нажатием
        if (currentCover &&
            selectingCover &&
            currentCover.uuid === selectingCover.uuid) {
            // Вернуть значение группы объектов (облочка и 3D модель)
            // Если группа объектов существует
            if (selectingCover.userData.commonObject instanceof ThreeUnit) {
                // Переопределить стандартное поведение события
                event.preventDefault();
                // Запретить вращение камерой по орбите
                this.editor.stopOrbitControl();
                threeUnit = selectingCover.userData.commonObject;
                // Выполнить перемещение группы объектов
                if (threeUnit) {
                    threeUnit.setIsMoving(true);
                    this.tryMoveCover(threeUnit);
                    this.tryMoving = true;
                    threeUnit.afterMove();
                }
            }
        }
    }

    public onPointerUp(event: PointerEvent) {
        // Переменная, которая хранит объект оболочки вокруг 3D модели (Mesh) и саму 3D модель (Group)
        let commonObject: CommonObject | undefined;
        let threeUnit: ThreeUnit | undefined;
        let trySelectingCover: Object3D | undefined;

        trySelectingCover = this.editor.getTrySelectingCover();
        // Если на сцене есть оболочка вокруг 3D модели
        if (trySelectingCover) {
            if (trySelectingCover.userData.commonObject instanceof CommonObject) {
                commonObject = trySelectingCover.userData.commonObject;
                if (commonObject instanceof ThreeUnit) {
                    threeUnit = commonObject;
                    // Если группа объектов существует и не правая кнопка
                    if (threeUnit && event.button !== 2) {
                        // Выполнить перемещение группы объектов, если было перемещение
                        if (this.tryMoving) {
                            this.tryMoveCover(threeUnit, true);
                            threeUnit.setIsMoving(false);
                            threeUnit.afterMove(true);
                        }
                        // Выполнить расчет фронтального направления группы объектов
                        threeUnit.calculateGlobalFrontVector();
                        // Скопировать значение координат для правильного позиционирования оболочки и 3D модели
                        threeUnit.syncCoverPositionRotation();
                    }
                }
                commonObject.setCoverSelectColor();
                commonObject.setSelectCoverSelectColor();
                // Обновить матрицу мира
                commonObject.cover.updateMatrixWorld();
                // Добавить в редактор выбранную оболочку
                this.editor.setSelectingCover(trySelectingCover);
                this.editor.setCurrentCover(trySelectingCover);
                this.editor.startKeyMoveCurrentCover();
                // // Обновить JSON данные проекта
                // this.getWizard().calculateProjectData();
                // Обновить параметры после завершения перемещения
                this.editor.onPointUpActions(event as unknown as ReactPointerEvent);
            }
        } else {
            // Обнулить значение выбранной группы до undefined
            this.editor.clearSelectedCovers();
            this.editor.clearCurrentCover();
            this.editor.clearSelectingCover();
            this.editor.clearTrySelectingCover();
            this.editor.hideContextMenu();
            this.editor.hideSettingsMenu();
        }
        if (!this.editor.getCurrentCover()) {
            this.editor.stopKeyMoveCurrentCover();
        }
        // Обработка возможности показа контекстного меню
        this.showRightClick(event);
        // // Разрешить вращение камерой по орбите
        // this.editor.startOrbitControl();
        // Установить флаг о завершении перемещения
        this.clearMovingInfo();
    }

    public onKeyDown(event: KeyboardEvent) {
    }

    protected tryMoveCover(threeUnit: ThreeUnit, isFinal?: boolean) {
        if (!threeUnit.canMove) {
            this.moving = false;

            return;
        }
        this.moving = threeUnit.tryMove(this.startVectors, isFinal);
        threeUnit.afterTryMove(this.moving, isFinal);
    }

    protected setLongTouch() {
        // Указываем, что сработал лонгтач
        this.longTouch = true;
    }

    protected clearMovingInfo() {
        this.editor.setDefaultPointer();
        this.tryMoving = false;
        this.moving = false;
        this.isMove = {
            isVerticalMove: false,
            isLeftRightMove: false,
            isForwardBackMove: false
        }
    }

    protected showRightClick(event: PointerEvent) {
        // Определить тип ввода
        switch (event.pointerType) {
            case 'mouse':
                // Если отжата левая или правая клавиша мыши
                if (event.button === 2) {
                    // Показать контекстное меню
                    return this.editor.onRightClick(event as unknown as ReactPointerEvent);
                }
                // Прекратить выполнение
                return false;
            case 'touch':
            case 'pen':
                // Если нажатие является первым И выполнено длительное удержание (long touch) И не происходило перемещение объекта
                if (event.isPrimary && this.longTouch && !this.moving) {
                    // Показать контекстное меню
                    return this.editor.onRightClick(event as unknown as ReactPointerEvent);
                }
                // Обнулить значение таймера для сенсорного нажатия
                this.clearLongTouchInfo();
                break;
        }

        return false;
    }

    protected validatePointerMove(event: PointerEvent): boolean {
        if (!this.editor.isReady()) {
            return false;
        }

        if (event.pointerType !== undefined) {
            // Определить тип ввода
            switch (event.pointerType) {
                case 'mouse':
                    // Если была нажата любая клавиша кроме левой клавиши мыши
                    if (event.buttons !== 1) {
                        // Прекратить выполнение
                        return false;
                    }
                    break;
                case 'touch':
                    // Если более одного нажатия
                    if (!event.isPrimary) {
                        // Прекратить выполнение
                        return false;
                    }
                    break;
            }
        }

        return true;
    }

    protected getIntersectSelectingCovers(event: PointerEvent): Object3D[] {
        let selectingCoversData: { [s: string]: { index: number, object: Object3D } } = {};
        let selectingCoversArray: { index: number, object: Object3D }[];
        let item: { index: number, object: Object3D };
        let selectingCovers: Object3D[] = [];
        let intersects: Intersection[];
        let cover: Object3D;
        let commonObject: CommonObject;
        let index: number;

        if (isMobile()) {
            return selectingCovers;
        }
        // Получить список объектов, которые пересекает луч
        intersects = this.editor.getRayCasterIntersection(this.editor.getThreeCoversWithArrowsArray());
        // Если количество объектов, которые пересекает луч больше 0
        if (intersects.length > 0) {
            // Для каждого объекта, которые пересекает луч
            for (index = 0; index < intersects.length; index++) {
                // Получить объект оболочки вокруг 3D модели (Mesh)
                cover = intersects[index].object;
                commonObject = cover.userData.commonObject;
                if (!commonObject) {
                    continue;
                }
                // Если тип ввода мышь И нажата клавиша Ctrl И объект выбран И uuid оболочки совпадает с uuid выбранного объекта
                if (event.pointerType === 'mouse' && event.ctrlKey &&
                    this.editor.hasSelectedCover(cover)) {
                    // Продолжить выполнение
                    continue;
                }

                // Если 3D модель существует на сцене (не disable)
                if (commonObject.canSelect) {
                    // Определить тип ввода
                    switch (event.pointerType) {
                        case 'mouse':
                            // Добавить в массив выбранных элементов оболочку и группу объектов
                            if (!selectingCoversData[cover.uuid]) {
                                selectingCoversData[cover.uuid] = {
                                    index: index,
                                    object: cover
                                };
                            }
                            break;
                        case 'touch':
                            // Инициализировать выбранный объект
                            this.initialSelectingCover(cover);
                            this.longTouchTimer = setTimeout(() => {
                                this.setLongTouch();
                            }, LONG_TOUCH_TIME);
                            break;
                    }
                }
            }
        }
        selectingCoversArray = Object.values(selectingCoversData).sort((a, b) => {
            return a.index - b.index;
        });
        for (item of selectingCoversArray) {
            selectingCovers.push(item.object);
        }

        return selectingCovers;
    }

    public initialSelectingCover(cover: Object3D): boolean {
        let isOk: boolean;
        this.editor.initialSelectingCover(cover);
        isOk = this.initialStartVectors(cover);
        if (cover.userData.commonObject instanceof ThreeUnit) {
            cover.userData.commonObject.initialCover();
        }

        return isOk;
    }

    protected initialStartVectors(cover: Object3D): boolean {
        // let intersectsMovePlanes, intersect;

        this.startVectors = {
            'cover': cover.position.clone()
        };
        // intersectsMovePlanes = this.editor.getRayCasterIntersection(this.editor.getPlanes());
        // if (intersectsMovePlanes.length > 0) {
        //     for (intersect of intersectsMovePlanes) {
        //         if (intersect.object.userData.type) {
        //             this.startVectors[intersect.object.userData.type] = intersect.point.clone();
        //         }
        //     }
        // }

        return !!Object.keys(this.startVectors).length;
    }

    public setScreenPositionByEvent(event: PointerEvent) {
        let screenPosition = this.editor.calculateScreenPosition(event as unknown as ReactPointerEvent);

        if (screenPosition) {
            this.screenPosition = screenPosition;

            return true;
        }

        return false;
    }

    public setScreenPosition(position: TScreenPosition): TScreenPosition {
        this.screenPosition = position;
        return this.screenPosition;
    }

    public getScreenPosition(): TScreenPosition {
        return this.screenPosition;
    }

    protected clearLongTouchInfo() {
        this.longTouch = false;
        if (this.longTouchTimer) {
            clearTimeout(this.longTouchTimer);
        }
        this.longTouchTimer = undefined;
    }

    protected clearSelecting() {
        this.editor.clearTrySelectingCover();
        this.editor.clearSelectingCover();
        this.startVectors = {};
    }
}

import {ThreeEditor} from '../ThreeEditor/ThreeEditor';
import {Dispatch} from 'redux';
import {KitchenService} from '../../services/KitchenService/KitchenService';
import {AmbientLight, Box3, DirectionalLight, Object3D, PerspectiveCamera, SpotLight, Vector2, Vector3} from 'three';
import {ThreeUnit} from '../../objects/threeD/ThreeUnit/ThreeUnit';
import {TUnitsVectors} from '../../types/TUnitsVectors';
import {ICoverPoints} from '../../interfaces/ICoverPoints';
import {ThreeMathHelper} from '../../helpers/ThreeMathHelper/ThreeMathHelper';
import {TThreeLine} from '../../types/TThreeLine';
import {TTwoDLine} from '../../types/TTwoDLine';
import {TEditorScreenData} from '../../types/TEditorScreenData';
import {SCREEN_TYPE_HORIZONTAL, SCREEN_TYPE_VERTICAL} from '../../constants';
import {TScreenType} from '../../types/TScreenType';
import {TScreenFrameData} from '../../types/TScreenFrameData';
import {IEditorOptions} from '../../../interfaces/IEditorOptions';
import {TAspectData} from '../../../../common-code/types/TAspectData';
import {LEVEL_BOTTOM} from '../../../../common-code/constants';
import {MathHelper} from 'common-code';
import {PointerEvent} from 'react';

export class KitchenEditor extends ThreeEditor {
    public screenWidth: number | undefined;
    public screenHeight: number | undefined;
    private reduxDispatch: Dispatch;
    protected service?: KitchenService;

    constructor(win: Window, dispatch: Dispatch, initOptions?: IEditorOptions) {
        super(win, initOptions);
        this.reduxDispatch = dispatch;
    }

    public setService(service: KitchenService) {
        this.service = service;
    }

    public getService(): KitchenService | undefined {
        return this.service;
    }

    public onPointUpActions(event: PointerEvent) {
        this.service?.rebuildScene();
    }

    protected initCamera() {
        super.initCamera();
        if (this.camera) {
            let aspect: TAspectData;

            aspect = this.getResetAspect();
            this.camera.position.set(aspect.camera.x, aspect.camera.y, aspect.camera.z);
        }
    }

    protected initOrbitControl() {
        super.initOrbitControl();
        if (this.orbitControl) {
            let aspect: TAspectData;

            aspect = this.getResetAspect();
            this.orbitControl.target.set(aspect.target.x, aspect.target.y, aspect.target.z);
        }
    }

    public getDefaultAspect(): TAspectData {
        let aspectData: TAspectData | undefined;

        if (!this.service) {
            throw new Error('error-KitchenEditor-getDefaultAspect');
        }
        aspectData = this.service.getFixAspect();
        if (!aspectData) {
            aspectData = this.getShowAllAspect();
        }

        return aspectData;
    }

    public getScreenWidth(): number | undefined {
        return this.screenWidth;
    }

    public getScreenHeight(): number | undefined {
        return this.screenHeight;
    }

    public setScreenWidth(width: number): void {
        this.screenWidth = width;
    }

    public setScreenHeight(height: number): void {
        this.screenHeight = height;
    }

    public getFrameData(): TScreenFrameData {
        let screenData: TEditorScreenData;

        screenData = this.getScreenData();
        screenData.frameWidth = this.getScreenWidth() || screenData.frameWidth;
        screenData.frameHeight = this.getScreenHeight() || screenData.frameHeight;

        return  {
            start: {
                x: (screenData.width - screenData.frameWidth) / 2,
                y: (screenData.height - screenData.frameHeight) / 2
            },
            end: {
                x: (screenData.width - screenData.frameWidth) / 2 + screenData.frameWidth,
                y: (screenData.height - screenData.frameHeight) / 2 + screenData.frameHeight
            },
            width: screenData.frameWidth,
            height: screenData.frameHeight
        };
    }

    public getFrameDataEDIT(): TScreenFrameData {
        let screenData: TEditorScreenData;

        screenData = this.getScreenDataEDIT();
        screenData.frameWidth = this.getScreenWidth() || screenData.frameWidth;
        screenData.frameHeight = this.getScreenHeight() || screenData.frameHeight;

        return  {
            start: {
                x: (screenData.width - screenData.frameWidth) / 2,
                y: (screenData.height - screenData.frameHeight) / 2
            },
            end: {
                x: (screenData.width - screenData.frameWidth) / 2 + screenData.frameWidth,
                y: (screenData.height - screenData.frameHeight) / 2 + screenData.frameHeight
            },
            width: screenData.frameWidth,
            height: screenData.frameHeight
        };
    }

    protected getRendererImageData(rendererType: string): string | undefined {
        switch (rendererType) {
            case 'cutting':
                if (this.cuttingRenderer !== undefined) {
                    return this.cuttingRenderer.domElement.toDataURL('image/png');
                }
                break;
            default:
                if (this.renderer !== undefined) {
                    return this.renderer.domElement.toDataURL('image/png');
                }
                break;
        }

        return undefined;
    }

    public getImageData(rendererType: string, screenAll?: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!this.renderer) {
                reject('error-KitchenEditor-getImageData');
                throw new Error('error-KitchenEditor-getImageData')
            }
            const imageData: string | undefined = this.getRendererImageData(rendererType);
            if (!imageData) {
                reject('error-KitchenEditor-getImageData');
                throw new Error('error-KitchenEditor-getImageData')
            }
            let image: HTMLImageElement;
            let saveImageData: string;
            let canvas: HTMLCanvasElement;
            let canvasContext: CanvasRenderingContext2D | null;
            let frameData: TScreenFrameData;

            if (screenAll) {
                resolve(imageData);
                return;
            }
            image = new Image();
            image.onload = () => {
                frameData = this.getFrameData();
                canvas = document.createElement('canvas');
                canvas.width = frameData.width;
                canvas.height = frameData.height;
                canvasContext = canvas.getContext('2d');
                if (canvasContext) {
                    canvasContext.drawImage(image, -frameData.start.x, -frameData.start.y);
                    saveImageData = canvas.toDataURL('image/png');
                    resolve(saveImageData);
                } else {
                    reject();
                }

            };
            image.onerror = () => {
                reject();
            }
            image.src = imageData;
        })

    }

    public getDivImageData(rendererType: string, screenAll?: boolean): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!this.renderer) {
                reject('error-KitchenEditor-getImageData');
                throw new Error('error-KitchenEditor-getImageData')
            }
            const imageData: string | undefined = this.getRendererImageData(rendererType);
            if (!imageData) {
                reject('error-KitchenEditor-getImageData');
                throw new Error('error-KitchenEditor-getImageData')
            }
            let image: HTMLImageElement;
            let saveImageData: string;
            let canvas: HTMLCanvasElement;
            let canvasContext: CanvasRenderingContext2D | null;
            let frameData: TScreenFrameData;

            if (screenAll) {
                resolve(imageData);
                return;
            }
            image = new Image();
            image.onload = () => {
                frameData = this.getFrameDataEDIT();
                canvas = document.createElement('canvas');
                canvas.width = frameData.width;
                canvas.height = frameData.height;
                canvasContext = canvas.getContext('2d');
                if (canvasContext) {
                    canvasContext.drawImage(image, -frameData.start.x, -frameData.start.y);
                    saveImageData = canvas.toDataURL('image/png');
                    resolve(saveImageData);
                } else {
                    reject();
                }

            };
            image.onerror = () => {
                reject();
            }
            image.src = imageData;
        })

    }

    public getResetAspect(): TAspectData {
        return {
            target: {x: 0, y: 1000, z: 0},
            camera: {x: 0, y: 2200, z: 7000}
        };
    }

    public getShowAllAspect(): TAspectData {
        let vectors;
        let units: ThreeUnit[];
        let target: Vector3;
        let viewPosition;

        if (!this.service) {
            throw new Error('error-KitchenEditor-getShowAllAspect');
        }
        units = this.service.getObjects();
        if (units.length <= 0) {
            return this.getResetAspect();
        }
        target = this.service.getSceneBox().getCenter(new Vector3());
        // Объект, который содержит координаты точек A и B для каждой группы объектов (групп по направлению
        // лицевой стороны) от центра общего для группы BoundingBox прямо, на расстояние общей суммы ширины
        // всех юнитов группы, а также содержит минимальные и максимальные точки BoundingBox для каждого юнита
        vectors = this.calculateUnitVectors(units);
        // Координаты для установки камеры
        viewPosition = this.getViewPosition(vectors, target);
        if (units.length === 1) {
            let direction = units[0].getLevel() === LEVEL_BOTTOM ? 1 : -1;
            viewPosition.x += units[0].getWidth();
            viewPosition.y += direction * units[0].getHeight()/2;
        }

        return {
            target: target,
            camera: viewPosition
        };
    }

    protected calculateUnitVectors(units: ThreeUnit[]): TUnitsVectors {
        let unitsByDirection: {[s: string]: ThreeUnit[]};
        let group;
        let vectors: {[s: string]: TThreeLine};
        let unit;
        let directionUnits;
        let points;
        let unitsBoxPoints;
        let width;
        let coverPoints: ICoverPoints;
        let boundingBox;
        let pointA: Vector3;
        let pointB: Vector3;

        unitsBoxPoints = [];
        boundingBox = new Box3();
        vectors = {};
        unitsByDirection = this.getUnitsByDirection(units);
        for (group in unitsByDirection) {
            if (unitsByDirection.hasOwnProperty(group)) {
                directionUnits = unitsByDirection[group];
                points = [];
                for (unit of directionUnits) {
                    coverPoints = unit.getGlobalPoints();
                    points.push(coverPoints.bottom.A);
                    points.push(coverPoints.bottom.B);
                    unitsBoxPoints.push(coverPoints.box.min);
                    unitsBoxPoints.push(coverPoints.box.max);
                }
                boundingBox.setFromPoints(points);
                // TODO Добавлен коэффициент (100), чтобы точка viewVector была не внутри модуля а за его пределами
                width = ThreeMathHelper.getLength(boundingBox.max, boundingBox.min) + 100;

                pointA = boundingBox.getCenter(new Vector3());
                pointB = new Vector3(
                    pointA.x + width * Math.sin(+group),
                    pointA.y,
                    pointA.z + width * Math.cos(+group)
                );
                vectors[group] = {
                    pointA: pointA,
                    pointB: pointB
                };
            }
        }

        return {vectors: vectors, unitsBoxPoints: unitsBoxPoints};
    }

    protected getViewPosition(unitVectors: TUnitsVectors, target: Vector3): Vector3 {
        let index;
        let result;
        let delta;
        let viewVector;
        let ratio;

        // Нахождение для каждой группы юнитов
        // (которые направлены в одном направлении) координаты точки delta (точки
        // общего направления), которая рассчитывается путем вычитания
        // координаты точки А (центр каждого юнита)
        // от точки В (вектор от центра юнита прямо), т.е. получаем вектор (точку) общего направления по осям
        // X и Z для всех групп
        delta = new Vector2();
        for (index in unitVectors.vectors) {
            if (unitVectors.vectors.hasOwnProperty(index)) {
                delta.x += unitVectors.vectors[index].pointB.x - unitVectors.vectors[index].pointA.x;
                delta.y += unitVectors.vectors[index].pointB.z - unitVectors.vectors[index].pointA.z;
            }
        }

        // Получение векторов (координат точек)
        // Координата центра вращения камерой и координата общего направления юнитов
        viewVector = {
            pointA: new Vector2(+target.x, +target.z),
            pointB: new Vector2(+target.x + delta.x, +target.z + delta.y)
        };
        // Выполнение расчета ratio
        ratio = this.calculateViewRatio(unitVectors, viewVector, target);
        // Если ratio меньше единицы
        if (ratio < 1 || ratio > 2) {
            viewVector.pointB = ThreeMathHelper.getPointByRatio2D(viewVector.pointA, viewVector.pointB, ratio,  false);
            ratio = this.calculateViewRatio(unitVectors, viewVector, target);
        }
        // Нахождение координат точки для установки камеры
        delta = ThreeMathHelper.getPointByRatio2D(viewVector.pointA, viewVector.pointB, ratio,  false);
        result = new Vector3(delta.x, target.y + 200, delta.y);

        return result;
    }

    protected calculateViewRatio(vectors: TUnitsVectors, viewVector: TTwoDLine, target: Vector3): number {
        let box;
        let directVector: TTwoDLine;
        let directLine;
        let screenData;
        let width;
        let height;
        let distance;
        let vFOV;
        let hFOV;
        let ratio;

        if (!this.camera ||
            !(this.camera instanceof PerspectiveCamera)) {
            throw new Error('error-KitchenEditor-getViewPosition');
        }
        // Построение общего BoundingBox для всех юнитов
        box = new Box3().setFromPoints(vectors.unitsBoxPoints);

        // Получение векторов (координат точек)
        // Координата центра вращения камерой и координата линии направления, полученная путем поворота вектора
        // viewVector на 90 градусов (перпендикулярно) относительно координаты центра вращения камерой
        directLine = {
            pointA: new Vector2(+target.x, +target.z),
            pointB: ThreeMathHelper.turnVector2D(
                viewVector.pointB.clone().sub(viewVector.pointA),
                Math.PI / 2,
                viewVector.pointA
            )
        };

        // Получение вектора направления, путем получения максимальных углов (положительного и отрицательного) от точки
        // установки камеры до каждой вершины (для вида сверху) BoundingBox каждого юнита
        directVector = this.getViewDirectionPoints(vectors.unitsBoxPoints, directLine, viewVector);

        // Получение ширины, путем вычисления длины вектора направления
        width = ThreeMathHelper.getLength2D(directVector.pointA, directVector.pointB);
        // Получение высоты, путем вычета нижней координаты общего BoundingBox от верхней
        height = (box.max.y - box.min.y) + 400;

        // Получение информации о характеристиках экрана и canvas
        screenData = this.getScreenData();
        // Вертикальный угол обзора камеры (в радианах)
        vFOV = this.camera.fov * (Math.PI / 180);
        // Горизонтальный угол обзора камеры (в радианах)
        hFOV = 2 * Math.atan(Math.tan(vFOV / 2) * (screenData.frameWidth / screenData.frameHeight));

        distance = {
            // Нахождение расстояния на основе вертикального угла камеры и высоты
            height: (height / 2) / Math.tan(vFOV / 2),
            // Нахождение расстояния на основе горизонтального угла камеры и ширины
            width: (width / 2) / Math.tan(hFOV / 2)
        };

        // Нахождение соотношения высоты / ширины к расстоянию
        ratio = (Math.max(distance.height, distance.width) * 1.05) / screenData.scale /
            ThreeMathHelper.getLength2D(viewVector.pointA, viewVector.pointB);

        return ratio;
    }

    protected getScreenData(): TEditorScreenData {
        let canvasSize, frameHeight, frameWidth, ratio, scale, type: TScreenType;

        ratio = (window) ? window.devicePixelRatio : 1;
        canvasSize = this.getCanvasSize(ratio);
        frameWidth = canvasSize.width;
        frameHeight = frameWidth / 1.777777778;
        type = SCREEN_TYPE_HORIZONTAL;
        scale = (frameHeight / canvasSize.height);
        if (frameHeight > canvasSize.height) {
            frameHeight = canvasSize.height;
            frameWidth = frameHeight * 1.777777778;
            scale = frameWidth / canvasSize.width;
            type = SCREEN_TYPE_VERTICAL;
        }

        return {
            width: canvasSize.width,
            height: canvasSize.height,
            frameWidth: frameWidth,
            frameHeight: frameHeight,
            scale: scale,
            type: type,
            ratio: ratio
        };
    }

    protected getScreenDataEDIT(): TEditorScreenData {
        let canvasSize, frameHeight, frameWidth, ratio, scale, type: TScreenType;

        ratio = (window) ? window.devicePixelRatio : 1;
        canvasSize = this.getCanvasSize(ratio);
        frameWidth = canvasSize.width;
        frameHeight = frameWidth
        type = SCREEN_TYPE_HORIZONTAL;
        scale = (frameHeight / canvasSize.height);
        if (frameHeight > canvasSize.height) {
            frameHeight = canvasSize.height;
            frameWidth = frameHeight
            scale = frameWidth / canvasSize.width;
            type = SCREEN_TYPE_VERTICAL;
        }

        return {
            width: canvasSize.width,
            height: canvasSize.height,
            frameWidth: frameWidth,
            frameHeight: frameHeight,
            scale: scale,
            type: type,
            ratio: ratio
        };
    }

    protected getViewDirectionPoints(unitsBoxPoints: Vector3[], directLine: TTwoDLine, viewVector: TTwoDLine): TTwoDLine {
        let points, boxPoints: {[n: number]: Vector2}, index, index1, angle, angles, boxVector,
            line1: TTwoDLine, line2: TTwoDLine, intersectionPoint1, intersectionPoint2;

        points = [];
        for (index in unitsBoxPoints) {
            if (unitsBoxPoints.hasOwnProperty(index)) {
                if (!unitsBoxPoints[(+index + 1)]) {
                    break;
                }
                points.push(new Vector2(unitsBoxPoints[index].x, unitsBoxPoints[index].z));
                points.push(new Vector2(unitsBoxPoints[(+index + 1)].x, unitsBoxPoints[(+index + 1)].z));
                points.push(new Vector2(unitsBoxPoints[index].x, unitsBoxPoints[(+index + 1)].z));
                points.push(new Vector2(unitsBoxPoints[(+index + 1)].x, unitsBoxPoints[index].z));
            }
        }

        boxPoints = {};
        for (index1 in points) {
            if (!points.hasOwnProperty(index1)) {
                continue;
            }
            angle = MathHelper.getNormalAngle({x: points[index1].x - viewVector.pointB.x, y: points[index1].y - viewVector.pointB.y}) * 180 / Math.PI -
                MathHelper.getNormalAngle( {x: viewVector.pointA.x - viewVector.pointB.x, y: viewVector.pointA.y - viewVector.pointB.y}) * 180 / Math.PI;
            boxPoints[angle] = points[index1];
        }

        angles = Object.keys(boxPoints).map(function (x) {
            return parseFloat(x);
        });

        boxVector = {
            pointA: {x: boxPoints[Math.min.apply(Math, angles)].x, y: boxPoints[Math.min.apply(Math, angles)].y},
            pointB: {x: boxPoints[Math.max.apply(Math, angles)].x, y: boxPoints[Math.max.apply(Math, angles)].y}
        };

        line1 = {
            pointA: viewVector.pointB,
            pointB: new Vector2(boxVector.pointA.x, boxVector.pointA.y)
        };

        line2 = {
            pointA: viewVector.pointB,
            pointB: new Vector2(boxVector.pointB.x, boxVector.pointB.y)
        };

        intersectionPoint1 = ThreeMathHelper.getIntersectionPoint(line1, directLine);
        intersectionPoint2 = ThreeMathHelper.getIntersectionPoint(line2, directLine);

        if (intersectionPoint1 && intersectionPoint2) {
            return {
                pointA: intersectionPoint1,
                pointB: intersectionPoint2
            }
        }

        throw new Error('error-KitchenEditor-getViewDirectionPoints');
    }

    protected getUnitsByDirection(units: ThreeUnit[]): {[s: string]: ThreeUnit[]} {
        let unitsByDirection: {[s: string]: ThreeUnit[]};
        let unit;
        let group: string;

        unitsByDirection = {};
        for (unit of units) {
            group = ''+unit.view3d.rotation.y;
            if (!unitsByDirection[group]) {
                unitsByDirection[group] = [];
            }
            unitsByDirection[group].push(unit);
        }

        return unitsByDirection;
    }

    protected setStatsPosition() {
        if (this.stats) {
            this.stats.dom.style.position = 'absolute';
            this.stats.dom.style.right = '0';
            this.stats.dom.style.left = 'auto';
            this.stats.dom.style.top = 'auto';
            this.stats.dom.style.bottom = '0';
        }
    }

    protected initLights() {
        if (!this.scene) {
            return null;
        }
        this.ambientLight = new AmbientLight("#d0d0d0", 0.3);
        this.scene.add(this.ambientLight);
        this.directionLight = new DirectionalLight(0xffffff, 0.38);
        this.directionLight.position.set(0.5, 0.2, 0.35);
        this.scene.add(this.directionLight);
        let spotLight = new SpotLight('#fffaf1', 0.38, 0);
        spotLight.position.set(4500, 4500, 0);
        this.scene.add(spotLight);
        let spotLight2 = new SpotLight('#fffaf1', 0.4, 10000);
        spotLight2.castShadow = true;
        spotLight2.shadow.normalBias = 20;
        spotLight2.shadow.mapSize.width = 1024;
        spotLight2.shadow.mapSize.height = 1024;
        spotLight2.shadow.camera.near = 0.1;
        spotLight2.shadow.camera.far = 10000;
        spotLight2.shadow.focus = 1;
        spotLight2.position.set(-4500, 4500, 0);
        spotLight2.name = 'light2';
        this.scene.add(spotLight2);
    };

    public sceneChildren():Object3D<THREE.Event>[]  {
        if (this.scene && this.scene.children) {
            return this.scene.children;
        } else {
            return [];
        }
    }
}
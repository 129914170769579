import { TSideType } from "../../../../../../common-code/types/TSideType";
import {
  Box3,
  Euler,
  ExtrudeGeometry,
  Mesh,
  MeshStandardMaterial,
  Vector2,
  Vector3,
} from "three";
import {
  CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE,
  CLASSNAME_PENAL_UNIT_SINGLE_HANDLE,
  SIDE_TYPE_BACK,
  SIDE_TYPE_LEFT,
  SIDE_TYPE_RIGHT,
  T_I_HANDLE_FUNCTIONAL_TYPE_BETWEEN_BOXES,
  T_I_HANDLE_FUNCTIONAL_TYPE_DEFAULT,
} from "../../../../../../common-code/constants";
import { TPoint2D } from "../../../../../../common-code/types/TPoint2D";
import { ThreeKUnitDetail } from "../ThreeKUnitDetail/ThreeKUnitDetail";
import { IThreeKUnitDetail } from "../../../../interfaces/IThreeKUnitDetail";
import { ICoverMainPoints } from "../../../../interfaces/ICoverMainPoints";
import { ThreeUnit } from "../../ThreeUnit/ThreeUnit";
import { TPositionSideType } from "../../../../../../common-code/types/TPositionSideType";
import { CommonHelper, KitchenHelper } from "common-code";
import { IGlobalSidePoints } from "../../../../interfaces/IGlobalSidePoints";
import { IIntegratedHandleData } from "../../../../../../common-code/interfaces/materials/IIntegratedHandleData";
import { IDetailPosition } from "../../../../../../common-code/interfaces/geometry/IDetailPosition";
import { TPoint3D } from "../../../../../../common-code/types/TPoint3D";
import { ISaveIntegratedHandleData } from "../../../../../../common-code/interfaces/saveData/ISaveIntegratedHandleData";
import { TIntegratedHandleFunctionalType } from "../../../../../../common-code/types/materials/TIntegratedHandleFunctionalType";

export class ThreeIntegratedHandle
  extends ThreeKUnitDetail
  implements IThreeKUnitDetail
{
  saveData: ISaveIntegratedHandleData;
  materialData: IIntegratedHandleData;
  pointA: TPoint3D = { x: 0, y: 0, z: 0 };
  pointB: TPoint3D = { x: 0, y: 0, z: 0 };

  constructor(options: ISaveIntegratedHandleData, unit: ThreeUnit) {
    super(options, unit);
    this.saveData = this.initThreeUnitSaveData(options);
    this.materialData = this.initMaterialData();

    if (
      this.unit.saveData.sideType === "left" &&
      this.unit.saveData.className ===
        CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE &&
      this.unit.saveData.corpus?.frontPanel
    ) {
      this.pointA = this.calculatePoint(
        -this.unit.getWidth() / 2 + this.unit.saveData.corpus.frontPanel.length,
        this.saveData.interval?.pointA
      );

      this.pointB = this.calculatePoint(
        this.unit.getWidth() / 2,
        this.saveData.interval?.pointB
      );
    } else if (
      this.unit.saveData.sideType === "right" &&
      this.unit.saveData.className ===
        CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE &&
      this.unit.saveData.corpus?.frontPanel
    ) {
      this.pointA = this.calculatePoint(
        -this.unit.getWidth() / 2,
        this.saveData.interval?.pointA
      );
      this.pointB = this.calculatePoint(
        this.unit.getWidth() / 2 - this.unit.saveData.corpus.frontPanel.length,
        this.saveData.interval?.pointB
      );
    } else {
      this.pointA = this.calculatePoint(
        -this.unit.getWidth() / 2,
        this.saveData.interval?.pointA
      );
      this.pointB = this.calculatePoint(
        this.unit.getWidth() / 2,
        this.saveData.interval?.pointB
      );
    }
  }

  public getFunctionalType(): TIntegratedHandleFunctionalType {
    return this.saveData.functionalType || T_I_HANDLE_FUNCTIONAL_TYPE_DEFAULT;
  }

  public getDefaultPositionByType(): Vector3 {
    let position: Vector3;

    position = new Vector3(
      (this.pointA.x + this.pointB.x) / 2,
      (this.pointA.y + this.pointB.y) / 2,
      (this.pointA.z + this.pointB.z) / 2
    );

    return position;
  }

  public createBody(): void {
    let geometry;
    let extrudeSettings;
    let depth = 0;

    if(this.unit.saveData.className ===
      CLASSNAME_BOTTOM_ANGLE_UNIT_NORMAL_SINGLE_HANDLE &&
    this.unit.saveData.corpus?.frontPanel){
      depth =  this.getLength() - this.unit.saveData.corpus?.frontPanel.length;
    } else if (this.unit.saveData.className === CLASSNAME_PENAL_UNIT_SINGLE_HANDLE && this.unit.saveData.corpus) {
      depth = this.getLength() - this.unit.saveData.corpus?.thickness*2;
    } else {
      depth = this.getLength();
    }

    extrudeSettings = {
      steps: 1,
      depth: depth,
      bevelEnabled: false,
    };
    geometry = new ExtrudeGeometry(this.shape, extrudeSettings);
    geometry.center();
    this.body = new Mesh(geometry, this.getBodyMaterial());
    this.body.rotation.y = -0.5 * Math.PI;
    this.body.updateMatrix();
    this.body.geometry.applyMatrix4(this.body.matrix);
    this.body.rotation.y = 0;
    this.body.name = "body";
    this.body.matrixAutoUpdate = false;
    this.body.updateMatrix();
    this.body.userData.notTransparentForBack = true;
    this.view3d.add(this.body);
    this.addCoverPoints(this.calculateMeshCoverPoints(this.body));
  }

  public getBodyMaterial() {
    return new MeshStandardMaterial({
      // color: this.materialData.color || "#17181a",
      color: "#17181a",
      metalness: 0.8,
      // map: this.materialTextures.texture || null,
      // normalMap: this.materialTextures.normal || null,
      // roughnessMap: this.materialTextures.roughness || null,
      envMapIntensity: 5,
    });
  }

  public getGlobalSidePoints(cover: Mesh = this.cover): IGlobalSidePoints {
    return super.getGlobalSidePoints();
  }

  public initHeight(positionType?: TPositionSideType): number {
    return this.service.getIntegrationHandleHeight();
  }

  public initWidth(positionType?: TPositionSideType): number {
    return this.service.getIntegrationHandleWidth();
  }

  public initLength(positionType?: TPositionSideType) {
    let length: number;
    let coverBox: Box3;

    coverBox = this.unit.getCorpusCoverBox(0);
    switch (positionType) {
      case SIDE_TYPE_LEFT:
      case SIDE_TYPE_RIGHT:
        length = coverBox.max.z - coverBox.min.z;
        break;
      case SIDE_TYPE_BACK:
      default:
        length = coverBox.max.x - coverBox.min.x;
        break;
    }

    return length;
  }

  public getDefaultRotationByType(): Euler {
    let rotation: Euler;

    rotation = new Euler();

    return rotation;
  }

  public getDefaultPoints(type: TSideType): Vector2[] {
    let points;

    points = [new Vector2(0, 0)];

    return points;
  }

  public getGlobalMainPoints(cover: Mesh = this.cover): ICoverMainPoints {
    if (!this.correctLeftPoints || !this.correctRightPoints) {
      throw new Error("error-ThreeApron-getGlobalMainPoints");
    }
    this.view3d.updateMatrixWorld();
    this.globalCoverMainPoints.bottom.pointA.set(
        this.correctLeftPoints[0].x,
        this.correctLeftPoints[0].y,
        0
    ).applyMatrix4(this.view3d.matrixWorld);
    this.globalCoverMainPoints.bottom.pointB.set(
        this.correctRightPoints[0].x,
        this.correctRightPoints[0].y,
        0
    ).applyMatrix4(this.view3d.matrixWorld);

    return this.globalCoverMainPoints;
  }

  public getUnionYPosition(): number {
    return this.getHeight() / 2;
  }

  protected initPriceType() {
    if (
      this.saveData.priceType === undefined &&
      this.service.appConfig.catalog.corners !== undefined
    ) {
      this.saveData.priceType =
        this.service.appConfig.catalog.corners.priceType;
    }
  }

  protected getOtherDetails(): ThreeKUnitDetail[] {
    return this.unit.integratedHandles || [];
  }

  protected calculateShapePoints(): Vector2[] {
    let contourPath: TPoint2D[] | undefined;
    let uniquePoints: { [key: string]: boolean } = {};
    let shapePoints: Vector2[] = [];
    let index: number;

    contourPath = this.getContourPath();

    for (index = 0; index < contourPath.length; index++) {
      if (contourPath[index] === undefined) {
        continue;
      }
      if (!uniquePoints[contourPath[index].x + "_" + contourPath[index].y]) {
        shapePoints.push(
          new Vector2(contourPath[index].x, contourPath[index].y)
        );
        uniquePoints[contourPath[index].x + "_" + contourPath[index].y] = true;
      }
    }
    shapePoints.push(new Vector2(contourPath[0].x, contourPath[0].y));

    return shapePoints;
  }

  protected getContourPath(): TPoint2D[] {
    let contourPath: TPoint2D[] | undefined;

    contourPath = this.saveData.contourPath;
    if (contourPath) {
      return contourPath;
    } else {
      return this.defaultContourPath();
    }
  }

  protected defaultContourPath(): TPoint2D[] {
    switch (this.getFunctionalType()) {
      case T_I_HANDLE_FUNCTIONAL_TYPE_BETWEEN_BOXES:
        return this.createShapeTypeBetweenBoxes();
      case T_I_HANDLE_FUNCTIONAL_TYPE_DEFAULT:
      default:
        return this.createShapeTypeDefault();
    }
  }

  protected createShapeTypeDefault(): TPoint2D[] {
    return [
      { x: 0, y: 0 },
      { x: 0, y: -this.getWidth() },
      { x: this.getHeight(), y: -this.getWidth() },
      { x: this.getHeight(), y: -this.getWidth() + 5 },
      { x: 5, y: -this.getWidth() + 5 },
      { x: 5, y: 0 },
      { x: 0, y: 0 },
    ];
  }

  protected createShapeTypeBetweenBoxes(): TPoint2D[] {
    // TODO: check this
    const x = 10;
    return [
      { x: 0, y: 0 },
      { x: 0, y: -this.getWidth() - x },
      { x: this.getHeight(), y: -this.getWidth() - x },
      { x: this.getHeight(), y: (-this.getWidth() - x) + 5 },
      { x: 5, y: (-this.getWidth() - x) + 5 },
      { x: 5, y: -5 },
      { x: this.getHeight(), y: -5 },
      { x: this.getHeight(), y: 0 },
      { x: 0, y: 0 },
    ];
  }

  protected initThreeUnitSaveData(
    saveData: ISaveIntegratedHandleData
  ): ISaveIntegratedHandleData {
    saveData = super.initThreeUnitSaveData(
      saveData
    ) as ISaveIntegratedHandleData;

    return saveData;
  }

  protected initMaterialData(): IIntegratedHandleData {
    return CommonHelper.deepCopy(
      this.service.getIntegratedHandleMaterial(this.saveData.material)
    );
  }

  protected calculatePoint(initX: number, initPoint?: IDetailPosition) {
    let point: TPoint3D;
    if (
      this.saveData.functionalType === T_I_HANDLE_FUNCTIONAL_TYPE_BETWEEN_BOXES
    ) {
      point = {
        x: initX,
        y: this.unit.getTechHoleCPosition(),
        z: this.unit.getCorpusSizes().width / 2 - this.getHeight() / 2, 
      };
    } else {
      point = {
        x: initX,
        // TODO: add right condition
        y: this.unit.getTechHoleLPosition() !== 0 ? this.unit.getTechHoleLPosition() : this.unit.getCorpusSizes().height / 2 - this.getWidth() / 2,
        z: this.unit.getCorpusSizes().width / 2 - this.getHeight() / 2,
      };
    }

    if (initPoint) {
      if (initPoint.x !== undefined && initPoint.x !== "") {
        point.x =
          KitchenHelper.calculateSizeByParent(
            initPoint.x,
            this.unit.getCorpusSizes().length,
            this.service.getDataForSizeByParent()
          ) -
          this.unit.getCorpusSizes().length / 2;
      }
      if (initPoint.y !== undefined && initPoint.y !== "") {
        point.y =
          KitchenHelper.calculateSizeByParent(
            initPoint.y,
            this.unit.getCorpusSizes().height,
            this.service.getDataForSizeByParent()
          ) -
          this.unit.getCorpusSizes().height / 2;
      }
      if (initPoint.z !== undefined && initPoint.z !== "") {
        point.z =
          KitchenHelper.calculateSizeByParent(
            initPoint.z,
            this.unit.getCorpusSizes().width,
            this.service.getDataForSizeByParent()
          ) -
          this.unit.getCorpusSizes().width / 2;
      }
    }

    return point;
  }
}

import React from 'react';
import { Textinput } from "@yandex/ui/Textinput/desktop/bundle";
import { cnModal, Modal } from "../../../components/Modal";
import { Tumbler } from "../../../components/Tumbler/Tumbler";
import { ITechnologMapEdit } from '../EditTechnologMap/initialTecnologMap';
import { cn } from '@bem-react/classname';
import { Button, cnButton } from '../../../components/Button';
import "./FormTechnologMap.css";


const cnFormTechnologMap = cn("cnFormTechnologMap");

interface IFormTechnologMapProps {
    technologMapEdit: ITechnologMapEdit;
    setTechnologMapEdit: (technologMapEdit: ITechnologMapEdit) => void;
    keyValue: string;
    saveTechnologMap: () => void;
}

const FormTechnologMap = ({technologMapEdit, setTechnologMapEdit, keyValue, saveTechnologMap}: IFormTechnologMapProps) => {
    const {
        bottomUnitsDefaultDepth,
        bottomUnitsDefaultHeight,
        bottomUnitsLegsHeight,
        topUnitsDefaultDepth,
        topUnitsDefaultHeight,
        tabletopsFrontGap,
        tabletopsDefaultWidth,
        apronsInstallHeight,
        plinths,
        plinthsCheckRebuild,
        legs,
        legsHeights,
        legsHeights0,
        legsHeights1,
        legsHeights2,
        legsHeights3,
        legsDefaultHeight,
        hob,
        hobMinWidth,
        modal,
        error
    } = technologMapEdit;

    const onInputChange = (e:  React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        setTechnologMapEdit({ ...technologMapEdit, [e.target.name]: e.target.value, error: "" });
    };

    const onChecked = (e:React.ChangeEvent<HTMLInputElement>) => {
        setTechnologMapEdit({ ...technologMapEdit, [e.target.name]: e.target.checked, error: "" });
    }

    const onAddLegsHeight = () => {
        setTechnologMapEdit({ ...technologMapEdit, legsHeights: [...legsHeights, 0], error: "" });
    }
    const handleToggleModal = () => {
        setTechnologMapEdit({ ...technologMapEdit, modal: !modal , error: "" });
    }

  return (
    <Modal
            className={cnFormTechnologMap(cnModal())}
            visible={modal}
            callBack={handleToggleModal}
            key={`#EditTechnologMap-${keyValue}`}
        >
                <h4 className={cnFormTechnologMap('Header')}>Редактирование технологической карты</h4>
                <div className={cnFormTechnologMap('Content')}>
       
                    <h4 className={cnFormTechnologMap('Title')}>Нижние модули *</h4>
                    <div className={cnFormTechnologMap('Box-Input')}>
                        <div>
                            <label htmlFor="bottomUnitsDefaultDepth">Глубина модуля по умолчанию *</label>
                            <Textinput
                                className={cnFormTechnologMap('Textinput')}
                                value={bottomUnitsDefaultDepth}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="bottomUnitsDefaultDepth"
                                type="number"
                            />
                        </div>
                        <div>
                            <label htmlFor="bottomUnitsDefaultHeight">Высота модуля по умолчанию *</label>
                            <Textinput
                                className={cnFormTechnologMap('Textinput')}
                                value={bottomUnitsDefaultHeight}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="bottomUnitsDefaultHeight"
                                type="number"
                            />
                        </div>
                        <div>
                            <label htmlFor="bottomUnitsLegsHeight">Высота ножки модуля *</label>
                            <Textinput
                                className={cnFormTechnologMap('Textinput')}
                                value={bottomUnitsLegsHeight}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="bottomUnitsLegsHeight"
                                type="number"
                            />
                        </div>
                    </div>
                    
                    <h4 className={cnFormTechnologMap('Title')}>Верхние модули *</h4> 
                    <div className={cnFormTechnologMap('Box-Input')}>
                        <div>
                            <label htmlFor="topUnitsDefaultDepth">Глубина модуля по умолчанию *</label>
                            <Textinput
                                className={cnFormTechnologMap('Textinput')}
                                value={topUnitsDefaultDepth}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="topUnitsDefaultDepth"
                                type="number"
                            />
                        </div>
                        <div>
                            <label htmlFor="topUnitsDefaultHeight">Высота модуля по умолчанию *</label>
                            <Textinput
                                className={cnFormTechnologMap('Textinput')}
                                value={topUnitsDefaultHeight}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="topUnitsDefaultHeight"
                                type="number"
                            />
                        </div>
                    </div>

                    <h4 className={cnFormTechnologMap('Title')}>Столешница *</h4> 
                    <div className={cnFormTechnologMap('Box-Input')}>
                        <div>
                            <label htmlFor="tabletopsFrontGap">Передний зазор *</label>
                            <Textinput
                                className={cnFormTechnologMap('Textinput')}
                                value={tabletopsFrontGap}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="tabletopsFrontGap"
                                type="number"
                            />
                        </div>
                        <div>
                            <label htmlFor="tabletopsDefaultWidth">Ширина по умолчанию *</label>
                            <Textinput
                                className={cnFormTechnologMap('Textinput')}
                                value={tabletopsDefaultWidth}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                                name="tabletopsDefaultWidth"
                                type="number"
                            />
                        </div>
                    </div>

                    <h4 className={cnFormTechnologMap('Title')}>Стеновые панели *</h4>
                    <div>
                        <label htmlFor="apronsInstallHeight">Высота установки стеновой панели *</label>
                        <Textinput
                            className={cnFormTechnologMap('Textinput')}
                            value={apronsInstallHeight}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e)}
                            name="apronsInstallHeight"
                            type="number"
                        />
                    </div>

                    <h4 className={cnFormTechnologMap('Title')}>Цоколь</h4>
                        <Tumbler
                            className={cnFormTechnologMap('Tumbler')}
                            checked={plinths}
                            callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
                            label="Добавить проверку восстановления цоколя"
                            name="plinths"
                        />
                        { plinths &&
                            <Tumbler
                                className={cnFormTechnologMap('Tumbler')}
                                checked={plinthsCheckRebuild}
                                callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
                                label="Проверка восстановления цоколя"
                                name="plinthsCheckRebuild"
                            />
                        }

                    <h4 className={cnFormTechnologMap("Title")}>Ножки</h4>
                    <Tumbler
                        className={cnFormTechnologMap("Tumbler")}
                        checked={legs}
                        callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
                        label="Ножки"
                        name="legs"
                    />
                    
                    {legs && (
                        <>
                            <div className={cnFormTechnologMap("Box-Input")}>
                                <div>
                                    <label htmlFor="legsHeights0">Высота ножки 1</label>
                                    <Textinput
                                        className={cnFormTechnologMap("Textinput")}
                                        value={legsHeights0}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            onInputChange(e)
                                        }
                                        name="legsHeights0"
                                        type="number"
                                    />
                                </div>
                                {legsHeights.length > 1 && (
                                    <div>
                                        <label htmlFor="legsHeights1">Высота ножки 2</label>
                                        <Textinput
                                            className={cnFormTechnologMap("Textinput")}
                                            value={legsHeights1}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                onInputChange(e)
                                            }
                                            name="legsHeights1"
                                            type="number"
                                        />
                                    </div> 
                                )}
                                
                                {legsHeights.length > 2 && (
                                    <div>
                                        <label htmlFor="legsHeights2">Высота ножки 3</label>
                                        <Textinput
                                            className={cnFormTechnologMap("Textinput")}
                                            value={legsHeights2}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                onInputChange(e)
                                            }
                                            name="legsHeights2"
                                            type="number"
                                        />
                                    </div> 
                                )}

                                {legsHeights.length > 3 && (
                                    <div>
                                        <label htmlFor="legsHeights3">Высота ножки 4</label>
                                        <Textinput
                                            className={cnFormTechnologMap("Textinput")}
                                            value={legsHeights3}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                onInputChange(e)
                                            }
                                            name="legsHeights3"
                                            type="number"
                                        />
                                    </div> 
                                )}
                            </div> 
                            {legsHeights.length < 4 && <Button
                                className={cnFormTechnologMap(cnButton())}
                                type={"button"}
                                text={"Добавить высоту ножки"}
                                leftIcon={{path: 'plus2', width: 16, height: 16, marginRight: 8}}
                                mods={{
                                    mods: { bkg: "white", color: "lightGreen", border: "lightGreen", fill: "lightGreen"},
                                    hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" } },
                                }}
                                callBack={onAddLegsHeight}
                            />}


                            <div>
                                <label htmlFor="legsDefaultHeight">Выссота ножки по умолчанию</label>
                                <Textinput
                                    className={cnFormTechnologMap("Textinput")}
                                    value={legsDefaultHeight}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        onInputChange(e)
                                    }
                                    name="legsDefaultHeight"
                                    type="number"
                                />
                            </div>
                        </> )}
        

                    <h4 className={cnFormTechnologMap("Title")}>Плита</h4>
                    <Tumbler
                        className={cnFormTechnologMap("Tumbler")}
                        checked={hob}
                        callBack={(e: React.ChangeEvent<HTMLInputElement>) => onChecked(e)}
                        label="Плита"
                        name="hob"
                    />

                    {hob && (
                        <div>
                            <label htmlFor="hobMinWidth">Минимальная ширина плиты</label>
                            <Textinput
                                className={cnFormTechnologMap("Textinput")}
                                value={hobMinWidth}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    onInputChange(e)
                                }
                                name="hobMinWidth"
                                type="number"
                            />
                        </div>
                    )}
    
                    {error && <p className={cnFormTechnologMap('Error')}>{error}</p>}
                    <Button
                        className={cnFormTechnologMap(cnButton())}
                        type={"button"}
                        text={"Cохранить изменения"}
                        mods={{
                            mods: { bkg: "white", color: "lightGreen", border: "lightGreen", fill: "lightGreen"},
                            hover: { mods: { bkg: "lightGreen", color: "white", fill: "white" } },
                        }}
                        callBack={saveTechnologMap}
                    />

                </div>
        </Modal>
  )
}

export default FormTechnologMap
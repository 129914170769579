import {CommonObject} from "../../CommonObject/CommonObject";
import {KitchenService} from "../../../services/KitchenService/KitchenService";
import {IPositionInfo} from "../../../interfaces/IPositionInfo";
import {ThreeWall} from "../rooms/ThreeWall/ThreeWall";
import {TAnglePosition} from "../../../types/TAnglePosition";
import {
    ACTION_SETTINGS,
    ACTION_SHOW_UNIT_SPEC,
    KITCHEN_SIZES_TYPE_NONE,
    MESSAGE_TYPE_ERROR,
    MESSAGE_TYPE_WARNING, PLANE_TYPE_FLOOR,
    PLANE_TYPE_FORWARD_BACK,
    PLANE_TYPE_HORIZONTAL,
    PLANE_TYPE_LEFT_RIGHT, PLANE_TYPE_ROOF,
    PLANE_TYPE_VERTICAL,
    PLANE_TYPE_WALL,
    SETTING_GROUP_APRONS,
    SETTING_GROUP_CORPUS,
    SETTING_GROUP_EQUIPMENTS,
    SETTING_GROUP_FACADES,
    SETTING_GROUP_GENERAL,
    SETTING_GROUP_LEGS,
    SETTING_GROUP_POSITION, SETTING_GROUP_TABLETOPS,
} from "../../../../constants";
import {
    Box3,
    ColorRepresentation,
    Euler,
    Intersection,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    Vector3,
} from "three";
import {TWallOffsetPoints} from "../../../types/TWallOffsetPoints";
import {TPlanesIntersections} from "../../../types/TPlanesIntersections";
import {TPlaneType} from "../../../types/TPlaneType";
import {ICoverPoints} from "../../../interfaces/ICoverPoints";
import {TNearWallPosition} from "../../../types/TNearWallPosition";
import {CommonObjectHelper} from "../../../helpers/CommonObjectHelper/CommonObjectHelper";
import {TNeighborSides} from "../../../types/TNeighborSides";
import {ICoverMainPoints2D} from "../../../interfaces/ICoverMainPoints2D";
import {TStickPointsByNeighbor} from "../../../types/TStickPointsByNeighbor";
import {IUnitNeighbors} from "../../../interfaces/IUnitNeighbors";
import {
    COVER_CORRECTION_SIZE,
    DEFAULT_ADD_STEP,
    DEFAULT_HORIZONTAL_LINE_STICK,
    DEFAULT_NEAR_ROUNDING,
    HISTORY_STATE_TYPE_MOVE,
    UNIT_SIZE_TEXT_SIZE,
    DEFAULT_VERTICAL_LINE_STICK, DEFAULT_APRON_WIDTH, MAX_CACHE_POOL_SIZE,
} from "../../../constants";
import {TDetail} from "../../../types/TDetail";
import {ThreeSize} from "../ThreeSize/ThreeSize";
import {TLevelBoxes} from "../../../types/TLevelBoxes";
import {ThreeApron} from "../details/ThreeApron/ThreeApron";
import {
    CLASSNAME_APRON_UNIT,
    CLASSNAME_SIDE_BOTTOM_FACADE_UNIT, CLASSNAME_TABLETOP_UNIT,
    FACADE_CALCULATE_NONE,
    FACADE_CALCULATE_SELF_AMOUNT,
    FACADE_FOR_SECOND, FACADE_MATERIAL_TYPE_DEFAULT,
    GEOMETRY_TYPE_SQUARE,
    GROUP_BOTTOM_ANGLE_UNITS,
    GROUP_BOTTOM_END_UNITS,
    GROUP_BOTTOM_NORMAL_UNITS,
    GROUP_EQUIPMENTS,
    GROUP_PENAL_UNITS,
    GROUP_TOP_ANGLE_UNITS,
    GROUP_TOP_END_UNITS,
    GROUP_TOP_NORMAL_UNITS,
    LEVEL_BOTTOM,
    LEVEL_TOP,
    NONE_MATERIAL,
    OPTION_TYPE_NUMBER,
    OPTION_TYPE_OFFERS,
    OPTION_TYPE_RADIOBUTTON, OPTION_TYPE_RANGE,
    OPTION_TYPE_SELECT,
    OPTION_TYPE_TEXT,
    PRICE_CELL_CORPUS,
    PRICE_CELL_EXTRA_OFFERS,
    PRICE_CELL_FACADE,
    PRICE_CELL_FURNITURE,
    PRICE_CELL_HANDLE,
    PRICE_CELL_HINGES,
    PRICE_CELL_MODULE, SIDE_TYPE_ANGLE,
    SIDE_TYPE_ARC,
    SIDE_TYPE_BACK,
    SIDE_TYPE_BOTTOM,
    SIDE_TYPE_DEFAULT,
    SIDE_TYPE_FRONT,
    SIDE_TYPE_LEFT,
    SIDE_TYPE_NONE,
    SIDE_TYPE_RIGHT,
    SIDE_TYPE_TOP,
    SIZE_TYPE_DEPTH,
    SIZE_TYPE_HEIGHT,
    SIZE_TYPE_WIDTH,
    UNIT_PLINTHS_TYPE_DEFAULT,
} from "../../../../../common-code/constants";
import {IContextIcon} from "../../../../interfaces/IContextIcon";
import {i18n} from "../../../../i18n";
import {ThreeTabletop} from "../details/ThreeTabletop/ThreeTabletop";
import {ThreeCorner} from "../details/ThreeCorner/ThreeCorner";
import {ThreePlinth} from "../details/ThreePlinth/ThreePlinth";
import {ThreeFacade} from "../details/ThreeFacade/ThreeFacade";
import {ThreeSquareFacade} from "../details/ThreeFacade/geometry/ThreeSquareFacade";
import {CurrencyHelper} from "../../../../../domain/CurrencyHelper/CurrencyHelper";
import {DataManager} from "../../../services/KitchenService/managers/DataManager";
import {TInterval} from "../../../types/TInterval";
import {ThreePlinthArc} from "../details/ThreePlinth/types/ThreePlinthArc";
import {ISaveUnitData} from "../../../../../common-code/interfaces/saveData/ISaveUnitData";
import {IModulePriceData} from "../../../../../common-code/interfaces/catalog/IModulePriceData";
import {IModulePriceParams} from "../../../../../common-code/interfaces/catalog/IModulePriceParams";
import {CommonHelper} from "common-code";
import {ISpecItem} from "../../../../../common-code/interfaces/catalog/ISpecItem";
import {ISaveFacadeData} from "../../../../../common-code/interfaces/saveData/ISaveFacadeData";
import {ISaveKUnitDetailData} from "../../../../../common-code/interfaces/saveData/ISaveKUnitDetailData";
import {TClassName} from "../../../../../common-code/types/TClassName";
import {ICreateObjectData} from "../../../../../common-code/interfaces/createData/ICreateObjectData";
import {TSideType} from "../../../../../common-code/types/TSideType";
import {TPlinthsType} from "../../../../../common-code/types/TPlinthsType";
import {TPositionSideType} from "../../../../../common-code/types/TPositionSideType";
import {ITabletopData} from "../../../../../common-code/interfaces/materials/ITabletopData";
import {ICornerData} from "../../../../../common-code/interfaces/materials/ICornerData";
import {IApronData} from "../../../../../common-code/interfaces/materials/IApronData";
import {IPlinthData} from "../../../../../common-code/interfaces/materials/IPlinthData";
import {IMaterialData} from "../../../../../common-code/interfaces/materials/IMaterialData";
import {IFacadeMaterialData} from "../../../../../common-code/interfaces/materials/IFacadeMaterialData";
import {IFacadeData} from "../../../../../common-code/interfaces/materials/IFacadeData";
import {IFacadeModelData} from "../../../../../common-code/interfaces/materials/IFacadeModelData";
import {ISettingGroup} from "../../../../interfaces/settingData/ISettingGroup";
import {IOption} from "../../../../../common-code/interfaces/option/IOption";
import {IOptionGroup} from "../../../../../common-code/interfaces/option/IOptionGroup";
import {ISettingGroupGeneral} from "../../../../interfaces/settingData/ISettingGroupGeneral";
import {ISettingGroupCorpus} from "../../../../interfaces/settingData/ISettingGroupCorpus";
import {ISettingApron} from "../../../../interfaces/settingData/ISettingApron";
import {ISettingGroupAprons} from "../../../../interfaces/settingData/ISettingGroupAprons";
import {ISettingFacade} from "../../../../interfaces/settingData/ISettingFacade";
import {ISettingGroupFacades} from "../../../../interfaces/settingData/ISettingGroupFacades";
import {ISaveSizeData} from "../../../../../common-code/interfaces/saveData/ISaveSizeData";
import {TKitchenView} from "../../../../types/TKitchenView";
import {TLevel} from "../../../../../common-code/types/TLevel";
import {TDirectionSideType} from "../../../../../common-code/types/TDirectionSideType";
import {ICorpusPriceParams} from "../../../../../common-code/interfaces/catalog/ICorpusPriceParams";
import {IFacadePriceParam} from "../../../../../common-code/interfaces/catalog/IFacadePriceParam";
import {TFacadeSizes} from "../../../../../common-code/types/TFacadeSizes";
import {MathHelper} from "common-code";
import {TPoint2D} from "../../../../../common-code/types/TPoint2D";
import {TLine} from "../../../../../common-code/types/TLine";
import {ISaveReplaceData} from "../../../../../common-code/interfaces/saveData/ISaveReplaceData";
import {ICreateGroup} from "../../../../../common-code/interfaces/createData/ICreateGroup";
import {TCreateGroup} from "../../../../../common-code/types/TCreateGroup";
import {ICreateObjectDataWidths} from "../../../../../common-code/interfaces/createData/ICreateObjectDataWidths";
import {ICreateObjectDataHeights} from "../../../../../common-code/interfaces/createData/ICreateObjectDataHeights";
import {ICreateObjectDataDepths} from "../../../../../common-code/interfaces/createData/ICreateObjectDataDepths";
import {IOptionCorpusMaterial} from "../../../../../common-code/interfaces/option/IOptionCorpusMaterial";
import {IFacadePriceData} from "../../../../../common-code/interfaces/catalog/IFacadePriceData";
import {ICorpusPriceData} from "../../../../../common-code/interfaces/catalog/ICorpusPriceData";
import {IHistoryMoveState} from "../../../interfaces/history/IHistoryMoveState";
import {ISaveBoxData} from "../../../../../common-code/interfaces/saveData/ISaveBoxData";
import {IDetailPriceData} from "../../../../../common-code/interfaces/catalog/IDetailPriceData";
import {TCatalogCalculateType} from "../../../../../common-code/types/TCatalogCalculateType";
import {KitchenHelper} from "common-code";
import {IImportOffer} from "../../../../../common-code/interfaces/api/IImportOffer";
import {IUnitKitPrices} from "../../../../../common-code/interfaces/catalog/IUnitKitPrices";
import {IProjectOffers} from "../../../../../common-code/interfaces/project/IProjectOffers";
import {IProjectOffer} from "../../../../../common-code/interfaces/project/IProjectOffer";
import {ISideNeighbors} from "../../../interfaces/ISideNeighbors";
import {TUnitSideNeighbor} from "../../../types/TUnitSideNeighbor";
import {ISettingGroupPosition} from "../../../../interfaces/settingData/ISettingGroupPosition";
import {ISideWallInterval} from "../../../../interfaces/settingData/ISideWallInterval";
import {IOptionOffers} from "../../../../../common-code/interfaces/option/IOptionOffers";
import {IOffer} from "../../../../../common-code/interfaces/catalog/IOffer";
import {TStartVectors} from "../../../types/TStartVectors";
import {IHistoryObjectData} from "../../../interfaces/history/IHistoryObjectData";
import {ISaveLegData} from "../../../../../common-code/interfaces/saveData/ISaveLegData";
import {ThreeLeg} from "../details/ThreeLeg/ThreeLeg";
import {IProjectOrderParts} from "../../../../../common-code/interfaces/project/IProjectOrderParts";
import {TCheckCatalogType} from "../../../../../common-code/types/appConfig/TCheckCatalogType";
import {ITechnologMap} from "../../../../../common-code/interfaces/ITechnologMap";
import {IProjectLegsData} from "../../../../../common-code/interfaces/project/IProjectLegsData";
import {ISettingGroupLegs} from "../../../../interfaces/settingData/ISettingGroupLegs";
import {TSizes} from "../../../../../common-code/types/geometry/TSizes";
import {TStickPositions} from "../../../types/TStickPositions";
import {TStickPosition} from "../../../types/TStickPosition";
import {TProjectionPosition} from "../../../types/TProjectionPosition";
import {IOfferPriceParam} from "../../../../../common-code/interfaces/catalog/IOfferPriceParam";
import {TPriceCell} from "../../../../../common-code/types/price/TPriceCell";
import {ThreeIntegratedHandle} from '../details/ThreeIntegratedHandle/ThreeIntegratedHandle';
import {ISaveIntegratedHandleData} from '../../../../../common-code/interfaces/saveData/ISaveIntegratedHandleData';
import {IProductPrice} from '../../../../../common-code/interfaces/catalog/IproductPrice';
import {ISettingTabletop} from '../../../../interfaces/settingData/ISettingTabletop';
import {ISettingGroupTabletops} from '../../../../interfaces/settingData/ISettingGroupTabletops';
import {ThreeKUnitDetail} from '../details/ThreeKUnitDetail/ThreeKUnitDetail';
import {IMaterialTextures} from '../../../interfaces/IMaterialTextures';
import {TFacadeCalculateType} from '../../../../../common-code/types/TFacadeCalculateType';
import {TDeltaMovePosition} from '../../../types/TDeltaMovePosition';
import {ThreeWallIsland} from '../constructive/ThreeWallIsland/ThreeWallIsland';
import {TPoint3D} from '../../../../../common-code/types/TPoint3D';
import {Sphere} from 'three/src/math/Sphere';

export class ThreeUnit extends CommonObject {
    saveData: ISaveUnitData;
    service: KitchenService;

    positionInfo: IPositionInfo;
    wall?: ThreeWall;
    neighbors: IUnitNeighbors;
    tabletops?: ThreeTabletop[];
    aprons?: ThreeApron[];
    corners?: ThreeCorner[];
    integratedHandles?: ThreeIntegratedHandle[];
    plinths?: ThreePlinth[];
    legs?: ThreeLeg[];
    extraOffers?: IProjectOffers;
    facades: ThreeFacade[];
    sizes: ThreeSize[];
    orderParts?: IProjectOrderParts;
    priceParams?: IModulePriceParams;
    movePosition: Vector3;
    startPosition: Vector3;
    startVector: Vector3;
    readonly SIZE_GAP: number = 10;

    constructor(options: ISaveUnitData, service: KitchenService) {
        super(options, service);
        this.saveData = options;
        this.service = service;
        this.positionInfo = this.initPositionInfo();
        this.neighbors = this.initNeighbors();
        this.sizes = [];
        this.facades = [];
        this.wall = this.initWall();
        this.movePosition = new Vector3();
        this.startPosition = new Vector3();
        this.startVector = new Vector3();
        this.initExtraOffers();
    }

    public initState(isRebuild?: boolean) {
        this.createFacades();
        this.createTabletops();
        this.createAprons();
        this.createCorners();
        this.createIntegratedHandles();
        this.createPlinths();
        this.initGapCoverPoints();
        this.initOrderParts(isRebuild);
        super.initState(isRebuild);
    }

    public createView(isRebuild?: boolean) {
        this.createSizes();
        super.createView(isRebuild);
        this.calculatePriceData(true);
    }

    public createViewEDIT(isRebuild?: boolean) {
        this.createSizes();
        super.createViewEDIT(isRebuild);
        // this.calculatePriceData();
    }

    public rebuild(saveData: ISaveUnitData) {
        super.rebuild(saveData);
        this.saveData = saveData;
        this.removeChildren();
        this.initState(true);
        this.createView(true);
        this.afterSetPosition();
        this.setPositionInfo();
    }

    public getPlinthHeight(): number {
        if (
            this.saveData.legs &&
            this.saveData.legs[0] &&
            this.saveData.legs[0].height
        ) {
            return this.saveData.legs[0].height;
        }
        return this.service.getPlinthHeight();
    }

    public getPlinthDepth(): number {
        return this.service.getPlinthDepth();
    }

    public getTabletopHeight(): number {
        if (this.tabletops && this.tabletops[0]) {
            return this.tabletops[0].getHeight();
        }
        return 0;
    }

    public getExtraOffersPriceParams(): IOfferPriceParam[] | undefined {
        if (!this.extraOffers || !Object.keys(this.extraOffers).length) {
            return undefined;
        }
        let index: string;
        let priceParams: IOfferPriceParam[] = [];

        for (index in this.extraOffers) {
            priceParams.push({
                id: this.extraOffers[index].id,
                count: this.extraOffers[index].count,
            });
        }

        return priceParams;
    }

    public getCheckCatalogType(isStretch?: boolean): TCheckCatalogType | undefined {
        return this.service.getCheckCatalogType(this.getData(), isStretch);
    }

    public getTechnologMapFacadeId(): string {
        if (this.facades && this.facades[0]) {
            return this.facades[0].getFacadeMaterial().facade;
        }
        return this.service.getFacadeMaterial(this.getLevel()).facade;
    }

    public getExtraOffers(): IProjectOffers | undefined {
        return this.extraOffers;
    }

    public getExtraOffersArray(): IProjectOffer[] {
        return (this.extraOffers) ? Object.values(this.extraOffers) : [];
    }

    public setExtraOffer(offer: IImportOffer, count: number, once?: boolean) {
        let externalId: 'externalGuid' | 'vendorCode';
        let priceData: IModulePriceData;
        let index: string;

        externalId = this.service.getOfferExternalId();
        if (!this.extraOffers) {
            this.extraOffers = {};
        }
        if (count) {
            if (once) {
                for (index in this.extraOffers) {
                    if (this.extraOffers[index].cell === PRICE_CELL_EXTRA_OFFERS) {
                        delete this.extraOffers[index];
                    }
                }
            }
            priceData = this.service.getDetailPriceData(offer, count, this.getId(), PRICE_CELL_EXTRA_OFFERS, 0);
            this.extraOffers[offer[externalId]] = {
                id: offer[externalId],
                count: count,
                offer: offer,
                price: priceData.price,
                oldPrice: priceData.oldPrice,
                stock: priceData.stock,
                active: priceData.active,
                unitId: priceData.unitId,
                cell: priceData.cell,
            };
        } else if (!count) {
            delete this.extraOffers[offer[externalId]];
        }

        this.loadExtraOffersPrices();
    }

    public loadExtraOffersPrices() {
        if (!this.extraOffers) {
            return;
        }
        let index: string;
        let offerProductPrice: IProductPrice | undefined;
        let loadExtraOffers: IProjectOffers = {};

        for (index in this.extraOffers) {
            offerProductPrice = this.service.getOfferProductPrice(index);
            if (offerProductPrice) {
                this.setExtraOffersPrice(index, offerProductPrice)
            } else {
                loadExtraOffers[index] = this.extraOffers[index];
            }
        }
        if (Object.keys(loadExtraOffers).length) {
            this.service.loadExtraOffersPrices(loadExtraOffers).then((prices: IUnitKitPrices) => {
                this.setExtraOffersPrices(prices);
                this.service.rebuildScene();
            });
        } else {
            this.service.rebuildScene();
        }
    }

    public calculatePriceData(force?: boolean) {
        let modulePrice: IModulePriceData;
        let priceParams: IModulePriceParams;

        priceParams = this.service.calculateUnitPriceParams(this);
        if (!force && this.priceParams !== undefined &&
            this.saveData.modulePrice &&
            CommonHelper.deepCompare(this.priceParams, priceParams)) {
            return;
        }
        this.priceParams = priceParams;
        modulePrice = this.service.calculatePrice(priceParams);
        modulePrice = this.service.setOrderPartsToModulePrice(modulePrice, this.orderParts);
        this.saveData.offerId = modulePrice.id;
        this.saveData.modulePrice = modulePrice;
    }

    public removeChildren() {
        this.removeFacades();
        this.removeTabletops();
        this.removeAprons();
        this.removeCorners();
        this.removePlinths();
        this.removeSizes();
        this.removeLegs();
        super.removeChildren();
    }

    public getData(): ISaveUnitData {
        let data: ISaveUnitData = super.getData() as ISaveUnitData;
        data.wall = this.wall !== undefined ? this.wall.getId() : undefined;
        data.name = this.getName();
        data.image = this.getImage();
        data.sizes = this.getSizes();
        data.isDimensions = this.getIsDimensions();
        data.isStick = this.getIsStick();
        data.isLevelStick = this.getIsLevelStick();
        data.isWallStick = this.getIsWallStick();
        data.facades = this.getFacadesData();
        data.aprons = this.getApronsData();
        data.tabletops = this.getTabletopsData();
        data.corners = this.getCornersData();
        data.plinths = this.getPlinthsData();
        data.extraOffers = this.calculateExtraOffersData();
        data.orderParts = this.calculateOrderParts();
        data.legs = this.calculateLegsData();
        if (data.modulePrice) {
            data.offerPrice = data.modulePrice.price;
            delete data.modulePrice;
        }
        return data;
    }

    public getUnitModuleSpecData(count: number): ISpecItem | undefined {
        const priceData: IModulePriceData | undefined = this.getPriceData();
        let offerPriceData: IModulePriceData;
        let stock: number;

        if (!priceData) {
            return undefined;
        }
        if (priceData.offer) {
            offerPriceData = this.service.getDetailPriceData(
                priceData.offer,
                1,
                this.getId(),
                PRICE_CELL_MODULE,
                0
            );
            stock =
                offerPriceData.stock !== undefined && !isNaN(+offerPriceData.stock)
                    ? offerPriceData.stock
                    : -1;

            return {
                externalGuid: "" + priceData.offer.id,
                vendorCode: priceData.offer.vendorCode,
                name: priceData.offer.name,
                count: count,
                formatCount: count + " " + i18n.t("шт"),
                price: priceData.price,
                stock: stock,
                active: offerPriceData.active,
                formatStock: this.getFormatStock(stock),
                article: priceData.offer.article,
                part: priceData.part,
                unitId: this.getId(),
                cell: PRICE_CELL_MODULE,
                cellIndex: 0,
            };
        }

        return undefined;
    }

    public getUnitSpec(): ISpecItem[] | undefined {
        let specItems: { [key: string]: ISpecItem } = {};
        let detailPrice: IDetailPriceData;
        let facadePrice: IFacadePriceData;
        let furniturePrice: ICorpusPriceData;
        let index: number;
        let index2: number;

        const priceData: IModulePriceData | undefined = this.getPriceData();
        if (!priceData) {
            return undefined;
        }
        if (priceData.module) {
            this.setSpecItem(
                specItems,
                this.getPriceDataSpecItem(priceData.module, 1, PRICE_CELL_MODULE, 0)
            );
        }
        if (priceData.corpus && priceData.corpus.offer) {
            this.setSpecItem(
                specItems,
                this.getPriceDataSpecItem(
                    priceData.corpus,
                    priceData.corpus.count,
                    PRICE_CELL_CORPUS,
                    0
                )
            );
            if (priceData.corpus.furniture) {
                index = 0;
                for (furniturePrice of priceData.corpus.furniture) {
                    if (furniturePrice.offer) {
                        this.setSpecItem(
                            specItems,
                            this.getPriceDataSpecItem(
                                furniturePrice,
                                furniturePrice.count,
                                PRICE_CELL_FURNITURE,
                                index
                            )
                        );
                        index++;
                    }
                }
            }
        }
        if (priceData.facades) {
            index = 0;
            for (facadePrice of priceData.facades) {
                if (facadePrice && facadePrice.offer) {
                    this.setSpecItem(
                        specItems,
                        this.getPriceDataSpecItem(
                            facadePrice,
                            facadePrice.count,
                            PRICE_CELL_FACADE,
                            index
                        )
                    );
                    index++;
                }
                if (facadePrice.handle) {
                    this.setSpecItem(
                        specItems,
                        this.getPriceDataSpecItem(
                            facadePrice.handle,
                            facadePrice.handle.count,
                            PRICE_CELL_HANDLE,
                            0
                        )
                    );
                }
                if (facadePrice.hinges) {
                    index2 = 0;
                    for (detailPrice of facadePrice.hinges) {
                        this.setSpecItem(
                            specItems,
                            this.getPriceDataSpecItem(
                                detailPrice,
                                detailPrice.count,
                                PRICE_CELL_HINGES,
                                index2
                            )
                        );
                        index2++;
                    }
                }
            }
        }
        if (priceData.extraOffers) {
            index = 0;
            for (furniturePrice of priceData.extraOffers) {
                if (furniturePrice && furniturePrice.offer) {
                    this.setSpecItem(
                        specItems,
                        this.getPriceDataSpecItem(
                            furniturePrice,
                            furniturePrice.count,
                            PRICE_CELL_EXTRA_OFFERS,
                            index
                        )
                    );
                    index++;
                }
            }
        }

        return Object.values(specItems);
    }

    public getSelfCalculateFacades(): ThreeFacade[] {
        let facades: ThreeFacade[] = [];
        let facade: ThreeFacade;

        if (this.facades) {
            for (facade of this.facades) {
                if (facade.getCalculateType() === FACADE_CALCULATE_SELF_AMOUNT) {
                    facades.push(facade);
                }
            }
        }

        return facades;
    }

    public getExtraOfferSpecItem(priceData: IProjectOffer): ISpecItem | undefined {
        if (priceData.offer) {
            return {
                externalGuid: priceData.offer.externalGuid,
                vendorCode: priceData.offer.vendorCode,
                name: priceData.offer.name,
                count: priceData.count,
                formatCount: priceData.count + ' ' + i18n.t('шт'),
                price: priceData.price || 0,
                stock: priceData.stock || 0,
                formatStock: this.getFormatStock(priceData.stock || 0),
                article: priceData.offer.article,
                part: priceData.part,
                active: priceData.active,
                onlyOrder: priceData.onlyOrder,
                inWay: priceData.inWay,
                cell: PRICE_CELL_EXTRA_OFFERS,
                cellIndex: 0,
                unitId: this.getId(),
            };
        }

        return undefined;
    }

    public getPriceDataSpecItem(
        priceData: ICorpusPriceData | IFacadePriceData | IDetailPriceData,
        count: number,
        cell: TPriceCell,
        cellIndex: number
    ): ISpecItem | undefined {
        if (priceData.offer) {
            return {
                externalGuid: "" + priceData.offer.id,
                vendorCode: priceData.offer.vendorCode,
                name: priceData.offer.name,
                count: count,
                formatCount: count + " " + i18n.t("шт"),
                price: priceData.price,
                stock: priceData.stock || 0,
                active: priceData.active,
                formatStock: this.getFormatStock(priceData.stock || 0),
                article: priceData.offer.article,
                part: priceData.part,
                unitId: this.getId(),
                cell: cell,
                cellIndex: cellIndex,
            };
        }

        return undefined;
    }

    public getSpecModuleData(count: number): ISpecItem {
        let specData: ISpecItem | undefined = this.getUnitModuleSpecData(count);
        if (specData) {
            return specData;
        }
        return {
            externalGuid: "-",
            vendorCode: this.getVendorCode(),
            name: this.getSpecName(),
            count: count,
            formatCount: count ? "" + count + " " + i18n.t("шт") : "-",
            price: this.getPrice(),
            stock: this.getStock(),
            formatStock: this.getStock()
                ? "" + this.getStock() + " " + i18n.t("шт")
                : "-",
            active: this.getOfferActive(),
            part: this.getOrderPart(),
            unitId: this.getId(),
            cell: PRICE_CELL_MODULE,
            cellIndex: 0,
        };
    }

    public getUid(): string {
        return this.saveData.uid;
    }

    public getFacadesData(): ISaveFacadeData[] | undefined {
        if (!this.facades) {
            return undefined;
        }
        let facade: ThreeFacade;
        let facadesData: ISaveFacadeData[] = [];

        for (facade of this.facades) {
            facadesData.push(facade.getData());
        }

        return facadesData.length > 0 ? facadesData : undefined;
    }

    public getApronsData(): ISaveKUnitDetailData[] | undefined {
        if (!this.aprons) {
            return undefined;
        }
        let apron: ThreeApron;
        let apronsData: ISaveKUnitDetailData[] = [];

        for (apron of this.aprons) {
            apronsData.push(apron.getData());
        }

        return apronsData;
    }

    public getTabletopsData(): ISaveKUnitDetailData[] | undefined {
        if (!this.tabletops) {
            return undefined;
        }
        let tabletop: ThreeTabletop;
        let tabletopsData: ISaveKUnitDetailData[] = [];

        for (tabletop of this.tabletops) {
            tabletopsData.push(tabletop.getData());
        }

        return tabletopsData;
    }

    public getCornersData(): ISaveKUnitDetailData[] | undefined {
        if (!this.corners) {
            return undefined;
        }
        let corner: ThreeCorner;
        let cornersData: ISaveKUnitDetailData[] = [];

        for (corner of this.corners) {
            cornersData.push(corner.getData());
        }

        return cornersData;
    }

    public getPlinthsData(): ISaveKUnitDetailData[] | undefined {
        if (!this.plinths) {
            return undefined;
        }
        let plinth: ThreePlinth;
        let plinthsData: ISaveKUnitDetailData[] = [];

        for (plinth of this.plinths) {
            plinthsData.push(plinth.getData());
        }

        return plinthsData;
    }

    public showOnlyFacades() {
        this.removeTabletops();
        this.removeAprons();
        this.removeCorners();
        this.removePlinths();
        this.removeSizes();
    }

    public hideHandles() {
        if (!this.facades) {
            return;
        }
        let facade: ThreeFacade;

        for (facade of this.facades) {
            facade.hideHandle();
        }
    }

    public checkCreateEquipment(className: TClassName): boolean {
        return this.availableEquipments().indexOf(className) !== -1;
    }

    public selectForEquipment(objectData: ICreateObjectData) {
        this.setSelectCoverSelectColor();
    }

    public getWallId(): number | undefined {
        if (this.wall) {
            return this.wall.getId();
        }

        return undefined;
    }

    public getPositionInfo(force?: boolean): IPositionInfo {
        if (force) {
            this.setPositionInfo();
        }

        return this.positionInfo;
    }

    public initPositionInfo(): IPositionInfo {
        return {
            position: {x: 0, y: 0, z: 0},
            rotation: {x: 0, y: 0, z: 0},
            wall: undefined,
            inAngle: {
                result: false,
                leftSide: false,
                rightSide: false
            }
        };
    }

    public setPositionInfo(): IPositionInfo {
        let anglePosition: TAnglePosition | undefined;

        this.positionInfo = {
            position: this.getPositionPoint(),
            rotation: this.getRealRotationPoint(),
            wall: this.getWallId(),
            inAngle: {
                result: false,
                leftSide: false,
                rightSide: false
            }
        };

        anglePosition = this.getAnglePosition();
        this.positionInfo.inAngle.result =
            !!anglePosition.leftPosition || !!anglePosition.rightPosition;
        this.positionInfo.inAngle.leftSide = !!anglePosition.leftPosition;
        this.positionInfo.inAngle.rightSide = !!anglePosition.rightPosition;

        return this.positionInfo;
    }

    public initialCover() {
        this.setSelect();
        this.cover.userData.oldPosition =
            this.cover.userData.correctPosition =
                this.cover.position.clone();
        this.cover.userData.oldPositionInfo = this.setPositionInfo();
    }

    public getOldPositionInfo(): IPositionInfo | undefined {
        if (this.cover.userData.oldPositionInfo &&
            this.cover.userData.oldPositionInfo.position) {
            return CommonHelper.deepCopy(this.cover.userData.oldPositionInfo);
        }

        return undefined;
    }

    public clearNeighbors() {
        this.neighbors = this.initNeighbors();
    }

    public getUnionDetailYPosition(detail: TDetail): number {
        if (detail instanceof ThreePlinth) {
            return (
                this.view3d.position.y -
                this.getSideDistance(SIDE_TYPE_BOTTOM) +
                detail.getUnionYPosition()
            );
        }
        if (detail instanceof ThreeIntegratedHandle) {
            return (
                detail.globalPosition.y
            );
        }
        if (detail instanceof ThreeApron) {
            return (
                this.view3d.position.y +
                this.getSideDistance(SIDE_TYPE_TOP) +
                detail.getUnionYPosition() -
                this.getTabletopHeight()
            );
        }
        return (
            this.view3d.position.y +
            this.getSideDistance(SIDE_TYPE_TOP) +
            detail.getUnionYPosition()
        );
    }

    public getContextIcons(): IContextIcon[] {
        let icons: IContextIcon[];
        let actionData = this.actionData();

        icons = [
            {
                channelName: "ThreeKUnit",
                action: ACTION_SETTINGS,
                actionData: actionData,
                popup: true,
                icon: "settings-object",
                hide: false,
                title: i18n.t("Свойства"),
                sort: 100,
            },
            {
                channelName: "ThreeUnit",
                action: ACTION_SHOW_UNIT_SPEC,
                actionData: actionData,
                popup: true,
                icon: "list",
                hide: false,
                title: i18n.t("Спецификация"),
                sort: 130,
            },
        ];
        icons = icons.concat(super.getContextIcons());

        return icons;
    }

    public updateFacadeModel(facade: ThreeFacade) {
        if (this.transparentForBack) {
            facade.setTransparentForBack();
        }
    }

    public getCorpusSizes(): TSizes {
        return this.getSizes();
    }

    public getTechHoleCPosition(): number {
        if (!this.saveData.corpus?.techHole) {
            return 0
        }
        return this.saveData.corpus?.techHole?.positionC
    }

    public getTechHoleLPosition(): number {
        if (!this.saveData.corpus?.techHole) {
            return 0
        }
        return this.saveData.corpus?.techHole?.positionL
    }

    public getCorpusPosition(): Vector3 {
        return new Vector3();
    }

    public getInitTabletopPosition(tabletop: ThreeTabletop): Vector3 {
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);
        return new Vector3(
            (coverBox.min.x + coverBox.max.x) / 2,
            coverBox.max.y + tabletop.getHeight() / 2,
            this.getZInitTabletopPosition(tabletop)
        );
    }

    public getCorpusInitPosition(): Vector3 {
        let position: Vector3;

        position = new Vector3();

        return position;
    }

    public getApronHeight(sideType: TSideType): number {
        let height: number | undefined;
        let apron: ThreeApron | undefined;

        apron = this.getApronBySide(sideType);
        if (apron) {
            height = apron.getHeight();
        }
        if (height === undefined) {
            height = this.service.getApronHeight();
        }

        return height;
    }

    public getCorpusCoverBox(gap: number = COVER_CORRECTION_SIZE): Box3 {
        return this.getCoverBox(gap);
    }

    public checkRebuildPlinths() {
        let newPlinthsType: TPlinthsType;

        if (!this.service.checkRebuildPlinths(this.getTechnologMapFacadeId())) {
            return false;
        }
        newPlinthsType = CommonObjectHelper.calculatePlinthsType(this);
        if (newPlinthsType !== this.getPlinthsType()) {
            return this.rebuildPlinths(newPlinthsType);
        }

        return false;
    }

    public getPlinthsType(): TPlinthsType {
        return this.saveData.plinthsType || UNIT_PLINTHS_TYPE_DEFAULT;
    }

    public hideTabletops() {
        let tabletop: ThreeTabletop;

        if (this.tabletops && this.tabletops.length > 0) {
            for (tabletop of this.tabletops) {
                tabletop.setVisible(
                    this.service.isShowTabletops() && this.isVisibleTabletops()
                );
            }
        }
    }

    public isVisibleTabletops(): boolean {
        return true;
    }

    public getClassName(): string {
        return this.saveData.className;
    }

    public hideAprons() {
        let apron: ThreeApron;
        let enablePositionTypes: TPositionSideType[] = [];
        let positionInfo: IPositionInfo;

        if (this.aprons && this.aprons.length > 0) {
            for (apron of this.aprons) {
                apron.hide();
            }
            if (!this.service.isShowAprons()) {
                return;
            }
            if (this.wall !== undefined) {
                enablePositionTypes.push(SIDE_TYPE_BACK);
                positionInfo = this.getPositionInfo();
                if (positionInfo.inAngle.leftSide) {
                    enablePositionTypes.push(SIDE_TYPE_LEFT);
                }
                if (positionInfo.inAngle.rightSide) {
                    enablePositionTypes.push(SIDE_TYPE_RIGHT);
                }
            }
            for (apron of this.aprons) {
                if (this.service.isShowAprons() &&
                    enablePositionTypes.indexOf(apron.getPositionType()) !== -1) {
                    apron.show();
                }
            }
        }
    }

    public hidePlinths() {
        let plinth: ThreePlinth;
        let enablePositionTypes: TPositionSideType[] = [];
        let positionInfo: IPositionInfo;
        if (this.plinths && this.plinths.length > 0) {
            for (plinth of this.plinths) {
                plinth.hide();
            }
            if (!this.service.isShowPlinths()) {
                return;
            }
            enablePositionTypes.push(SIDE_TYPE_NONE);
            enablePositionTypes.push(SIDE_TYPE_ANGLE);
            if (this.wall === undefined && !this.neighbors.back) {
                enablePositionTypes.push(SIDE_TYPE_BACK);
            }
            positionInfo = this.getPositionInfo();
            if (!this.neighbors.front) {
                enablePositionTypes.push(SIDE_TYPE_FRONT);
            }
            if (
                !positionInfo.inAngle.leftSide &&
                !this.checkHidePlinthNeighbor(this.neighbors.left)
            ) {
                enablePositionTypes.push(SIDE_TYPE_LEFT);
            }
            if (
                !positionInfo.inAngle.rightSide &&
                !this.checkHidePlinthNeighbor(this.neighbors.right)
            ) {
                enablePositionTypes.push(SIDE_TYPE_RIGHT);
            }
            for (plinth of this.plinths) {
                if (
                    enablePositionTypes.indexOf(plinth.getPositionType()) !== -1 ||
                    plinth.isSelfVisible()
                ) {
                    plinth.show();
                }
            }
        }
    }

    public hideLegs() {
        let leg: ThreeLeg;

        if (this.legs && this.legs.length > 0) {
            for (leg of this.legs) {
                if (!this.isShowLegs()) {
                    leg.hide();
                } else {
                    leg.show();
                }
            }
        }
    }

    public updateFromTechnologMap(topTechnologMap?: ITechnologMap) {
        debugger;
    }

    public getApronInitWidth(positionType?: TPositionSideType): number {
        return DEFAULT_APRON_WIDTH;
    }

    public canStretch(): boolean {
        return this.saveData.canStretch === true;
    }
    public isStretch(): boolean {
        return this.saveData.isStretch === true;
    }

    public setIsStretch(value: boolean) {
        this.saveData.isStretch = value;
    }

    protected getMoveLines(): number[] {
        let unitGlobalPoints: ICoverPoints;
        let lines: number[];
        let unit: ThreeUnit;

        lines = [];
        const topUnits: ThreeUnit[] = this.service.getUnits(["topUnits"]);
        lines.push(this.service.getTopFirstYPosition());
        lines.push(this.service.getInstallTopUnitHeight());
        lines.push(this.service.getInstallBottomUnitHeight());
        for (unit of topUnits) {
            if (this.getId() === unit.getId()) {
                continue;
            }
            unitGlobalPoints = unit.getGlobalPoints(unit.selectCover);
            lines.push(Math.round(unitGlobalPoints.box.min.y));
            lines.push(Math.round((unitGlobalPoints.box.min.y + unitGlobalPoints.box.max.y)/2));
            lines.push(Math.round(unitGlobalPoints.box.max.y));
        }
        const penalUnits: ThreeUnit[] = this.service.getUnits(["penals"]);
        for (unit of penalUnits) {
            if (this.getId() === unit.getId()) {
                continue;
            }
            unitGlobalPoints = unit.getGlobalPoints(unit.selectCover);
            lines.push(Math.round(unitGlobalPoints.box.max.y));
        }
        lines = lines.filter((item, index) => {
            return lines.indexOf(item) === index;
        });

        return lines;
    }

    protected checkHidePlinthNeighbor(
        neighbor: ISideNeighbors | undefined
    ): boolean {
        let sideNeighbor: TUnitSideNeighbor;
        let index: string;
        const excludeClassNames: string[] = [
            CLASSNAME_SIDE_BOTTOM_FACADE_UNIT,
            CLASSNAME_APRON_UNIT,
            CLASSNAME_TABLETOP_UNIT
        ];

        if (!neighbor) {
            return false;
        }
        for (index in neighbor.neighbors) {
            sideNeighbor = neighbor.neighbors[index];
            if (excludeClassNames.includes(sideNeighbor.object.getClassName())) {
                return false;
            }
        }

        return true;
    }

    public hideCorners() {
        let corner: ThreeCorner;
        let enablePositionTypes: TPositionSideType[] = [];
        let positionInfo: IPositionInfo;

        if (this.corners && this.corners.length > 0) {
            for (corner of this.corners) {
                corner.hide();
            }
            if (!this.service.isShowCorners()) {
                return;
            }

            if (this.wall !== undefined) {
                enablePositionTypes.push(SIDE_TYPE_BACK);
                positionInfo = this.getPositionInfo();
                if (positionInfo.inAngle.leftSide) {
                    enablePositionTypes.push(SIDE_TYPE_LEFT);
                }
                if (positionInfo.inAngle.rightSide) {
                    enablePositionTypes.push(SIDE_TYPE_RIGHT);
                }
                for (corner of this.corners) {
                    if (enablePositionTypes.indexOf(corner.getPositionType()) !== -1) {
                        corner.show();
                    }
                }
            }
        }
    }

    public trySetTabletop(material: ITabletopData, cornerMaterial?: ICornerData) {
        let tabletop: ThreeTabletop;
        let isRebuild: boolean = false;
        let newSaveData: ISaveUnitData;
        let tabletopData: ISaveKUnitDetailData;
        let cornerData: ISaveKUnitDetailData;

        if (this.tabletops && this.tabletops.length > 0) {
            for (tabletop of this.tabletops) {
                if (
                    tabletop.materialData.id !== material.id ||
                    (tabletop.materialData.id === material.id &&
                        tabletop.materialData.height !== material.height)
                ) {
                    isRebuild = true;
                    break;
                }
            }
        }
        if (isRebuild) {
            newSaveData = this.getData();
            if (newSaveData.tabletops) {
                for (tabletopData of newSaveData.tabletops) {
                    tabletopData.material = material.id;
                    if (!tabletopData.sizes) {
                        tabletopData.sizes = {};
                    }
                    tabletopData.sizes.height = material.height;
                }
                if (cornerMaterial && newSaveData.corners) {
                    for (cornerData of newSaveData.corners) {
                        cornerData.material = cornerMaterial.id;
                    }
                }
                this.rebuild(newSaveData);
            }
        }
    }

    public trySetApron(material: IApronData) {
        let apron: ThreeApron;
        let isRebuild: boolean = false;

        if (this.aprons && this.aprons.length > 0) {
            for (apron of this.aprons) {
                if (
                    apron.materialData.id !== material.id ||
                    (apron.materialData.id === material.id &&
                        apron.materialData.height !== material.height)
                ) {
                    isRebuild = true;
                    break;
                }
            }
        }
        if (isRebuild) {
            this.rebuildNewMaterial(material);
        }
    }

    public rebuildNewMaterial(material: IApronData) {
        let newSaveData: ISaveUnitData;
        let apronData: ISaveKUnitDetailData;

        newSaveData = this.getData();
        if (newSaveData.aprons) {
            for (apronData of newSaveData.aprons) {
                apronData.material = material.id;
                if (!apronData.sizes) {
                    apronData.sizes = {};
                }
                apronData.sizes.height = material.height;
            }
            this.rebuild(newSaveData);
        }
    }

    public trySetCorner(material: ICornerData) {
        let corner: ThreeCorner;
        let isRebuild: boolean = false;
        let newSaveData: ISaveUnitData;
        let cornerData: ISaveKUnitDetailData;

        if (this.corners && this.corners.length > 0) {
            for (corner of this.corners) {
                if (
                    corner.materialData.id !== material.id ||
                    (corner.materialData.id === material.id &&
                        corner.materialData.height !== material.height)
                ) {
                    isRebuild = true;
                    break;
                }
            }
        }

        if (isRebuild) {
            newSaveData = this.getData();
            if (newSaveData.corners) {
                for (cornerData of newSaveData.corners) {
                    cornerData.material = material.id;
                    if (!cornerData.sizes) {
                        cornerData.sizes = {};
                    }
                    cornerData.sizes.height = material.height;
                    cornerData.sizes.width = material.width;
                    cornerData.contourPath = material.contourPath;
                }
                this.rebuild(newSaveData);
            }
        }
    }

    public trySetPlinth(material: IPlinthData) {
        let plinth: ThreePlinth;
        let isRebuild: boolean = false;
        let newSaveData: ISaveUnitData;
        let plinthData: ISaveKUnitDetailData;

        if (this.plinths && this.plinths.length > 0) {
            for (plinth of this.plinths) {
                if (
                    plinth.materialData.id !== material.id ||
                    (plinth.materialData.id === material.id &&
                        plinth.materialData.height !== material.height)
                ) {
                    isRebuild = true;
                    break;
                }
            }
        }

        if (isRebuild) {
            newSaveData = this.getData();
            if (newSaveData.plinths) {
                for (plinthData of newSaveData.plinths) {
                    plinthData.material = material.id;
                    if (!plinthData.sizes) {
                        plinthData.sizes = {};
                    }
                    plinthData.sizes.height = material.depth;
                    // eslint-disable-next-line
                    plinthData.sizes.width = material.height;
                }
                this.rebuild(newSaveData);
            }
        }
    }

    public trySetCorpusMaterial(corpusMaterial: IMaterialData): boolean {
        return false;
    }

    public trySetLegsHeight(height: number): IHistoryObjectData | undefined {
        let oldSaveData: ISaveUnitData;
        let newSaveData: ISaveUnitData;
        let legData: ISaveLegData;
        let plinthData: ISaveKUnitDetailData;
        let isRebuild: boolean = false;
        let position: Vector3;

        oldSaveData = this.getData();
        newSaveData = this.getData();
        if (newSaveData.legs && newSaveData.legs.length) {
            isRebuild = true;
            for (legData of newSaveData.legs) {
                legData.height = height;
            }
            if (newSaveData.plinths && newSaveData.plinths.length) {
                for (plinthData of newSaveData.plinths) {
                    delete plinthData.sizes?.width;
                }
            }
        }
        if (isRebuild) {
            this.rebuild(newSaveData);
            position = this.getPosition();
            position.y = this.defaultYPosition();
            this.setPosition(position);

            return {
                oldData: oldSaveData,
                newData: newSaveData,
            };
        }

        return undefined;
    }

    public trySetFacadeMaterial(
        facadeMaterial: IFacadeMaterialData,
        notCheckEnable?: boolean
    ): boolean {
        if (!this.facades || this.facades.length <= 0) {
            return false;
        }

        let newPriceParams: IModulePriceParams;
        let newModulePrice: IModulePriceData;
        let facade: ThreeFacade;
        let facadeData: IFacadeData | undefined;
        let threeModelData: IFacadeModelData | undefined;
        let createUnit: ICreateObjectData | undefined;
        let index: string;

        facadeData = this.service.getFacadeData(facadeMaterial.facade);
        if (!facadeData) {
            this.service.showMessage({
                type: MESSAGE_TYPE_WARNING,
                message: i18n.t(
                    "Не удалось сменить фасад - не найден товар в каталоге"
                ),
                params: {id: "notSetFacadeMaterial"},
            });
            return false;
        }
        createUnit = this.service.getCreateUnitByUid(this.getUid());
        if (
            !notCheckEnable &&
            createUnit &&
            createUnit.enableFacades &&
            createUnit.enableFacades.indexOf(facadeData.id) === -1
        ) {
            this.service.showMessage({
                type: MESSAGE_TYPE_WARNING,
                message: i18n.t(
                    "Не удалось сменить фасад - недоступен в данной серии фасадов"
                ),
                params: {id: "notSetFacadeMaterial"},
            });
            return false;
        }
        if (
            !notCheckEnable &&
            createUnit &&
            createUnit.disableFacades &&
            createUnit.disableFacades.indexOf(facadeData.id) !== -1
        ) {
            this.service.showMessage({
                type: MESSAGE_TYPE_WARNING,
                message: i18n.t(
                    "Не удалось сменить фасад - недоступен в данной серии фасадов"
                ),
                params: {id: "notSetFacadeMaterial"},
            });
            return false;
        }
        newPriceParams = this.service.calculateUnitPriceParams(this);
        if (newPriceParams.facadeMaterial !== NONE_MATERIAL) {
            newPriceParams.facadeMaterial = facadeMaterial.id;
        }
        if (newPriceParams.facades && newPriceParams.facades.length > 0) {
            for (index in newPriceParams.facades) {
                if (newPriceParams.facades[index].facadeMaterial !== NONE_MATERIAL &&
                    (!newPriceParams.facades[index].facadeMaterialType ||
                        newPriceParams.facades[index].facadeMaterialType === FACADE_MATERIAL_TYPE_DEFAULT)) {
                    newPriceParams.facades[index].facadeMaterial = facadeMaterial.id;
                }
            }
        }
        newModulePrice = this.service.calculatePrice(newPriceParams);
        if (!notCheckEnable && !this.service.checkCalculatePrice(newPriceParams, newModulePrice)) {
            this.service.showMessage({
                type: MESSAGE_TYPE_ERROR,
                message: i18n.t(
                    "Не удалось сменить фасад - не найден товар в каталоге"
                ),
                autoClose: true,
                params: {id: "notSetFacadeMaterial"},
            });
            return false;
        }
        for (facade of this.facades) {
            threeModelData = this.service.getFacadeThreeModel(
                facadeData,
                {
                    height: facade.getHeight(),
                    width: facade.getWidth(),
                    depth: facade.getDepth(),
                    type: facade.getModelType(),
                },
                {
                    height: facade.HEIGHT_GAP,
                    width: facade.WIDTH_GAP,
                }
            );

            if (!threeModelData) {
                this.service.showMessage({
                    type: MESSAGE_TYPE_WARNING,
                    message: i18n.t(
                        "Не удалось сменить фасад - не найдена модель фасада в каталоге"
                    ),
                    params: {id: "notSetFacadeMaterial"},
                });
                return false;
            }
        }
        // Применяем
        this.saveData.offerId = newModulePrice.id;
        this.saveData.modulePrice = newModulePrice;
        for (facade of this.facades) {
            facade.setFacadeMaterial(facadeMaterial);
            facade.updateHandleByFacadeMaterial(facadeMaterial);
        }

        return true;
    }

    public getOfferIds(): string[] {
        if (this.saveData.modulePrice) {
            return this.service.getModuleOfferIds(this.saveData.modulePrice);
        } else if (this.saveData.offerId) {
            return [this.saveData.offerId];
        }
        return [];
    }

    public getOffers(): IOffer[] {
        if (this.saveData.modulePrice) {
            return this.service.getModuleOffers(this.saveData.modulePrice);
        }
        return [];
    }

    public getLoadingOfferIds(): string[] {
        if (this.saveData.modulePrice) {
            return this.service.getModuleLoadingOfferIds(this.saveData.modulePrice);
        }
        return [];
    }

    public updatePriceErrors() {
        if (this.saveData.modulePrice) {
            if (
                this.saveData.modulePrice.errors &&
                this.saveData.modulePrice.errors.length > 0
            ) {
                this.setError({
                    id: "calculatePrice",
                    group: "price",
                    message: this.saveData.modulePrice.errors
                        .map((error) => {
                            return error.message;
                        })
                        .join("; "),
                });
            }
            if (this.saveData.modulePrice.active === false) {
                this.setError({
                    id: "activeOffer",
                    group: "price",
                    message: i18n.t("Товар не активен"),
                });
            }
        }
    }

    public getPrice(): number {
        let modulePrice: number;
        let modulePriceData: IModulePriceData | undefined;

        modulePrice = 0;
        modulePriceData = this.getPriceData();
        if (modulePriceData) {
            modulePrice = modulePriceData.price;
        }

        return modulePrice;
    }

    public isOnlyOrder(): boolean | undefined {
        let modulePriceData: IModulePriceData | undefined;

        modulePriceData = this.getPriceData();
        if (modulePriceData) {
            return modulePriceData.onlyOrder;
        }

        return undefined;
    }

    public isInWay(): boolean | undefined {
        let modulePriceData: IModulePriceData | undefined;

        modulePriceData = this.getPriceData();
        if (modulePriceData) {
            return modulePriceData.inWay;
        }

        return undefined;
    }

    public getStock(): number {
        let modulePriceData: IModulePriceData | undefined;

        modulePriceData = this.getPriceData();
        if (modulePriceData && modulePriceData.stock) {
            return modulePriceData.stock;
        }

        return -1;
    }

    public getFormatStock(stock?: number): string {
        let itemStock: number = stock ? stock : this.getStock();
        return itemStock && itemStock > 0 ? itemStock + " " + i18n.t("шт") : "-";
    }

    public getOfferActive(): boolean | undefined {
        let modulePriceData: IModulePriceData | undefined;
        modulePriceData = this.getPriceData();
        if (modulePriceData) {
            return modulePriceData.active;
        }

        return undefined;
    }

    public getOrderPart(): number | undefined {
        if (!this.service.getCanOrderPart()) {
            return undefined;
        }
        if (this.orderParts && this.orderParts[PRICE_CELL_MODULE]) {
        }

        return undefined;
    }

    public getOldPrice(): number {
        let modulePrice: number;
        let modulePriceData: IModulePriceData | undefined;

        modulePrice = 0;
        modulePriceData = this.getPriceData();
        if (modulePriceData && modulePriceData.oldPrice) {
            modulePrice = modulePriceData.oldPrice;
        }

        return modulePrice;
    }

    public getSizeItemValue(id: string): number | undefined {
        switch (id) {
            case "width":
                return this.getWidth();
            case "height":
                return this.getHeight();
            case "depth":
                return this.getDepth();
        }
    }

    public getCorpusSizeItemValue(id: string): number | undefined {
        return this.getSizeItemValue(id);
    }

    public getSettingsGroups(): { [key: string]: ISettingGroup } {
        let groups: { [key: string]: ISettingGroup };
        let facadeGroup: ISettingGroup | undefined;
        let apronsGroup: ISettingGroup | undefined;
        let tabletopsGroup: ISettingGroup | undefined;
        let legsGroup: ISettingGroup | undefined;
        let equipmentsGroup: ISettingGroup | undefined;
        let createObjectData: ICreateObjectData | undefined;
        let index: string;
        let generalOtherOptions: { [key: string]: IOption } = {};

        groups = super.getSettingsGroups();
        createObjectData = this.service.getCreateUnitByUid(this.getUid());
        if (createObjectData) {
            createObjectData = this.service.calculateStretchCreateObjectData(CommonHelper.deepCopy(createObjectData));
            (groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral).createObjectData = createObjectData;
            for (index in createObjectData.options) {
                if (createObjectData.options[index].id === "sizes") {
                    this.addSizesOptions(
                        createObjectData.options[index] as IOptionGroup,
                        groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral
                    );
                }
                if (createObjectData.options[index].id === "corpus") {
                    groups[SETTING_GROUP_CORPUS] = this.getSettingCorpus(
                        createObjectData,
                        createObjectData.options[index] as IOptionGroup
                    );
                }
                if (
                    this.getOtherGroupIds().includes(createObjectData.options[index].id)
                ) {
                    if ("isGroup" in createObjectData.options[index]) {
                        this.addGroupOtherOptions(
                            generalOtherOptions,
                            createObjectData.options[index] as IOptionGroup
                        );
                    } else {
                        this.addOtherOption(
                            generalOtherOptions,
                            createObjectData.options[index] as IOption
                        );
                    }
                }
                if (
                    this.getOtherOptionIds().includes(createObjectData.options[index].id)
                ) {
                    if ("isGroup" in createObjectData.options[index]) {
                        this.addGroupOtherOptions(
                            generalOtherOptions,
                            createObjectData.options[index] as IOptionGroup
                        );
                    } else {
                        this.addOtherOption(
                            generalOtherOptions,
                            createObjectData.options[index] as IOption
                        );
                    }
                }
            }
            (groups[SETTING_GROUP_GENERAL].data as ISettingGroupGeneral).other =
                Object.values(generalOtherOptions);
        }
        facadeGroup = this.getSettingFacades(createObjectData);
        if (facadeGroup) {
            groups[SETTING_GROUP_FACADES] = facadeGroup;
        }
        apronsGroup = this.getSettingAprons();
        if (apronsGroup) {
            groups[SETTING_GROUP_APRONS] = apronsGroup;
        }
        tabletopsGroup = this.getSettingTabletops(createObjectData);
        if (tabletopsGroup) {
            groups[SETTING_GROUP_TABLETOPS] = tabletopsGroup;
        }
        legsGroup = this.getSettingLegs();
        if (legsGroup) {
            groups[SETTING_GROUP_LEGS] = legsGroup;
        }
        equipmentsGroup = this.getSettingEquipments();
        if (equipmentsGroup) {
            groups[SETTING_GROUP_EQUIPMENTS] = equipmentsGroup;
        }
        groups[SETTING_GROUP_POSITION] = this.getSettingPosition();

        return groups;
    }

    public getFormatStore(): string {
        return "-";
    }

    public getFormatPrice(): string {
        if (this.service.isHidePrice()) {
            return "-";
        }
        if (this.getPrice() > 0) {
            return CurrencyHelper.formatValue(this.getPrice());
        }

        return "-";
    }

    public hasBoxes(): boolean {
        return false;
    }

    public getBoxes(): ISaveBoxData[] {
        return [];
    }

    public isEndUnit(): boolean {
        return false;
    }

    public isAngleUnit(): boolean {
        return false;
    }

    public getNumberSaveDataValue(id: string, groupId?: string): number {
        let sizeValue: number | undefined;

        sizeValue = this.getSizeItemValue(id);
        if (sizeValue) {
            return sizeValue;
        }
        if (groupId && groupId in this.saveData) {
            // @ts-ignore
            if (id in this.saveData[groupId]) {
                // @ts-ignore
                return this.saveData[groupId][id] as number;
            }
        }
        if (id in this.saveData) {
            // @ts-ignore
            return this.saveData[id] as number;
        }

        throw new Error("error-CommonObject-getNumberSaveDataValue " + id);
    }

    public getTextSaveDataValue(id: string, groupId?: string): string {
        if (groupId && groupId in this.saveData) {
            // @ts-ignore
            if (id in this.saveData[groupId]) {
                // @ts-ignore
                return this.saveData[groupId][id] as string;
            }
        }
        if (id in this.saveData) {
            // @ts-ignore
            return this.saveData[id] as string;
        }

        throw new Error("error-CommonObject-getTextSaveDataValue " + id);
    }

    public getBooleanSaveDataValue(id: string, groupId?: string): boolean {
        if (groupId && groupId in this.saveData) {
            // @ts-ignore
            if (id in this.saveData[groupId]) {
                // @ts-ignore
                return this.saveData[groupId][id] as boolean;
            }
        }
        if (id in this.saveData) {
            // @ts-ignore
            return this.saveData[id] as boolean;
        }

        throw new Error("error-CommonObject-getBooleanSaveDataValue " + id);
    }

    public getFormatCount(count: number): string {
        return count ? count + " " + i18n.t("шт") : "-";
    }

    public setSpecItemOrderPart(
        specItem: ISpecItem,
        orderPart: number,
        index: number
    ): boolean {
        let orderParts: IProjectOrderParts;
        let externalId: "externalGuid" | "vendorCode";

        externalId = this.service.getOfferExternalId();
        if (!this.service.getCanOrderPart()) {
            return false;
        }
        orderParts = this.orderParts || {};
        if (!orderParts[specItem.cell]) {
            orderParts[specItem.cell] = [];
        }
        orderParts[specItem.cell][index] = {
            id: specItem[externalId],
            part: orderPart,
        };
        this.orderParts = orderParts;

        return true;
    }

    public setExtraOffersPrice(offerId: string, offerProductPrice: IProductPrice) {
        if (this.extraOffers && this.extraOffers[offerId]) {
            this.extraOffers[offerId].price = offerProductPrice.price;
            this.extraOffers[offerId].oldPrice = offerProductPrice.oldPrice;
            this.extraOffers[offerId].active = offerProductPrice.active;
            this.extraOffers[offerId].stock = offerProductPrice.stock;
            this.extraOffers[offerId].onlyOrder = offerProductPrice.onlyOrder;
            this.extraOffers[offerId].inWay = offerProductPrice.inWay;
        }
    }

    public setExtraOffersPrices(prices: IUnitKitPrices) {
        if (!this.extraOffers) {
            return;
        }
        let index: string;
        let offerProductPrice: IProductPrice | undefined;

        for (index in prices) {
            if (this.extraOffers[index]) {
                offerProductPrice = this.service.getOfferProductPrice(index);
                if (offerProductPrice) {
                    this.setExtraOffersPrice(index, offerProductPrice);
                }
            }
        }
    }

    public getLegsHeight(legsData: IProjectLegsData): number {
        if (this.legs && this.legs[0]) {
            return this.legs[0].getHeight();
        }

        return legsData.height;
    }

    public isShowLegs(): boolean {
        return this.saveData.showLegs !== undefined
            ? this.saveData.showLegs
            : this.service.isShowLegs();
    }

    public setShowLegs(value: boolean): boolean {
        this.saveData.showLegs = value;

        return this.isShowLegs();
    }

    protected initExtraOffers() {
        if (!this.saveData.extraOffers) {
            return;
        }
        let index;
        let extraOffers: IProjectOffers = {};
        let offer: IImportOffer | undefined;
        let externalId: "externalGuid" | "vendorCode";

        externalId = this.service.getOfferExternalId();
        for (index in this.saveData.extraOffers) {
            offer = this.service.getOfferById(this.saveData.extraOffers[index].id);
            if (offer) {
                extraOffers[offer[externalId]] = {
                    id: offer[externalId],
                    count: this.saveData.extraOffers[index].count,
                    offer: offer,
                    unitId: this.saveData.extraOffers[index].unitId,
                    cell: this.saveData.extraOffers[index].cell,
                };
            }
        }
        if (Object.keys(extraOffers).length) {
            this.extraOffers = extraOffers;
            this.loadExtraOffersPrices();
        }
    }

    protected calculateLegsData(): ISaveLegData[] | undefined {
        let leg: ThreeLeg;
        let legsData: ISaveLegData[] = [];

        if (this.legs) {
            for (leg of this.legs) {
                legsData.push(leg.getData());
            }

            return legsData;
        }

        return undefined;
    }

    protected calculateOrderParts(): IProjectOrderParts | undefined {
        if (!this.service.getCanOrderPart()) {
            return undefined;
        }

        return this.orderParts;
    }

    protected calculateExtraOffersData(): IProjectOffers | undefined {
        if (!this.extraOffers) {
            return undefined;
        }
        let index;
        let extraOffers: IProjectOffers = {};

        for (index in this.extraOffers) {
            extraOffers[index] = {
                id: this.extraOffers[index].id,
                count: this.extraOffers[index].count,
                unitId: this.extraOffers[index].unitId,
                cell: this.extraOffers[index].cell,
            };
        }

        return extraOffers;
    }

    protected setSpecItem(
        specItems: { [key: string]: ISpecItem },
        specItem?: ISpecItem
    ) {
        let externalId: "externalGuid" | "vendorCode";

        if (!specItem) {
            return;
        }
        externalId = this.service.getOfferExternalId();
        if (!specItems[specItem[externalId]]) {
            specItems[specItem[externalId]] = specItem;
        } else {
            specItems[specItem[externalId]].count += specItem.count;
            specItems[specItem[externalId]].formatCount = this.getFormatCount(
                specItems[specItem[externalId]].count
            );
        }
    }

    protected getCorpusMaterialSettings(
        createObjectData: ICreateObjectData
    ): IOptionCorpusMaterial | undefined {
        return undefined;
    }

    protected getCorpusOtherSettings(): IOption[] {
        return [];
    }

    protected getSettingEquipments(): ISettingGroup | undefined {
        return undefined;
    }

    protected getSettingPosition(): ISettingGroup {
        let globalPoints: ICoverPoints;
        let roomPolygon: TPoint2D[];
        let roomHeight: number;

        globalPoints = this.getGlobalPoints(this.selectCover);
        roomPolygon = this.service.getRoom().getPolygon();
        roomHeight = this.service.getRoom().getHeight();

        return {
            id: SETTING_GROUP_POSITION,
            sort: 10,
            title: i18n.t("Расстояние до стен"),
            hideTitle: true,
            data: {
                back: this.calculateWallInterval(
                    globalPoints,
                    roomPolygon,
                    SIDE_TYPE_BACK
                ),
                front: this.calculateWallInterval(
                    globalPoints,
                    roomPolygon,
                    SIDE_TYPE_FRONT
                ),
                left: this.calculateWallInterval(
                    globalPoints,
                    roomPolygon,
                    SIDE_TYPE_LEFT
                ),
                right: this.calculateWallInterval(
                    globalPoints,
                    roomPolygon,
                    SIDE_TYPE_RIGHT
                ),
                top: {
                    current: Math.abs(roomHeight - globalPoints.top.A.y),
                    min: 0,
                    max: roomHeight - (globalPoints.top.A.y - globalPoints.bottom.A.y),
                },
                bottom: {
                    current: Math.abs(globalPoints.bottom.A.y),
                    min: 0,
                    max: roomHeight - (globalPoints.top.A.y - globalPoints.bottom.A.y),
                },
            } as ISettingGroupPosition,
        };
    }

    protected calculateWallInterval(
        globalPoints: ICoverPoints,
        roomPolygon: TPoint2D[],
        siteType: TSideType
    ): ISideWallInterval | undefined {
        let current: number = 0;
        let min: number = 0;
        let max: number = 0;
        let line: TLine | undefined;
        let line2: TLine | undefined;
        let calculateDistance: { current: number; max: number };

        switch (siteType) {
            case SIDE_TYPE_BACK:
                line = {
                    pointA: globalPoints.polygon[0],
                    pointB: globalPoints.polygon[1],
                };
                line2 = {
                    pointA: globalPoints.polygon[3],
                    pointB: globalPoints.polygon[2],
                };
                break;
            case SIDE_TYPE_FRONT:
                line = {
                    pointA: globalPoints.polygon[3],
                    pointB: globalPoints.polygon[2],
                };
                line2 = {
                    pointA: globalPoints.polygon[0],
                    pointB: globalPoints.polygon[1],
                };
                break;
            case SIDE_TYPE_LEFT:
                line = {
                    pointA: globalPoints.polygon[0],
                    pointB: globalPoints.polygon[3],
                };
                line2 = {
                    pointA: globalPoints.polygon[1],
                    pointB: globalPoints.polygon[2],
                };
                break;
            case SIDE_TYPE_RIGHT:
                line = {
                    pointA: globalPoints.polygon[1],
                    pointB: globalPoints.polygon[2],
                };
                line2 = {
                    pointA: globalPoints.polygon[0],
                    pointB: globalPoints.polygon[3],
                };
                break;
        }
        if (line && line2) {
            calculateDistance = this.calculateSideWallDistance(
                line,
                line2,
                roomPolygon,
                siteType
            );
            current = calculateDistance.current;
            max = calculateDistance.max;
        }

        if (current === 0 && max === 0) {
            return undefined;
        }
        return {
            current: current,
            min: min,
            max: max,
        };
    }

    protected calculateSideWallDistance(
        line: TLine,
        line2: TLine,
        roomPolygon: TPoint2D[],
        siteType: TSideType
    ): { current: number; max: number } {
        let current: number = 0;
        let max: number = 0;
        let turnLines: {
            A: TLine;
            B: TLine;
        };
        let wallLine: TLine;
        let wallPoints: { A?: TPoint2D; B?: TPoint2D } | undefined;
        let wallPoints2: { A?: TPoint2D; B?: TPoint2D } | undefined;
        let objectWallLine: TLine | undefined;
        let objectWallLine2: TLine | undefined;
        let index: number;
        let intersectPoint: TPoint2D | undefined;
        let intersects: {
            A: TPoint2D[];
            B: TPoint2D[];
            neighborA: TPoint2D[];
            neighborB: TPoint2D[];
        };

        turnLines = {
            A: {
                pointA: line.pointA,
                pointB: MathHelper.turnVector2D(
                    {
                        x: line.pointB.x - line.pointA.x,
                        y: line.pointB.y - line.pointA.y,
                    },
                    Math.PI / 2,
                    line.pointA
                ),
            },
            B: {
                pointA: line.pointB,
                pointB: MathHelper.turnVector2D(
                    {
                        x: line.pointA.x - line.pointB.x,
                        y: line.pointA.y - line.pointB.y,
                    },
                    Math.PI / 2,
                    line.pointB
                ),
            },
        };
        intersects = {
            A: [],
            B: [],
            neighborA: [],
            neighborB: [],
        };
        for (index = 0; index < roomPolygon.length; index++) {
            wallLine = {
                pointA: roomPolygon[index],
                pointB: roomPolygon[index + 1]
                    ? roomPolygon[index + 1]
                    : roomPolygon[0],
            };
            intersectPoint = MathHelper.getIntersectionPoint(turnLines.A, wallLine);
            if (intersectPoint) {
                if (
                    this.isCorrectWallIntersect(intersectPoint, line.pointA, line2.pointA)
                ) {
                    intersects.A.push(intersectPoint);
                } else {
                    intersects.neighborA.push(intersectPoint);
                }
            }
            intersectPoint = MathHelper.getIntersectionPoint(turnLines.B, wallLine);
            if (intersectPoint) {
                if (
                    this.isCorrectWallIntersect(intersectPoint, line.pointB, line2.pointB)
                ) {
                    intersects.B.push(intersectPoint);
                } else {
                    intersects.neighborB.push(intersectPoint);
                }
            }
        }
        wallPoints = {
            A: intersects.A.length
                ? MathHelper.getNearPoint2D(line.pointA, intersects.A)
                : undefined,
            B: intersects.B.length
                ? MathHelper.getNearPoint2D(line.pointB, intersects.B)
                : undefined,
        };
        wallPoints2 = {
            A: intersects.neighborA.length
                ? MathHelper.getNearPoint2D(line2.pointA, intersects.neighborA)
                : undefined,
            B: intersects.neighborB.length
                ? MathHelper.getNearPoint2D(line2.pointB, intersects.neighborB)
                : undefined,
        };
        objectWallLine = this.getNearWallPoint(line, wallPoints);
        objectWallLine2 = this.getNearWallPoint(line2, wallPoints2);

        if (objectWallLine) {
            current = Math.round(
                MathHelper.getLength2D(objectWallLine.pointA, objectWallLine.pointB)
            );
            if (objectWallLine2) {
                max =
                    current +
                    this.getSideDistance(siteType) +
                    MathHelper.getLength2D(
                        objectWallLine2.pointA,
                        objectWallLine2.pointB
                    );
            } else {
                max = current + this.getSideDistance(siteType);
            }
        }

        return {
            current: current,
            max: max,
        };
    }

    protected getNearWallPoint(
        line: TLine,
        wallPoints: { A?: TPoint2D; B?: TPoint2D }
    ): TLine | undefined {
        if (wallPoints.A && wallPoints.B) {
            if (
                MathHelper.getLength2D(line.pointA, wallPoints.A) <
                MathHelper.getLength2D(line.pointB, wallPoints.B)
            ) {
                return {
                    pointA: line.pointA,
                    pointB: wallPoints.A,
                };
            } else {
                return {
                    pointA: line.pointB,
                    pointB: wallPoints.B,
                };
            }
        }
        if (wallPoints.A) {
            return {
                pointA: line.pointA,
                pointB: wallPoints.A,
            };
        }
        if (wallPoints.B) {
            return {
                pointA: line.pointB,
                pointB: wallPoints.B,
            };
        }

        return undefined;
    }

    protected isCorrectWallIntersect(
        intersectPoint: TPoint2D,
        point: TPoint2D,
        neighborPoint: TPoint2D
    ): boolean {
        return (
            MathHelper.getLength2D(intersectPoint, neighborPoint) >
            MathHelper.getLength2D(intersectPoint, point)
        );
    }

    protected getSettingAprons(): ISettingGroup | undefined {
        let group: ISettingGroup | undefined;
        let apron: ThreeApron;
        let apronSetting: ISettingApron;
        let apronsSetting: ISettingApron[] = [];

        if (this.aprons) {
            for (apron of this.aprons) {
                apronSetting = {
                    id: apron.getId(),
                    material: apron.getMaterialData(),
                    visible: apron.isVisible(),
                    name: apron.getSettingName(),
                    positionType: apron.getPositionType(),
                    visibleTitle: apron.getVisibleTitle(),
                };
                apronsSetting.push(apronSetting);
            }
            if (apronsSetting.length > 0) {
                group = {
                    id: SETTING_GROUP_APRONS,
                    title: i18n.t("Стеновые панели"),
                    data: {
                        items: apronsSetting,
                    } as ISettingGroupAprons,
                };
            }
        }

        return group;
    }

    protected getSettingTabletops(createObjectData: ICreateObjectData | undefined): ISettingGroup | undefined {
        let group: ISettingGroup | undefined;
        let tabletop: ThreeTabletop;
        let tabletopSetting: ISettingTabletop;
        let tabletopsSetting: ISettingTabletop[] = [];

        if (this.tabletops) {
            for (tabletop of this.tabletops) {
                tabletopSetting = {
                    id: tabletop.getId(),
                    material: tabletop.getMaterialData(),
                    visible: tabletop.isVisible(),
                    name: tabletop.getSettingName(),
                    positionType: tabletop.getPositionType(),
                    visibleTitle: tabletop.getVisibleTitle(),
                    canUnion: tabletop.isCanUnion(),
                    availableMaterials: tabletop.getAvailableMaterialIds(createObjectData),
                }
                tabletopsSetting.push(tabletopSetting);
            }
            if (tabletopsSetting.length > 0) {
                group = {
                    id: SETTING_GROUP_TABLETOPS,
                    title: i18n.t('Столешница'),
                    data: {
                        items: tabletopsSetting
                    } as ISettingGroupTabletops
                }
            }
        }

        return group;
    }

    protected getSettingLegs(): ISettingGroup | undefined {
        let group: ISettingGroup | undefined;
        let legsData: IProjectLegsData | undefined;

        legsData = this.service.getLegsData(this.getTechnologMapFacadeId());
        if (this.legs && legsData) {
            group = {
                id: SETTING_GROUP_LEGS,
                title: i18n.t("Ножки"),
                data: {
                    showLegs: this.isShowLegs(),
                    height: this.getLegsHeight(legsData),
                    heights: legsData.heights,
                } as ISettingGroupLegs,
            };
        }

        return group;
    }

    protected getZInitTabletopPosition(tabletop: ThreeTabletop): number {
        if (this.getDepth() >= tabletop.getWidth()) {
            return this.getDepth() / 2 - tabletop.getWidth() / 2;
        }
        if (
            this.getDepth() + this.service.getTabletopFrontGap() <=
            tabletop.getWidth()
        ) {
            return (
                this.service.getTabletopFrontGap() +
                this.getDepth() / 2 -
                tabletop.getWidth() / 2
            );
        }
        return 0;
    }

    protected getOtherGroupIds(): string[] {
        return [OPTION_TYPE_OFFERS];
    }

    protected getOtherOptionIds(): string[] {
        return [OPTION_TYPE_OFFERS];
    }

    protected addSizesOptions(
        sizesGroup: IOptionGroup,
        settingGeneralGroupData: ISettingGroupGeneral
    ) {
        let index: string;
        let optionItem: IOption;

        for (index in sizesGroup.options) {
            switch (sizesGroup.options[index].type) {
                case OPTION_TYPE_NUMBER:
                case OPTION_TYPE_RANGE:
                    optionItem = CommonHelper.deepCopy(sizesGroup.options[index]);
                    optionItem.value = optionItem.defaultValue = this.getSizeItemValue(optionItem.id);
                    settingGeneralGroupData.sizes.push(optionItem);
                    break;
                case OPTION_TYPE_TEXT:
                    optionItem = CommonHelper.deepCopy(sizesGroup.options[index]);
                    optionItem.value = optionItem.defaultValue = '' + this.getSizeItemValue(optionItem.id);
                    settingGeneralGroupData.sizes.push(optionItem);
                    break;
                case OPTION_TYPE_SELECT:
                case OPTION_TYPE_RADIOBUTTON:
                    optionItem = CommonHelper.deepCopy(sizesGroup.options[index]);
                    optionItem.value = optionItem.defaultValue = '' + this.getSizeItemValue(optionItem.id);
                    settingGeneralGroupData.sizes.push(optionItem);
                    break;
            }
        }
    }

    protected addGroupOtherOptions(
        generalOtherOptions: { [key: string]: IOption },
        group: IOptionGroup
    ) {
        let index: string;

        for (index in group.options) {
            this.addOtherOption(generalOtherOptions, group.options[index]);
        }
    }

    protected addOtherOption(
        generalOtherOptions: { [key: string]: IOption },
        option: IOption
    ) {
        let offersOption: IOptionOffers;

        if (option.id === OPTION_TYPE_OFFERS) {
            offersOption = CommonHelper.deepCopy(option);
            offersOption.items = CommonHelper.deepCopy(this.getExtraOffersArray());
            generalOtherOptions[option.id] = offersOption;
        } else {
            generalOtherOptions[option.id] = option;
        }
    }

    protected getSettingCorpus(
        createObjectData: ICreateObjectData,
        corpusGroup: IOptionGroup
    ): ISettingGroup {
        let settingCorpusGroup: ISettingGroup;
        let index: string;
        let optionItem: IOption;

        settingCorpusGroup = {
            id: SETTING_GROUP_CORPUS,
            title: i18n.t("Корпус"),
            hideTitle: true,
            data: {
                createObjectData: createObjectData,
                disableSelects: createObjectData.disableSelects,
                sizes: [],
                material: this.getCorpusMaterialSettings(createObjectData),
                other: this.getCorpusOtherSettings(),
            } as ISettingGroupCorpus,
        };
        for (index in corpusGroup.options) {
            switch (corpusGroup.options[index].type) {
                case OPTION_TYPE_NUMBER:
                case OPTION_TYPE_RANGE:
                    optionItem = CommonHelper.deepCopy(corpusGroup.options[index]);
                    optionItem.value = optionItem.defaultValue = this.getCorpusSizeItemValue(optionItem.id);
                    (settingCorpusGroup.data as ISettingGroupCorpus).sizes.push(optionItem);
                    break;
                case OPTION_TYPE_TEXT:
                    optionItem = CommonHelper.deepCopy(corpusGroup.options[index]);
                    optionItem.value = '' + this.getCorpusSizeItemValue(optionItem.id);
                    (settingCorpusGroup.data as ISettingGroupCorpus).sizes.push(optionItem);
                    break;
                case OPTION_TYPE_SELECT:
                case OPTION_TYPE_RADIOBUTTON:
                    optionItem = CommonHelper.deepCopy(corpusGroup.options[index]);
                    optionItem.defaultValue = '' + this.getCorpusSizeItemValue(optionItem.id);
                    (settingCorpusGroup.data as ISettingGroupCorpus).sizes.push(optionItem);
                    break;
            }
        }

        return settingCorpusGroup;
    }

    protected getSettingFacades(
        createObjectData?: ICreateObjectData
    ): ISettingGroup | undefined {
        let group: ISettingGroup | undefined;
        let facade: ThreeFacade;
        let facadeSetting: ISettingFacade;
        let facadeSettingItems: { [key: string]: ISettingFacade };

        if (!createObjectData) {
            return undefined;
        }
        if (this.facades.length > 0) {
            facadeSettingItems = {};
            for (facade of this.facades) {
                facadeSetting = {
                    id: facade.getId(),
                    sort: facade.getPosition().y,
                    unitUid: this.getUid(),
                    groupId: facade.getGroupId(),
                    facadeMaterial: facade.getFacadeMaterial(),
                    sideType: facade.getSideType(),
                    sideTypes: facade.getAvailableSideTypes(),
                };
                if (facadeSetting.groupId) {
                    if (facade.getCalculateType() !== FACADE_CALCULATE_NONE) {
                        facadeSettingItems["" + facadeSetting.groupId] = facadeSetting;
                    }
                } else {
                    facadeSettingItems["" + facadeSetting.id] = facadeSetting;
                }
            }
            group = {
                id: SETTING_GROUP_FACADES,
                title: i18n.t("Фасады"),
                data: {
                    items: Object.values(facadeSettingItems).sort((a, b) => {
                        return b.sort - a.sort;
                    }),
                    createUnit: createObjectData,
                } as ISettingGroupFacades,
            };
        }

        return group;
    }

    protected removeFacades() {
        let facade: ThreeFacade;

        if (!this.facades) {
            return;
        }
        for (facade of this.facades) {
            facade.remove();
        }

        this.facades = [];
    }

    protected removeTabletops() {
        let tabletop: ThreeTabletop;

        if (!this.tabletops) {
            return;
        }
        for (tabletop of this.tabletops) {
            tabletop.remove();
        }

        this.tabletops = undefined;
    }

    protected removeAprons() {
        let apron: ThreeApron;

        if (!this.aprons) {
            return;
        }
        for (apron of this.aprons) {
            apron.remove();
        }

        this.aprons = undefined;
    }

    protected removeCorners() {
        let corner: ThreeCorner;

        if (!this.corners) {
            return;
        }
        for (corner of this.corners) {
            corner.remove();
        }

        this.corners = undefined;
    }

    protected removeLegs() {
        let leg: ThreeLeg;

        if (!this.legs) {
            return;
        }
        for (leg of this.legs) {
            leg.remove();
        }

        this.legs = [];
    }

    protected removeSizes() {
        let size: ThreeSize;

        if (!this.sizes) {
            return;
        }
        for (size of this.sizes) {
            size.remove();
        }

        this.sizes = [];
    }

    protected removePlinths() {
        let plinth: ThreePlinth;

        if (!this.plinths) {
            return;
        }
        for (plinth of this.plinths) {
            this.view3d.remove(plinth.view3d);
            plinth.remove();
        }

        this.plinths = undefined;
    }

    protected initPosition(): Vector3 {
        const initPosition: Vector3 = new Vector3();
        if (this.saveData.initPosition) {
            if (this.saveData.initPosition.x !== undefined) {
                initPosition.x = KitchenHelper.calculateSizeByParent(
                    "" + this.saveData.initPosition.x,
                    this.getWidth(),
                    this.service.getDataForSizeByParent()
                );
            }
            if (this.saveData.initPosition.y !== undefined) {
                initPosition.y = KitchenHelper.calculateSizeByParent(
                    "" + this.saveData.initPosition.y,
                    this.getWidth(),
                    this.service.getDataForSizeByParent()
                );
            }
            if (this.saveData.initPosition.z !== undefined) {
                initPosition.z = KitchenHelper.calculateSizeByParent(
                    "" + this.saveData.initPosition.z,
                    this.getWidth(),
                    this.service.getDataForSizeByParent()
                );
            }
        }
        if (this.saveData.position) {
            if (
                this.saveData.position.x !== undefined &&
                !isNaN(+this.saveData.position.x)
            ) {
                initPosition.x = this.saveData.position.x;
            }
            if (
                this.saveData.position.y !== undefined &&
                !isNaN(+this.saveData.position.y)
            ) {
                initPosition.y = this.saveData.position.y;
            }
            if (
                this.saveData.position.z !== undefined &&
                !isNaN(+this.saveData.position.z)
            ) {
                initPosition.z = this.saveData.position.z;
            }
        }

        return initPosition;
    }

    protected availableEquipments(): TClassName[] {
        if (this.saveData.availableEquipments) {
            return this.filterDisableEquipments(this.saveData.availableEquipments);
        }

        return [];
    }

    protected filterDisableEquipments(equipments: TClassName[]): TClassName[] {
        let disableEquipments: TClassName[];

        if (
            this.saveData.disableEquipments &&
            Object.keys(this.saveData.disableEquipments).length
        ) {
            if (this.saveData.disableEquipments["" + this.getWidth()]) {
                disableEquipments =
                    this.saveData.disableEquipments["" + this.getWidth()];

                return equipments.filter((item) => {
                    return !disableEquipments.includes(item);
                });
            }
            if (this.saveData.disableEquipments["all"]) {
                disableEquipments = this.saveData.disableEquipments["all"];

                return equipments.filter((item) => {
                    return !disableEquipments.includes(item);
                });
            }
        }
        return equipments;
    }

    protected rebuildPlinths(plinthsType: TPlinthsType) {
        let index;

        this.saveData.plinthsType = plinthsType;
        this.removePlinths();
        if (this.saveData.plinths) {
            for (index in this.saveData.plinths) {
                delete this.saveData.plinths[index].sizes;
            }
        }
        this.createPlinths();
    }

    protected clearPlinths() {
        let plinth: ThreePlinth;

        if (this.plinths) {
            for (plinth of this.plinths) {
                this.view3d.remove(plinth.view3d);
                plinth.remove();
            }
        }
        this.plinths = [];
    }

    protected getApronBySide(sideType: TSideType): ThreeApron | undefined {
        let apron: ThreeApron;

        if (this.aprons) {
            for (apron of this.aprons) {
                if (apron.getPositionType() === sideType) {
                    return apron;
                }
            }
        }

        return undefined;
    }

    protected createTabletops() {
        let tabletopData: ISaveKUnitDetailData;
        let tabletop: ThreeTabletop;

        this.saveData.tabletops = this.initTabletopsData(this.saveData.tabletops);
        if (!this.saveData.tabletops) {
            return;
        }
        this.tabletops = [];
        for (tabletopData of this.saveData.tabletops) {
            tabletop = new ThreeTabletop(CommonHelper.deepCopy(tabletopData), this);
            tabletop.initState();
            tabletop.createView();
            this.view3d.add(tabletop.view3d);
            this.tabletops.push(tabletop);
        }
        for (tabletop of this.tabletops) {
            this.addDetailToCoverPoints(tabletop);
        }
    }

    protected initTabletopsData(
        tabletops?: ISaveKUnitDetailData[]
    ): ISaveKUnitDetailData[] | undefined {
        return tabletops;
    }

    protected createAprons() {
        let apronData: ISaveKUnitDetailData;
        let apron: ThreeApron;

        this.saveData.aprons = this.initApronsData(this.saveData.aprons);
        if (!this.saveData.aprons) {
            return;
        }
        this.aprons = [];
        for (apronData of this.saveData.aprons) {
            apron = new ThreeApron(CommonHelper.deepCopy(apronData), this);
            apron.initState();
            apron.createView();
            apron.view3d.name = "apron";
            this.view3d.add(apron.view3d);
            this.aprons.push(apron);
        }
    }

    protected initApronsData(
        aprons?: ISaveKUnitDetailData[]
    ): ISaveKUnitDetailData[] | undefined {
        return aprons;
    }

    protected createCorners() {
        let cornerData: ISaveKUnitDetailData;
        let corner: ThreeCorner;

        this.saveData.corners = this.initCornersData(this.saveData.corners);
        if (!this.saveData.corners) {
            return;
        }
        this.corners = [];
        for (cornerData of this.saveData.corners) {
            corner = new ThreeCorner(CommonHelper.deepCopy(cornerData), this);
            corner.initState();
            corner.createView();
            corner.view3d.name = "corner";
            this.view3d.add(corner.view3d);
            this.corners.push(corner);
        }
    }

    protected createIntegratedHandles() {
        let integratedHandleData: ISaveIntegratedHandleData;
        let integratedHandle: ThreeIntegratedHandle;

        this.saveData.integratedHandles = this.initIntegratedHandlesData(this.saveData.integratedHandles);
        if (!this.saveData.integratedHandles) {
            return;
        }
        this.integratedHandles = [];
        for (integratedHandleData of this.saveData.integratedHandles) {
            integratedHandle = new ThreeIntegratedHandle(CommonHelper.deepCopy(integratedHandleData), this);
            integratedHandle.initState();
            integratedHandle.createView();
            integratedHandle.view3d.name = "integratedHandle";
            this.view3d.add(integratedHandle.view3d);
            this.integratedHandles.push(integratedHandle);
        }
    }

    protected initCornersData(
        corners?: ISaveKUnitDetailData[]
    ): ISaveKUnitDetailData[] | undefined {
        return corners;
    }

    protected initIntegratedHandlesData(
        integratedHandles?: ISaveIntegratedHandleData[]
    ): ISaveIntegratedHandleData[] | undefined {
        return integratedHandles;
    }

    protected createPlinths() {
        let plinthData: ISaveKUnitDetailData;
        let plinth: ThreePlinth;

        this.saveData.plinths = this.calculateInitPlinthsData(this.saveData.plinths);
        if (!this.saveData.plinths) {
            return;
        }
        this.plinths = [];
        for (plinthData of this.saveData.plinths) {
            if (plinthData.sizes && plinthData.sizes.length === 0) {
                continue;
            }
            switch (plinthData.positionType) {
                case SIDE_TYPE_ARC:
                    plinth = new ThreePlinthArc(CommonHelper.deepCopy(plinthData), this);
                    break;
                default:
                    plinth = new ThreePlinth(CommonHelper.deepCopy(plinthData), this);
            }
            plinth.initState();
            plinth.createView();
            this.view3d.add(plinth.view3d);
            this.plinths.push(plinth);
        }
    }

    protected initGapCoverPoints() {
    }

    protected initOrderParts(isRebuild?: boolean) {
        if (!this.service.getCanOrderPart() || isRebuild) {
            return;
        }
        let orderParts: IProjectOrderParts = {};
        if (this.saveData.orderParts) {
            orderParts = this.saveData.orderParts;
        }

        this.orderParts = orderParts;
    }

    protected calculateInitPlinthsData(plinths?: ISaveKUnitDetailData[]): ISaveKUnitDetailData[] | undefined {
        return plinths;
    }

    protected calculateInitFacadesData(facades?: ISaveFacadeData[]): ISaveFacadeData[] | undefined {
        return facades;
    }

    protected createFacades() {
        let facadeData: ISaveFacadeData;
        let facade: ThreeFacade | undefined;

        this.saveData.facades = this.calculateInitFacadesData(this.saveData.facades);
        if (!this.saveData.facades) {
            return;
        }
        this.facades = [];
        for (facadeData of this.saveData.facades) {
            switch (facadeData.geometryType) {
                case GEOMETRY_TYPE_SQUARE:
                    facade = new ThreeSquareFacade(
                        CommonHelper.deepCopy(facadeData),
                        this
                    );
            }
            if (facade) {
                facade.initState();
                facade.createView();
                this.view3d.add(facade.view3d);
                this.facades.push(facade);
                if (facade.getIsDimensions()) {
                    this.addCoverPoints(Object.values(facade.getDummyBox()));
                }
            }
        }
    }

    protected createSizes() {
        let sizesData: ISaveSizeData[];
        let sizeData: ISaveSizeData;

        sizesData = this.initSizesData();
        for (sizeData of sizesData) {
            this.createSize(sizeData);
        }
    }

    public rebuildSizes(levelBoxes?: TLevelBoxes) {
        let size: ThreeSize;
        let positionInfo: IPositionInfo;

        positionInfo = this.getPositionInfo();
        for (size of this.sizes) {
            size.hide();
            if (this.service.getOptions().sizesType === KITCHEN_SIZES_TYPE_NONE) {
                continue;
            }
            switch (size.getType()) {
                case SIZE_TYPE_DEPTH:
                    if (
                        positionInfo.inAngle.result ||
                        !this.neighbors.right ||
                        !this.neighbors.left
                    ) {
                        if (positionInfo.inAngle.leftSide) {
                            size.setDepthToLeft(this.SIZE_GAP);
                        } else if (positionInfo.inAngle.rightSide) {
                            size.setDepthToRight(this.SIZE_GAP);
                        } else if (!this.neighbors.left) {
                            size.setDepthToLeft(this.SIZE_GAP);
                        } else {
                            size.setDepthToRight(this.SIZE_GAP);
                        }
                        size.show();
                    }
                    break;
                case SIZE_TYPE_WIDTH:
                    if (this.getLevel() === LEVEL_TOP) {
                        if (!this.neighbors.bottom) {
                            size.show();
                        }
                    } else {
                        if (!this.neighbors.top) {
                            size.show();
                        }
                    }
                    break;
                case SIZE_TYPE_HEIGHT:
                    if (!this.neighbors.right && !positionInfo.inAngle.rightSide) {
                        size.setHeightToRight();
                        size.show();
                    } else if (!this.neighbors.left && !positionInfo.inAngle.leftSide) {
                        size.setHeightToLeft();
                        size.show();
                    }
                    break;
            }
        }
        this.setSizesPosition(levelBoxes);
    }

    public setViewType(viewType: TKitchenView) {
        super.setViewType(viewType);
        if (this.facades) {
            let facade: ThreeFacade;

            for (facade of this.facades) {
                facade.setViewType(viewType);
            }
        }
    }

    protected calculateChildrenGlobalFrontVector() {
        let size: ThreeSize;
        let detail: ThreeKUnitDetail;

        for (size of this.sizes) {
            size.calculateGlobalFrontVector();
        }
        if (this.aprons) {
            for (detail of this.aprons) {
                detail.calculateGlobalFrontVector();
            }
        }
        if (this.tabletops) {
            for (detail of this.tabletops) {
                detail.calculateGlobalFrontVector();
            }
        }
        if (this.corners) {
            for (detail of this.corners) {
                detail.calculateGlobalFrontVector();
            }
        }
        if (this.plinths) {
            for (detail of this.plinths) {
                detail.calculateGlobalFrontVector();
            }
        }
    }

    protected setSizesPosition(levelBoxes?: TLevelBoxes) {
    }

    protected initSizesData(): ISaveSizeData[] {
        let sizesData: ISaveSizeData[] = [];
        let coverBox: Box3;

        coverBox = this.getCoverBox(0);

        sizesData.push({
            id: 0,
            pointA: {
                x: coverBox.min.x,
                y: coverBox.max.y,
                z: coverBox.min.z + this.SIZE_GAP,
            },
            pointB: {
                x: coverBox.max.x,
                y: coverBox.max.y,
                z: coverBox.min.z + this.SIZE_GAP,
            },
            type: SIZE_TYPE_WIDTH,
            decimal: 0,
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });
        sizesData.push({
            id: 0,
            pointA: {
                x: coverBox.max.x,
                y: coverBox.min.y,
                z: coverBox.min.z + this.SIZE_GAP,
            },
            pointB: {
                x: coverBox.max.x,
                y: coverBox.max.y,
                z: coverBox.min.z + this.SIZE_GAP,
            },
            type: SIZE_TYPE_HEIGHT,
            decimal: 0,
            rotation: {z: -Math.PI / 2},
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });
        sizesData.push({
            id: 0,
            pointA: {
                x: coverBox.min.x + this.SIZE_GAP,
                y: coverBox.max.y,
                z: coverBox.min.z,
            },
            pointB: {
                x: coverBox.min.x + this.SIZE_GAP,
                y: coverBox.max.y,
                z: coverBox.max.z,
            },
            type: SIZE_TYPE_DEPTH,
            decimal: 0,
            rotation: {y: Math.PI / 2},
            textSize: UNIT_SIZE_TEXT_SIZE,
            textIndent: 0,
        });

        return sizesData;
    }

    protected createSize(sizeData: ISaveSizeData) {
        let size: ThreeSize;

        size = new ThreeSize(sizeData, this);
        size.initState();
        size.createView();
        this.view3d.add(size.view3d);
        this.sizes.push(size);
    }

    protected getAnglePosition(
        nearRounding: number = DEFAULT_NEAR_ROUNDING
    ): TAnglePosition {
        let leftPosition: Vector3 | undefined;
        let rightPosition: Vector3 | undefined;
        let globalPoints: ICoverPoints;
        let leftPoint;
        let rightPoint;
        let walls: ThreeWall[];
        let wall: ThreeWall;
        let anglePosition: TAnglePosition;

        anglePosition = {
            leftPosition: undefined,
            rightPosition: undefined,
        };
        if (!this.wall) {
            return anglePosition;
        }

        globalPoints = this.getGlobalPoints(this.cover);
        leftPoint = {
            x: globalPoints.bottom.A.x,
            y: (globalPoints.bottom.A.z + globalPoints.bottom.C.z) / 2,
        };
        rightPoint = {
            x: globalPoints.bottom.B.x,
            y: (globalPoints.bottom.B.z + globalPoints.bottom.D.z) / 2,
        };

        walls = this.service.getWalls();
        for (wall of walls) {
            if (wall.getId() === this.wall.getId()) {
                continue;
            }
            if (this.isSelfWall(wall)) {
                continue;
            }
            leftPosition = this.calculateWallAnglePosition(
                leftPoint,
                wall,
                nearRounding,
                this.getSideDistance(SIDE_TYPE_LEFT),
                SIDE_TYPE_LEFT
            );
            if (leftPosition) {
                anglePosition.leftPosition = leftPosition;
            }
            rightPosition = this.calculateWallAnglePosition(
                rightPoint,
                wall,
                nearRounding,
                this.getSideDistance(SIDE_TYPE_RIGHT),
                SIDE_TYPE_RIGHT
            );
            if (rightPosition) {
                anglePosition.rightPosition = rightPosition;
            }
            if (anglePosition.leftPosition && anglePosition.rightPosition) {
                break;
            }
        }

        return anglePosition;
    }

    public getSideDistance(sideType: TSideType, gap?: number): number {
        let distance;

        if (!gap || isNaN(+gap)) {
            gap = 0;
        } else {
            gap = +gap;
        }
        //TODO тут еще было про вертикальный разворот, чтобы к стене другой стороной прилепляться,
        // нужно будет потом доработать
        switch (sideType) {
            case SIDE_TYPE_LEFT:
                if (this.selectCover && this.selectCover.geometry.boundingBox) {
                    distance =
                        Math.abs(this.selectCover.geometry.boundingBox.min.x) + gap;
                } else {
                    distance = this.getWidth() / 2 + gap;
                }
                break;
            case SIDE_TYPE_RIGHT:
                if (this.selectCover && this.selectCover.geometry.boundingBox) {
                    distance =
                        Math.abs(this.selectCover.geometry.boundingBox.max.x) + gap;
                } else {
                    distance = this.getWidth() / 2 + gap;
                }
                break;
            case SIDE_TYPE_BACK:
                if (this.selectCover && this.selectCover.geometry.boundingBox) {
                    distance =
                        Math.abs(this.selectCover.geometry.boundingBox.min.z) + gap;
                } else {
                    distance = this.getDepth() / 2 + gap;
                }
                break;
            case SIDE_TYPE_FRONT:
                if (this.selectCover && this.selectCover.geometry.boundingBox) {
                    distance =
                        Math.abs(this.selectCover.geometry.boundingBox.max.z) + gap;
                } else {
                    distance = this.getDepth() / 2 + gap;
                }
                break;
            case SIDE_TYPE_TOP:
                if (this.selectCover && this.selectCover.geometry.boundingBox) {
                    distance =
                        Math.abs(this.selectCover.geometry.boundingBox.max.y) + gap;
                } else {
                    distance = this.getHeight() / 2 + gap;
                }
                break;
            case SIDE_TYPE_BOTTOM:
                if (this.selectCover && this.selectCover.geometry.boundingBox) {
                    distance =
                        Math.abs(this.selectCover.geometry.boundingBox.min.y) + gap;
                } else {
                    distance = this.getHeight() / 2 + gap;
                }
                break;
        }

        return Math.round((distance + Number.EPSILON) * 100) / 100;
    }

    public getWidth(): number {
        return +this.saveData.sizes.length;
    }

    public getDepth(): number {
        return +this.saveData.sizes.width;
    }

    public getHeight(): number {
        return +this.saveData.sizes.height;
    }

    public getSizes(): TSizes {
        return this.saveData.sizes;
    }

    public getLevel(): TLevel {
        if (this.saveData.level) {
            return this.saveData.level;
        }
        return LEVEL_BOTTOM;
    }

    public getName(): string {
        return this.saveData.name;
    }

    public getImage(): string | undefined {
        return this.saveData.image;
    }

    public getSideType(): TDirectionSideType {
        return this.saveData.sideType || SIDE_TYPE_DEFAULT;
    }

    public getCatalogCode(): string {
        return this.saveData.catalogCode || "";
    }

    public getNotPrice(): boolean {
        return this.saveData.notPrice || false;
    }

    public getCorpusMaterial(): IMaterialData | undefined {
        return undefined;
    }

    public getCorpusMaterialTextures(): IMaterialTextures | undefined {
        return undefined;
    }

    public getCorpusShapeMaterialTextures(): IMaterialTextures | undefined {
        return undefined;
    }

    public getCorpusMaterialId(): string | undefined {
        return;
    }

    public getCorpusPriceParams(): ICorpusPriceParams | undefined {
        return undefined;
    }

    public getFacadesPriceParams(): IFacadePriceParam[] {
        let facadeParams: IFacadePriceParam[] = [];
        let facade: ThreeFacade;
        let saveData: ISaveFacadeData;
        let facadeSizes: TFacadeSizes;

        if (this.facades) {
            for (facade of this.facades) {
                if (facade.getCalculateType() === FACADE_CALCULATE_NONE) {
                    continue;
                }
                saveData = facade.getData();
                facadeSizes = KitchenHelper.calculateFacadeSizes(
                    saveData.initSizes,
                    this.getCorpusSizes(),
                    this.service,
                    saveData.gap,
                    facade.getCalculateSizes()
                );
                facadeParams.push({
                    facadeMaterial: facade.getFacadeMaterialId(),
                    width: facadeSizes.width,
                    height: facadeSizes.height,
                    modelType: facade.getModelType(),
                    functionalType: facade.getFunctionalType(),
                    sideType: this.getSideType(),
                    handle: facade.getHandlePriceParams(),
                    hinges: facade.getHingesPriceParams(),
                    part: facade.getOrderPart(),
                    unitId: this.getId(),
                    cell: PRICE_CELL_FACADE,
                    canStretch: this.canStretch(),
                    isStretch: this.isStretch(),
                    facadeMaterialType: facade.getFacadeMaterialType(),
                    for: saveData.for,
                    calculateType: facade.getCalculateType(),
                });
            }
        }

        return facadeParams;
    }

    public getFrameMaterialId(): string | undefined {
        return undefined;
    }

    public getPanelMaterialId(): string | undefined {
        return undefined;
    }

    public getPriceFacadeMaterialId(): string {
        let facade: ThreeFacade;
        let facadeCalculateType: TFacadeCalculateType | undefined;

        if (this.facades) {
            for (facade of this.facades) {
                facadeCalculateType = facade.getCalculateType();
                if (facadeCalculateType !== undefined &&
                    [FACADE_CALCULATE_SELF_AMOUNT, FACADE_CALCULATE_NONE].includes(facadeCalculateType)) {
                    continue;
                }
                if (facade.saveData.for === FACADE_FOR_SECOND) {
                    continue;
                }
                return facade.facadeMaterialData.id;
            }
        }

        return NONE_MATERIAL;
    }

    public getPriceFacadeMaterial2Id(): string | undefined {
        let facade: ThreeFacade;
        let facadeCalculateType: TFacadeCalculateType | undefined;

        if (this.facades) {
            for (facade of this.facades) {
                facadeCalculateType = facade.getCalculateType();
                if (facadeCalculateType !== undefined &&
                    [FACADE_CALCULATE_SELF_AMOUNT, FACADE_CALCULATE_NONE].includes(facadeCalculateType)) {
                    continue;
                }
                if (facade.saveData.for === FACADE_FOR_SECOND) {
                    return facade.facadeMaterialData.id;
                }
            }
        }

        return undefined;
    }

    public getPriceData(): IModulePriceData | undefined {
        return this.saveData.modulePrice;
    }

    public checkPriceError(): boolean {
        let priceData: IModulePriceData | undefined;

        if (this.service.isSelectPriceErrorObjects()) {
            priceData = this.getPriceData();
            if (!priceData || priceData.errors.length) {
                return true;
            }
        }

        return false;
    }

    public getSpecName(): string {
        let name: string;

        name = i18n.t(this.saveData.name);
        name +=
            " (" +
            i18n.t("ШхВхГ") +
            " " +
            this.getWidth() +
            "x" +
            this.getHeight() +
            "x" +
            this.getDepth() +
            ")";

        return name;
    }

    public getSpecUid(): string {
        if (this.service.isUniqueSpecModules()) {
            return this.getUid() + "" + this.getId();
        }
        return (
            this.getUid() +
            this.getWidth() +
            this.getHeight() +
            this.getDepth() +
            this.getSideType() +
            this.getOffersSpecGuid()
        );
    }

    public getVendorCode(): string {
        const priceData: IModulePriceData | undefined = this.getPriceData();

        if (
            priceData &&
            priceData.offer &&
            priceData.offer.vendorCode &&
            priceData.offer.vendorCode.length > 0
        ) {
            return priceData.offer.vendorCode;
        }

        return "-";
    }

    public tryMove(startVectors: TStartVectors, isFinal?: boolean): boolean {
        let movePosition: TDeltaMovePosition | undefined;
        let result;

        if (!this.canMove) {
            this.applyMoveColor(this.service.getNotMoveColor());

            return false;
        }
        movePosition = this.tryCalculateDeltaMovePosition(startVectors);
        if (movePosition) {
            result = this.trySetPosition(movePosition, isFinal);

            return result;
        }

        return false;
    }

    public afterHistoryMove() {
    }

    public afterMove(setState?: boolean) {
        let oldPosition: IPositionInfo | undefined;

        this.updateAllMatrices();
        if (setState) {
            oldPosition = this.getOldPositionInfo();
            this.service.setHistoryState({
                type: HISTORY_STATE_TYPE_MOVE,
                data: {
                    objects: [
                        {
                            objectId: this.getId(),
                            oldPosition: oldPosition,
                            newPosition: this.getPositionInfo(true),
                        },
                    ],
                },
            } as IHistoryMoveState);
        }
    }

    public checkMoveInRoom(cover?: Mesh, gap?: number): boolean {
        let globalPoints: ICoverPoints;
        let result: boolean = true;
        let wallIslands: ThreeWallIsland[];
        let wallIsland: ThreeWallIsland;

        if (!cover) {
            cover = this.cover;
        }
        if (cover === undefined) {
            return result;
        }

        globalPoints = this.getGlobalPoints(cover, gap);
        if (!this.checkMoveInRoomVertical(globalPoints.bottom.A) ||
            !this.checkMoveInRoomVertical(globalPoints.top.A) ||
            !this.checkMoveInRoomHorizontal(globalPoints.bottom.A) ||
            !this.checkMoveInRoomHorizontal(globalPoints.bottom.B) ||
            !this.checkMoveInRoomHorizontal(globalPoints.bottom.C) ||
            !this.checkMoveInRoomHorizontal(globalPoints.bottom.D)) {
            return false;
        }

        wallIslands = this.service.getWallIslands();
        for (wallIsland of wallIslands) {
            if (this.isIntersectCover(wallIsland.cover, globalPoints)) {
                return false;
            }
        }

        return result;
    }

    public getCalculateType(): TCatalogCalculateType {
        if (this.saveData.calculateType) {
            return this.saveData.calculateType;
        }

        return this.service.getCatalogCalculateType();
    }

    public getOffersSpecGuid(): string {
        return CommonHelper.md5(this.getOfferIds());
    }

    public afterTryMove(isMove: boolean, isFinal?: boolean): boolean {
        return isMove;
    }

    protected initWall(): ThreeWall | undefined {
        if (this.saveData.wall) {
            return this.service.getWallById(this.saveData.wall);
        }
        return undefined;
    }

    protected initNeighbors(): IUnitNeighbors {
        return {
            bottom: undefined,
            top: undefined,
            back: undefined,
            front: undefined,
            left: undefined,
            right: undefined,
        };
    }

    protected checkMoveInRoomHorizontal(point: Vector3): boolean {
        return MathHelper.isPointInsidePolygon(
            {x: point.x, y: point.z},
            this.service.getRoomPolygon()
        );
    }

    protected checkMoveInRoomVertical(point: Vector3): boolean {
        let roomBox: Box3;

        roomBox = this.service.getRoomBox();

        return point.y >= roomBox.min.y && point.y <= roomBox.max.y;
    }

    public trySetDefaultPosition() {
        let isMove: boolean;

        this.setDefaultPosition();
        if (this.trySetStickWallPosition()) {    // Проверка постановки у стены
            return this.afterTryMove(true, true);
        }
        // Пытаемся поставить в центр
        this.wall = undefined;
        this.view3d.rotation.set(0, 0, 0);
        this.cover.rotation.set(0, 0, 0);
        isMove = this.trySetPosition({position: this.view3d.position}, true);

        return this.afterTryMove(isMove, true);
    }

    public trySetDefaultPositionEdit() {
        this.setDefaultPosition();
    }

    protected setDefaultPosition() {
        this.view3d.position.set(
            this.defaultXPosition(),
            this.defaultYPosition(),
            this.defaultZPosition()
        );
        this.cover.position.copy(this.view3d.position);
        this.updateAllMatrices();
    }

    protected defaultXPosition(): number {
        return 0;
    }

    public defaultYPosition(): number {
        return 0;
    }

    protected defaultZPosition(): number {
        return 0;
    }

    protected isSelfWall(wall: ThreeWall): boolean {
        return false;
    }

    protected trySetStickWallPosition(): boolean {
        let index: number;
        let pointA: TPoint2D, pointB: TPoint2D;
        let wall: ThreeWall, offsetPoints: TLine;
        let ratio: number, length: number, position2d: TPoint2D;
        let walls: ThreeWall[];
        let oldWall: ThreeWall | undefined;

        oldWall = this.wall;
        walls = this.service.getWalls();
        this.service.oldPosition.copy(this.cover.position);
        this.service.checkPosition.copy(this.cover.position);
        this.service.oldRotation.copy(this.cover.rotation);
        this.sortWallsByCameraPosition(walls);
        for (wall of walls) {
            if (this.isSelfWall(wall)) {
                continue;
            }
            this.wall = wall;
            this.cover.rotation.y = this.getRotationByWall(this.wall);
            offsetPoints = MathHelper.getParallelLinePoints(
                {x: wall.pointA.value.x, y: wall.pointA.value.z},
                {x: wall.pointB.value.x, y: wall.pointB.value.z},
                -this.getSideDistance(SIDE_TYPE_BACK, this.getBackWallGap())
            );
            if (!wall.getDirection()) {
                pointA = offsetPoints.pointB;
                pointB = offsetPoints.pointA;
            } else {
                pointA = offsetPoints.pointA;
                pointB = offsetPoints.pointB;
            }
            length = MathHelper.getLength2D(pointA, pointB);
            for (index = 1; index <= length / DEFAULT_ADD_STEP; index++) {
                ratio = (DEFAULT_ADD_STEP * index) / length;
                position2d = MathHelper.getPointByRatio2D(pointA, pointB, ratio);
                this.service.checkPosition.x = position2d.x;
                this.service.checkPosition.z = position2d.y;
                if (this.trySetPosition({position: this.service.checkPosition}, true)) {
                    this.cover.position.copy(this.view3d.position);
                    this.updateRotation();
                    this.updateAllMatrices();

                    return true;
                }
            }
        }
        this.cover.rotation.copy(this.service.oldRotation);
        this.cover.position.copy(this.service.oldPosition);
        this.wall = oldWall;
        this.updateRotation();
        this.updateAllMatrices();

        return false;
    }

    public trySetPosition(moveData: TDeltaMovePosition, isFinal?: boolean, isStick?: boolean): boolean {
        let result: boolean;

        if (this.cover.position.equals(moveData.position) && !isFinal) {
            return true;
        }
        this.cover.position.copy(moveData.position);
        //Проверяем не вышел ли объект за пределы комнаты
        if (this.checkMoveInRoom()) {
            this.moveObjectInRoom(isStick, moveData.wall);
        } else {
            this.moveObjectOutRoom(moveData.wall);
        }
        //Если не пересекается с другими объектами на сцене и входит в габариты кухни
        if (this.checkMoveInRoom()) {
            if (this.checkIntersects()) {
                this.updateRotation();
                result = this.applyStickPosition(isFinal, isStick);
            } else {
                result = this.applyStickPosition(isFinal, isStick);
                if (!result) {
                    this.setOldCorrectPositionAndRotation(isFinal);
                }
            }
        } else {
            // Иначе пытаемся поставить объект в последнюю корректную позицию
            this.setOldCorrectPositionAndRotation(isFinal);
            result = false;
        }

        if (isNaN(this.cover.position.x) || isNaN(this.cover.position.y) || isNaN(this.cover.position.z)) {
            throw new Error('error-ThreeUnit-trySetPosition');
        }
        if (result) {
            if (this.cover.userData.correctRotation &&
                this.cover.userData.correctRotation.isEuler) {
                this.cover.userData.correctRotation.copy(this.cover.rotation);
            } else {
                this.cover.userData.correctRotation = this.cover.rotation.clone();
            }
        }

        return result;
    }

    protected sortWallsByCameraPosition(walls: ThreeWall[]): ThreeWall[] {
        let cameraPosition: Vector3;

        cameraPosition = this.service.getCameraPosition(this.view3d.userData.cameraPosition);
        cameraPosition.sub(this.service.getOrbitControlTarget(this.view3d.userData.orbitControlTarget))
        cameraPosition.y = 0;
        return walls.sort((a, b) => {
            let aFrontVector: Vector3 = a.getGlobalFrontVector();
            let bFrontVector: Vector3 = b.getGlobalFrontVector();
            let aCoDirection: boolean = MathHelper.isCoDirectionVectors(
                {x: aFrontVector.x, y: aFrontVector.z},
                {x: cameraPosition.x, y: cameraPosition.z}
            )
            let bCoDirection: boolean = MathHelper.isCoDirectionVectors(
                {x: bFrontVector.x, y: bFrontVector.z},
                {x: cameraPosition.x, y: cameraPosition.z}
            )
            if (!aCoDirection && !bCoDirection) {
                return bFrontVector.angleTo(cameraPosition) - aFrontVector.angleTo(cameraPosition)
            } else if (!aCoDirection) {
                return -1;
            } else if (!bCoDirection) {
                return 1;
            }
            return 0;
        });
    }

    protected setOldCorrectPositionAndRotation(isFinal?: boolean): void {
        let correctPosition: Vector3 | undefined;
        let correctRotation: Euler | undefined;

        if (this.cover.userData.correctPosition && this.cover.userData.correctPosition.isVector3) {
            correctPosition = this.cover.userData.correctPosition;
        } else if (this.cover.userData.oldPosition && this.cover.userData.oldPosition.isVector3) {
            correctPosition = this.cover.userData.oldPosition;
        }
        if (this.cover.userData.correctRotation && this.cover.userData.correctRotation.isEuler) {
            correctRotation = this.cover.userData.correctRotation;
        } else if (this.cover.userData.oldRotation && this.cover.userData.oldRotation.isEuler) {
            correctRotation = this.cover.userData.oldRotation;
        }
        (this.cover.material as MeshBasicMaterial).color.set(this.service.getErrorColor());
        if (this.cover.userData.carcass) {
            this.cover.userData.carcass.material.color.set(this.service.getErrorColor());
        }
        (this.selectCover.material as MeshBasicMaterial).color.set(this.getSelectColor());
        if (this.selectCover.userData.carcass) {
            this.selectCover.userData.carcass.material.color.set(this.getSelectColor());
        }
        if (correctPosition) {
            this.view3d.position.copy(correctPosition);
            if (isFinal === true) {
                this.cover.position.copy(correctPosition);
            }
        } else {
            this.cover.position.copy(this.view3d.position);
        }
        if (correctRotation) {
            this.view3d.rotation.copy(correctRotation);
            if (isFinal === true) {
                this.cover.rotation.copy(correctRotation);
            }
        } else {
            this.cover.rotation.copy(this.view3d.rotation);
        }
        this.afterSetOldCorrectPosition();
    }

    protected afterSetOldCorrectPosition() {
        if (this.wall) {
            this.updateNearWall();
        }
        this.view3d.updateMatrix();
        this.view3d.updateMatrixWorld();
        this.cover.updateMatrixWorld();
    }

    public updateNearWall() {
        if (!this.wall) {
            return;
        }
        let offsetPoints: TWallOffsetPoints;
        let position2d: TPoint2D;
        let point: TPoint2D;

        offsetPoints = this.getAvailableOffsetPoints(this.wall);
        position2d = {x: this.cover.position.x, y: this.cover.position.z};
        point = MathHelper.projectionPoint2D(
            position2d,
            offsetPoints.unit.pointA,
            offsetPoints.unit.pointB
        );
        // Если стоит не у стены, то убираем информацию, что объект у стены
        if (MathHelper.getLength2D(position2d, point) > DEFAULT_NEAR_ROUNDING) {
            this.wall = undefined;
        }
    }

    protected applyStickPosition(isFinal?: boolean, isStick?: boolean): boolean {
        let itemStickPositions: TStickPositions;
        let stickPositions: TStickPositions = {};
        let stickPositionsArray: TStickPosition[];
        let stickPositionData: TStickPosition;
        let stickPosition: TPoint3D;
        let stickAnglePositions: TPoint3D[];
        let isMove: boolean;
        let stickId: string;

        isMove = isStick !== false ? true : this.checkIntersects();
        // Применяем цвет перемещения
        this.applyMoveColor(this.service.getMoveColor());
        this.service.correctPosition.copy(this.view3d.position);
        this.view3d.position.copy(this.cover.position);
        this.view3d.updateMatrix();
        this.view3d.updateMatrixWorld();
        this.service.coverPosition.copy(this.cover.position);
        // Если прилипание к соседям включено
        if (this.getIsStick() && isStick !== false) {
            isMove = this.checkIntersects();
            // Проверяем есть ли рядом объекты к которым стоит "прилепиться"
            itemStickPositions = this.findStickNearObjectPosition();
            // Если нашли позиции "прилипания" к объекту,
            // то добавляем в список доступных прилипаний
            for (stickId in itemStickPositions) {
                if (!stickPositions[stickId]) {
                    stickPositions[stickId] = itemStickPositions[stickId];
                }
            }
            // Проверяем есть ли рядом углы, и прилипаем к ним
            stickAnglePositions = this.getStickNearAngle();
            // Если нашли позиции "прилипания" к объекту,
            // то сдвигаем сам объект, а cover двигается по своей траектории
            if (stickAnglePositions.length > 0) {
                for (stickPosition of stickAnglePositions) {
                    stickId = stickPosition.x + '_' + stickPosition.y + '_' + stickPosition.z;
                    if (!stickPositions[stickId]) {
                        this.service.stickPosition.set(stickPosition.x, stickPosition.y, stickPosition.z)
                        stickPositions[stickId] = {
                            position: stickPosition,
                            length: this.service.coverPosition.distanceTo(this.service.stickPosition)
                        };
                    }
                }
            }
            if (this.getIsLevelStick()) {
                itemStickPositions = this.findStickParallelObjectPosition();
                for (stickId in itemStickPositions) {
                    if (!stickPositions[stickId]) {
                        stickPositions[stickId] = itemStickPositions[stickId];
                    }
                }
            }
            if (Object.keys(stickPositions).length > 0) {
                stickPositionsArray = Object.values(stickPositions).sort((a, b) => {
                    return a.length - b.length;
                });
                for (stickPositionData of stickPositionsArray) {
                    isMove = this.trySetStickPosition(stickPositionData.position);
                    if (isMove) {
                        break;
                    }
                }
            }
        }
        if (isMove) {
            this.service.correctPosition.copy(this.view3d.position);
        }
        this.cover.userData.correctPosition = this.service.correctPosition;
        if (isFinal === true) {
            this.cover.position.copy(this.view3d.position);
        }
        this.cover.updateMatrixWorld();

        return isMove;
    }

    protected getStickNearAngle(): TPoint3D[] {
        let stickPositions: TPoint3D[] = [];
        let anglePosition: TAnglePosition | undefined;

        if (this.wall) {
            anglePosition = this.getAnglePosition(DEFAULT_HORIZONTAL_LINE_STICK);
            // Прилипание к углу в приоритете левая стенка
            if (anglePosition.rightPosition) {
                stickPositions.push(anglePosition.rightPosition);
            }
            if (anglePosition.leftPosition) {
                stickPositions.push(anglePosition.leftPosition);
            }
        }

        return stickPositions;
    }

    protected trySetStickPosition(position: TPoint3D): boolean {
        let isMove: boolean;
        let coverPoints: ICoverPoints;

        this.service.oldPosition.copy(this.view3d.position);
        this.view3d.position.set(position.x, position.y, position.z);
        this.view3d.updateMatrix();
        this.view3d.updateMatrixWorld();
        this.selectCover.updateMatrix();
        coverPoints = this.getGlobalPoints(this.selectCover, COVER_CORRECTION_SIZE);
        if (this.checkIntersects(coverPoints) && this.checkMoveInRoom(this.selectCover, COVER_CORRECTION_SIZE)) {
            (this.selectCover.material as MeshBasicMaterial).color.set(this.getSelectColor());
            if (this.selectCover.userData.carcass) {
                this.selectCover.userData.carcass.material.color.set(this.getSelectColor());
            }
            if (this.cover.userData.correctPosition &&
                this.cover.userData.correctPosition.isVector3) {
                this.cover.userData.correctPosition.copy(this.view3d.position);
            } else {
                this.cover.userData.correctPosition = this.view3d.position.clone();
            }
            isMove = true;
        } else {
            this.view3d.position.copy(this.service.oldPosition);
            isMove = false;
        }

        return isMove;
    }

    protected findStickParallelObjectPosition(): TStickPositions {
        let index: string;
        let covers: { [n: string]: Object3D };
        let findStickPositions: TPoint3D[];
        let findStickPosition: TPoint3D;
        let stickPositions: TStickPositions = {};
        let stickId: string;
        let compareCover: Object3D;
        let commonObject: CommonObject;

        covers = this.service.getEditorCovers();
        this.service.coverPosition.copy(this.cover.position);
        for (index in covers) {
            if (!covers.hasOwnProperty(index)) {
                continue;
            }
            compareCover = covers[index];
            if (!(compareCover.userData.commonObject instanceof CommonObject)) {
                continue;
            }
            commonObject = compareCover.userData.commonObject;
            if (compareCover.uuid === this.cover.uuid) {
                continue;
            }
            findStickPositions = this.compareObjectsAndGetParallelPositions(commonObject);
            for (findStickPosition of findStickPositions) {
                stickId = findStickPosition.x + '_' + findStickPosition.y + '_' + findStickPosition.z;
                if (!stickPositions[stickId]) {
                    this.service.stickPosition.set(findStickPosition.x, findStickPosition.y, findStickPosition.z)
                    stickPositions[stickId] = {
                        position: findStickPosition,
                        length: this.service.coverPosition.distanceTo(this.service.stickPosition)
                    };
                }
            }
        }

        return stickPositions;
    }

    protected findStickNearObjectPosition(): TStickPositions {
        let index: string;
        let covers: { [n: string]: Object3D };
        let itemStickPositions: TPoint3D[];
        let itemStickPosition: TPoint3D;
        let stickPositions: TStickPositions = {};
        let stickId: string;
        let compareCover: Object3D;
        let commonObject: CommonObject;

        covers = this.service.getEditorCovers();
        this.service.coverPosition.copy(this.cover.position);
        for (index in covers) {
            if (!covers.hasOwnProperty(index)) {
                continue;
            }
            compareCover = covers[index];
            if (!(compareCover.userData.commonObject instanceof CommonObject)) {
                continue;
            }
            commonObject = compareCover.userData.commonObject;
            if (compareCover.uuid === this.cover.uuid) {
                continue;
            }
            itemStickPositions = this.compareObjectsAndGetPositions(commonObject);
            for (itemStickPosition of itemStickPositions) {
                stickId = itemStickPosition.x + '_' + itemStickPosition.y + '_' + itemStickPosition.z;
                if (!stickPositions[stickId]) {
                    this.service.stickPosition.set(itemStickPosition.x, itemStickPosition.y, itemStickPosition.z)
                    stickPositions[stickId] = {
                        position: itemStickPosition,
                        length: this.service.coverPosition.distanceTo(this.service.stickPosition)
                    };
                }
            }
        }

        return stickPositions;
    }

    protected compareObjectsAndGetParallelPositions(commonObject: CommonObject): TPoint3D[] {
        let stickPositions: TPoint3D[] = [];
        let neighborData: TNeighborSides | undefined;
        let compareObject: ThreeUnit;

        compareObject = commonObject as ThreeUnit;
        neighborData = CommonObjectHelper.getParallelNeighborSides(
            this,
            commonObject,
            DEFAULT_HORIZONTAL_LINE_STICK
        );
        if (neighborData !== undefined) {
            stickPositions = this.calculateHorizontalStickNearObjectPositions(compareObject, [neighborData]);
        }

        return stickPositions;
    }

    protected compareObjectsAndGetPositions(commonObject: CommonObject): TPoint3D[] {
        let stickPositions: TPoint3D[] = [];
        let globalCurrentSphere: Sphere;
        let globalNearSphere: Sphere;
        let neighborData: TNeighborSides[] | undefined;

        globalCurrentSphere = this.getGlobalSphere();
        globalNearSphere = commonObject.getGlobalSphere();
        if (commonObject.hasWalls()) {
            return stickPositions;
        }
        // отсекаем совсем далекие друг от друга объекты
        if (!globalCurrentSphere.intersectsSphere(globalNearSphere)) {
            return stickPositions;
        }
        // Отсекаем тех, кто по вертикали не пересекается
        if (!this.isVerticalIntersect(commonObject)) {
            return stickPositions;
        }
        neighborData = CommonObjectHelper.getHorizontalNeighborSides(
            this,
            commonObject,
            DEFAULT_HORIZONTAL_LINE_STICK
        );
        if (neighborData !== undefined) {
            stickPositions = this.calculateHorizontalStickNearObjectPositions(commonObject, neighborData);
        }

        return stickPositions;
    }

    protected calculateHorizontalStickNearObjectPositions(
        commonObject: CommonObject,
        neighborData: TNeighborSides[]
    ): TPoint3D[] {
        let stickPositions: TPoint3D[] = [];
        let currentGlobalPoints: ICoverMainPoints2D;
        let stickPoints;
        let nearPointA: TPoint2D;
        let nearPointCenter: TPoint2D;
        let nearPointB: TPoint2D;
        let nearPoints: TLine;

        currentGlobalPoints = CommonObjectHelper.convertMainPointsTo2D(this.getGlobalMainPoints(this.selectCover));
        stickPoints = this.getStickPointsByNeighbor(currentGlobalPoints, neighborData[0], commonObject);
        nearPointA = MathHelper.getNearPoint2D(
            stickPoints.main.pointA,
            [stickPoints.neighbor.pointA, stickPoints.neighbor.pointB]
        );
        nearPointB = MathHelper.getNearPoint2D(
            stickPoints.main.pointB,
            [stickPoints.neighbor.pointA, stickPoints.neighbor.pointB]
        );
        nearPointCenter = stickPoints.neighborCenter;
        nearPoints = {
            pointA: MathHelper.getNearPoint2D(stickPoints.main.pointA, [nearPointA, nearPointCenter, nearPointB]),
            pointB: MathHelper.getNearPoint2D(stickPoints.main.pointB, [nearPointA, nearPointCenter, nearPointB])
        }
        this.addNearHorizontalPositionToStickPositions(
            stickPositions,
            nearPoints.pointA.x - (stickPoints.main.pointA.x + stickPoints.main.pointB.x) / 2,
            nearPoints.pointA.y - (stickPoints.main.pointA.y + stickPoints.main.pointB.y) / 2
        );
        this.addNearHorizontalPositionToStickPositions(
            stickPositions,
            nearPoints.pointA.x - stickPoints.main.pointA.x,
            nearPoints.pointA.y - stickPoints.main.pointA.y
        );
        this.addNearHorizontalPositionToStickPositions(
            stickPositions,
            nearPoints.pointB.x - (stickPoints.main.pointA.x + stickPoints.main.pointB.x) / 2,
            nearPoints.pointB.y - (stickPoints.main.pointA.y + stickPoints.main.pointB.y) / 2
        );
        this.addNearHorizontalPositionToStickPositions(
            stickPositions,
            nearPoints.pointB.x - stickPoints.main.pointB.x,
            nearPoints.pointB.y - stickPoints.main.pointB.y
        );

        return stickPositions;
    }

    protected addNearHorizontalPositionToStickPositions(stickPositions: TPoint3D[], x: number, z: number) {
        stickPositions.push({x: this.view3d.position.x + x, y: this.view3d.position.y, z: this.view3d.position.z + z});
    }

    protected getStickPointsByNeighbor(
        currentGlobalPoints: ICoverMainPoints2D,
        neighborData: TNeighborSides,
        commonObject: CommonObject): TStickPointsByNeighbor {
        let currentPoints: TLine;
        let projectionPoints: TLine;
        let comparePoints: TLine;
        let stickPoints: TStickPointsByNeighbor;
        let compareMainPoints: ICoverMainPoints2D;

        currentPoints = {
            pointA: currentGlobalPoints[neighborData.mainSide].pointA,
            pointB: currentGlobalPoints[neighborData.mainSide].pointB
        };
        compareMainPoints = CommonObjectHelper.convertMainPointsTo2D(commonObject.getGlobalMainPoints(commonObject.selectCover));
        comparePoints = {
            pointA: compareMainPoints[neighborData.neighborSide].pointA,
            pointB: compareMainPoints[neighborData.neighborSide].pointB
        };
        projectionPoints = this.getStickProjectionPoints(comparePoints, currentPoints);

        stickPoints = {
            stick: projectionPoints,
            main: currentPoints,
            neighbor: comparePoints,
            mainCenter: {
                x: (currentPoints.pointA.x + currentPoints.pointB.x) / 2,
                y: (currentPoints.pointA.y + currentPoints.pointB.y) / 2
            },
            neighborCenter: {
                x: (comparePoints.pointA.x + comparePoints.pointB.x) / 2,
                y: (comparePoints.pointA.y + comparePoints.pointB.y) / 2
            }
        };

        return stickPoints;
    }

    protected getStickProjectionPoints(comparePoints: TLine, currentPoints: TLine): TLine {
        return {
            pointA: MathHelper.projectionPoint2D(
                currentPoints.pointA,
                comparePoints.pointA,
                comparePoints.pointB
            ),
            pointB: MathHelper.projectionPoint2D(
                currentPoints.pointB,
                comparePoints.pointA,
                comparePoints.pointB
            ),
        };
    }

    protected isVerticalIntersect(compareObject: CommonObject): boolean {
        let globalPoints1: ICoverPoints, globalPoints2: ICoverPoints;

        globalPoints1 = this.getGlobalPoints();
        globalPoints2 = compareObject.getGlobalPoints();

        return !(
            globalPoints1.box.max.y < globalPoints2.box.min.y ||
            globalPoints2.box.max.y < globalPoints1.box.min.y
        );
    }

    public checkIntersects(coverPoints?: ICoverPoints): boolean {
        let index: string;
        let covers: { [n: string]: Object3D };

        if (!coverPoints) {
            coverPoints = this.getGlobalPoints();
        }
        covers = this.service.getEditorCovers();
        if (!this.getIsDimensions() && !this.getIsDimensionsSameClassName()) {
            return true;
        }
        for (index in covers) {
            if (!covers.hasOwnProperty(index)) {
                continue;
            }
            if (this.isIntersectCover(covers[index], coverPoints)) {
                return false;
            }
        }

        return true;
    }

    protected isIntersectCover(checkCover: Object3D, coverGlobalPoints: ICoverPoints): boolean {
        let compareCommonObject: CommonObject;
        let compareCoverPoints: ICoverPoints;
        let index: string;
        let index2: number;
        let pointA: TPoint2D;
        let pointB: TPoint2D;

        if (checkCover.uuid === this.cover.uuid ||
            !(checkCover.userData.commonObject instanceof CommonObject) ||
            !(checkCover.userData.commonObject.getIsDimensions() && !checkCover.userData.commonObject.getIsDimensionsSameClassName())) {
            return false;
        }
        if (checkCover.userData.commonObject === undefined ||
            !checkCover.userData.commonObject) {
            return false;
        }
        compareCommonObject = checkCover.userData.commonObject;
        if (!('getClassName' in compareCommonObject) ||
            !('getGlobalPoints' in compareCommonObject)) {
            return false;
        }
        if (!this.getIsDimensions() &&
            this.getIsDimensionsSameClassName() &&
            this.getClassName() !== compareCommonObject.getClassName()) {
            return false;
        }
        compareCoverPoints = compareCommonObject.getGlobalPoints();
        if (coverGlobalPoints.box.max.y >= compareCoverPoints.box.min.y &&
            compareCoverPoints.box.max.y >= coverGlobalPoints.box.min.y) {
            if (MathHelper.isPointInsidePolygon({
                    x: this.cover.position.x,
                    y: this.cover.position.z
                }, compareCoverPoints.polygon) ||
                MathHelper.isPointInsidePolygon({
                    x: checkCover.position.x,
                    y: checkCover.position.z
                }, coverGlobalPoints.polygon)) {
                return true;
            }
            for (index in coverGlobalPoints.polygon) {
                if (coverGlobalPoints.polygon.hasOwnProperty(index) &&
                    MathHelper.isPointInsidePolygon(coverGlobalPoints.polygon[index], compareCoverPoints.polygon)) {
                    return true;
                }
            }
            for (index2 = 0; index2 < coverGlobalPoints.polygon.length; index2++) {
                pointA = coverGlobalPoints.polygon[index2];
                pointB = coverGlobalPoints.polygon[(+index2 + 1) % coverGlobalPoints.polygon.length];
                if (MathHelper.isLineIntersectPolygon(pointA, pointB, compareCoverPoints.polygon)) {
                    return true;
                }
            }
        }

        return false;
    }

    protected moveObjectOutRoom(wall?: ThreeWall) {
        if (wall) {
            this.trySetWall(wall);
        } else if (!this.wall) {
            this.trySetWall(this.findNearWall().wall);
        } else {
            let roomIntersects = this.service.getEditor().getRayCasterIntersection(this.service.getRoom().getIntersectWalls())
            if (roomIntersects.length > 0 &&
                roomIntersects[0].object.userData.wall &&
                roomIntersects[0].object.userData.wall.getId() !== this.wall.getId()) {
                this.wall = roomIntersects[0].object.userData.wall;
                this.updateRotation();
            }
        }
        this.updateAllMatrices();
        // Пытаемся поставить объект в пределы комнаты с привязкой к стене
        this.trySetPositionInRoom();
        // Обнуляем кэш расчета точек у стен и пробуем 2 раз
        if (!this.checkMoveInRoom()) {
            this.wallOffsetPoints = {};
            this.trySetPositionInRoom()
        }
    }

    protected trySetPositionInRoom(): boolean {
        let nearWallPosition: TNearWallPosition | undefined;

        nearWallPosition = this.findNearWallPosition();
        if (nearWallPosition && nearWallPosition.point) {
            this.cover.position.x = nearWallPosition.point.x;
            this.cover.position.z = nearWallPosition.point.y;
            this.cover.updateMatrixWorld();
            return true;
        }

        return false;
    }

    protected findNearWall(): TNearWallPosition {
        let walls: ThreeWall[];
        let wall: ThreeWall;
        let position2d: TPoint2D;
        let currentOutPosition: TNearWallPosition;
        let outPosition: TNearWallPosition | undefined;

        position2d = {x: this.cover.position.x, y: this.cover.position.z};
        walls = this.service.getWalls();
        for (wall of walls) {
            if (this.isSelfWall(wall)) {
                continue;
            }
            if (!outPosition) {
                outPosition = this.getNearPositionByWall(wall, position2d);
                continue;
            }
            currentOutPosition = this.getNearPositionByWall(wall, position2d);
            if (currentOutPosition.length < outPosition.length) {
                outPosition = currentOutPosition;
            }
        }
        if (!outPosition) {
            throw new Error("error-ThreeUnit-findNearWall");
        }

        return outPosition;
    }

    protected moveObjectInRoom(isStick?: boolean, wall?: ThreeWall) {
        this.trySetWall(wall);
        this.tryStickToWall(isStick);
    }

    protected trySetWall(wall?: ThreeWall, isNew?: boolean) {
        this.setWall(wall, isNew);
        if (this.wall) {
            this.updateRotation();
        }

        return this.wall;
    }

    protected tryStickToWall(isStick?: boolean) {
        let nearWallPosition;

        if (isStick === false || !this.wall) {
            return;
        }
        nearWallPosition = this.findNearWallPosition();
        if (nearWallPosition && this.getIsWallStick()) {
            this.cover.position.x = nearWallPosition.point.x;
            this.cover.position.z = nearWallPosition.point.y;
            this.cover.updateMatrixWorld();
        }
    }

    protected findNearWallPosition(): TNearWallPosition | undefined {
        let position2d;
        let nearWallPosition: TNearWallPosition | undefined;

        position2d = {x: this.cover.position.x, y: this.cover.position.z};
        if (this.wall) {
            nearWallPosition = this.getNearPositionByWall(this.wall, position2d);
        }

        return nearWallPosition;
    }

    protected getNearPositionByWall(wall: ThreeWall, position2d: TPoint2D): TNearWallPosition {
        let offsetPoints: TWallOffsetPoints;
        let point: TPoint2D;
        let length: number;
        let nearWallPosition: TNearWallPosition;

        offsetPoints = this.getAvailableOffsetPoints(wall);
        if (MathHelper.isEqualPoints2D(offsetPoints.unit.pointA, offsetPoints.unit.pointB)) {
            point = offsetPoints.unit.pointA;
        } else {
            point = MathHelper.projectionPoint2D(position2d, offsetPoints.unit.pointA, offsetPoints.unit.pointB);
            if (!MathHelper.isPointInLine(point, {
                pointA: offsetPoints.unit.pointA,
                pointB: offsetPoints.unit.pointB
            }, true)) {
                point = MathHelper.getNearPoint2D(point, [offsetPoints.unit.pointA, offsetPoints.unit.pointB]);
            }
        }
        length = MathHelper.getLength2D(position2d, point);
        nearWallPosition = {
            wall: wall,
            point: point,
            length: length,
        };

        return nearWallPosition;
    }

    protected updateRotation() {
        this.view3d.rotation.y = this.cover.rotation.y = this.getRotationByWall(
            this.wall
        );
        this.view3d.updateMatrixWorld();
        this.cover.updateMatrixWorld();
    }

    protected getRotationByWall(wall: ThreeWall | undefined): number {
        let rotation: number;
        let correctRotation: Euler | undefined;

        if (this.cover.userData.correctRotation && this.cover.userData.correctRotation.isEuler) {
            correctRotation = this.cover.userData.correctRotation;
        } else if (this.cover.userData.oldRotation && this.cover.userData.oldRotation.isEuler) {
            correctRotation = this.cover.userData.oldRotation;
        }
        rotation = correctRotation ? (correctRotation.y - this.getSelfVerticalRotation()) :
            (this.positionInfo.rotation.y !== undefined ? this.positionInfo.rotation.y : 0);

        if (wall) {
            rotation = wall.getFrontVectorAngle();
        }
        rotation += this.getSelfVerticalRotation();

        return rotation;
    }

    public setWall(wall?: ThreeWall, isNew?: boolean) {
        let stickWall: ThreeWall | undefined;

        if (wall) {
            stickWall = wall;
        } else {
            stickWall = this.getStickWall(isNew);
        }
        this.wall = stickWall;

        return this.wall;
    }

    protected getStickWall(isNew?: boolean): ThreeWall | undefined {
        let index1;
        let walls: ThreeWall[];
        let stickWalls: { [n: number]: ThreeWall } = {};
        let stickWall: ThreeWall;
        let currentWall: ThreeWall | undefined;
        let coverPoint: TPoint2D;

        walls = this.service.getWalls();
        for (stickWall of walls) {
            if (this.isSelfWall(stickWall)) {
                continue;
            }
            for (coverPoint of this.getGlobalPoints().polygon) {
                if (MathHelper.isPointInsidePolygon(coverPoint, stickWall.getStickPolygon())) {
                    stickWalls[stickWall.getId()] = stickWall;
                }
            }
        }
        for (index1 in stickWalls) {
            if (stickWalls.hasOwnProperty(index1)) {
                stickWall = stickWalls[index1];
                if (((this.wall === undefined && isNew !== true) ||
                        (this.wall && this.wall.isReady() && stickWall.getId() === this.wall.getId())) ||
                    (isNew === true &&
                        +this.getRealVerticalRotation().toFixed(10) ===
                        -stickWall.getFrontVectorAngle().toFixed(10))) {
                    currentWall = stickWall;
                    break;
                }
            }
        }

        return currentWall;
    }

    protected tryCalculateDeltaMovePosition(startVectors: TStartVectors): TDeltaMovePosition | undefined {
        let movePosition: TDeltaMovePosition | undefined;
        let intersects: TPlanesIntersections;

        intersects = this.service.getIntersectionPlanes();
        movePosition = this.tryCalculateIntersectsDeltaMovePosition(startVectors, intersects);
        if (movePosition) {
            this.correctMovePosition(movePosition.position);
        }

        return movePosition;
    }

    protected correctMovePosition(movePosition: Vector3) {

    }

    protected correctTopUnitMovePosition(movePosition: Vector3) {
        let lines: number[];
        let line: number;
        let itemBottomDelta: number;
        let itemTopDelta: number;
        let itemDelta: number;
        let itemType: TSideType;
        let selectLine: {line: number | undefined, type: TSideType | undefined, delta: number};

        if (movePosition.y > this.service.getRoom().getHeight() - this.getSideDistance(SIDE_TYPE_TOP)) {
            movePosition.y = this.service.getRoom().getHeight() - this.getSideDistance(SIDE_TYPE_TOP);
        }
        if (movePosition.y < this.getSideDistance(SIDE_TYPE_BOTTOM)) {
            movePosition.y = this.getSideDistance(SIDE_TYPE_BOTTOM);
        }
        if (!this.getIsStick()) {
            return;
        }
        lines = this.getMoveLines();
        selectLine = {
            type: undefined,
            line: undefined,
            delta: Infinity
        };
        for (line of lines) {
            itemBottomDelta = Math.abs(movePosition.y - this.getSideDistance(SIDE_TYPE_BOTTOM) - line);
            itemTopDelta = Math.abs(movePosition.y + this.getSideDistance(SIDE_TYPE_TOP) - line);
            itemType = (itemTopDelta < itemBottomDelta) ? SIDE_TYPE_TOP : SIDE_TYPE_BOTTOM;
            switch (itemType) {
                case SIDE_TYPE_TOP:
                    itemDelta = itemTopDelta;
                    if ((line - this.getHeight()) < 0) {
                        itemDelta = Infinity;
                    }
                    break;
                case SIDE_TYPE_BOTTOM:
                    itemDelta = itemBottomDelta;
                    if ((line + this.getHeight()) > this.service.getRoom().getHeight()) {
                        itemDelta = Infinity;
                    }
                    break;
            }
            if (itemDelta > DEFAULT_VERTICAL_LINE_STICK) {
                continue;
            }
            if (selectLine.delta > itemDelta) {
                selectLine.delta = itemDelta;
                selectLine.line = line;
                selectLine.type = itemType;
            }
        }
        if (isFinite(selectLine.delta) && selectLine.delta !== undefined && selectLine.line !== undefined) {
            switch (selectLine.type) {
                case SIDE_TYPE_TOP:
                    movePosition.y = (selectLine.line - this.getSideDistance(SIDE_TYPE_TOP));
                    break;
                case SIDE_TYPE_BOTTOM:
                    movePosition.y = (selectLine.line + this.getSideDistance(SIDE_TYPE_BOTTOM));
                    break;
            }
        }
    }

    protected correctFloorUnitMovePosition(movePosition: Vector3) {
        if (movePosition.y !== this.defaultYPosition()) {
            movePosition.y = this.defaultYPosition();
        }
    }

    protected getIntersectsTypes(): TPlaneType[] {
        return [
            PLANE_TYPE_WALL,
            PLANE_TYPE_FLOOR,
            PLANE_TYPE_HORIZONTAL,
            PLANE_TYPE_VERTICAL
        ];
    }

    protected getStartVectorByType(startVectors: TStartVectors, planeType: TPlaneType): Vector3 | undefined {
        if (startVectors['cover']) {
            return startVectors['cover'];
        }
        if (startVectors[planeType]) {
            return startVectors[planeType];
        }
        if (planeType === PLANE_TYPE_WALL && startVectors[PLANE_TYPE_HORIZONTAL]) {
            return startVectors[PLANE_TYPE_HORIZONTAL];
        }

        return undefined;
    }

    protected tryCalculateIntersectsDeltaMovePosition(
        startVectors: TStartVectors,
        intersects: TPlanesIntersections
    ): TDeltaMovePosition | undefined {
        let movePosition: Vector3 | undefined;
        let planeTypes: TPlaneType[];
        let planeType: TPlaneType;
        let startVector: Vector3 | undefined;

        planeTypes = this.getIntersectsTypes();
        for (planeType of planeTypes) {
            startVector = this.getStartVectorByType(startVectors, planeType);
            if (startVector && intersects[planeType]) {
                movePosition = this.calculateDeltaMovePosition(
                    startVector,
                    intersects[planeType],
                    planeType
                );
                if (movePosition) {
                    return {
                        position: movePosition,
                        wall: intersects[planeType].object.userData.wall || undefined
                    }
                }
            }
        }

        return undefined;
    }

    protected calculateDeltaMovePosition(
        startVector: Vector3,
        intersect: Intersection,
        moveType: TPlaneType
    ): Vector3 | undefined {
        let delta: Vector3 | undefined;
        let movePosition: boolean = false;
        let oldPosition: boolean = false;
        let alpha: number;
        let deltaPosition: TPoint2D;
        let points: TInterval;
        let pointA: TPoint2D;
        let pointB: TPoint2D;
        let projectPoint: TPoint2D;

        if (this.cover.userData.oldPosition && this.cover.userData.oldPosition.isVector3) {
            this.startPosition.copy(this.cover.userData.oldPosition)
            oldPosition = true;
        }
        if (!oldPosition && this.cover.userData.correctPosition !== undefined &&
                this.cover.userData.correctPosition.isVector3) {
            this.startPosition.copy(this.cover.userData.correctPosition);
            if (!this.cover.userData.oldPosition) {
                this.cover.userData.oldPosition = this.cover.userData.correctPosition;
            }
            oldPosition = true;
        }
        if (!oldPosition) {
            return undefined;
        }
        this.startVector.copy(startVector);
        alpha = this.startPosition.sub(this.service.getCameraPosition(this.view3d.userData.cameraPosition)).length() /
            this.startVector.copy(startVector).sub(this.service.getCameraPosition(this.view3d.userData.cameraPosition)).length();
        delta = intersect.point.sub(startVector);
        this.movePosition.copy(this.cover.position);
        switch (moveType) {
            case PLANE_TYPE_HORIZONTAL:
            case PLANE_TYPE_FLOOR:
            case PLANE_TYPE_ROOF:
                this.movePosition.x = startVector.x + delta.x * alpha;
                this.movePosition.z = startVector.z + delta.z * alpha;
                this.movePosition.y = this.view3d.position.y;
                movePosition = true;
                break;
            case PLANE_TYPE_LEFT_RIGHT:
                deltaPosition = {x: startVector.x + delta.x * alpha, y: startVector.z + delta.z * alpha};
                points = this.getGlobalFrontLine();
                pointA = {x: points.pointA.x, y: points.pointA.z};
                pointB = MathHelper.turnVector2D({
                    x: points.pointB.x - points.pointA.x,
                    y: points.pointB.z - points.pointA.z
                }, Math.PI / 2, {x: points.pointA.x, y: points.pointA.z});
                projectPoint = MathHelper.projectionPoint2D(deltaPosition, pointA, pointB);
                this.movePosition.x = projectPoint.x;
                this.movePosition.z = projectPoint.y;
                movePosition = true;
                break;
            case PLANE_TYPE_FORWARD_BACK:
                deltaPosition = {x: startVector.x + delta.x * alpha, y: startVector.z + delta.z * alpha};
                points = this.getGlobalFrontLine();
                pointA = {x: points.pointA.x, y: points.pointA.z};
                pointB = {x: points.pointB.x, y: points.pointB.z};
                projectPoint = MathHelper.projectionPoint2D(deltaPosition, pointA, pointB);
                this.movePosition.x = projectPoint.x;
                this.movePosition.z = projectPoint.y;
                movePosition = true;
                break;
            case PLANE_TYPE_VERTICAL:
                this.movePosition.y = startVector.y + delta.y;
                movePosition = true;
                break;
            case PLANE_TYPE_WALL:
                this.movePosition.x = startVector.x + delta.x;
                this.movePosition.z = startVector.z + delta.z;
                this.movePosition.y = startVector.y + delta.y;
                movePosition = true;
                break;
            default:
                movePosition = false;
                break;
        }

        return movePosition ? this.movePosition : undefined;
    }

    protected applyMoveColor(color: ColorRepresentation) {
        (this.cover.material as MeshBasicMaterial).color.set(color);
        this.cover.userData.carcass.material.color.set(color);
        (this.selectCover.material as MeshBasicMaterial).color.set(color);
        if (this.selectCover.userData.carcass && !this.selectCover.userData.carcass.material) {
            (this.selectCover.userData.carcass.material as MeshBasicMaterial).color.set(color);
        }
    }

    private calculateWallAnglePosition(
        point: TPoint2D,
        wall: ThreeWall,
        nearRounding: number,
        distance: number,
        sideType: TSideType
    ): Vector3 | undefined {
        let position: Vector3 | undefined = undefined;
        let nearPoint: TPoint2D;
        let wallPoints: TLine;
        let nearLength: number;
        let anglePosition2D: TPoint2D | undefined;

        wallPoints = wall.getPoints2D();
        nearPoint = MathHelper.projectionPoint2D(
            point,
            wallPoints.pointA,
            wallPoints.pointB
        );
        nearLength = MathHelper.getLength2D(point, nearPoint);
        if (nearLength <= nearRounding &&
            MathHelper.isPointInLine(
                nearPoint,
                wallPoints,
                true
            )) {
            anglePosition2D = this.calculateAnglePosition2D(
                this.cover,
                wall,
                distance,
                sideType
            );
            if (anglePosition2D) {
                position = new Vector3(anglePosition2D.x, this.cover.position.y, anglePosition2D.y);
            }
        }

        return position;
    }

    public getAvailableOffsetPoints(wall: ThreeWall): TWallOffsetPoints {
        let wallPoints: TLine;
        let points: TWallOffsetPoints;
        let wallOffsetPointsId: string;
        let coverBox: Box3;

        wallPoints = wall.getPoints2D();
        coverBox = this.getCoverBox(-1);
        wallOffsetPointsId = CommonHelper.md5({
            id: wall.getUuid(),
            wallPoints: wallPoints,
            rotation: this.cover.rotation.toArray(),
            selfVerticalRotation: this.getSelfVerticalRotation(),
            coverBox: {
                min: coverBox.min.toArray(),
                max: coverBox.max.toArray()
            },
        });
        if (this.wallOffsetPoints[wallOffsetPointsId]) {
            return this.wallOffsetPoints[wallOffsetPointsId];
        }
        points = this.calculateAvailableOffsetPoints(wallPoints);
        if (Object.keys(this.wallOffsetPoints).length > MAX_CACHE_POOL_SIZE) {
            delete this.wallOffsetPoints[Object.keys(this.wallOffsetPoints)[0]];
        }
        this.wallOffsetPoints[wallOffsetPointsId] = points;

        return this.wallOffsetPoints[wallOffsetPointsId];
    }

    protected getProjectPositionLine(projectPosition: TProjectionPosition, position2D: TPoint2D): TLine {
        let line: TLine;

        if (projectPosition.direction > 0) {
            line = {
                pointA: projectPosition.position,
                pointB: position2D,
            };
        } else {
            line = {
                pointA: position2D,
                pointB: projectPosition.position,
            };
        }

        return line;
    }

    protected getPointDirectionByLine(point: TPoint2D, line: TLine): -1 | 1 {
        let direction: number;

        direction = MathHelper.getPointDirectionByLine(point, line);

        // eslint-disable-next-line
        return (direction == 0 || direction > 0) ? 1 : -1;
    }

    protected calculateAvailableOffsetPoints(wallPoints: TLine): TWallOffsetPoints {
        let globalPoints: ICoverPoints;
        let position2D: TPoint2D;
        let point: TPoint2D;
        let projectPoint: TPoint2D;
        let projectPoint2: TPoint2D;
        let projectPointData: TProjectionPosition;
        let projectPosition: TProjectionPosition;
        let offsetPoints: TLine;
        let length: number;
        let itemLength: number;
        let leftLength: number = -Infinity;
        let rightLength: number = -Infinity;
        let backLength: number = Infinity;
        let backPointData: TProjectionPosition | undefined;
        let offsetDistance: number;
        let line: TLine;

        this.cover.updateMatrixWorld();
        globalPoints = this.getGlobalPoints(this.cover, -1);
        position2D = {x: globalPoints.position.x, y: globalPoints.position.z};
        projectPoint = MathHelper.projectionPoint2D(position2D, wallPoints.pointA, wallPoints.pointB);
        projectPosition = {
            position: projectPoint,
            direction: this.getPointDirectionByLine(position2D, wallPoints),
            length: MathHelper.getLength2D(position2D, projectPoint)
        };
        line = this.getProjectPositionLine(projectPosition, position2D);
        for (point of globalPoints.polygon) {
            projectPoint = MathHelper.projectionPoint2D(point, wallPoints.pointA, wallPoints.pointB);
            projectPoint2 = MathHelper.projectionPoint2D(point, line.pointA, line.pointB);
            projectPointData = {
                position: projectPoint,
                direction: this.getPointDirectionByLine(point, wallPoints),
                length: MathHelper.getLength2D(point, projectPoint)
            };
            itemLength = MathHelper.getLength2D(projectPointData.position, projectPosition.position);
            if (this.getPointDirectionByLine(projectPointData.position, line) < 0) {
                if (rightLength < itemLength) {
                    rightLength = itemLength;
                }
            } else {
                if (leftLength < itemLength) {
                    leftLength = itemLength;
                }
            }
            itemLength = projectPointData.length * this.getPointDirectionByLine(projectPoint2, wallPoints);
            if (backLength > itemLength) {
                backLength = itemLength;
                backPointData = projectPointData;
            }
        }
        if (backPointData) {
            offsetDistance = -(Math.abs((backPointData.length * backPointData.direction) - (projectPosition.length * projectPosition.direction)) + this.getBackWallGap());
        } else {
            offsetDistance = -this.getSideDistance(SIDE_TYPE_BACK, this.getBackWallGap());
        }
        offsetPoints = MathHelper.getParallelLinePoints(
            wallPoints.pointA, wallPoints.pointB,
            offsetDistance
        );
        length = MathHelper.getLength2D(offsetPoints.pointA, offsetPoints.pointB);
        if (!isFinite(leftLength)) {
            leftLength = this.getSideDistance(SIDE_TYPE_LEFT);
        }
        if (!isFinite(rightLength)) {
            rightLength = this.getSideDistance(SIDE_TYPE_RIGHT);
        }
        return {
            wall: offsetPoints,
            unit: {
                pointA: MathHelper.getPointByRatio2D(
                    offsetPoints.pointA,
                    offsetPoints.pointB,
                    leftLength / length
                ),
                pointB: MathHelper.getPointByRatio2D(
                    offsetPoints.pointA,
                    offsetPoints.pointB,
                    (length - rightLength) / length
                ),
            },
        };
    }

    protected getBackWallGap(): number {
        return 0;
    }

    protected calculateAnglePosition2D(
        cover: Object3D,
        wall: ThreeWall,
        innerGap: number,
        sideType: TSideType
    ): TPoint2D | undefined {
        let anglePosition: TPoint2D | undefined;
        let offsetPoints: TWallOffsetPoints;
        let intersectPoint: TPoint2D | undefined;
        let length;
        let wallPoint;
        let otherWallPoints: TLine;
        if (!this.wall || this.wall.getId() === wall.getId()) {
            return undefined;
        }
        otherWallPoints = wall.getPoints2D();
        offsetPoints = this.getAvailableOffsetPoints(this.wall);
        if (MathHelper.isEqualPoints2D(offsetPoints.unit.pointA, offsetPoints.unit.pointB)) {
            // TODO когда модуль по ширине равен ширине стены, то А и Б одна точка
            debugger;
        }
        intersectPoint = MathHelper.getIntersectionPoint(
            otherWallPoints,
            offsetPoints.unit
        );
        if (!intersectPoint) {
            return undefined;
        }
        if (sideType === SIDE_TYPE_LEFT && MathHelper.isPointInLine(
            offsetPoints.unit.pointA,
            {pointA: {x: cover.position.x, y: cover.position.z}, pointB: intersectPoint},
            true)) {
            anglePosition = offsetPoints.unit.pointA;
        } else if (sideType === SIDE_TYPE_RIGHT && MathHelper.isPointInLine(
            offsetPoints.unit.pointB,
            {pointA: {x: cover.position.x, y: cover.position.z}, pointB: intersectPoint},
            true)) {
            anglePosition = offsetPoints.unit.pointB;
        }
        if (!anglePosition) {
            if (!MathHelper.isEqualPoints2D(offsetPoints.wall.pointA, intersectPoint) &&
                MathHelper.isPointInLine(
                    {x: cover.position.x, y: cover.position.z},
                    {pointA: offsetPoints.wall.pointA, pointB: intersectPoint},
                    true)) {
                wallPoint = offsetPoints.wall.pointA;
            } else if (!MathHelper.isEqualPoints2D(offsetPoints.wall.pointB, intersectPoint) &&
                MathHelper.isPointInLine(
                    {x: cover.position.x, y: cover.position.z},
                    {pointA: offsetPoints.wall.pointB, pointB: intersectPoint},
                    true)) {
                wallPoint = offsetPoints.wall.pointB;
            } else {
                return undefined;
            }
            length = MathHelper.getLength2D(wallPoint, intersectPoint);
            anglePosition = MathHelper.getPointByRatio2D(intersectPoint, wallPoint, (Math.abs(innerGap)) / length);
        }

        return anglePosition;
    }

    public getSimilarBySizes(): ISaveReplaceData[] | undefined {
        let group: ICreateGroup;
        let createObjectData: ICreateObjectData;
        let newCreateObjectsData: ISaveReplaceData[] = [];
        let priceParams: IModulePriceParams | undefined;

        const unitTypes: TCreateGroup[] = [
            GROUP_BOTTOM_ANGLE_UNITS,
            GROUP_BOTTOM_END_UNITS,
            GROUP_BOTTOM_NORMAL_UNITS,
            GROUP_PENAL_UNITS,
            GROUP_TOP_ANGLE_UNITS,
            GROUP_TOP_END_UNITS,
            GROUP_TOP_NORMAL_UNITS,
        ];
        const equipmentTypes: TCreateGroup[] = [GROUP_EQUIPMENTS];
        const createGroupUnits: ICreateGroup[] | undefined =
            this.service.getCreateGroupUnits([...unitTypes], this.getLevel());
        const createGroupEquipments: ICreateGroup[] | undefined =
            this.service.getCreateGroupUnits([...equipmentTypes], this.getLevel());
        if (createGroupUnits) {
            for (group of createGroupUnits) {
                for (createObjectData of group.items) {
                    if (createObjectData.uid === this.getUid()) {
                        continue;
                    }
                    if (this.isSimilarBySizes(createObjectData, this.isEquipment())) {
                        const newCreateObjectData: any = this.service.getDefaultOptions(createObjectData, this.getWidth(), this.getCorpusSizes().length);
                        priceParams = this.service.calculateCreateObjectPriceParams(createObjectData, newCreateObjectData, this.getWidth());
                        if (!priceParams) {
                            continue;
                        }
                        if (!this.service.checkCalculatePrice(priceParams)) {
                            continue;
                        }
                        newCreateObjectsData.push({
                            objectData: createObjectData,
                            createOptions: newCreateObjectData
                        });
                    }
                }
            }
        }
        if (createGroupEquipments) {
            for (group of createGroupEquipments) {
                for (createObjectData of group.items) {
                    if (createObjectData.uid === this.getUid()) {
                        continue;
                    }
                    if (this.isSimilarBySizes(createObjectData, true) && !createObjectData.builtIn) {
                        const newCreateObjectData: any = this.service.getDefaultOptions(createObjectData, this.getWidth(), this.getCorpusSizes().length);
                        priceParams = this.service.calculateCreateObjectPriceParams(createObjectData, newCreateObjectData, this.getWidth());
                        if (!priceParams) {
                            continue;
                        }
                        if (!this.service.checkCalculatePrice(priceParams)) {
                            continue;
                        }
                        newCreateObjectsData.push({
                            objectData: createObjectData,
                            createOptions: newCreateObjectData
                        });
                    }
                }
            }
        }

        return newCreateObjectsData.length ? newCreateObjectsData : undefined;
    }

    public isEquipment(): boolean {
        return false;
    }

    public isSimilarBySizes(
        createObjectData: ICreateObjectData,
        isEquipment?: boolean
    ): boolean {
        if (isEquipment) {
            return this.isSimilarEquipmentSizes(createObjectData);
        }

        return this.isSimilarUnitSizes(createObjectData);
    }

    public isSimilarEquipmentSizes(createObjectData: ICreateObjectData): boolean {
        return this.isSimilarWidths(createObjectData);
    }

    public isSimilarUnitSizes(createObjectData: ICreateObjectData): boolean {
        return (
            this.isSimilarWidths(createObjectData) &&
            this.isSimilarHeights(createObjectData) &&
            this.isSimilarDepths(createObjectData)
        );
    }

    public isSimilarWidths(createObjectData: ICreateObjectData): boolean {
        const allWidths: ICreateObjectDataWidths =
            DataManager.calculateCreateObjectWidths(createObjectData);
        let similarWidths: string[] | undefined;

        if (allWidths.widths.length) {
            similarWidths = [...allWidths.widths.map((value) => value.id)];
        }
        if (!similarWidths && allWidths.defaultWidth) {
            similarWidths = [allWidths.defaultWidth];
        }
        if (similarWidths) {
            return this.isSimilarSize(
                similarWidths,
                this.getWidth(),
                this.getCorpusSizes().length
            );
        }
        if (allWidths.corpusWidths.length) {
            similarWidths = [...allWidths.corpusWidths.map((value) => value.id)];
        }
        if (!similarWidths && allWidths.defaultCorpusWidth) {
            similarWidths = [allWidths.defaultCorpusWidth];
        }
        if (similarWidths) {
            return this.isSimilarSize(
                similarWidths,
                this.getWidth(),
                this.getCorpusSizes().length
            );
        }

        return false;
    }

    public isSimilarHeights(createObjectData: ICreateObjectData): boolean {
        const allHeights: ICreateObjectDataHeights =
            DataManager.calculateCreateObjectHeights(createObjectData);
        let similarHeights: string[] | undefined;

        if (allHeights.heights.length) {
            similarHeights = [...allHeights.heights];
        }
        if (!similarHeights && allHeights.defaultHeight) {
            similarHeights = [allHeights.defaultHeight];
        }
        if (similarHeights) {
            return this.isSimilarSize(
                similarHeights,
                this.getHeight(),
                this.getCorpusSizes().height
            );
        }
        if (allHeights.corpusHeights.length) {
            similarHeights = [...allHeights.corpusHeights];
        }
        if (!similarHeights && allHeights.defaultCorpusHeight) {
            similarHeights = [allHeights.defaultCorpusHeight];
        }
        if (similarHeights) {
            return this.isSimilarSize(
                similarHeights,
                this.getHeight(),
                this.getCorpusSizes().height
            );
        }

        return false;
    }

    public isSimilarDepths(createObjectData: ICreateObjectData): boolean {
        const allDepths: ICreateObjectDataDepths =
            DataManager.calculateCreateObjectDepths(createObjectData);
        let similarDepths: string[] | undefined;

        if (allDepths.depths.length) {
            similarDepths = [...allDepths.depths];
        }
        if (!similarDepths && allDepths.defaultDepth) {
            similarDepths = [allDepths.defaultDepth];
        }
        if (similarDepths) {
            return this.isSimilarSize(
                similarDepths,
                this.getDepth(),
                this.getCorpusSizes().width
            );
        }
        if (allDepths.corpusDepths.length) {
            similarDepths = [...allDepths.corpusDepths];
        }
        if (!similarDepths && allDepths.defaultCorpusDepth) {
            similarDepths = [allDepths.defaultCorpusDepth];
        }
        if (similarDepths) {
            return this.isSimilarSize(
                similarDepths,
                this.getDepth(),
                this.getCorpusSizes().width
            );
        }

        return false;
    }

    public isSimilarSize(
        sizes: string[] | undefined,
        overallSize: number,
        corpusSize: number
    ): boolean {
        let size: string;

        if (!sizes) {
            return false;
        }

        for (size of sizes) {
            if (+size === overallSize || +size === corpusSize) {
                return true;
            }
        }

        return false;
    }
}

import React from 'react'
import { useFormContext } from 'react-hook-form';
import InputNumber from '../../components/InputNumber';
import { Tumbler } from '../../../../../components/Tumbler/Tumbler';
import { cnCreateForm } from '../../FormModule';
import { IFormInput } from '../../initialValue';


function Sizes() {
  const {  getValues,setValue } = useFormContext<IFormInput>();
  const [enabled, setEnabled] = React.useState<boolean>(!!getValues("heightText") || !!getValues("widthText") || !!getValues("depthText"));

  React.useEffect(() => {
    setValue( "isSizes", enabled);
    // eslint-disable-next-line
  }, [enabled])

  return (
    <>
      <Tumbler
          className={cnCreateForm('Tumbler')}
          checked={enabled}
          callBack={(e) => setEnabled(e.target.checked)}
          label="Указать размеры модуля"
      />
      {enabled && 
        <>
          <InputNumber  min={2} max={2000} label="Ширина модуля" name="widthText" required={false}/>
          <InputNumber  min={2} max={3000} label="Высота модуля" name="heightText" required={false}/>
          <InputNumber  min={2} max={2000} label="Глубина модуля" name="depthText" required={false}/>
        </>
      }
    </>
  )
}

export default Sizes